<template>
  <div class="c-pcf">
    <div
      v-if="!manualEntry"
      class="row"
      :class="[{ 'h-mb-2': errors.has(stateKey + '.' + prefix + 'postcode') }]"
    >
      <div class="col-auto">
        <div class="c-pcf-input">
          <v-text-input
            :scope="scope"
            :namespace="namespace"
            :placeholder="placeholder"
            :focus-on-mounted="focusOnMounted"
            autocomplete="postal-code postcode"
            validation="required|verify_postcode"
            class="-small-width"
            :uppercase-field="true"
            label="Postcode"
            :field="prefix + 'postcode'"
            :state-key="stateKey"
            maxlength="8"
            @change="reset"
          />
        </div>
      </div>

      <div class="d-none d-sm-flex col-auto align-items-center">
        <div class="c-pcf-button" @click="findAddresses">
          <v-button :disabled="!postcode" type="button" :class="[buttonType]"
            >Find address
          </v-button>
        </div>
      </div>
      <div class="d-flex d-sm-none col-4 col-md-6 p-sm-0 align-items-center">
        <div class="c-pcf-button" @click="findAddresses">
          <v-button type="button" :class="[buttonType]" :disabled="false"
            >Find
          </v-button>
        </div>
      </div>
    </div>
    <transition mode="out-in" name="fade">
      <v-loader v-if="showLoader" class="h-mt-1" />
    </transition>
    <div class="row">
      <div v-if="!manualEntry && showResults" class="col-12 h-mt-1 mb-1">
        <div ref="pcfSelect" class="c-pcf-select">
          <v-select-input
            v-if="showResults"
            :scope="scope"
            :namespace="namespace"
            :options="selectAddresses"
            form="addressSelect"
            :field="prefix + 'address_select'"
            label="Select your address"
            :state-key="stateKey"
            error-name="Address select"
            @selectionUpdated="addressSelected"
          />
        </div>
      </div>
      <div class="col-12">
        <div
          class="type-aside type-link"
          @click="handleManualAddress(!manualEntry)"
        >
          {{ !manualEntry ? "Add address manually" : "Use postcode search" }}
        </div>
      </div>
      <div class="col-12">
        <aside
          v-if="apiErrorMessage"
          class="type-aside h-color-error"
          v-text="apiErrorMessage"
        ></aside>
        <div
          v-if="selectedAddress || manualEntry"
          ref="pcfAddressInput"
          class="h-mb-1 h-mt-1 c-pcf-address-input"
        >
          <div v-if="addressTitle" v-text="addressTitle"></div>

          <v-address-input
            :namespace="namespace"
            :prefix="prefix"
            :state-key="stateKey"
            :scope="scope"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import AddressLookup from "js-admin/Models/AddressLookup";
import VAddressInput from "js-admin/components/form/AddressInput.vue";

export default {
  components: {
    VAddressInput,
  },
  inject: ["$validator"],
  props: {
    stateKey: {
      required: true,
      type: String,
    },
    scope: {
      required: true,
      type: String,
    },
    addressTitle: {
      default: "",
      type: String,
    },
    prefix: {
      default: "",
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    validation: {
      default: "required|verify_postcode",
      type: String,
    },
    buttonType: {
      default: "h-no-outline -small",
      type: String,
    },
    focusOnMounted: {
      default: true,
      type: Boolean,
    },
    namespace: {
      default: null,
      type: String,
    },
  },

  data() {
    return {
      addresses: [],
      selectAddresses: [],
      manualEntry: false,
      selectedAddress: null,
      showResults: false,
      apiErrorMessage: "",
      showLoader: false,
    };
  },
  computed: {
    postcode() {
      return this.$store.state.fieldStore[this.stateKey][
        this.prefix + "postcode"
      ];
    },
  },

  mounted() {
    this.selectedAddress = !!this.postcode;
  },

  methods: {
    handleManualAddress(bool) {
      this.apiErrorMessage = "";
      this.manualEntry = bool;
    },
    reset() {
      this.showResults = false;
      this.addresses = [];
      this.selectAddresses = [];
    },
    addressSelected(val) {
      const selectedAddress = this.addresses[val];

      this.$store.dispatch("updateStoreItem", {
        value: selectedAddress.line_1,
        stateKey: this.stateKey,
        field: this.prefix + "line_1",
      });
      this.$store.dispatch("updateStoreItem", {
        value: selectedAddress.line_2,
        stateKey: this.stateKey,
        field: this.prefix + "line_2",
      });
      this.$store.dispatch("updateStoreItem", {
        value: selectedAddress.line_3,
        stateKey: this.stateKey,
        field: this.prefix + "line_3",
      });
      this.$store.dispatch("updateStoreItem", {
        value: selectedAddress.post_town,
        stateKey: this.stateKey,
        field: this.prefix + "town",
      });
      this.$store.dispatch("updateStoreItem", {
        value: selectedAddress.postal_county,
        stateKey: this.stateKey,
        field: this.prefix + "county",
      });
      this.$store.dispatch("updateStoreItem", {
        value: selectedAddress.postcode,
        stateKey: this.stateKey,
        field: this.prefix + "postcode",
      });
      this.$store.dispatch("updateStoreItem", {
        value: "United Kingdom",
        stateKey: this.stateKey,
        field: this.prefix + "country",
      });

      this.selectedAddress = selectedAddress;

      this.$emit("address-selected", selectedAddress);

      setTimeout(() => {
        VueScrollTo.scrollTo(this.$refs.pcfAddressInput, 500, { offset: -100 });
      }, 100);
    },
    findAddresses() {
      this.reset();

      this.showLoader = true;

      AddressLookup.lookup(this.postcode).then((results) => {
        this.showLoader = false;

        if (results.total > 0) {
          this.addresses = results.addresses;

          this.addresses.forEach((Address, key) => {
            this.selectAddresses.push({
              value: key.toString(),
              title: Address.humanize(),
            });
          });

          this.showResults = true;

          setTimeout(() => {
            VueScrollTo.scrollTo(this.$refs.pcfSelect, 500, { offset: -100 });
          }, 100);
        } else {
          this.apiErrorMessage =
            "We were unable to find your address. Please enter it manually below";

          this.showResults = false;
          this.manualEntry = true;
        }
      });
    },
  },
};
</script>
