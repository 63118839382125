export default {
  setCsvExportFlag(state, bool) {
    state.reports.exportFlag = bool;
  },
  setGenerateReportFlag(state, bool) {
    state.reports.generateFlag = bool;
  },
  setOverdueTasksOnly(state, { reportStateKey, bool }) {
    state.fieldStore[reportStateKey].show_overdue_tasks_only = bool;
  },
  setHideCompletedTasks(state, { reportStateKey, bool }) {
    state.fieldStore[reportStateKey].hide_completed_tasks = bool;
  },
};
