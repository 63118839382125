<template>
  <div class="v-accounts">
    <section v-if="instrumentsReady" class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title mb-5">
            Primary Market Instruments - Drawdown Progress
          </h1>
        </div>
      </div>

      <div class="row align-items-end">
        <div v-if="checkPermission('view_issuer')" class="col-4">
          <div class="mb-3">
            Search: <br />
            Please enter instrument symbol or name.
          </div>
          <form :data-vv-scope="searchScope" @submit.prevent="search">
            <v-text-input
              :scope="searchScope"
              :state-key="searchStateKey"
              field="searchTerm"
              label="Search"
              validation="required"
              error-name="search term"
              class="mr-3 h-mb-1"
            />
            <v-button
              class="c-button -admin-small mb-2"
              :loading="searchInProgress"
              :disabled="searchInProgress"
            >
              Search
            </v-button>

            <a
              v-if="searchPerformed"
              :href="false"
              :loading="isLoading"
              :disabled="isLoading"
              class="c-button -admin-small mb-2 ml-1"
              @click="clearSearch"
            >
              Clear Search
            </a>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header">
              <div class="row">
                <div class="col-2">
                  Name
                </div>
                <div class="col-2">
                  External Id
                </div>
                <div class="col-2">
                  Issuer
                </div>
                <div class="col-2">
                  Status
                </div>
                <div class="col-2">
                  &nbsp;Failed Reason
                </div>
              </div>
            </div>

            <div v-if="instrumentCount">
              <div
                v-for="(instrument, index) in displayedInstruments"
                :key="index"
                class="c-admin-table-row"
              >
                <DrawndownInstrumentsRow :instrument="instrument" />
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  No instruments found
                </div>
              </div>
            </div>

            <div class="c-admin-table-footer">
              <div class="row">
                <div class="col-12">
                  <v-pagination
                    v-model="page"
                    :page-count="pages.length"
                    :classes="bootstrapPaginationClasses"
                    :labels="paginationAnchorTexts"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import vPagination from "vue-plain-pagination";
import PrimaryMarketService from "js-admin/classes/AjaxServices/PrimaryMarket/PrimaryMarketService";
import DrawndownInstrumentsRow from "js-admin/router/views/drawdown-instruments/DrawndownInstrumentsRow.vue";

export default {
  components: {
    DrawndownInstrumentsRow,
    vPagination,
  },

  data() {
    return {
      instruments: null,
      page: 1,
      perPage: 10,
      pages: [],
      isLoading: false,
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      searchScope: "searchForm",
      searchStateKey: "instrumentsList",
      searchInProgress: false,
      searchPerformed: false,
      instrumentCount: null,
      instrumentsReady: false,
    };
  },
  computed: {
    searchTerm() {
      return this.$store.state.fieldStore[this.searchStateKey].searchTerm;
    },
    displayedInstruments() {
      return this.instruments;
    },
  },

  watch: {
    page() {
      this.paginate();
    },

    instruments() {
      this.instrumentsReady = true;
    },

    instrumentCount() {
      this.setPages();
    },
  },

  mounted() {
    if (!this.checkPermission("retrigger_drawdown")) {
      return this.$router.push({ name: "home" });
    }

    this.clearSearch();
  },

  methods: {
    setPages() {
      this.pages = [];
      const numberOfPages = Math.ceil(this.instrumentCount / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
      if (this.page > numberOfPages) {
        this.page = 1;
      }
    },

    loadData() {
      this.page = 1;
      this.paginate();
    },

    paginate() {
      const paginateParams = {
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      };

      const params = {
        source: "asmx",
      };

      if (this.searchTerm) {
        params.term = this.searchTerm;
      }

      PrimaryMarketService.getTriggeredInstruments({
        count: true,
        ...params,
      }).then((response) => {
        if (response.data) {
          this.instrumentCount = response.data.data.instruments_count;
        }
      });

      PrimaryMarketService.getTriggeredInstruments({
        ...paginateParams,
        ...params,
      }).then((response) => {
        if (response.data) {
          this.instruments = response.data.data;
        }
      });
    },

    search() {
      this.$validator.validateAll(this.searchScope).then(async (result) => {
        this.searchPerformed = true;
        if (result) {
          this.$nextTick(() => {
            this.loadData();
          });
        }
      });
    },

    clearSearch() {
      this.searchPerformed = false;

      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.searchStateKey,
        field: "searchTerm",
      });

      this.clearErrors(1);

      this.$nextTick(() => {
        this.loadData();
      });
    },
  },
};
</script>
