<template>
  <div class="v-cash-wallet">
    <div v-if="cashWalletDataReady">
      <section v-if="!cashWalletNotFound" class="c-section">
        <div class="row">
          <div class="col-12">
            <h1 class="c-admin-page-title"> Deposit {{ transferRef }} </h1>
            <a
              :href="false"
              class="c-button -admin-small mt-3 ml-3"
              @click="backToCashWalletPage(cashWalletUuid)"
            >
              Back to Cash Wallet
            </a>
          </div>
        </div>

        <div class="row -column-reverse">
          <!-- Start of left section -->
          <div class="col-lg-7 col-sm-12">
            <VDepositDetails :deposit="transfer" />
          </div>
          <!-- End of left section -->
        </div>

        <div class="row">
          <div class="col-12">
            <v-corrections />
          </div>
        </div>
      </section>
      <div v-else>
        <section class="c-section">
          <div class="row">
            <div class="col-12">
              <h1 class="c-admin-page-title">
                Deposit Not Found
              </h1>
              <div class="mt-5">
                Please use the buttons below to go back to the accounts list.
              </div>
              <div class="mt-3">
                <a
                  :href="false"
                  class="c-button -admin-small"
                  @click="accountsPage"
                >
                  Accounts List
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import VDepositDetails from "js-admin/components/deposits/DepositDetails.vue";
import VCorrections from "js-admin/components/cash-wallet/deposits/corrections/Corrections.vue";
import PaymentService from "js-admin/classes/AjaxServices/PaymentService";

export default {
  components: {
    VDepositDetails,
    VCorrections,
  },
  data() {
    return {
      cashWalletDataReady: false,
      cashWalletNotFound: false,
      stateKey: "transferData",
    };
  },

  computed: {
    transfer() {
      return this.$store.state.fieldStore[this.stateKey];
    },
    cashWalletUuid() {
      return this.$store.state.fieldStore[this.stateKey].cash_wallet_uuid;
    },
    transferRef() {
      if (this.$store.state.fieldStore[this.stateKey].reference) {
        return (
          "(" + this.$store.state.fieldStore[this.stateKey].reference + ")"
        );
      }

      return null;
    },
  },

  async mounted() {
    if (!this.checkPermission("view_manual_bank_transfers")) {
      return this.$router.push({ name: "home" });
    }

    try {
      const bankTransfer = await PaymentService.getBankTransfer(
        this.$route.params.uuid
      );

      this.$store.dispatch("updateStoreItem", {
        value: bankTransfer.data.data,
        stateKey: this.stateKey,
        field: null,
      });
    } catch {
      this.cashWalletNotFound = true;
    } finally {
      this.cashWalletDataReady = true;
    }
  },

  methods: {
    backToAccountPage(uuid) {
      this.$router.push({ name: "account", params: { uuid } });
    },

    backToCashWalletPage(uuid) {
      this.$router.push({ name: "cash-wallet", params: { uuid } });
    },

    accountsPage() {
      this.$router.push({ name: "accounts" });
      return false;
    },
  },
};
</script>
