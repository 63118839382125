<template>
  <div class="v-profile">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Audit Log Report
          </h1>
        </div>
      </div>

      <!-- Search -->
      <v-date-search
        ref="searchDate"
        :scope="reportScope"
        :mode="mode"
        :submit-results="submitResults"
        @get-results="getResults"
      />

      <!-- CSV Export -->
      <v-csv-export
        v-if="reportData"
        class="mt-4"
        @start-csv-export="handleExport"
      />

      <!-- Full Report -->
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header -bold">
              <div class="row">
                <div class="col-2">
                  Date
                </div>
                <div class="col-2">
                  Account Name
                </div>
                <div class="col-3">
                  Type
                </div>
                <div class="col">
                  Description
                </div>
              </div>
            </div>
            <div v-if="!submitResults">
              <div v-if="reportResults">
                <div
                  v-for="data in reportResults"
                  :key="data.uuid"
                  class="c-admin-table-row"
                >
                  <div class="row">
                    <div class="col-2">
                      {{ dateTimeFormat(data.created_at) }}
                    </div>
                    <div class="col-2">
                      <router-link
                        class="c-link"
                        :to="`/account/${data.account_uuid}`"
                      >
                        {{ data.account_name }}
                      </router-link>
                    </div>
                    <div class="col-3">
                      {{ data.type }}
                    </div>
                    <div class="col">
                      {{ data.description }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="c-admin-table-row">
                <div class="row">
                  <div class="col-12">
                    No results found.
                  </div>
                </div>
              </div>
              <div class="c-admin-table-footer -bold">
                <div class="row">
                  <div class="col-10 text-right">
                    Total:
                  </div>
                  <div class="col-2">
                    {{ reportTotal }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  <v-loader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CSV Export -->
      <v-csv-export
        v-if="reportData"
        class="mt-4"
        @start-csv-export="handleExport"
      />
    </section>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VDateSearch from "js-admin/components/Search/DateSearch";
import ReportingService from "js-admin/classes/AjaxServices/ReportingService";
import VCsvExport from "js-admin/components/csv-export/CsvExport";
import mixinReport from "js-admin/mixins/mixinReport";

export default {
  components: {
    VCsvExport,
    VDateSearch,
  },
  mixins: [mixinReport],
  data() {
    return {
      reportScope: "reportSearch",
      reportStateKey: "reportSearch",
      reportData: null,
      submitResults: false,
      currentDate: new Date(),
      mode: "single",
    };
  },
  computed: {
    reportResults() {
      return this.reportData;
    },
    reportTotal() {
      let total = 0;
      if (this.reportResults) {
        total = this.reportResults.length;
      }
      return total;
    },
  },
  mounted() {
    if (!this.checkPermission("reporting_level1")) {
      return this.$router.push({ name: "home" });
    }
  },
  methods: {
    async getResults(e = null, downloadCsv = false) {
      let asCsv = {};
      if (downloadCsv) {
        asCsv = {
          as_csv: true,
        };
      } else {
        this.submitResults = true;
      }

      let data = this.formatDateData(
        this.$refs.searchDate.fromDate,
        this.$refs.searchDate.toDate
      );
      data = { ...data, ...asCsv };

      return ReportingService.getAuditLogsReport(data)
        .then((response) => {
          this.submitResults = false;
          if (response.status === 204) {
            this.reportData = [];
            return;
          }

          if (downloadCsv) {
            return {
              response,
              label: "Audit_Logs_Report",
            };
          } else {
            this.reportData = response.data.data;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },
  },
};
</script>
