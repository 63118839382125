<template>
  <div class="v-account">
    <div v-if="issuerLoadedData">
      <section v-if="!issuer404" class="c-section">
        <div class="row align-items-start">
          <div class="col-lg-7 col-sm-12">
            <h1 class="c-admin-page-title"> Issuer - {{ issuer.name }} </h1>
            <a
              :href="false"
              class="c-button -secondary -sidebar"
              @click="issuersPage"
            >
              <strong>
                Back to Issuers List
              </strong>
            </a>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <VUpdateIssuerForm />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <VInstruments :issuer-uuid="issuer.uuid" />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <VCarousel :issuer-uuid="issuer.uuid" />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <VIssuerRssFeeds :issuer-uuid="issuer.uuid" />
          </div>
          <div class="col-12">
            <VDocuments
              v-if="checkPermission('view_files')"
              :state-key="stateKey"
              :issuer-uuid="issuer.uuid"
            />
          </div>
        </div>
      </section>
      <div v-else>
        <section class="c-section">
          <div class="row">
            <div class="col-12">
              <h1 class="c-admin-page-title">
                Issuer Not Found
              </h1>
              <div class="mt-4">
                Please use the buttons below to go back to the issuers list.
              </div>
              <div class="mt-4">
                <a
                  :href="false"
                  class="c-button -admin-small"
                  @click="issuersPage"
                >
                  Back to Issuers List
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import VCarousel from "js-admin/components/issuers/carousel-slides/Carousel";
import VDocuments from "js-admin/components/issuers/issuer-documents/Documents.vue";
import VInstruments from "js-admin/components/issuers/instruments/LinkedInstruments.vue";
import VUpdateIssuerForm from "js-admin/components/issuers/UpdateIssuerForm.vue";
import VIssuerRssFeeds from "js-admin/components/issuers/issuer-rss/LinkedRssFeeds";

export default {
  components: {
    VCarousel,
    VDocuments,
    VInstruments,
    VIssuerRssFeeds,
    VUpdateIssuerForm,
  },
  data() {
    return {
      issuerDataReady: false,
      issuerNotFound: false,
      stateKey: "issuerData",
      contactStateKey: "issuerContactData",
    };
  },

  computed: {
    issuer() {
      return this.$store.state.fieldStore[this.stateKey];
    },
    issuerLoadedData() {
      return this.issuerDataReady;
    },
    issuer404() {
      return this.issuerNotFound;
    },
  },

  mounted() {
    if (!this.checkPermission("view_issuer")) {
      return this.$router.push({ name: "home" });
    }

    this.fetchIssuer();
  },

  methods: {
    async fetchIssuer() {
      await this.$store
        .dispatch("fetchIssuerData", this.$route.params.uuid)
        .then((response) => {
          this.$store.dispatch("updateStoreItem", {
            value: response.data.data,
            stateKey: this.stateKey,
            field: null,
          });

          this.$store.dispatch("updateStoreItem", {
            value: response.data.data.primary_contact,
            stateKey: this.contactStateKey,
            field: null,
          });
        })
        .catch(() => {
          this.issuerNotFound = true;
        });
      this.issuerDataReady = true;
    },
    issuersPage() {
      this.$router.push({ name: "issuers" });
      return false;
    },
  },
};
</script>
