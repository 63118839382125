export default {
  mergeAccount: {
    accountSelect: null,
    accountArray: [],
    headerArray: [
      {
        title: "Owner",
        value: "owner_uuid",
      },
      {
        title: "Title",
        value: "title",
      },
      {
        title: "First Name",
        value: "first_name",
      },
      {
        title: "Middle Name",
        value: "middle_name",
      },
      {
        title: "Last Name",
        value: "last_name",
      },
      {
        title: "Email",
        value: "email",
      },
      {
        title: "Primary Contact Number",
        value: "primary_contact_number",
      },
      {
        title: "Secondary Contact Number",
        value: "secondary_contact_number",
      },
      {
        title: "Date of Birth",
        value: "dob",
      },
      {
        title: "National Insurance",
        value: "national_insurance_number",
      },
      {
        title: "Nationality",
        value: "nationality",
      },
      {
        title: "Type",
        value: "type",
      },
      {
        title: "Date notified of death",
        value: "date_notified_of_death",
      },
      {
        title: "Closed on",
        value: "closed_on",
      },
      {
        title: "GDPR Marketing",
        value: "gdpr_optin_flag",
      },
      {
        title: "GDPR Marketing Optin DateTime",
        value: "gdpr_optin_datetime",
      },
      {
        title: "GDPR Marketing Optout DateTime",
        value: "gdpr_optout_datetime",
      },
      {
        title: "Marketing notifications",
        value: "subscribed_marketing_notifications",
      },
      {
        title: "Product notifications",
        value: "subscribed_product_notifications",
      },
      {
        title: "Industry news notifications",
        value: "subscribed_industry_news_notifications",
      },
      {
        title: "Contact Method - email",
        value: "subscribed_email",
      },
      {
        title: "Contact Method - sms",
        value: "subscribed_sms",
      },
      {
        title: "Contact Method - phone",
        value: "subscribed_phone",
      },
      {
        title: "Contact Method - post",
        value: "subscribed_post",
      },
    ],
  },
};
