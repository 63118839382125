//add class '-loading' to activate loading spinner //options: // • '-loading' =
activate loading spinner // • '-sans' = sans font with bold // • '-caps' = all
uppercase // • '-margin-auto' = centered by margin auto // • '-small' = smaller

<template>
  <button
    ref="main"
    class="c-button"
    :class="{ '-loading': loading }"
    :disabled="disabled"
    :type="type"
  >
    <svg
      v-if="loading"
      class="ani-rotating c-button-loader"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 38"
      stroke="#fff"
    >
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)" stroke-width="2">
          <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
          <path
            d="M36 18c0-9.94-8.06-18-18-18"
            transform="rotate(33.8962 18 18)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
    <span v-else class="c-button-text">
      <slot />
    </span>
    <svg
      v-if="cta"
      class="c-button-arrow"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="18"
      height="18"
      viewBox="0 0 451.846 451.847"
      style=" fill: #000;"
    >
      <g>
        <path
          d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
            L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
            c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"
        />
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    cta: {
      default: false,
    },
    type: {
      default: "submit",
    },
    loading: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
};
</script>
