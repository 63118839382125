<template>
  <div v-if="visible" class="row justify-content-center">
    <div class="col-8">
      <div class="c-admin-panel" :class="boxClass">
        <div class="c-admin-panel-section">
          <div>
            <button
              type="button"
              class="c-admin-panel-close-box-icon"
              @click="closeBox"
            >
              <svg
                width="12"
                height="12"
                version="1.1"
                class="c-modal-close-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 47.971 47.971"
                style="enable-background: new 0 0 47.971 47.971;"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                  c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                  C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                  s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
                  />
                </g>
              </svg>
            </button>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visibility: {
      default: true,
      type: Boolean,
    },
    boxClass: {
      default: "-success-box",
      type: String,
    },
  },

  data() {
    return {
      hideBox: false,
    };
  },

  computed: {
    visible() {
      return this.visibility && !this.hideBox;
    },
  },

  methods: {
    closeBox() {
      this.hideBox = true;
      this.$emit("closeBox");
    },
  },
};
</script>
