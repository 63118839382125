<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title> Calls ({{ callCount }}) </template>

        <div v-if="dataReady" class="mt-2">
          <div class="c-admin-table">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-1"> Started At </div>
                <div class="col-2"> From </div>
                <div class="col-2"> To </div>
                <div class="col-1"> Direction </div>
                <div class="col-1"> Status </div>
                <div class="col-1"> Duration </div>
                <div class="col-1"> Call Disposition </div>
                <div class="col-1"> Caller/Answered </div>
                <div class="col-2" />
              </div>
            </div>
            <div v-if="accountCalls.length">
              <div
                v-for="(call, index) in accountCalls"
                :key="index"
                class="c-admin-table-row"
              >
                <VLinkedCallsRow :call="call" :index="index" />
              </div>
            </div>
            <div v-else class="c-admin-table-row"> No calls </div>
          </div>
          <div v-if="accountCalls.length" class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-model="page"
                  :page-count="pages.length"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VPagination from "vue-plain-pagination";
import AccountService from "js-admin/classes/AjaxServices/AccountService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VLinkedCallsRow from "js-admin/components/accounts/calls/LinkedCallsRow.vue";

export default {
  components: {
    VPagination,
    VLinkedCallsRow,
  },

  data() {
    return {
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      calls: null,
      callCount: null,
      accountUuid: this.$store.state.fieldStore.accountData.uuid,
    };
  },

  computed: {
    accountCalls() {
      return this.calls;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    return AccountService.getAccountCalls({
      uuid: this.accountUuid,
      return_count: true,
    })
      .then((response) => {
        if (response.data.data) {
          this.callCount = response.data.data.call_count;
          this.setPages();
        }
      })
      .catch((error) => {
        FlashMessageService.error(error.response.data.data.message);
      });
  },

  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },

    paginate() {
      this.dataReady = false;

      return AccountService.getAccountCalls({
        uuid: this.accountUuid,
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      })
        .then((response) => {
          if (response.data) {
            this.calls = response.data.data;
            this.dataReady = true;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(this.callCount, this.perPage);
    },
  },
};
</script>
