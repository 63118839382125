import APIService from "js-admin/classes/APIService";

export default class CompanyService {
  static CRUD_URL = "companies";

  static async updateCompany(data) {
    const header = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return APIService.request(
      this.CRUD_URL + "/" + data.get("uuid"),
      "post",
      data,
      header
    );
  }
}
