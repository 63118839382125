<template>
  <div class="c-admin-app" :class="[clientClass]">
    <main v-if="!loading" role="main">
      <VNotificationBox
        ref="taskNotification"
        :channel-name="channelName"
        :channel-broadcast="channelReminder"
        :view-account-flag="true"
        notification-name="Reminder"
        notification-of="Reminder for"
      >
        <template v-slot:title>
          Task Reminder
        </template>
      </VNotificationBox>
      <VSidebar></VSidebar>

      <div class="c-cd-container">
        <VEnableTwoFactorBox v-if="showTwoFactorMessage" />
        <transition mode="out-in" name="fade">
          <router-view key="0"></router-view>
        </transition>
      </div>

      <VFullscreenPanel></VFullscreenPanel>
      <VPhoneCallBox v-if="!systemFeatureIsDisabled('disabled_phone_system')" />
    </main>
    <v-loader v-else></v-loader>
  </div>
</template>

<script>
import VSidebar from "js-admin/components/layout/sidebar/Sidebar.vue";
import VFullscreenPanel from "js-admin/components/layout/fullscreenPanel/FullscreenPanel.vue";
import VPhoneCallBox from "js-admin/components/phoneCallBox/PhoneCallBox.vue";
import VNotificationBox from "js-admin/components/notifications/notification-box.vue";
import VEnableTwoFactorBox from "js-admin/components/layout/two-factor-box/EnableTwoFactorBox.vue";

export default {
  name: "Admin",
  components: {
    VSidebar,
    VFullscreenPanel,
    VPhoneCallBox,
    VNotificationBox,
    VEnableTwoFactorBox,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    configSet() {
      return this.$store.state.config.company_info;
    },
    clientClass() {
      return "-" + window.whiteLabelClient;
    },
    showTwoFactorMessage() {
      return !!(
        this.$store.state.me && !this.$store.state.user.two_factor_required
      );
    },
    channelName() {
      return window.pusher.task_channel_name;
    },
    channelBroadcast() {
      return window.pusher.task_channel_broadcast;
    },
    channelReminder() {
      return window.pusher.task_channel_reminder;
    },
  },
  watch: {
    configSet() {
      this.loading = false;
    },
  },
  created() {
    this.$store.dispatch("setConfigData");
    this.hideRecaptcha();
  },
};
</script>

<style lang="scss">
@import "~sass-admin/admin-dash.scss";
</style>
