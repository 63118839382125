<template>
  <div class="c-admin-table-row">
    <div class="row">
      <div class="col-3">
        {{ owner.name }}
      </div>
      <div class="col-3">
        <span v-if="owner.completed_apps_last_assigned_at">
          {{ dateTimeFormat(owner.completed_apps_last_assigned_at) }}
        </span>
        <span v-else> N.A.</span>
      </div>
      <div class="col-4">
        <div v-if="!showEditForm">
          <div class="row align-items-center">
            <div class="col-4">
              {{ capitaliseFirstLetter(owner.status) }}
            </div>
            <div class="col-2">
              <a
                :href="false"
                class="c-button -admin-small ml-4"
                @click="showForm"
              >
                Edit
              </a>
            </div>
          </div>
        </div>
        <div v-else>
          <v-select-input
            label="Phone status:"
            :scope="amStatusScope"
            :state-key="stateKey"
            :options="this.$store.state.config.owner_phone_statuses"
            :center-error="false"
            field="status"
            validation="required"
            error-name="status"
            placeholder="Phone Status"
            class="mb-4"
            @selectionUpdated="updateAMStatus"
          />
        </div>
      </div>
      <div class="col-2 text-center">
        <form @submit.prevent="moveOwnerToTop">
          <v-button
            :loading="isLoading"
            :disabled="isLoading"
            class="-admin-small"
          >
            Move to top
          </v-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    owner: {
      required: true,
    },
  },
  data() {
    return {
      stateKey: "amStatus",
      amStatusScope: "amStatusScope",
      isLoading: false,
      updateInProgress: false,
      showEditForm: false,
    };
  },

  methods: {
    showForm() {
      this.$store.dispatch("updateStoreItem", {
        value: this.owner.status,
        stateKey: this.stateKey,
        field: "status",
      });

      this.$parent.toggle(this);
    },

    updateAMStatus(val) {
      this.$store.dispatch("updateAMOwner", {
        owner_uuid: this.owner.uuid,
        status: val,
      });

      this.showEditForm = false;

      FlashMessageService.success(
        this.owner.name +
          " status updated to: " +
          this.capitaliseFirstLetter(val)
      );
    },
    moveOwnerToTop() {
      this.$parent.resetForms();

      this.isLoading = true;
      this.$store
        .dispatch("moveAMToTopRoundRobin", this.owner.uuid)
        .then((response) => {
          if (response.data) {
            this.isLoading = false;
          }
        });
    },
  },
};
</script>
