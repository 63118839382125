const url = window.location.host;
let wlClientName = url.split(".")[1];

if (wlClientName === "dev") {
  wlClientName = url.split(".")[2];
}

if (wlClientName) {
  window.whiteLabelClient = wlClientName;
  window.whiteLabelClientApiUrl = cleanUrl(url);
  window.whiteLabelClientPlatformUrl = cleanPlatformUrl(url);
  changeFavicon(wlClientName);
  document.title = wlClientName + " Admin Dashboard";
}

function cleanUrl(baseUrl) {
  return window.apiUrls.api.replace(
    "[whiteLabelClient]",
    baseUrl.replace(/:3000|admin.|admin.dev./gi, "")
  );
}

function cleanPlatformUrl(baseUrl) {
  return window.apiUrls.platform.replace(
    "[whiteLabelClient]",
    baseUrl.replace(/:3000|admin.|admin.dev.|admin.stg.|dev.|stg./gi, "")
  );
}

function changeFavicon(clientName) {
  const faviconElement = document.getElementById("favicon32by32");
  const faviconElement2 = document.getElementById("favicon16by16");
  const faviconElement3 = document.getElementById("faviconIcon");
  const faviconElement4 = document.getElementById("faviconAppleIcon");
  const faviconElement5 = document.getElementById("manifest");
  const faviconElement6 = document.getElementById("faviconSafari");
  const faviconElement7 = document.getElementById("browserConfig");
  const faviconElement8 = document.getElementById("appleIconTitle");

  faviconElement.setAttribute(
    "href",
    "/images/favicons/" + clientName + "/favicon-32x32.png"
  );
  faviconElement2.setAttribute(
    "href",
    "/images/favicons/" + clientName + "/favicon-16x16.png"
  );
  faviconElement3.setAttribute(
    "href",
    "/images/favicons/" + clientName + "/favicon.ico"
  );
  faviconElement4.setAttribute(
    "href",
    "/images/favicons/" + clientName + "/apple-touch-icon.png"
  );
  faviconElement5.setAttribute(
    "href",
    "/images/favicons/" + clientName + "/site.webmanifest"
  );
  faviconElement6.setAttribute(
    "href",
    "/images/favicons/" + clientName + "/safari-pinned-tab.svg"
  );
  faviconElement7.setAttribute(
    "href",
    "/images/favicons/" + clientName + "/browserconfig.xml"
  );
  faviconElement8.setAttribute("content", clientName + " | Admin Dashboard");
}
