<template>
  <div class="row">
    <div class="col-12">
      <div class="c-csv-export">
        <v-button
          :loading="getCsvExportFlag"
          :disabled="getCsvExportFlag"
          class="c-button -admin-small"
          type="button"
          name="button"
          @click.native="handleExport"
          >{{ getCsvExportFlag ? "Working..." : "Export CSV" }}</v-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    type: {
      type: String,
      default: "suitability",
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["getCsvExportFlag"]),
  },
  methods: {
    ...mapMutations(["setCsvExportFlag"]),
    handleExport() {
      this.setCsvExportFlag(!this.getCsvExportFlag);
      this.$emit("start-csv-export");
    },
  },
};
</script>
