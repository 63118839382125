<template>
  <div class="v-profile">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title"> Call Recordings </h1>
        </div>
      </div>

      <!-- Search -->
      <div class="row">
        <div class="col-12">
          <div class="c-admin-panel">
            <v-accordion :open-first-item="true">
              <v-accordion-item>
                <template v-slot:title> Search </template>
                <form :data-vv-scope="reportScope" @submit.prevent="getResults">
                  <div class="row align-items-center">
                    <div class="col-6">
                      From:
                      <DatePicker key="0" v-model="fromDate" :mode="mode" />
                    </div>
                    <div class="col-6">
                      To:
                      <DatePicker key="0" v-model="toDate" :mode="mode" />
                    </div>
                  </div>
                  <div class="row align-items-center mt-4">
                    <div class="col-6">
                      <div> Account Manager </div>
                      <v-select-input
                        :scope="reportScope"
                        :state-key="reportStateKey"
                        :options="ownerData"
                        :center-error="false"
                        field="account_manager_uuid"
                        error-name="account manager"
                        placeholder="Select a account manager"
                        class="-centered mb-4"
                        validation=""
                        :enable-blank-option="true"
                      />
                    </div>
                    <div class="col-6">
                      <v-button
                        class="-admin-small"
                        :loading="submitResults"
                        :disabled="submitResults"
                      >
                        Search
                      </v-button>
                    </div>
                  </div>
                </form>
              </v-accordion-item>
            </v-accordion>
          </div>
        </div>
      </div>

      <!-- Summary-->
      <div v-if="reportSummary" class="row">
        <div class="col-12">
          <div class="c-admin-panel">
            <v-accordion :open-first-item="true">
              <v-accordion-item>
                <template v-slot:title> Summary </template>
                <VCallStatsSummary :summary-data="reportSummary" />
              </v-accordion-item>
            </v-accordion>
          </div>
        </div>
      </div>

      <!-- Full Report -->
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header -bold">
              <div class="row">
                <div class="col-1"> Date </div>
                <div class="col-1"> Client Name </div>
                <div class="col-1"> Telephone Number </div>
                <div class="col-1"> Duration </div>
                <div class="col-1"> Direction </div>
                <div class="col-1"> Status </div>
                <div class="col-2"> Call Disposition </div>
                <div class="col-2"> Account Manager </div>
                <div class="col-2"> Recording </div>
              </div>
            </div>
            <div v-if="!submitResults">
              <div v-if="reportResults">
                <div
                  v-for="data in reportResults"
                  :key="data.owner_uuid"
                  class="c-admin-table-row"
                >
                  <VComplianceCallReportRow :data="data" />
                </div>
              </div>
              <div v-else class="c-admin-table-row">
                <div class="row">
                  <div class="col-12"> No results found. </div>
                </div>
              </div>
              <div class="c-admin-table-footer -bold">
                <div class="row">
                  <div class="col-9 text-right"> Total: </div>
                  <div class="col-3">
                    {{ reportTotal }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  <v-loader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import ReportingService from "js-admin/classes/AjaxServices/ReportingService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VComplianceCallReportRow from "js-admin/components/call-stats/ComplianceCallReportRow.vue";
import VCallStatsSummary from "js-admin/components/call-stats/CallStatsSummary.vue";

export default {
  components: {
    DatePicker,
    VComplianceCallReportRow,
    VCallStatsSummary,
  },

  data() {
    return {
      reportScope: "reportSearch",
      reportStateKey: "reportSearch",
      reportData: null,
      summaryData: null,
      submitResults: false,
      currentDate: new Date(),
      mode: "single",
    };
  },
  computed: {
    fromDate: {
      get() {
        if (
          this.$store.state.fieldStore[this.reportStateKey].from_date == null
        ) {
          return new Date(
            this.currentDate.getUTCFullYear(),
            this.currentDate.getUTCMonth(),
            1
          );
        }
        return this.$store.state.fieldStore[this.reportStateKey].from_date;
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.reportStateKey,
          field: "from_date",
        });
      },
    },
    toDate: {
      get() {
        if (this.$store.state.fieldStore[this.reportStateKey].to_date == null) {
          return new Date(
            this.currentDate.getUTCFullYear(),
            this.currentDate.getUTCMonth() + 1,
            0,
            23,
            59,
            59
          );
        }
        return this.$store.state.fieldStore[this.reportStateKey].to_date;
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.reportStateKey,
          field: "to_date",
        });
      },
    },
    ownerData() {
      return this.$store.state.agents;
    },
    reportResults() {
      return this.reportData;
    },
    reportTotal() {
      let total = 0;
      if (this.reportResults) {
        total = this.reportResults.length;
      }
      return total;
    },
    reportSummary() {
      return this.summaryData;
    },
  },
  mounted() {
    if (
      !this.checkPermission("compliance_view_call_recordings") ||
      this.systemFeatureIsDisabled("disabled_phone_system")
    ) {
      return this.$router.push({ name: "home" });
    }
    this.$store.dispatch("fetchOwners");
  },
  methods: {
    getResults() {
      const startDate =
        this.$store.state.fieldStore[this.reportStateKey].from_date ||
        this.fromDate;
      const endDate =
        this.$store.state.fieldStore[this.reportStateKey].to_date ||
        this.toDate;

      if (endDate < startDate) {
        FlashMessageService.error("The To date is less than the From date");
        return;
      }

      const data = {
        from_date: this.formattedDateForApi(startDate, "00:00:00"),
        to_date: this.formattedDateForApi(endDate, "23:59:59"),
      };

      if (
        this.$store.state.fieldStore[this.reportStateKey]
          .account_manager_uuid &&
        this.$store.state.fieldStore[this.reportStateKey]
          .account_manager_uuid !== "Select a account manager"
      ) {
        data.account_manager_uuid = this.$store.state.fieldStore[
          this.reportStateKey
        ].account_manager_uuid;
      }

      this.submitResults = true;
      ReportingService.getComplianceCallStats(data)
        .then((response) => {
          this.submitResults = false;
          this.reportData = response.data.data.results;
          this.summaryData = response.data.data.summary;
        })
        .catch((error) => {
          this.submitResults = false;
          FlashMessageService.error(error.response.data.message);
        });
    },
  },
};
</script>
