<template>
  <div class="v-profile">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Accounts Held Report
          </h1>
        </div>
      </div>

      <!-- Search -->
      <v-date-select-search
        ref="searchDate"
        :scope="reportScope"
        :report-state-key="reportStateKey"
        :select-data="ownerData"
        :mode="mode"
        :submit-results="submitResults"
        @get-results="getResults"
      />

      <!-- Summary -->
      <div v-if="reportSummary" class="row">
        <div class="col-12">
          <div class="c-admin-panel">
            <v-accordion :open-first-item="true">
              <v-accordion-item>
                <template v-slot:title>
                  Summary
                </template>

                <div v-if="!submitResults" class="c-admin-table mt-4">
                  <div class="c-admin-table-header -bold">
                    <div class="row">
                      <div class="col-6">
                        Account Manager
                      </div>
                      <div class="col-6">
                        Total
                      </div>
                    </div>
                  </div>
                  <div v-if="reportSummary">
                    <div
                      v-for="(data, index) in reportSummary"
                      :key="index"
                      class="c-admin-table-row"
                    >
                      <div class="row">
                        <div class="col-6">
                          {{ data.owner }}
                        </div>
                        <div class="col-6">
                          {{ data.total }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="c-admin-table-row">
                    <div class="row">
                      <div class="col-12">
                        No results found.
                      </div>
                    </div>
                  </div>
                  <div class="c-admin-table-footer -bold">
                    <div class="row">
                      <div class="col-6 text-right">
                        Total:
                      </div>
                      <div class="col-6">
                        {{ summaryTotal }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-accordion-item>
            </v-accordion>
          </div>
        </div>
      </div>

      <!-- CSV Export -->
      <v-csv-export
        v-if="reportData"
        class="mt-4"
        @start-csv-export="handleExport"
      />

      <!-- Full Report -->
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header -bold">
              <div class="row">
                <div class="col-2">
                  Date
                </div>
                <div class="col-3">
                  Account Name
                </div>
                <div class="col-2">
                  Investor Type
                </div>
                <div class="col-2">
                  Account Type
                </div>
                <div class="col-1">
                  Status
                </div>
                <div class="col-2">
                  Account Manager
                </div>
              </div>
            </div>
            <div v-if="!submitResults">
              <div v-if="reportResults">
                <div
                  v-for="data in reportResults"
                  :key="data.owner_uuid"
                  class="c-admin-table-row"
                >
                  <div class="row">
                    <div class="col-2">
                      {{ dateTimeFormat(data.created_at) }}
                    </div>
                    <div class="col-3">
                      <router-link
                        class="c-link"
                        :to="`/account/${data.account_uuid}`"
                      >
                        {{ data.account_name }}
                      </router-link>
                    </div>
                    <div class="col-2">
                      {{ data.investor_type }}
                    </div>
                    <div class="col-2">
                      {{ data.type }}
                    </div>
                    <div class="col-1">
                      {{ data.status }}
                    </div>
                    <div class="col-2">
                      {{ data.owner }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="c-admin-table-row">
                <div class="row">
                  <div class="col-12">
                    No results found.
                  </div>
                </div>
              </div>
              <div class="c-admin-table-footer -bold">
                <div class="row">
                  <div class="col-10 text-right">
                    Total:
                  </div>
                  <div class="col-2">
                    {{ reportTotal }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  <v-loader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CSV Export -->
      <v-csv-export
        v-if="reportData"
        class="mt-4"
        @start-csv-export="handleExport"
      />
    </section>
  </div>
</template>

<script>
import VDateSelectSearch from "js-admin/components/Search/DateSelectSearch";
import ReportingService from "js-admin/classes/AjaxServices/ReportingService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

import VCsvExport from "js-admin/components/csv-export/CsvExport";
import mixinReport from "js-admin/mixins/mixinReport";

export default {
  components: {
    VDateSelectSearch,
    VCsvExport,
  },
  mixins: [mixinReport],
  data() {
    return {
      reportScope: "reportSearch",
      reportStateKey: "reportSearch",
      reportData: null,
      summaryData: null,
      submitResults: false,
      currentDate: new Date(),
      mode: "single",
    };
  },
  computed: {
    ownerData() {
      return this.$store.state.agents;
    },
    reportResults() {
      return this.reportData;
    },
    reportTotal() {
      let total = 0;
      if (this.reportResults) {
        total = this.reportResults.length;
      }
      return total;
    },
    reportSummary() {
      return this.summaryData;
    },
    summaryTotal() {
      let total = 0;
      if (this.reportSummary) {
        for (let i = 0; i < this.reportSummary.length; i++) {
          total += this.reportSummary[i].total;
        }
      }
      return total;
    },
  },
  mounted() {
    if (!this.checkPermission("reporting_level2")) {
      return this.$router.push({ name: "home" });
    }
    this.$store.dispatch("fetchOwners");
  },
  methods: {
    async getResults(e = null, downloadCsv = false) {
      let asCsv = {};
      if (downloadCsv) {
        asCsv = {
          as_csv: true,
        };
      } else {
        this.submitResults = true;
      }

      let data = this.formatDateData(
        this.$refs.searchDate.fromDate,
        this.$refs.searchDate.toDate
      );
      data = { ...data, ...asCsv };

      if (
        this.$store.state.fieldStore[this.reportStateKey]
          .account_manager_uuid &&
        /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/gi.test(
          this.$store.state.fieldStore[this.reportStateKey].account_manager_uuid
        )
      ) {
        data.account_manager_uuid = this.$store.state.fieldStore[
          this.reportStateKey
        ].account_manager_uuid;
      }

      return ReportingService.getAccountsHeldReport(data)
        .then((response) => {
          this.submitResults = false;

          if (response.status === 204) {
            this.reportData = [];
            return;
          }

          if (downloadCsv) {
            return {
              response,
              label: "Account_Held_Report",
            };
          } else {
            this.reportData = response.data.data.results;
            this.summaryData = response.data.data.summary;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },
  },
};
</script>
