<template>
  <div class="v-account">
    <div v-if="instrumentLoadedData">
      <section v-if="!instrument404" class="c-section">
        <div class="row align-items-start">
          <div class="col-lg-7 col-sm-12">
            <h1 class="c-admin-page-title">
              {{ instrument.name }}
            </h1>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <PrimaryMarketInfo
              :instrument="instrument"
              :show-more-button="false"
              title="Key Instrument Information"
            />

            <div class="c-admin-panel mt-2">
              <div class="c-admin-panel-section">
                <h3 class="c-admin-panel-title">
                  Display Info
                </h3>
                <div class="row">
                  <div v-if="instrument.marketing_live_date" class="col-6">
                    <p>
                      Marketing Live Date Time:
                      <strong>
                        {{ dateTimeFormat(instrument.marketing_live_date) }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.marketing_end_date" class="col-6">
                    <p>
                      Marketing End Date Time:
                      <strong>
                        {{ dateTimeFormat(instrument.marketing_end_date) }}
                      </strong>
                    </p>
                  </div>
                  <div
                    v-if="instrument.start_accepting_bids_date"
                    class="col-6"
                  >
                    <p>
                      Start Accepting Bids Date Time:
                      <strong>
                        {{
                          dateTimeFormat(instrument.start_accepting_bids_date)
                        }}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="c-admin-panel mt-2">
              <div class="c-admin-panel-section">
                <h3 class="c-admin-panel-title">
                  Other Details
                </h3>
                <div class="row">
                  <div v-if="instrument.instrument_number" class="col-5">
                    <p>
                      Instrument Number:
                      <strong>
                        {{ instrument.instrument_number }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.cfi_code" class="col-5">
                    <p>
                      CFI Code:
                      <strong>
                        {{ instrument.cfi_code }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.investor_category" class="col-5">
                    <p>
                      Instrument Client Category:
                      <strong>
                        {{
                          stripUnderScoresAndCapitalise(
                            instrument.investor_category
                          )
                        }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.instrument_category" class="col-5">
                    <p>
                      Instrument Category:
                      <strong>
                        {{
                          stripUnderScoresAndCapitalise(
                            instrument.instrument_category
                          )
                        }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.instrument_sub_category" class="col-5">
                    <p>
                      Instrument Sub-Category:
                      <strong>
                        {{
                          stripUnderScoresAndCapitalise(
                            instrument.instrument_sub_category
                          )
                        }}
                      </strong>
                    </p>
                  </div>

                  <div v-if="isInstrumentDebtType" class="col-5">
                    <p>
                      Loan Type:
                      <strong>
                        {{
                          stripUnderScoresAndCapitalise(instrument.debt_type)
                        }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="isInstrumentDebtType" class="col-5">
                    <p>
                      Return:
                      <strong> {{ instrument.debt_interest_rate }}% </strong>
                    </p>
                  </div>
                  <div v-if="isInstrumentDebtType" class="col-5">
                    <p>
                      Term:
                      <strong>
                        {{ instrument.debt_term }} month{{
                          instrument.debt_term > 1 ? "s" : ""
                        }}
                      </strong>
                    </p>
                  </div>

                  <div v-if="instrument.distributions" class="col-5">
                    <p>
                      Distributions:
                      <strong>
                        {{ capitaliseFirstLetter(instrument.distributions) }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.stock_exchange" class="col-5">
                    <p>
                      Exchange:
                      <strong>
                        {{
                          stripUnderScoresAndCapitalise(
                            instrument.stock_exchange
                          )
                        }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.isin" class="col-5">
                    <p>
                      ISIN:
                      <strong>
                        {{ capitaliseFirstLetter(instrument.isin) }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.mic" class="col-5">
                    <p>
                      MIC:
                      <strong>
                        {{ capitaliseFirstLetter(instrument.mic) }}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <ScheduledRepayments :instrument="instrument" />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <ActualRepayments :instrument="instrument" />
          </div>
        </div>
      </section>
      <div v-else>
        <section class="c-section">
          <div class="row">
            <div class="col-12">
              <h1 class="c-admin-page-title">
                Instrument Not Found
              </h1>
              <div class="mt-4">
                Please use the nav buttons below to go back to previous page.
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import ActualRepayments from "js-admin/components/instrument/primary-market/actual-repayments/ActualRepayments.vue";
import PrimaryMarketService from "js-admin/classes/AjaxServices/PrimaryMarket/PrimaryMarketService";
import PrimaryMarketInfo from "js-admin/components/instrument/primary-market/PrimaryMarketInfo.vue";
import ScheduledRepayments from "js-admin/components/instrument/primary-market/scheduled-repayments/ScheduledRepayments.vue";

export default {
  components: {
    ActualRepayments,
    PrimaryMarketInfo,
    ScheduledRepayments,
  },

  data() {
    return {
      stateKey: "primaryMarketInstrumentData",
      instrumentDataReady: false,
      instrumentNotFound: false,
      loading: false,
    };
  },

  computed: {
    instrument() {
      return this.$store.state.fieldStore[this.stateKey];
    },
    instrumentLoadedData() {
      return this.instrumentDataReady;
    },
    instrument404() {
      return this.instrumentNotFound;
    },
    isInstrumentDebtType() {
      return !!(
        this.instrument.instrument_category &&
        this.instrument.instrument_category.toLowerCase() === "debt"
      );
    },
  },

  async mounted() {
    if (!this.checkPermission("view_issuer")) {
      return this.$router.push({ name: "home" });
    }

    await PrimaryMarketService.getInstrument(this.$route.params.uuid)
      .then((response) => {
        this.$store.dispatch("updateStoreItem", {
          value: response.data.data,
          stateKey: this.stateKey,
          field: null,
        });
      })
      .catch(() => {
        this.instrumentNotFound = true;
      });

    this.instrumentDataReady = true;
  },

  methods: {
    viewAllocations(uuid) {
      this.$router.push({ name: "instrument-allocations", params: { uuid } });
      return false;
    },
  },
};
</script>
