<template>
  <div class="row align-items-center">
    <div class="col-2">
      <span v-if="invAccount.created_at">
        {{ dateTimeFormat(invAccount.created_at) }}
      </span>
    </div>
    <div class="col-2">
      {{ invAccount.reference_number }}
    </div>
    <div class="col-3">
      <div v-if="invAccount.product_type">
        {{ invAccount.product_type.toUpperCase() }}
      </div>
    </div>
    <div class="col-3">
      <div v-if="invAccount.closed_on" class="c-admin-alert-text -error">
        Account Closed
      </div>
      <div>
        Stage: <strong> {{ investmentAccountStage }} </strong>
      </div>
    </div>
    <div class="col-2 text-center">
      <a
        :href="false"
        class="c-button -admin-small"
        @click="viewInvAccount(invAccount.uuid)"
      >
        View
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    invAccount: {
      required: true,
    },
  },

  data() {
    return {
      stateKey: "accountData",
    };
  },

  computed: {
    investmentAccountStage() {
      const temp = this.invAccount.stage.split("_").join(" ");
      return this.capitaliseFirstLetter(temp);
    },
  },

  methods: {
    viewInvAccount(uuid) {
      this.$router.push({ name: "investment-account", params: { uuid } });
    },
  },
};
</script>
