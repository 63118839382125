<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2">
        {{ dateFormat(rssDetails.created_at) }}
      </div>
      <div class="col-3 text-break">
        {{ rssDetails.name }}
      </div>
      <div class="col-4 text-break">
        {{ rssDetails.url }}
      </div>
      <div v-if="checkPermission('delete_issuer')" class="col-3 text-center">
        <a
          :href="false"
          class="c-button -admin-small"
          @click="deleteRssFeed(rssDetails.uuid)"
        >
          Delete
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import IssuerService from "js-admin/classes/AjaxServices/IssuerService";

export default {
  props: {
    rssDetails: {
      required: true,
    },
    index: {
      required: true,
    },
  },

  methods: {
    deleteRssFeed(uuid) {
      if (confirm("Are you sure you want to delete this rss feed?")) {
        IssuerService.deleteRssFeed(uuid)
          .then(() => {
            FlashMessageService.success("RSS Feed have been deleted.");
            this.$parent.$parent.$parent.$parent.getRssFeedsCount(true);
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      }
    },
  },
};
</script>
