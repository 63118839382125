<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title> Documents ({{ fileCount }}) </template>

        <VDocumentsForm
          v-if="checkPermission('upload_files')"
          :issuer-uuid="issuerUuid"
          :state-key="stateKey"
          :file-type-options="fileTypeOptions"
          @addDocument="addDocument"
        />

        <div v-if="fileCount" class="c-admin-table">
          <div class="c-admin-table-header">
            <div class="row align-items-center">
              <div class="col-3">
                Uploaded At
              </div>
              <div class="col-3">
                File Name
              </div>
              <div class="col-2">
                Type
              </div>
              <div class="col-2 text-center" />
              <div
                v-if="checkPermission('delete_files')"
                class="col-2 text-center"
              />
            </div>
          </div>

          <div
            v-for="(document, index) in fileData"
            :key="document.uuid"
            class="c-admin-table-row"
          >
            <VDocumentsRow
              :document="document"
              :index="index"
              :state-key="stateKey"
            />
          </div>

          <div class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-model="page"
                  :page-count="pages.length"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          No linked documents.
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VDocumentsForm from "js-admin/components/issuers/issuer-documents/DocumentsForm.vue";
import IssuerService from "js-admin/classes/AjaxServices/IssuerService";
import VDocumentsRow from "js-admin/components/issuers/issuer-documents/DocumentsRow.vue";
import VPagination from "vue-plain-pagination";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import { formatObjectForSelect } from "js-admin/abstract/helpers.js";

export default {
  components: {
    VDocumentsForm,
    VDocumentsRow,
    VPagination,
  },
  props: {
    issuerUuid: {
      required: true,
    },
    stateKey: {
      required: true,
    },
    stateKey2: {
      required: false,
      default: "addDocument",
    },
  },
  data() {
    return {
      fileCount: 0,
      fileData: null,
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
    };
  },
  computed: {
    fileTypeOptions() {
      return formatObjectForSelect(this.$store.state.config.issuer_file_types);
    },
  },
  watch: {
    page() {
      this.paginate();
    },
  },
  mounted() {
    this.getFilesCount();
  },
  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },
    getFilesCount(forcePagination = false) {
      IssuerService.getIssuerFiles(this.issuerUuid, {
        return_count: true,
      })
        .then((response) => {
          this.fileCount = response.data.data.count;
          this.setPages();
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
      if (forcePagination) {
        this.paginate();
      }
    },
    setPages() {
      this.pages = this.calculatePaginationPages(this.fileCount, this.perPage);
    },
    paginate() {
      this.dataReady = false;
      IssuerService.getIssuerFiles(this.issuerUuid, {
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      }).then((response) => {
        if (response.data.data) {
          this.fileData = null;
          if (response.data.data.length) {
            this.fileData = response.data.data;
          }
          this.dataReady = true;
        }
      });
    },
    async addDocument(formData) {
      try {
        const response = await this.$store.dispatch("addIssuerFile", {
          formData,
          uuid: this.issuerUuid,
        });
        if (response.data) {
          this.getFilesCount(true);

          await this.$store.dispatch("updateStoreItem", {
            value: response.data,
            stateKey: this.stateKey,
            field: "documents",
          });
          FlashMessageService.success("Document has been uploaded.");
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
        FlashMessageService.error(`
          ${error.response.data.message} <br/>
          ${error.response.data.errors?.file.join("<br/>")}
          `);
      }
    },
  },
};
</script>
