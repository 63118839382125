import Vue from "vue";
import Vuex from "vuex";
import state from "js-admin/store/state/state";
import mutations from "js-admin/store/mutations/mutations";
import getters from "js-admin/store/getters/getters";
import actions from "js-admin/store/actions/actions";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  strict: process.env.NODE_ENV !== "production",
});
