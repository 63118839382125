<template>
  <div class="h-mt-1 h-mb-1">
    <v-loader :loading="loading" />
    <v-button
      class="-admin-small -icon-btn -tiny"
      @click.native="handleComment"
    >
      <v-comment-button :button-value="buttonState" />
    </v-button>
    <div class="row">
      <div :ref="refId" class="col-12 c-asana-status-comment h-mt-1">
        <form :data-vv-scope="scope" @submit.prevent="submitComment">
          <v-textarea
            :scope="scope"
            state-key="helpdesk"
            class="mb-3"
            field="task_comment"
            label="comment..."
            placeholder=""
            validation="required"
          />
          <v-button class="-admin-small"> Send </v-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VCommentButton from "js-admin/components/helpdesk/CommentBtn";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  components: {
    VCommentButton,
  },
  props: {
    refId: {
      type: String,
      default: null,
    },
    gid: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    scope: "commentScope",
    buttonState: false,
    loading: false,
  }),
  methods: {
    ...mapActions(["createStoryForTask"]),
    ...mapGetters(["getSupportComment"]),
    handleComment() {
      this.$refs[this.refId].classList.toggle("-reveal");
      this.buttonState = !this.buttonState;
    },
    submitComment() {
      this.loading = true;
      this.createStoryForTask({
        task_gid: this.gid,
        text: this.getSupportComment(),
        is_pinned: false,
      }).then(() => {
        this.loading = false;
        FlashMessageService.success("Your support comment has been submitted");
        this.handleComment();
      });
    },
  },
};
</script>
