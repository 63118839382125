import APIService from "js-admin/classes/APIService";
import { prepSearchUrlParameters } from "js-admin/abstract/helpers";

export default class CustomerChangeRequestService {
  static CRUD_URL = "change-requests";

  static async get(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid);
  }

  static async updateStatus(data) {
    return APIService.request(
      this.CRUD_URL + "/" + data.uuid + "/change-status",
      "put",
      data
    );
  }

  static async delete(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }

  static async getList(params) {
    const extraParams = prepSearchUrlParameters(params);
    return APIService.request(this.CRUD_URL + "?" + extraParams);
  }
}
