<template>
  <div
    class="c-amount-input"
    :class="{
      '-value': value,
      '-error': hasErrors && !focused,
      '-label': label,
    }"
  >
    <label
      v-if="label"
      :for="'display_' + field"
      class="c-input-label-outside"
      v-text="label"
    />
    <label
      v-else
      :for="'display_' + field"
      class="h-hidden"
      v-text="'Amount input'"
    />
    <div
      :class="[
        'c-amount-input-container',
        {
          '-error': hasErrors && !focused,
          'position-relative': positionRelative,
          '-focus': focused,
        },
      ]"
    >
      <div class="c-amount-input-unit">{{ currencySymbol }}</div>

      <div class="c-amount-input-slot">
        <input
          :ref="fieldRef"
          v-model="displayValue"
          :disabled="(max !== null && max <= 0) || disabled"
          type="text"
          :name="'display_' + field"
          inputmode="numeric"
          class="c-amount-input-input"
          :placeholder="internalPlaceholder"
          :aria-label="label ? label : 'amount'"
          @focus="focus($event)"
          @keypress="keypress"
          @blur="blur"
        />
      </div>
      <v-text-input-icons
        v-if="iconVisible"
        :has-errors="hasErrors"
        :value="storeValue"
        :focused="focused && !showErrorsOnFocus"
        :icon="icon"
      />
    </div>
    <v-input-hidden
      :ref="field"
      :scope="scope"
      :field="field"
      :label="label"
      :error-name="errorName"
      :state-key="stateKey"
      :validation="validation"
      :namespace="namespace"
      :center-error="false"
    />

    <div
      v-if="addAll"
      class="c-amount-input-all type-link"
      @click="addAllClicked"
      v-text="addAllText"
    ></div>
  </div>
</template>

<script>
import VTextInputIcons from "js-admin/components/form/TextInputIcons";
import numeral from "numeral";

export default {
  name: "VAmountInput",
  components: {
    VTextInputIcons,
  },
  props: {
    iconVisible: {
      default: true,
      type: Boolean,
    },
    scope: {
      required: true,
      type: String,
    },
    field: {
      required: true,
      type: String,
    },
    namespace: {
      default: null,
      type: String,
    },
    stateKey: {
      required: true,
      type: String,
    },
    label: {
      default: null,
      type: String,
    },
    placeholder: {
      default: "Type amount",
      type: String,
    },
    errorName: {
      default: null,
      type: String,
    },
    validation: {
      default: "required|min_value:10000",
      type: [String, Object, Boolean],
    },
    min: {
      default: 0,
      type: Number,
    },
    max: {
      default: null,
      type: Number,
    },
    positionRelative: {
      default: false,
      type: Boolean,
    },
    allowFloat: {
      default: false,
      type: Boolean,
    },
    allowNegative: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    addAll: {
      default: false,
      type: Boolean,
    },
    addAllText: {
      default: "add all",
      type: String,
    },
    showErrorsOnFocus: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: null,
      type: String,
    },
    focusOnMounted: {
      default: false,
      type: Boolean,
    },
    currencySymbolOverride: {
      default: "",
      type: String,
    }
  },
  inject: ["$validator"],
  data() {
    return {
      focused: false,
      value: false,
      internalPlaceholder: null,
    };
  },
  computed: {
    currencySymbol() {
      if(this.currencySymbolOverride) {
        return this.currencySymbolOverride;
      }

      return this.$store.getters.defaultCurrency.symbol;
    },
    fieldRef() {
      return this.scope + "-" + this.field;
    },
    displayValue: {
      get() {
        if (this.storeValue !== null && !this.allowFloat) {
          // handle integers
          return numeral(this.storeValue / 100).format(this.displayFormat);
        } else if (this.storeValue !== null && this.allowFloat) {
          // handle float values - dont change formatting while focused
          if (this.focused) {
            return this.storeValue / 100;
          } else {
            return numeral(this.storeValue / 100).format(this.displayFormat);
          }
        }
        return null;
      },
      set(newValue) {
        if (!this.allowFloat) {
          this.$refs[this.field].updateValue(
            numeral(newValue).format("0") * 100
          );
        } else {
          this.$refs[this.field].updateValue(
            numeral(newValue).format("0.00") * 100
          );
        }
      },
    },
    storeValue() {
      if (this.namespace != null) {
        return this.$store.state[this.namespace].fieldStore[this.stateKey][
          this.field
        ];
      } else {
        return this.$store.state.fieldStore[this.stateKey][this.field];
      }
    },
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
    displayFormat() {
      return this.allowFloat ? "0,0.00" : "0,0";
    },
  },
  beforeMount() {
    this.internalPlaceholder = this.placeholder;
  },
  mounted() {
    if (this.focusOnMounted) {
      this.inputFocus();
    }
  },
  methods: {
    inputFocus() {
      this.$refs[this.fieldRef].focus();
    },

    addAllClicked() {
      this.$refs[this.field].updateValue(this.max);
    },

    prevDef(e) {
      e.preventDefault();
    },

    focus($event) {
      if (!this.xIsMobile) {
        $event.target.select();
      }
      this.focused = true;
      this.internalPlaceholder = null;
    },

    blur() {
      this.focused = false;
      this.internalPlaceholder = this.placeholder;
      // clean up over max values
      if (this.max && this.storeValue > this.max) {
        this.$refs[this.field].updateValue(this.max);
      }
    },

    updateValue(value) {
      this.$refs[this.field].updateValue(value);
    },

    keypress($event) {
      $event = $event || window.event;
      const charCode = $event.which ? $event.which : $event.keyCode;

      // rules
      const isNotANumber = !(charCode > 47 && charCode < 58);
      const isDecimalPoint = charCode === 46;
      const isMinusSign = charCode === 45;

      const currentlyHaveDecimalPoint = /\./g.exec(this.displayValue);

      let stopKeypress = false;

      const oldVal = this.displayValue;

      setTimeout(() => {
        const result = /\.\d{3,}/g.exec(this.displayValue);

        if (this.allowFloat && result) {
          this.displayValue = oldVal;
        }
      }, 100);

      if (this.allowFloat && isDecimalPoint && !currentlyHaveDecimalPoint) {
        return true;
      }

      if (this.allowNegative && isMinusSign) {
        return true;
      }

      if (isNotANumber) {
        stopKeypress = true;
      }

      if (stopKeypress) {
        $event.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
