<template>
  <div class="c-admin-table-row">
    <div class="row align-items-center">
      <div class="col-2" v-text="dateTimeFormat(withdrawal.created_at)" />
      <div class="col-2" v-text="withdrawal.reference" />
      <div class="col-2" v-text="withdrawal.currency.symbol + convertToPounds(withdrawal.value)" />
      <div class="col-3" v-text="capitaliseFirstLetter(withdrawal.status)" />
      <div class="col-3 text-break">
        <div v-if="withdrawal.status === 'completed'">
          Completed On: {{ dateTimeFormat(withdrawal.completed_on) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    withdrawal: {
      required: true,
      type: Object,
    },
  },
};
</script>
