<template>
  <div class="mt-2">
    <div v-if="account_users_count" class="c-admin-table">
      <div class="c-admin-table-header">
        <div class="row align-items-center">
          <div class="col-2">
            Name
          </div>
          <div class="col-3">
            Username
          </div>
          <div class="col-3">
            Email Address
          </div>
          <div class="col-2 text-center">
            Reset Password
          </div>
          <div class="col-2 text-center">
            Reset Two-Factor
          </div>
        </div>
      </div>

      <div
        v-for="(user, index) in account_users"
        :key="user.uuid"
        class="c-admin-table-row"
      >
        <VLinkedUserRow :user="user" :index="index" />
      </div>
    </div>
    <div v-else>
      No linked Users.
    </div>
  </div>
</template>

<script>
import VLinkedUserRow from "js-admin/components/accounts/users/LinkedUsersRow.vue";

export default {
  components: {
    VLinkedUserRow,
  },
  data() {
    return {
      stateKey: "accountData",
    };
  },

  computed: {
    account_users() {
      return this.$store.state.fieldStore[this.stateKey].users;
    },
    account_users_count() {
      if (this.$store.state.fieldStore[this.stateKey].users) {
        return this.$store.state.fieldStore[this.stateKey].users.length;
      } else {
        return 0;
      }
    },
  },

  methods: {
    toggle(selectedItem) {
      this.$children.forEach((item) => {
        item.showEditForm = item._uid == selectedItem._uid;
      });
    },
  },
};
</script>
