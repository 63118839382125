<template>
  <div class="c-password-input">
    <v-text-input
      :ref="field"
      :type="showPassword ? 'text' : 'password'"
      :field="field"
      :state-key="stateKey"
      :scope="scope"
      :placeholder="placeholder"
      :label="label"
      :error-name="errorName"
      :validation="validation"
      :always-instructions="true"
      :icon-visible="!focused"
      :instructions="instructions"
      :with-error="withError"
      @focus="focus"
      @blur="blur($event)"
    />
    <transition name="fade" mode="out-in">
      <div
        v-if="focused"
        class="c-password-input-toggle"
        @click.prevent.stop="togglePassword"
      >
        <transition name="fade" mode="out-in">
          <div v-if="showPassword" key="eye" class="c-password-input-icon">
            <svg
              version="1.2"
              baseProfile="tiny"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 13"
            >
              <path
                d="M19.8 5.7c-1-1.7-2.4-3.1-4.2-4.2C13.8.5 12 0 10 0S6.2.5 4.4 1.6C2.7 2.6 1.3 4 .2 5.7c-.1.3-.2.5-.2.8s.1.5.2.8c1 1.7 2.4 3.1 4.2 4.2C6.2 12.5 8 13 10 13c2 0 3.8-.5 5.6-1.6 1.8-1 3.2-2.4 4.2-4.2.1-.2.2-.4.2-.7 0-.3-.1-.5-.2-.8zM7.6 2.6c.7-.7 1.5-1 2.4-1 .1 0 .3.1.4.2.1.1.2.2.2.4s-.1.3-.2.4c-.1.1-.2.2-.4.2-.6 0-1.2.2-1.6.7-.5.5-.7 1-.7 1.7 0 .2-.1.3-.2.4-.1-.1-.2 0-.4 0-.1 0-.2-.1-.3-.2-.1-.1-.2-.2-.2-.3 0-1 .3-1.8 1-2.5zm7.2 7.6c-1.5.9-3.1 1.4-4.8 1.4-1.7 0-3.4-.5-4.8-1.4S2.4 8 1.4 6.5c1.1-1.8 2.5-3.1 4.3-4-.5.8-.7 1.6-.7 2.6 0 1.4.5 2.6 1.5 3.6s2.2 1.5 3.5 1.5c1.4 0 2.6-.5 3.5-1.5S15 6.4 15 5.1c0-.9-.2-1.8-.7-2.5 1.7.9 3.1 2.2 4.3 4-1 1.4-2.3 2.7-3.8 3.6z"
              />
            </svg>
          </div>
          <div v-else key="eye_hide" class="c-password-input-icon">
            <svg
              version="1.2"
              baseProfile="tiny"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 13"
            >
              <path
                d="M19.2 11.8c-.2.3-.5.5-.9.5-.2 0-.4 0-.5-.2l-1.8-1-1.4-.8-1.7-1L5 4.9 3.5 4l-1.3-.7-1-.6c-.5-.3-.7-1-.4-1.5.2-.4.6-.5 1-.5.2 0 .4 0 .5.2l1.8 1 1.4.8.1.1 1.4.7L8 4l6.2 3.6L16.5 9l1.4.8 1 .6c.4.2.6.9.3 1.4zM7.2 3c.1-.1.2-.3.4-.4.7-.7 1.5-1 2.4-1 .1 0 .3.1.4.2.1.1.2.2.2.4s-.1.3-.2.4c-.1.1-.2.2-.4.2-.6 0-1.2.2-1.6.7l-.1.1 6.2 3.6c.3-.6.5-1.4.5-2.1 0-.9-.2-1.8-.7-2.5 1.7.9 3.1 2.2 4.3 4-.5.7-1.1 1.4-1.7 2l1.3.8c.6-.7 1.2-1.4 1.6-2.2.1-.2.2-.4.2-.7s-.1-.5-.2-.8c-1-1.7-2.4-3.1-4.2-4.2C13.8.5 12 0 10 0 8.1 0 6.3.5 4.6 1.5L7.2 3zm6.7 7.6c-1.3.6-2.6 1-3.9 1-1.7 0-3.4-.5-4.8-1.4C3.8 9.3 2.4 8 1.4 6.5c.5-.8 1.1-1.5 1.7-2.1l-1.2-.8C1.3 4.3.7 4.9.2 5.7c-.1.3-.2.5-.2.8s.1.5.2.8c1 1.7 2.4 3.1 4.2 4.2C6.2 12.5 8 13 10 13c1.9 0 3.7-.5 5.4-1.5l-1.5-.9z"
              />
              <path
                d="M10 10.2c.8 0 1.6-.2 2.2-.5L5 5.5c.1 1.2.6 2.3 1.5 3.2 1 1 2.2 1.5 3.5 1.5z"
              />
            </svg>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "PasswordInput",

  props: {
    field: {
      required: true,
    },
    stateKey: {
      required: true,
    },
    scope: {
      required: true,
    },
    validation: {
      default: "required|password",
    },
    placeholder: {
      default: "Create password",
    },
    label: {
      default: "label",
    },
    errorName: {
      default: "create password",
    },
    instructions: {
      default: "Your password must be a minimum of 8 characters",
    },
  },

  inject: ["$validator"],

  data() {
    return {
      showPassword: false,
      focused: false,
      withError: true,
    };
  },
  methods: {
    togglePassword() {
      const field = this.$refs[this.field].$refs[this.scope + "-" + this.field];

      field.focus();
      setTimeout(() => {
        field.selectionStart = field.value.length;
      }, 100);

      this.showPassword = !this.showPassword;
    },
    blur() {
      this.focused = false;
    },
    focus() {
      this.focused = true;
    },
  },
};
</script>
