<template>
  <div class="v-profile">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Suitability Report
          </h1>
        </div>
      </div>

      <!-- Search -->
      <v-date-search
        ref="searchDate"
        :scope="reportScope"
        :mode="mode"
        :submit-results="submitResults"
        @get-results="getResults"
      />

      <!-- CSV Export -->
      <v-csv-export
        v-if="reportData"
        class="mt-4"
        @start-csv-export="handleExport"
      />

      <!-- Full Report -->
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header -bold">
              <div class="row">
                <div class="col-2">
                  Date
                </div>
                <div class="col-2">
                  Account Name
                </div>
                <div class="col-6">
                  Answers
                </div>
                <div class="col-1">
                  Score
                </div>
                <div class="col-1">
                  Version
                </div>
              </div>
            </div>
            <div v-if="!submitResults">
              <div v-if="reportResults">
                <div
                  v-for="data in reportResults"
                  :key="data.owner_uuid"
                  class="c-admin-table-row"
                >
                  <div class="row">
                    <div class="col-2">
                      {{ dateTimeFormat(data.created_at) }}
                    </div>
                    <div class="col-2">
                      <router-link
                        class="c-link"
                        :to="`/account/${data.account_uuid}`"
                      >
                        {{ data.account_name }}
                      </router-link>
                    </div>
                    <div class="col-6">
                      <div v-if="data.answers">
                        <div
                          v-for="(answer, index) in data.answers"
                          :key="index"
                        >
                          {{ answer.label }}: <br>
                          <span v-if="!answer.value">
                            <strong>
                              N.A.
                            </strong>
                          </span>
                          <span v-else>
                            <strong>
                              <span v-if="index === 'dob'">
                                {{ dateFormat(answer.value) }}
                              </span>
                              <span
                                v-else-if="
                                  index === 'investment' ||
                                    index === 'net_worth' ||
                                    index === 'annual_income' ||
                                    index === 'other_investments' ||
                                    index === 'savings'
                                "
                              >
                                {{defaultCurrency.symbol + convertToPounds(answer.value) }}
                              </span>
                              <span v-else>
                                {{ stripUnderScoresAndCapitalise(answer.value) }}
                              </span>
                            </strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-1">
                      <strong>
                        {{ data.score }}
                      </strong>
                    </div>
                    <div class="col-1">
                      <strong>
                        {{ data.version }}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="c-admin-table-row">
                <div class="row">
                  <div class="col-12">
                    No results found.
                  </div>
                </div>
              </div>
              <div class="c-admin-table-footer -bold">
                <div class="row">
                  <div class="col-10 text-right">
                    Total:
                  </div>
                  <div class="col-2">
                    {{ reportTotal }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  <v-loader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CSV Export -->
      <v-csv-export
        v-if="reportData"
        class="mt-4"
        @start-csv-export="handleExport"
      />
    </section>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VDateSearch from "js-admin/components/Search/DateSearch";
import ReportingService from "js-admin/classes/AjaxServices/ReportingService";

import VCsvExport from "js-admin/components/csv-export/CsvExport";
import mixinReport from "js-admin/mixins/mixinReport";
import {mapGetters} from "vuex";

export default {
  components: {
    VCsvExport,
    VDateSearch,
  },
  mixins: [mixinReport],
  data() {
    return {
      reportScope: "reportSearch",
      reportStateKey: "reportSearch",
      reportData: null,
      submitResults: false,
      currentDate: new Date(),
      mode: "single",
    };
  },
  computed: {
    ...mapGetters(["defaultCurrency"]),
    reportResults() {
      return this.reportData;
    },
    reportTotal() {
      let total = 0;
      if (this.reportResults) {
        total = this.reportResults.length;
      }
      return total;
    },
  },
  mounted() {
    if (!this.checkPermission("reporting_level1")) {
      return this.$router.push({ name: "home" });
    }
  },
  methods: {
    async getResults(e = null, downloadCsv = false) {
      let asCsv = {};
      if (downloadCsv) {
        asCsv = {
          as_csv: true,
        };
      } else {
        this.submitResults = true;
      }

      let data = this.formatDateData(
        this.$refs.searchDate.fromDate,
        this.$refs.searchDate.toDate
      );
      data = { ...data, ...asCsv };

      return ReportingService.getSuitabilityReport(data)
        .then((response) => {
          this.submitResults = false;

          if (response.status === 204) {
            this.reportData = [];
            return;
          }

          if (downloadCsv) {
            return {
              response,
              label: "Suitability_Report",
            };
          } else {
            this.reportData = response.data.data.results;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },
  },
};
</script>
