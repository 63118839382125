<template>
  <div class="c-admin-panel h-mt-1">
    <v-accordion class="">
      <v-accordion-item>
        <template v-slot:title> Tasks ({{ accountTasksCount }}) </template>
        <VCreateTask :add-model-button-margin="true" />
        <div class="c-admin-panel">
          <VTaskTable
            :with-sort-options="false"
            :show-title="false"
            :task-owner="null"
            :task-data="accountTasks"
            title="Tasks"
          />
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VCreateTask from "js-admin/components/tasks/CreateTask.vue";
import VTaskTable from "js-admin/components/tasks/TaskTable.vue";

export default {
  components: {
    VCreateTask,
    VTaskTable,
  },
  data() {
    return {
      stateKey: "accountData",
    };
  },

  computed: {
    accountTasks() {
      if (this.$store.state.fieldStore[this.stateKey].tasks != null) {
        return this.$store.state.fieldStore[this.stateKey].tasks;
      } else {
        return [];
      }
    },
    accountTasksCount() {
      if (this.$store.state.fieldStore[this.stateKey].tasks) {
        return this.$store.state.fieldStore[this.stateKey].tasks.length;
      } else {
        return 0;
      }
    },
  },
};
</script>
