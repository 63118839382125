<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item>
        <template v-slot:title>
          Communications
        </template>

        <VLinkedCalls
          v-if="!systemFeatureIsDisabled('disabled_phone_system')"
        />

        <VEmails
          v-if="
            checkPermission('view_emails') &&
              !systemFeatureIsDisabled('disabled_emails')
          "
          :state-key="stateKey"
          :account-uuid="accountUuid"
          :account-email="accountEmail"
        />

        <VSecuredMessages
          v-if="
            checkPermission('view_secured_messages') &&
              !systemFeatureIsDisabled('disabled_secured_messages')
          "
          :state-key="stateKey"
          :account-uuid="accountUuid"
        />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VLinkedCalls from "js-admin/components/accounts/calls/LinkedCalls.vue";
import VSecuredMessages from "js-admin/components/secured-messages/SecureMessages.vue";
import VEmails from "js-admin/components/emails/Emails.vue";

export default {
  components: {
    VLinkedCalls,
    VSecuredMessages,
    VEmails,
  },
  props: {
    stateKey: {
      required: true,
      default: "accountData",
    },
    accountUuid: {
      required: true,
    },
    accountEmail: {
      required: true,
    },
  },

  methods: {},
};
</script>
