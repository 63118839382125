<template>
  <div class="c-asana-status-history">
    {{ createdAt }}<br />
    {{ historyItem.text }}
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    historyItem: {
      type: Object,
      default: () => ({
        created_at: null,
        gid: null,
        id: null,
        resource_subtype: null,
        task_gid: null,
        text: null,
      }),
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    createdAt() {
      return dayjs(this.historyItem.created_at).format("d/MM/YYYY HH:mm");
    },
  },
};
</script>
