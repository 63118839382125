<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2">
        {{ dateFormat(repayment.repayment_date) }}
      </div>
      <div class="col-2">
        {{ currencySymbol }}{{ convertToPounds(repayment.capital) }}
      </div>
      <div class="col-2">
        {{ currencySymbol }}{{ convertToPounds(repayment.interest) }}
      </div>
      <div class="col-2">
        {{ capitaliseFirstLetter(repayment.status) }}
        <div v-if="repayment.corporate_action_status">
          Corporate Action Status:
          {{ capitaliseFirstLetter(repayment.corporate_action_status) }}
        </div>
        <div v-if="repayment.corporate_action_in_progress_on">
          Corporate Action In Progress On:
          {{ dateTimeFormat(repayment.corporate_action_in_progress_on) }}
        </div>
      </div>
      <div class="col-2">
        <div v-if="repayment.is_matured">
          Yes
        </div>
        <div v-else>
          No
        </div>
      </div>
      <div class="col-2">
        <v-button
          v-if="repayment.no_of_repayments > 0"
          class="c-button -admin-small mb-2"
          type="button"
          @click.native="viewIndividualRepayments(repayment.uuid)"
        >
          View ({{ repayment.no_of_repayments }})
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    repayment: {
      required: true,
    },
    currencySymbol: {
      required: true,
      type: String,
    },
  },

  data() {
    return {};
  },
  methods: {
    viewIndividualRepayments(uuid) {
      this.$router.push({
        name: "primary-market-instrument-repayment",
        params: { uuid },
      });
      return false;
    },
  },
};
</script>
