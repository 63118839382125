<template>
  <div class="c-admin-table">
    <div class="c-admin-table-header">
      <div class="row">
        <div class="col-3">
          AM Name
        </div>
        <div class="col-3">
          Last Assigned Completed Account
        </div>
        <div class="col-4">
          Status
        </div>
        <div class="col-2" />
      </div>
    </div>

    <div v-for="(owner, index) in accountManagers" :key="index">
      <VRoundRobinRow :owner="owner" />
    </div>
  </div>
</template>

<script>
import VRoundRobinRow from "js-admin/components/round-robin/RoundRobinRow.vue";

export default {
  components: {
    VRoundRobinRow,
  },
  props: {
    accountManagers: {
      required: true,
    },
  },
  data() {
    return {};
  },

  methods: {
    toggle(selectedItem) {
      this.$children.forEach((item) => {
        item.showEditForm = item._uid == selectedItem._uid;
      });
    },

    resetForms() {
      this.$children.forEach((item) => {
        item.showEditForm = false;
      });
    },
  },
};
</script>
