import APIService from "js-admin/classes/APIService";

export default class HelpDeskService {
  static CRUD_URL = "helpdesk";

  static async submitHelpdesk(data) {
    return APIService.request(this.CRUD_URL + "/submit-helpdesk", "post", data);
  }

  // todo check - endpoint doesn't exist
  static async sendHelpdeskComment(data) {
    return APIService.request(
      this.CRUD_URL + "/submit-helpdesk-comment",
      "post",
      data
    );
  }

  static async getStoriesForTask(task_gid) {
    return APIService.request(
      this.CRUD_URL + "/get-stories-for-task/" + task_gid,
      "get"
    );
  }

  static async getTasklist(data) {
    return APIService.request(this.CRUD_URL + "/get-task-list", "post", data);
  }

  static async createStoryForTask(data) {
    return APIService.request(
      this.CRUD_URL + "/create-story-for-task",
      "post",
      data
    );
  }

  static async updateStoryForTask(data) {
    return APIService.request(
      this.CRUD_URL + "/update-story-for-task",
      "post",
      data
    );
  }

  static async addTaskToSection(data) {
    return APIService.request(
      this.CRUD_URL + "/add-task-to-section",
      "post",
      data
    );
  }
}
