import {
  parsePhoneNumberFromString as _parsePhoneNumberFromString,
  findNumbers as _findNumbers,
  AsYouType as _AsYouType,
} from "libphonenumber-js/core";

import metadata from "./metadata.gb.json";

function call(func, _arguments) {
  const args = Array.prototype.slice.call(_arguments);
  args.push(metadata);
  return func.apply(this, args);
}

export function parsePhoneNumberFromString() {
  return call(_parsePhoneNumberFromString, arguments);
}

export function findNumbers() {
  return call(_findNumbers, arguments);
}

export function AsYouType(country) {
  return _AsYouType.call(this, country, metadata);
}

AsYouType.prototype = Object.create(_AsYouType.prototype, {});
AsYouType.prototype.constructor = AsYouType;
