<template>
  <article ref="main" class="c-accordion-item" :class="{ '-active': isActive }">
    <h2 class="c-accordion-item-title" @click="toggle">
      <div class="row align-items-center">
        <div class="col">
          <slot name="title" />
        </div>
        <div v-if="!hideIndicator" class="col-auto">
          <div class="c-accordion-indicator" />
        </div>
      </div>
    </h2>
    <VSlideUpDown :active="isActive" :duration="200">
      <div class="c-accordion-item-content">
        <slot />
      </div>
    </VSlideUpDown>
  </article>
</template>

<script>
import VSlideUpDown from "vue-slide-up-down";

export default {
  components: {
    VSlideUpDown,
  },
  props: {
    hideIndicator: { default: false },
    selected: { default: false },
  },

  data() {
    return {
      isActive: false,
      number: 0,
    };
  },

  methods: {
    toggle() {
      this.$emit("toggle", !this.isActive);

      if (this.isActive) {
        this.isActive = false;
      } else {
        this.$parent.toggle(this);
        if (this.isTouch) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$scrollTo(this.$refs.main, 500, { offset: -80 });
            }, 201);
          });
        }
      }
    },

    closeAll() {
      this.$parent.closeAll();
    },
  },
};
</script>
