var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"c-admin-panel"},[_c('div',{staticClass:"c-admin-panel-section"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" "+_vm._s(_vm.cashWallet.reference_number)+" ")])])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" "+_vm._s(_vm.cashWallet.currency.symbol + _vm.convertToPounds(_vm.cashWallet.total_balance))+" ")])])]),_c('div',{staticClass:"row mt-2"},[_vm._m(3),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" "+_vm._s(_vm.cashWallet.currency.symbol + _vm.convertToPounds(_vm.cashWallet.available_balance))+" ")])])]),_c('div',{staticClass:"row mt-2"},[_vm._m(4),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.cashWallet.external_reference_number)+" ")])])]),(_vm.cashWallet.currency)?_c('div',{staticClass:"row mt-2"},[_vm._m(5),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.cashWallet.currency.code)+" ")])])]):_vm._e(),_c('div',{staticClass:"row mt-2"},[_vm._m(6),_c('div',{staticClass:"col-6"},[_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.cashWallet.has_pending_withdrawal)+" ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"c-admin-panel-title"},[_vm._v(" Cash Wallet Information ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" Reference Number: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" Total Balance: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" Available Balance: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" External Reference Number: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Currency: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Has Pending Withdrawal: ")])])
}]

export { render, staticRenderFns }