import APIService from "js-admin/classes/APIService";
import UserService from "js-admin/classes/AjaxServices/UserService";
import store from "js-admin/store/store";
import router from "js-admin/router/router";

export default class AuthService {
  static async refreshUser() {
    const loggedIn = store.state.me;
    // if we know who they are fetch new data:
    if (loggedIn) {
      await UserService.getUserInfo(loggedIn).then((response) => {
        const user = response.data.data;
        // kick out customers who login here
        if (user.roles.includes("customer")) {
          window.location.href =
            window.whiteLabelClientPlatformUrl +
            (window.location.port ? ":" + window.location.port : "") +
            "/my-accounts";
          throw Error("User is not authorised");
        }
        store.commit("setUser", user);
      });
    } else {
      // find out if they are logged in and get uuid;
      await this.getAndSetUser();
    }
  }

  static async getUserUuid() {
    const response = await UserService.getMe();
    const userUuid = response.data.data.user_uuid;
    store.commit("loggedIn", userUuid);
  }

  static userClear() {
    store.commit("setUser", {});
  }

  static async getAndSetUser() {
    await this.getUserUuid();
    await this.refreshUser();
  }

  static setRedirect(path) {
    if (path !== "/home" && path !== "/") {
      sessionStorage.setItem("redirectUrl", path);
    }
  }

  static clearRedirect() {
    sessionStorage.removeItem("redirectUrl");
  }

  static async login(userCredentials) {
    try {
      const response = await APIService.login(userCredentials);

      if (response.data) {
        return response.data.data.twofactor_type;
      }

      this.getUserUuid().then(() => {
        router.push(sessionStorage.getItem("redirectUrl") ?? "/home");
      });
    } catch (error) {
      const errors = error.response.data.errors;
      throw Error(errors[Object.keys(errors)[0]]);
    }
  }

  static async logout() {
    return APIService.logout().then(() => {
      this.clearRedirect();
    });
  }
}
