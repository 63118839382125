<template>
  <div class="c-select-tile-radio">
    <!-- <div class="container"> -->
    <div class="row justify-content-around no-gutters">
      <div
        v-for="(option, index) in options"
        :key="option.value"
        class="col-auto"
      >
        <VSelectTileItem
          :active="value === option.value"
          :class="{ '-last': index + 1 == options.length }"
          :button-class="buttonClass"
          :item="option"
          :index="index"
          @select="updateValue"
        />
        <v-modal v-if="option.help">
          {{ option.help }}
          <template v-slot:button>
            <div class="c-select-tile-help">
              What's this?
            </div>
          </template>
        </v-modal>
      </div>
    </div>
    <!-- </div> -->
    <v-input-hidden
      ref="input"
      :field="field"
      :state-key="stateKey"
      :scope="scope"
    />
  </div>
</template>

<script>
import VSelectTileItem from "./SelectTileItem.vue";

export default {
  inject: ["$validator"],
  components: {
    VSelectTileItem,
  },
  props: {
    options: {
      type: Array,
      default: () => [
        {
          title: "optionTitle",
          value: "value1",
          description: "description",
          icon: "i-placeholder.png", // optional filename in img/frontend/icons
          help: "help text", // optional
        },
        {
          title: "optionTitle",
          value: "value2",
          description: "description",
          icon: "i-placeholder.png",
          help: "help text",
        },
        {
          title: "optionTitle",
          value: "value3",
          description: "description",
          icon: "i-placeholder.png",
        },
      ],
    },
    validation: {
      default: "required",
    },
    scope: {
      default: "",
    },
    field: {
      required: true,
    },
    stateKey: {
      required: true,
    },
    buttonClass: {
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
    value() {
      return this.$store.state.fieldStore[this.stateKey][this.field];
    },
  },
  mounted() {
    window.addEventListener("keyup", this.keySelect);
  },
  destroyed() {
    window.removeEventListener("keyup", this.keySelect);
  },
  methods: {
    updateValue(value) {
      this.$refs.input.updateValue(value);
      this.$emit("updated", value);
    },
    keySelect(event) {
      const tagName = event.path[0].tagName;
      if (tagName == "INPUT" || tagName == "TEXTAREA") {
        return;
      }
      const key = event.keyCode;
      if (key > 64 && key < 91) {
        const index = key - 65;
        if (index < this.options.length) {
          this.updateValue(this.options[index].value);
        }
      }
    },
  },
};
</script>
