<template>
  <v-slide-col>
    <template v-slot:header>
      <h1 class="h-mb-2">Support Status</h1>
    </template>
    <template v-slot:leftCol>
      <v-asana-status-table :side-layout="true" />
    </template>
    <template v-slot:rightCol>
      <transition
        appear
        mode="out-in"
        :css="false"
        @before-enter="beforeNavEnter"
        @enter="enterNav"
        @before-leave="beforeNavLeave"
        @leave="leaveNav"
      >
        <router-view :key="key" />
      </transition>
    </template>
  </v-slide-col>
</template>

<script>
import gsap from "gsap";
import VSlideCol from "js-admin/components/layouts/SlideCol";
import VAsanaStatusTable from "js-admin/components/helpdesk/AsanaStatusTable";
export default {
  components: {
    VSlideCol,
    VAsanaStatusTable,
  },
  data: () => ({}),
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  beforeCreate() {
    this.$store.dispatch("getTasklist");
  },
  methods: {
    beforeNavEnter(el, done) {
      gsap.set(el, {
        css: {
          opacity: 0,
          x: "20%",
        },
        ease: "power3.inOut",
      });
    },
    enterNav(el, done) {
      // this.$store
      //   .dispatch("changeSelectedTask", this.$route.params.t_uuid)
      //   .then(() => {
      gsap.to(el, {
        duration: 0.6,
        css: {
          opacity: 1,
          x: "0%",
        },
        ease: "power3.inOut",
        onComplete: () => {
          done();
        },
      });
      // });
    },
    beforeNavLeave(el, done) {},
    leaveNav(el, done) {
      gsap.to(el, {
        duration: 0.3,
        css: {
          opacity: 0,
          x: "5%",
          // scale: 0.9,
          // transformOrigin: '0% 10%'
        },
        ease: "power3.inOut",
        onComplete: () => {
          done();
        },
      });
    },
  },
};
</script>
