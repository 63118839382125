<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title>
          Primary Market Bids ({{ primaryMarketBidsCount }})
        </template>

        <div v-if="dataReady">
          <div class="c-admin-table">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-3">
                  Submitted At
                </div>
                <div class="col-3">
                  Instrument
                </div>
                <div class="col-3">
                  Value
                </div>
                <div class="col-3">
                  Status
                </div>
              </div>
            </div>
            <div v-if="bids.length">
              <div
                v-for="(data, index) in bids"
                :key="index"
                class="c-admin-table-row"
              >
                <VPrimaryMarketBidsRow :bid="data" />
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              No primary market bids
            </div>
          </div>
          <div v-if="bids.length" class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-model="page"
                  :page-count="pages.length"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VPagination from "vue-plain-pagination";
import CashWalletService from "js-admin/classes/AjaxServices/CashWalletService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VPrimaryMarketBidsRow from "js-admin/components/cash-wallet/primary-market-bids/PrimaryMarketBidsRow.vue";

export default {
  components: {
    VPagination,
    VPrimaryMarketBidsRow,
  },

  data() {
    return {
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      primaryMarketBids: null,
      primaryMarketBidsCount: null,
    };
  },

  computed: {
    cashWalletUuid() {
      return this.$store.state.fieldStore.cashWalletData.uuid;
    },
    bids() {
      return this.primaryMarketBids;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    CashWalletService.getPrimaryMarketBids(this.cashWalletUuid, {
      return_count: true,
    })
      .then((response) => {
        if (response.data.data) {
          this.primaryMarketBidsCount = response.data.data.count;
          this.setPages();
        }
      })
      .catch((error) => {
        FlashMessageService.error(error.response.data.data.message);
      });
  },

  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },

    paginate() {
      this.dataReady = false;

      return CashWalletService.getPrimaryMarketBids(this.cashWalletUuid, {
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      })
        .then((response) => {
          if (response.data) {
            this.primaryMarketBids = response.data.data;
            this.dataReady = true;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(
        this.primaryMarketBidsCount,
        this.perPage
      );
    },
  },
};
</script>
