var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"c-admin-panel"},[_c('div',{staticClass:"c-admin-panel-section"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" "+_vm._s(_vm.investmentAccount.reference_number)+" ")])])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" "+_vm._s(_vm.investmentAccountStage)+" ")])])]),(_vm.investmentAccount.external_investment_account_id)?_c('div',{staticClass:"row mt-2"},[_vm._m(3),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.investmentAccount.external_investment_account_id)+" ")])])]):_vm._e(),(_vm.investmentAccount.open_date)?_c('div',{staticClass:"row mt-2"},[_vm._m(4),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.investmentAccount.open_date))+" ")])])]):_vm._e(),(_vm.investmentAccount.closed_on)?_c('div',{staticClass:"row mt-2"},[_vm._m(5),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.investmentAccount.closed_on))+" ")])])]):_vm._e(),(_vm.investmentAccount.product_type)?_c('div',{staticClass:"row mt-2"},[_vm._m(6),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.investmentAccount.product_type.toUpperCase())+" ")])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"c-admin-panel-title"},[_vm._v(" Investment Account Information ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" Reference Number: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" Stage: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" External Reference Number: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Open Date: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Closed Date: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Product Type: ")])])
}]

export { render, staticRenderFns }