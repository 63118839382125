import APIService from "js-admin/classes/APIService";
import { prepSearchUrlParameters } from "js-admin/abstract/helpers";

export default class TaskService {
  static CRUD_URL = "tasks";

  static async changeSelectedTask(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid);
  }

  static async getTasks(params) {
    const extraParams = prepSearchUrlParameters(params);
    return APIService.request(this.CRUD_URL + "? " + extraParams);
  }

  static async updateTask(params) {
    return APIService.request(this.CRUD_URL + "/" + params.uuid, "put", params);
  }

  static async createTask(params) {
    return APIService.request(this.CRUD_URL, "post", params);
  }
}
