<template>
  <div>
    <div class="row align-items-center">
      <div class="col-3">
        {{ dateTimeFormat(manualAml.author_decision_date) }}
      </div>
      <div class="col-3">
        <div> {{ capitaliseFirstLetter(manualAml.author_decision) }}</div>
      </div>
      <div class="col-2">
        {{ manualAml.author }}
      </div>
      <div class="col-2">
        {{ manualAml.notes }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    manualAml: {
      required: true,
    },
    index: {
      required: true,
    },
  },

  data() {
    return {
      stateKey: "accountData",
    };
  },
};
</script>
