<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item>
        <template v-slot:title>
          Customer AML - Status:
          <span v-if="accountVerified">
            Customer Verified
          </span>
          <span v-else>
            Customer Not Verified
          </span>
        </template>

        <v-add-manual-aml
          v-if="!accountVerified && checkPermission('create_aml')"
          :account-uuid="accountUuid"
        />

        <v-amls :account-uuid="accountUuid" />

        <v-manual-amls :account-uuid="accountUuid" />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VAmls from "js-admin/components/accounts/amls/Amls";
import VManualAmls from "js-admin/components/accounts/manual-amls/ManualAmls";
import VAddManualAml from "js-admin/components/accounts/manual-amls/AddManualAml";

export default {
  components: {
    VAmls,
    VManualAmls,
    VAddManualAml,
  },
  props: {
    accountUuid: {
      required: true,
    },
  },

  computed: {
    accountVerified() {
      return this.$store.state.fieldStore.accountData.is_verified_customer;
    },
  },

  methods: {},
};
</script>
