<template>
  <div class="c-select-input" :class="['t-' + field, { '-focus': value }]">
    <div class="c-select-input-label" v-text="label" />

    <div class="c-select-input-group">
      <select
        v-model="value"
        v-validate="validation"
        :name="field"
        :class="{ '-error': hasErrors && !focused, '-value': value }"
        :data-vv-as="errorName"
        :disabled="disabled"
        class="c-select-input-field"
        @blur="handleBlur"
        @focus="handleFocus"
        @change="updateModel"
      >
        <option
          v-if="enableBlankOption"
          :value="placeholder"
          selected
          v-text="placeholder"
        />
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.uuid || option.value || option || option.title"
        >
          {{ option.name || option.title || option }}
        </option>
      </select>
      <div class="c-select-input-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M8 0L6.5 1.5 12.1 7H0v2h12.1l-5.6 5.5L8 16l8-8z" />
        </svg>
      </div>
    </div>
    <v-input-hidden
      ref="input"
      :scope="scope"
      :field="field"
      :state-key="stateKey"
      :error-name="errorName"
      :focused="focused"
      :center-error="centerError"
      :validation="validation"
    />
  </div>
</template>

<script>
export default {
  inject: ["$validator"],
  props: {
    options: {
      type: Array,
      required: false,
    },
    field: {
      type: String,
      required: true,
    },
    stateKey: {
      type: String,
      required: true,
    },
    instructions: {
      type: String,
      required: false,
    },
    alwaysInstructions: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    validation: {
      default: "required",
    },
    scope: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null,
    },
    errorName: {
      type: String,
      default: null,
    },
    centerError: {
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
    enableBlankOption: {
      default: false,
    },
  },
  data() {
    return {
      focus: false,
    };
  },
  computed: {
    focused() {
      if (typeof this.$store.getters.getFocused !== "undefined") {
        return this.$store.getters.getFocused;
      } else {
        return this.focus;
      }
    },
    fieldRef() {
      return this.scope + "-" + this.field;
    },
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
    value: {
      get() {
        if (this.$store.state.fieldStore[this.stateKey][this.field]) {
          if (
            this.field === "task_priority" ||
            this.field === "task_status" ||
            this.field === "task_owner"
          ) {
            let cleanedVal = this.$store.state.fieldStore[this.stateKey][
              this.field
            ].toLowerCase();
            cleanedVal = cleanedVal.replace(/\s/g, "-");
            if (cleanedVal === "waiting-on-information") {
              cleanedVal = "waiting-info";
            }
            return cleanedVal;
          } else {
            return this.$store.state.fieldStore[this.stateKey][this.field];
          }
        }
        return this.placeholder;
      },
      set(val) {
        this.$emit("selectionUpdated", val);
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: this.field,
        });
      },
    },
  },
  mounted() {
    window.addEventListener("keyup", this.keySelect);
  },
  destroyed() {
    window.removeEventListener("keyup", this.keySelect);
  },

  methods: {
    updateModel() {
      this.$emit("updated", this.value);
    },
    handleBlur() {
      if (!this.value) {
        this.focus = false;
      }
    },
    handleFocus() {
      this.focus = true;
    },
  },
};
</script>
