import { Client } from "@ideal-postcodes/core-browser";
import Address from "js-admin/Models/Address";

class AddressLookup {
  constructor() {
    if (!AddressLookup.instance) {
      this.client = new Client({
        api_key: "ak_l06isbgz5YUj9kglIURLPndrXsH38",
      });
      AddressLookup.instance = this;
    }

    return AddressLookup.instance;
  }

  async lookup(postcode) {
    let results = [];
    const addresses = [];

    try {
      results = await this.client.lookupPostcode({
        postcode: postcode,
        filter: [
          "line_1",
          "line_2",
          "line_3",
          "post_town",
          "postal_county",
          "postcode",
        ],
      });
    } catch (error) {
      return {
        addresses: addresses,
        total: addresses.length,
      };
    }

    if (results) {
      results.forEach((item) => {
        addresses.push(new Address(item));
      });
    }

    return {
      addresses: addresses,
      total: addresses.length,
    };
  }
}

// This is to ensure that the class is a Singleton
const instance = new AddressLookup();
Object.freeze(instance);

export default instance;
