<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item>
        <template v-slot:title>
          User Logins ({{ account_users_count }})
        </template>

        <VLinkedUserTable />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VLinkedUserTable from "js-admin/components/accounts/users/LinkedUsersTable.vue";

export default {
  components: {
    VLinkedUserTable,
  },
  data() {
    return {
      stateKey: "accountData",
    };
  },

  computed: {
    account_users_count() {
      if (this.$store.state.fieldStore[this.stateKey].users) {
        return this.$store.state.fieldStore[this.stateKey].users.length;
      } else {
        return 0;
      }
    },
  },
};
</script>
