<template>
  <div class="c-address-input">
    <v-text-input
      label="Address Line 1"
      :field="prefix + 'line_1'"
      placeholder=""
      :state-key="stateKey"
      :scope="scope"
      :namespace="namespace"
      autocomplete="shipping street-address address_1"
    />
    <v-text-input
      :label="'Address Line 2'"
      :field="prefix + 'line_2'"
      placeholder=""
      :state-key="stateKey"
      :scope="scope"
      :namespace="namespace"
      validation=""
      autocomplete="address_2"
    />
    <v-text-input
      :label="'Address Line 3'"
      :field="prefix + 'line_3'"
      placeholder=""
      :state-key="stateKey"
      :scope="scope"
      :namespace="namespace"
      validation=""
      autocomplete="address_3"
    />
    <v-text-input
      label="City/Town"
      :field="prefix + 'town'"
      placeholder=""
      :state-key="stateKey"
      :scope="scope"
      :namespace="namespace"
      autocomplete="shipping locality town city"
    />
    <v-text-input
      label="County"
      :field="prefix + 'county'"
      placeholder=""
      :state-key="stateKey"
      :scope="scope"
      validation=""
      :namespace="namespace"
      autocomplete="shipping region county"
    />
    <v-text-input
      :field="prefix + 'postcode'"
      label="Postcode"
      validation="required|verify_postcode"
      placeholder=""
      :state-key="stateKey"
      :scope="scope"
      :namespace="namespace"
      autocomplete="shipping postal-code postcode"
      class="-small-width"
      :uppercase-field="true"
    />
    <v-combo-box
      :scope="scope"
      :namespace="namespace"
      :options="countries"
      :focus-on-mounted="false"
      class="-no-left-padding"
      :field="prefix + 'country'"
      label="Country"
      :state-key="stateKey"
      error-name="country"
      validation="required"
      autocomplete="country"
    />
  </div>
</template>

<script>
export default {
  inject: ["$validator"],

  props: {
    stateKey: {
      required: true,
      type: String,
    },
    scope: {
      required: true,
      type: String,
    },
    namespace: {
      default: null,
      type: String,
    },
    prefix: {
      default: "",
      type: String,
    },
  },

  computed: {
    countries() {
      return this.$store.state.config.countries;
    },
  },
};
</script>
