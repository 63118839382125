<template>
  <div class="v-stats">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            User Roles and Permissions
          </h1>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-12">
          <VAddPermission />
        </div>
      </div> -->

      <div class="row">
        <div class="col-lg-10 col-sm-12">
          <VRoles />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VRoles from "js-admin/components/rolesPermissions/Roles.vue";
// import VAddPermission from "js-admin/components/rolesPermissions/AddPermission.vue";

export default {
  components: {
    VRoles,
    // VAddPermission,
  },
  mounted() {
    if (!this.checkRole("superadmin")) {
      return this.$router.push({ name: "home" });
    }
  },
};
</script>
