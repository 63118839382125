<template>
  <div class="v-login">
    <section class="c-section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="c-admin-page-title mb-4">
              {{ companyName }} Admin Dashboard Login
            </h1>
            <h3 class="c-admin-page-subtitle mb-4">
              Sign In to access dashboard
            </h3>
            <form :data-vv-scope="scope" @submit.prevent="sendForm">
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                :focus-on-mounted="true"
                field="username"
                label="Username"
                validation="required"
              />
              <br />
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                field="password"
                type="password"
                label="Password"
                validation="required"
              />

              <div v-if="serverError" style="position: relative;">
                <div class="c-input-error -server text-left">
                  {{ serverError }}
                </div>
              </div>

              <br />

              <v-button :loading="loading">
                Login
              </v-button>

              <a
                :href="false"
                class="c-button -secondary ml-4"
                @click="forgottenPasswordPage"
              >
                Forgotten Password?
              </a>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AuthService from "js-admin/classes/AuthService";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      scope: "loginForm",
      stateKey: "loginFields",
      serverError: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      companyName: "getCompanyName",
    }),
    loggedUser() {
      return this.$store.state.user;
    },
  },
  async created() {
    this.$store.dispatch("setUserId").then(() => {
      if (this.$store.state.me) {
        this.$router.push({ name: "home" });
      }
    });
  },
  methods: {
    async sendForm() {
      const isValid = await this.$validator.validateAll(this.scope);
      if (!isValid) {
        setTimeout(() => {
          this.$scrollTo(".c-input-error", 500, { offset: -100 });
        }, 400);
        return false;
      }

      this.loading = true;
      const username = this.$store.state.fieldStore[this.stateKey].username;
      const password = this.$store.state.fieldStore[this.stateKey].password;
      try {
        const twofactorType = await AuthService.login({
          username,
          password,
          token: await this.generateRecaptchaToken("login"),
        });
        this.$emit("two-factor", twofactorType);
      } catch (error) {
        if (error.data?.errors) {
          this.serverError = error.errors[Object.keys(error.errors)[0]];
        }
        this.serverError = error;
      }
      this.loading = false;
    },
    forgottenPasswordPage() {
      this.$store.dispatch("updateStoreItem", {
        value: this.$store.state.fieldStore[this.stateKey].username,
        stateKey: "forgottenPassword",
        field: "username",
      });
      this.$router.push({ name: "forgotten-password" });
    },
  },
};
</script>
