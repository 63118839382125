<template lang="html">
  <div class="c-search-result h-mt-1">
    <div class="row">
      <div class="col-12">
        <div class="c-admin-table">
          <div class="c-admin-table-header">
            <div class="row">
              <div class="col-1"> </div>
              <div class="col-2">
                First Name
              </div>
              <div class="col-2">
                Last Name
              </div>
              <div class="col-2">
                Primary Contact Number
              </div>
              <div class="col-3">
                Email
              </div>
              <div class="col-2">
                Owner
              </div>
            </div>
          </div>

          <div
            v-for="(result, index) in getSearchResult"
            :key="index"
            :class="['c-admin-table-row', {}]"
          >
            <div class="row">
              <div class="col-1">
                <span @click="selectData(index)">
                  <VCheckboxVisual :active="selectArr[index]" />
                </span>
              </div>
              <div class="col-2">
                {{ result.first_name }}
              </div>
              <div class="col-2">
                {{ result.last_name }}
              </div>
              <div class="col-2">
                {{ result.primary_contact_number }}
              </div>
              <div class="col-3 text-break">
                {{ result.email }}
              </div>
              <div class="col-2">
                <span v-if="result.owner_name">
                  {{ result.owner_name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 h-mt-1 text-right">
        <span @click="handleMerge">
          <v-button class="c-button -admin-small h-mr-1">
            Go
          </v-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import VCheckboxVisual from "js-admin/components/form/checkbox/CheckboxVisual.vue";

export default {
  components: {
    VCheckboxVisual,
  },
  props: {},
  data() {
    return {
      selectArr: [],
      orderArr: [],
      orderInd: null,
      selectAll: false,
      selectCount: 0,
    };
  },
  computed: {
    ...mapGetters(["getSearchResult"]),
  },
  watch: {
    getSearchResult(n, o) {
      for (let i = 0; i < n.length; i++) {
        this.selectArr.push(false);
      }
    },
  },
  methods: {
    ...mapMutations(["updateMergeAccountArray"]),
    handleMerge() {
      const functionArr = [];
      for (let i = 0; i < this.selectArr.length; i++) {
        if (this.selectArr[i] == true) {
          functionArr.push(
            new Promise((resolve, reject) => {
              this.$store
                .dispatch("fetchAccountData", this.getSearchResult[i].uuid)
                .then((result) => {
                  resolve(result.data.data);
                });
            })
          );
        }
      }
      Promise.all(functionArr).then((values) => {
        this.$store.commit("updateMergeAccountArray", values);
      });
    },
    handleSelectAll() {
      this.selectAll = !this.selectAll;
      for (let i = 0; i < this.selectArr.length; i++) {
        this.$set(this.selectArr, i, this.selectAll);
      }
    },
    selectData(index) {
      let bool = null;
      bool = this.selectArr[index] != true;
      this.$set(this.selectArr, index, bool);
    },
  },
};
</script>
