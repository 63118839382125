<template>
  <div class="v-stats">
    <section class="c-section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="c-admin-page-title mb-4">
              {{ companyName }} Admin Dashboard - Password Reset
            </h1>

            <div>
              <transition name="fade" mode="out-in">
                <div v-if="!expired">
                  <form
                    v-if="!completed"
                    :data-vv-scope="passwordResetForm"
                    @submit.prevent="submitForm"
                  >
                    <h3 class="c-admin-page-subtitle mb-4">
                      Complete the form below to reset your password.
                    </h3>

                    <div>
                      <v-text-input
                        :scope="passwordResetForm"
                        :state-key="stateKey"
                        :disabled="true"
                        field="username"
                        label="Email address"
                        error-name="email address"
                        validation="required|email"
                      />
                    </div>

                    <div>
                      <v-text-input
                        :scope="passwordResetForm"
                        :state-key="stateKey"
                        type="password"
                        field="new_password"
                        label="New Password"
                        error-name="new password"
                        validation="required|min:8"
                      />
                    </div>

                    <div>
                      <v-text-input
                        :scope="passwordResetForm"
                        :state-key="stateKey"
                        type="password"
                        field="password_confirmation"
                        label="Confirm Password"
                        error-name="confirm password"
                        :validation="{ required: true, confirmed: newPassword }"
                      />
                    </div>

                    <div>
                      <v-button
                        class="mt-3"
                        :loading="submittingData"
                        :disabled="submittingData"
                      >
                        Reset password
                      </v-button>
                    </div>
                  </form>

                  <div v-else>
                    <h3 class="c-admin-page-subtitle mb-4">
                      Your password has been successfully updated.
                    </h3>
                    <p
                      >You are now being automatically redirected to the login
                      page...</p
                    >
                    <a :href="false" class="c-button -secondary" @click="login">
                      Login
                    </a>
                  </div>
                </div>
                <div v-else>
                  <h3 class="c-admin-page-subtitle mb-4">
                    Looks like your password reset link has expired.
                  </h3>
                  <p>
                    Please request another one using the forgotten password
                    page.
                  </p>
                  <a
                    :href="false"
                    class="c-button -secondary"
                    @click="forgottenPassword"
                  >
                    Forgotten Password
                  </a>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UserService from "js-admin/classes/AjaxServices/UserService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      stateKey: "passwordReset",
      passwordResetForm: "passwordResetForm",
      completed: false,
      expired: false,
      submittingData: false,
      token: null,
    };
  },

  computed: {
    ...mapGetters({
      companyName: "getCompanyName",
    }),
    newPassword() {
      return this.$store.state.fieldStore[this.stateKey].new_password;
    },
  },

  mounted() {
    this.token = this.$route.query.token;

    if (!this.token) {
      this.$router.push({ name: "login" });
    }

    this.checkToken();
  },

  methods: {
    checkToken() {
      UserService.verifyResetToken({ token: this.token })
        .then((response) => {
          this.$store.dispatch("updateStoreItem", {
            value: response.data.data.username,
            stateKey: this.stateKey,
            field: "username",
          });
        })
        .catch(() => {
          this.expired = true;
        });
    },

    resetForm() {
      this.$store.dispatch("updateStoreItem", {
        value: {
          token: null,
          username: null,
          new_password: null,
          password_confirmation: null,
        },
        stateKey: this.stateKey,
        field: null,
      });
    },

    forgottenPassword() {
      this.resetForm();
      this.$router.push({ name: "forgotten-password" });
    },

    login() {
      this.$store.dispatch("updateStoreItem", {
        value: this.$store.state.fieldStore[this.stateKey].username,
        stateKey: "loginFields",
        field: "username",
      });

      this.resetForm();
      this.$router.push({ name: "login" });
    },

    submitForm() {
      this.$validator
        .validateAll(this.passwordResetForm)
        .then(async (result) => {
          if (result) {
            this.submittingData = true;

            const params = {
              token: this.token,
              username: this.$store.state.fieldStore[this.stateKey].username,
              password: this.$store.state.fieldStore[this.stateKey]
                .new_password,
              password_confirmation: this.$store.state.fieldStore[this.stateKey]
                .password_confirmation,
            };

            UserService.resetMyPassword(params)
              .then(() => {
                this.completed = true;
              })
              .catch((error) => {
                FlashMessageService.error(error.response.data.message);
              });
            this.submittingData = false;
          } else {
            setTimeout(() => {
              this.$scrollTo(".c-input-error", 500, { offset: -100 });
            }, 400);
          }
        });
    },
  },
};
</script>
