<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2 text-center">
        <div v-if="!bankDetails.primary_bank_details">
          <div v-if="checkPermission('update_bank_details')">
            <a
              v-if="!makePrimaryInProgress"
              :href="false"
              class="c-button -admin-small"
              @click="makePrimary(bankDetails.uuid)"
            >
              Make Primary Bank Details
            </a>
            <v-loader v-else />
          </div>
        </div>
        <div v-else>
          Primary Bank Details
        </div>
      </div>
      <div class="col-1" />
      <div class="col-6">
        <div class="row align-items-center">
          <div class="col-12">
            <div v-if="bankDetails.bank_account_name">
              <div class="row align-items-center">
                <div class="col-5">
                  Bank Account Name:
                </div>
                <div class="col-7">
                  {{ bankDetails.bank_account_name }}
                </div>
              </div>
            </div>
            <div v-if="bankDetails.bank_account_iban">
              <div class="row align-items-center">
                <div class="col-5">
                  IBAN:
                </div>
                <div class="col-7">
                  {{ bankDetails.bank_account_iban_last_digits }}
                </div>
              </div>
            </div>
            <div v-if="bankDetails.bank_account_number">
              <div class="row align-items-center">
                <div class="col-5">
                  Bank Account Number:
                </div>
                <div class="col-7">
                  {{ bankDetails.bank_account_number_last_digits }}
                </div>
              </div>
            </div>
            <div v-if="bankDetails.bank_sort_code">
              <div class="row align-items-center">
                <div class="col-5">
                  Sort Code:
                </div>
                <div class="col-7">
                  {{ bankDetails.bank_sort_code }}
                </div>
              </div>
            </div>
            <div v-if="bankDetails.bank_payment_reference">
              <div class="row align-items-center">
                <div class="col-5">
                  Bank Payment Reference:
                </div>
                <div class="col-7">
                  {{ bankDetails.bank_payment_reference }}
                </div>
              </div>
            </div>
            <div v-if="bankDetails.bank_special_circumstances">
              <div class="mt-2">
                <u> Bank Special Circumstances: </u>
              </div>
              <div>
                {{ bankDetails.bank_special_circumstances }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="checkPermission('delete_bank_details')"
        class="col-3 text-center"
      >
        <a
          :href="false"
          class="c-button -admin-small"
          @click="deleteBankDetails(bankDetails.uuid)"
        >
          Delete
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import BankDetailsService from "js-admin/classes/AjaxServices/BankDetailsService";

export default {
  props: {
    bankDetails: {
      required: true,
    },
    index: {
      required: true,
    },
  },

  data() {
    return {
      stateKey: "accountData",
      makePrimaryInProgress: false,
    };
  },

  computed: {
    accountUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
  },

  methods: {
    makePrimary(uuid) {
      this.makePrimaryInProgress = true;

      BankDetailsService.updatePrimaryBankDetails(this.accountUuid, uuid).then(
        (response) => {
          this.makePrimaryInProgress = false;
          if (response.data) {
            FlashMessageService.success("Bank Details have been updated.");
            this.$parent.$parent.$parent.$parent.paginate();
          } else {
            console.log(response);
            return false;
          }
        }
      );
    },

    deleteBankDetails(uuid) {
      if (confirm("Are you sure you want to delete these bank details?")) {
        BankDetailsService.deleteBankDetails(uuid)
          .then(() => {
            FlashMessageService.success("Bank Details have been deleted.");
            this.$parent.$parent.$parent.$parent.paginate();
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      }
    },
  },
};
</script>
