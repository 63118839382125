<template>
  <div class="row">
    <div class="col-12">
      <div class="c-admin-panel">
        <v-accordion :open-first-item="true">
          <v-accordion-item>
            <template v-slot:title>
              <slot name="title"></slot>
            </template>
            <div class="c-admin-table">
              <div class="c-admin-table-header -bold">
                <div class="row">
                  <div class="col-2">Date</div>
                  <div class="col-2">Brochure</div>
                  <div class="col-2">Callback</div>
                  <div class="col-2">Contact</div>
                  <div class="col-2">Investments Started</div>
                  <div class="col-2">Total</div>
                </div>
              </div>
              <div v-if="leads">
                <div
                  v-for="data in leads"
                  :key="data.owner_uuid"
                  class="c-admin-table-row"
                >
                  <div class="row">
                    <div class="col-2">
                      {{ data.date }}
                    </div>
                    <div
                      :class="[
                        'col-2',
                        {
                          'h-color-greyed ': data.brochure === 0,
                          'h-color-highlight ': data.brochure > 0,
                        },
                      ]"
                    >
                      {{ data.brochure }}
                    </div>
                    <div
                      :class="[
                        'col-2',
                        {
                          'h-color-greyed ': data.callback === 0,
                          'h-color-highlight ': data.callback > 0,
                        },
                      ]"
                    >
                      {{ data.callback }}
                    </div>
                    <div
                      :class="[
                        'col-2',
                        {
                          'h-color-greyed ': data.contact === 0,
                          'h-color-highlight ': data.contact > 0,
                        },
                      ]"
                    >
                      {{ data.contact }}
                    </div>
                    <div
                      :class="[
                        'col-2',
                        {
                          'h-color-greyed ': data.investments === 0,
                          'h-color-highlight ': data.applications > 0,
                        },
                      ]"
                    >
                      {{ data.applications }}
                    </div>
                    <div
                      :class="[
                        'col-2',
                        {
                          'h-color-greyed ': data.total === 0,
                          'h-color-highlight ': data.total > 0,
                        },
                      ]"
                    >
                      {{ data.total }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="c-admin-table-row">
                <div class="row">
                  <div class="col-12"> No results found. </div>
                </div>
              </div>
            </div>
          </v-accordion-item>
        </v-accordion>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leads: {
      type: Array,
      default: null,
    },
  },
};
</script>
