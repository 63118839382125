<template>
  <div class="v-account">
    <div>
      <section class="c-section">
        <div class="row">
          <div class="col-12">
            <h1 class="c-admin-page-title">
              {{ pageTitle }}
            </h1>

            <a
              v-if="decisionMade"
              :href="false"
              class="c-button -admin-small mt-3"
              @click="backButton"
            >
              Back
            </a>
          </div>
        </div>

        <div v-if="!decisionMade" class="row mt-5">
          <div class="col-6">
            <a
              :href="false"
              class="c-circle-buttons"
              @click="toggleMode('account')"
            >
              <i class="fas fa-user" />
              New Account
            </a>
          </div>

          <div class="col-6">
            <a
              :href="false"
              class="c-circle-buttons"
              @click="toggleMode('lead')"
            >
              <i class="fas fa-folder-open" />
              New Lead
            </a>
          </div>
        </div>

        <div v-if="showAccountForm" class="row">
          <div class="col-xl-9 col-lg-12">
            <VCreateAccountForm :linked-call="linkedCall" />
          </div>
        </div>

        <div v-if="showLeadForm" class="row">
          <div class="col-xl-9 col-lg-12">
            <VCreateLeadForm :linked-call="linkedCall" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VCreateLeadForm from "js-admin/components/leads/CreateLeadForm.vue";
import VCreateAccountForm from "js-admin/components/accounts/CreateAccountForm.vue";

export default {
  components: {
    VCreateLeadForm,
    VCreateAccountForm,
  },
  data() {
    return {
      decisionMade: false,
      showAccountForm: false,
      showLeadForm: false,
    };
  },

  computed: {
    pageTitle() {
      if (this.showLeadForm) {
        return "Create New Lead";
      }
      if (this.showAccountForm) {
        return "Create New Account";
      }
      return "Create New Account/Lead";
    },
    contactNumber() {
      if (this.$route.query.contact_number) {
        return this.$route.query.contact_number;
      }
      return null;
    },
    linkedCall() {
      if (this.$route.query.call_uuid) {
        return this.$route.query.call_uuid;
      }
      return null;
    },
  },

  created() {
    this.$store.dispatch("updateStoreItem", {
      value: this.getEmptyAccount(this.contactNumber),
      stateKey: "accountData",
      field: null,
    });

    this.$store.dispatch("updateStoreItem", {
      value: this.getEmptyLead(this.contactNumber),
      stateKey: "createLead",
      field: null,
    });
    this.clearErrors(1);
  },

  mounted() {
    if (
      !this.checkPermission("create_account") ||
      this.systemFeatureIsDisabled("disabled_create_account")
    ) {
      return this.$router.push({ name: "home" });
    }
  },

  methods: {
    toggleMode(mode) {
      this.decisionMade = true;
      if (mode === "lead") {
        this.showLeadForm = true;
        return;
      } else if (mode === "account") {
        this.showAccountForm = true;
        return;
      }
      this.decisionMade = false;
    },
    backButton() {
      this.decisionMade = false;
      this.showAccountForm = false;
      this.showLeadForm = false;
    },
  },
};
</script>
