<template>
  <div>
    <div v-if="accountDocumentsCount" class="c-admin-table">
      <div class="c-admin-table-header">
        <div class="row align-items-center">
          <div class="col-3">
            Uploaded At
          </div>
          <div class="col-2">
            Type
          </div>
          <div class="col-3">
            File Name
          </div>
          <div class="col-2 text-center" />
          <div
            v-if="checkPermission('delete_files')"
            class="col-2 text-center"
          />
        </div>
      </div>

      <div
        v-for="(document, index) in accountDocuments"
        :key="document.uuid"
        class="c-admin-table-row"
      >
        <VDocumentsRow
          :document="document"
          :index="index"
          :state-key="stateKey"
        />
      </div>
    </div>
    <div v-else>
      No linked documents.
    </div>
  </div>
</template>

<script>
import VDocumentsRow from "js-admin/components/documents/DocumentsRow.vue";

export default {
  components: {
    VDocumentsRow,
  },
  props: {
    stateKey: {
      required: true,
    },
  },
  computed: {
    accountDocumentsCount() {
      if (this.$store.state.fieldStore[this.stateKey].documents) {
        return this.$store.state.fieldStore[this.stateKey].documents.length;
      } else {
        return 0;
      }
    },
    accountDocuments() {
      return this.$store.state.fieldStore[this.stateKey].documents;
    },
  },

  methods: {
    toggle(selectedItem) {
      this.$children.forEach((item) => {
        item.showEditForm = item._uid == selectedItem._uid;
      });
    },
  },
};
</script>
