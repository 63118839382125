<template>
  <div>
    <div class="c-admin-panel">
      <div class="c-admin-panel-section">
        <div class="row">
          <div class="col-12">
            <h3 class="c-admin-panel-title">
              Cash Wallet Information
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="c-admin-alert-text">
              Reference Number:
            </div>
          </div>
          <div class="col-6">
            <div class="c-admin-alert-text">
              {{ cashWallet.reference_number }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="c-admin-alert-text">
              Total Balance:
            </div>
          </div>
          <div class="col-6">
            <div class="c-admin-alert-text">
              {{
                cashWallet.currency.symbol +
                  convertToPounds(cashWallet.total_balance)
              }}
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-5">
            <div class="c-admin-alert-text">
              Available Balance:
            </div>
          </div>
          <div class="col-6">
            <div class="c-admin-alert-text">
              {{
                cashWallet.currency.symbol +
                  convertToPounds(cashWallet.available_balance)
              }}
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-5">
            <div>
              External Reference Number:
            </div>
          </div>
          <div class="col-6">
            <div>
              {{ cashWallet.external_reference_number }}
            </div>
          </div>
        </div>

        <div v-if="cashWallet.currency" class="row mt-2">
          <div class="col-5">
            <div>
              Currency:
            </div>
          </div>
          <div class="col-6">
            <div>
              {{ cashWallet.currency.code }}
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-5">
            <div>
              Has Pending Withdrawal:
            </div>
          </div>
          <div class="col-6">
            <div>
              <span>
                {{ cashWallet.has_pending_withdrawal }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cashWallet: {
      required: true,
    },
  },
};
</script>
