<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2">
        {{ dateTimeFormat(aml.ran_date) }}
      </div>

      <div class="col-2">
        <div> {{ aml.provider }}</div>
      </div>
      <div class="col-3">
        {{ aml.external_verification_id }}
      </div>
      <div class="col-3">
        <div v-if="aml.score">
          Score: <strong> {{ aml.score }} </strong>
        </div>
        <div>
          Result:
          <strong> {{ capitaliseFirstLetter(aml.decision_result) }} </strong>
        </div>
      </div>
      <div class="col-2">
        <a
          v-if="aml.pdf_url"
          :href="aml.pdf_url"
          class="c-button -admin-small"
          target="_blank"
          rel="noopener"
        >
          View
        </a>
        <span v-else> No file yet </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aml: {
      required: true,
    },
    index: {
      required: true,
    },
  },
};
</script>
