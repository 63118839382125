<template>
  <div>
    <div class="c-admin-panel">
      <div class="c-admin-panel-section">
        <div class="row">
          <div class="col-12">
            <h3 class="c-admin-panel-title">
              Investment Account Information
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="c-admin-alert-text">
              Reference Number:
            </div>
          </div>
          <div class="col-6">
            <div class="c-admin-alert-text">
              {{ investmentAccount.reference_number }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="c-admin-alert-text">
              Stage:
            </div>
          </div>
          <div class="col-6">
            <div class="c-admin-alert-text">
              {{ investmentAccountStage }}
            </div>
          </div>
        </div>

        <div
          v-if="investmentAccount.external_investment_account_id"
          class="row mt-2"
        >
          <div class="col-5">
            <div>
              External Reference Number:
            </div>
          </div>
          <div class="col-6">
            <div>
              {{ investmentAccount.external_investment_account_id }}
            </div>
          </div>
        </div>

        <div v-if="investmentAccount.open_date" class="row mt-2">
          <div class="col-5">
            <div>
              Open Date:
            </div>
          </div>
          <div class="col-6">
            <div>
              {{ dateFormat(investmentAccount.open_date) }}
            </div>
          </div>
        </div>

        <div v-if="investmentAccount.closed_on" class="row mt-2">
          <div class="col-5">
            <div>
              Closed Date:
            </div>
          </div>
          <div class="col-6">
            <div>
              {{ dateFormat(investmentAccount.closed_on) }}
            </div>
          </div>
        </div>

        <div v-if="investmentAccount.product_type" class="row mt-2">
          <div class="col-5">
            <div>
              Product Type:
            </div>
          </div>
          <div class="col-6">
            <div>
              {{ investmentAccount.product_type.toUpperCase() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    investmentAccount: {
      required: true,
    },
  },

  computed: {
    investmentAccountStage() {
      const temp = this.investmentAccount.stage.split("_").join(" ");
      return this.capitaliseFirstLetter(temp);
    },
  },
};
</script>
