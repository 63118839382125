<template>
  <!-- Start of personal details section -->
  <div>
    <h3
      v-if="accountInvestorType === 'Corporate'"
      class="c-admin-panel-title m-0"
    >
      Main Correspondence
    </h3>
    <div class="c-admin-panel mt-2">
      <form :data-vv-scope="updateFormScope" @submit.prevent="updateAccount">
        <div v-if="!investmentsAccountsCount" class="c-admin-panel-section">
          <h3 class="c-admin-panel-title">
            Lead Status
          </h3>
          <div class="row">
            <div class="col-6">
              <v-select-input
                :state-key="stateKey"
                :options="leadStatuses"
                :center-error="false"
                field="lead_status"
                label=""
                error-name="Select lead status"
                placeholder="Select lead status"
                validation="required"
                :enable-blank-option="true"
              />
            </div>
            <div v-if="leadStatus === 'Closed Lost'" class="col-6">
              <v-select-input
                :state-key="stateKey"
                :options="leadClosedReasons"
                :center-error="false"
                field="lead_closed_reason"
                label=""
                error-name="Select lead closed reason"
                placeholder="Select lead closed reason"
                validation="required"
                :enable-blank-option="true"
              />
            </div>
          </div>
        </div>
        <div class="c-admin-panel-section">
          <div class="row">
            <div class="col-5">
              <h3 class="c-admin-panel-title">
                Personal Details
              </h3>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="title"
                  label="Title"
                  validation=""
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="first_name"
                  label="First Name"
                  validation="required"
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="middle_name"
                  label="Middle Name"
                  validation=""
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="last_name"
                  label="Last Name"
                  validation="required"
                />
              </div>
            </div>
            <div class="col-7">
              <h3 class="c-admin-panel-title">
                Contact Details
              </h3>

              <div class="row">
                <div
                  class="col-8"
                  :class="{
                    'col-12':
                      !checkPermission('make_phone_calls') ||
                      systemFeatureIsDisabled('disabled_phone_system'),
                  }"
                >
                  <v-phone-number-text-input
                    :scope="updateFormScope"
                    :state-key="stateKey"
                    field="primary_contact_number"
                    type="tel"
                    label="Primary Contact Number"
                    error-name="primary contact number"
                    autocomplete="tel"
                    :maxlength="13"
                    validation="required|numeric"
                  />
                </div>
                <div
                  v-if="
                    checkPermission('make_phone_calls') &&
                      !errors.has('primary_contact_number', updateFormScope) &&
                      !systemFeatureIsDisabled('disabled_phone_system')
                  "
                  class="col-4"
                >
                  <div>
                    <a
                      v-if="!callInProgress"
                      :href="false"
                      class="c-button -admin-small -admin-phone-btn"
                      :title="'Call ' + accountPrimaryNumber"
                      @click="callNumber(accountPrimaryNumber)"
                    >
                      <i class="fas fa-phone-volume c-phone-icon" /> Call
                    </a>
                    <v-loader v-else />
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-8"
                  :class="{
                    'col-12':
                      !checkPermission('make_phone_calls') ||
                      systemFeatureIsDisabled('disabled_phone_system'),
                  }"
                >
                  <v-phone-number-text-input
                    :scope="updateFormScope"
                    :state-key="stateKey"
                    field="secondary_contact_number"
                    type="tel"
                    label="Secondary Contact Number"
                    error-name="secondary contact number"
                    autocomplete="tel"
                    :maxlength="13"
                    validation="numeric"
                  />
                </div>
                <div
                  v-if="
                    checkPermission('make_phone_calls') &&
                      accountSecondaryNumber &&
                      !errors.has(
                        'secondary_contact_number',
                        updateFormScope
                      ) &&
                      !systemFeatureIsDisabled('disabled_phone_system')
                  "
                  class="col-4"
                >
                  <div>
                    <a
                      v-if="!callInProgress"
                      :href="false"
                      class="c-button -admin-small -admin-phone-btn"
                      :title="'Call ' + accountSecondaryNumber"
                      @click="callNumber(accountSecondaryNumber)"
                    >
                      <i class="fas fa-phone-volume c-phone-icon" /> Call
                    </a>
                    <v-loader v-else />
                  </div>
                </div>
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="email"
                  label="Email address"
                  validation="email"
                  error-name="email address"
                />
              </div>

              <div>
                <VDobInput
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="dob"
                  label="Date of Birth"
                  error-name="date of birth"
                  validation="dob_format|over_18"
                />
              </div>
            </div>
          </div>

          <h3 class="c-admin-panel-title">
            Primary Address Details
          </h3>
          <div class="row">
            <div class="col-5">
              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="primary_address_1"
                  label="Address Line 1"
                  validation=""
                  error-name="address line 1"
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="primary_address_2"
                  label="Address Line 2"
                  error-name="address line 2"
                  validation=""
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="primary_address_3"
                  label="Address Line 3"
                  error-name="address line 3"
                  validation=""
                />
              </div>
            </div>
            <div class="col-7">
              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="primary_address_town"
                  label="Town"
                  validation=""
                  error-name="town"
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="primary_address_county"
                  label="County"
                  validation=""
                  error-name="county"
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="primary_address_postcode"
                  label="Postcode"
                  validation=""
                  error-name="postcode"
                />
              </div>
              <div>
                <v-combo-box
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  :options="countries"
                  :focus-on-mounted="false"
                  class="-no-left-padding"
                  field="primary_address_country"
                  label="Country"
                  error-name="country"
                />
              </div>
            </div>
          </div>

          <h3 class="c-admin-panel-title">
            Application Details
          </h3>
          <div class="row">
            <div class="col-5">
              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="national_insurance_number"
                  label="National Insurance Number"
                  validation="verify_ni"
                  error-name="national insurance number"
                />
              </div>
            </div>
            <div class="col-7">
              <v-combo-box
                :scope="updateFormScope"
                :state-key="stateKey"
                :options="countries"
                :focus-on-mounted="false"
                class="-no-left-padding"
                field="nationality"
                label="Citizenship"
                error-name="citizenship"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="occupation"
                  label="Occupation"
                  validation=""
                  error-name="occupation"
                />
              </div>
            </div>
            <div class="col-7"> </div>
          </div>

          <h3 class="c-admin-panel-title">
            Other Details
          </h3>
          <div class="row">
            <div class="col-5">
              <div>
                <v-select-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  :options="accountTypes"
                  :center-error="false"
                  field="type"
                  validation=""
                  error-name="account type"
                  placeholder="Select an account type"
                  class="mb-4"
                  :enable-blank-option="true"
                />
              </div>
            </div>
            <div class="col-7">
              <div>
                <VDobInput
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="date_notified_of_death"
                  label="Deceased Date"
                  validation="dob_format|min:10"
                  error-name="deceased date"
                />
              </div>
            </div>
          </div>

          <h3 class="c-admin-panel-title">
            <i class="fas fa-bell" />
            Preferences
          </h3>
          <div class="row mb-5">
            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-8">
                  GPDR Marketing Opt In
                </div>
                <div class="col-4">
                  <div class="c-notifications-switches">
                    <div
                      class="c-notifications-switches-item justify-content-end"
                    >
                      <v-switch
                        field="gdpr_optin_flag"
                        :state-key="stateKey"
                        class="d-inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-8">
                  Marketing notifications
                </div>
                <div class="col-4">
                  <div class="c-notifications-switches">
                    <div
                      class="c-notifications-switches-item justify-content-end"
                    >
                      <v-switch
                        field="subscribed_marketing_notifications"
                        :state-key="stateKey"
                        class="d-inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-8">
                  Product updates
                </div>
                <div class="col-4">
                  <div class="c-notifications-switches">
                    <div
                      class="c-notifications-switches-item justify-content-end"
                    >
                      <v-switch
                        field="subscribed_product_notifications"
                        :state-key="stateKey"
                        class="d-inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-8">
                  Industry news
                </div>
                <div class="col-4">
                  <div class="c-notifications-switches">
                    <div
                      class="c-notifications-switches-item justify-content-end"
                    >
                      <v-switch
                        field="subscribed_industry_news_notifications"
                        :state-key="stateKey"
                        class="d-inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 class="c-admin-panel-title">
            <i class="fas fa-inbox"></i>
            Contact Preferences
          </h3>
          <div class="row mb-5">
            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-8">
                  Phone
                </div>
                <div class="col-4">
                  <div class="c-notifications-switches">
                    <div
                      class="c-notifications-switches-item justify-content-end"
                    >
                      <v-switch
                        field="subscribed_phone"
                        :state-key="stateKey"
                        class="d-inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-8">
                  SMS
                </div>
                <div class="col-4">
                  <div class="c-notifications-switches">
                    <div
                      class="c-notifications-switches-item justify-content-end"
                    >
                      <v-switch
                        field="subscribed_sms"
                        :state-key="stateKey"
                        class="d-inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-8">
                  Email
                </div>
                <div class="col-4">
                  <div class="c-notifications-switches">
                    <div
                      class="c-notifications-switches-item justify-content-end"
                    >
                      <v-switch
                        field="subscribed_email"
                        :state-key="stateKey"
                        class="d-inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-8">
                  Post
                </div>
                <div class="col-4">
                  <div class="c-notifications-switches">
                    <div
                      class="c-notifications-switches-item justify-content-end"
                    >
                      <v-switch
                        field="subscribed_post"
                        :state-key="stateKey"
                        class="d-inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center">
              <v-button
                v-if="checkPermission('edit_account')"
                class="mt-3"
                :loading="submittingAccountPersonalDetails"
                :disabled="submittingAccountPersonalDetails"
              >
                Update
              </v-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- End of personal details section -->
</template>

<script>
import { parsePhoneNumberFromString } from "js-admin/classes/PhoneService";

import FlashMessageService from "js-admin/classes/FlashMessageService";
import VDobInput from "js-admin/components/form/dates/DOBInput.vue";
import AccountService from "js-admin/classes/AjaxServices/AccountService";
import AddressService from "js-admin/classes/AjaxServices/AddressService";
import { formatDate } from "js-admin/abstract/helpers";

export default {
  components: {
    VDobInput,
  },
  props: {
    investmentsAccountsCount: {
      default: 0,
    },
  },

  data() {
    return {
      updateFormScope: "accountData",
      stateKey: "accountData",
      submittingAccountPersonalDetails: false,
      callInProgress: false,
    };
  },

  computed: {
    accountUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
    accountInvestorType() {
      return this.$store.state.fieldStore[this.stateKey].type;
    },
    accountTypes() {
      const types = this.$store.state.config.account_types;
      const accountTypes = [];

      Object.keys(types).forEach((key) => {
        accountTypes.push(types[key]);
      });

      return accountTypes;
    },
    ownerUuid() {
      if (this.$store.state.user.owner) {
        return this.$store.state.user.owner.uuid;
      }
      return null;
    },
    accountPrimaryNumber() {
      return this.$store.state.fieldStore[this.stateKey].primary_contact_number;
    },
    accountSecondaryNumber() {
      return this.$store.state.fieldStore[this.stateKey]
        .secondary_contact_number;
    },
    leadStatuses() {
      const statuses = this.$store.state.config.lead_statuses;
      const leadStatuses = [];

      Object.keys(statuses).forEach((key) => {
        leadStatuses.push(statuses[key]);
      });

      return leadStatuses;
    },
    leadStatus() {
      return this.$store.state.fieldStore[this.stateKey].lead_status;
    },
    leadClosedReasons() {
      const reasons = this.$store.state.config.lead_reasons_closed;
      const leadClosedReasons = [];

      Object.keys(reasons).forEach((key) => {
        leadClosedReasons.push(reasons[key]);
      });

      return leadClosedReasons;
    },
    countries() {
      return this.$store.state.config.countries;
    },
  },

  methods: {
    updateAccount() {
      this.$validator.validateAll(this.updateFormScope).then(async (result) => {
        if (result) {
          this.submittingAccountPersonalDetails = true;

          let accountType = this.$store.state.fieldStore[this.stateKey].type;
          if (accountType === "Select an account type") {
            accountType = null;
          }

          let leadStatus = this.$store.state.fieldStore[this.stateKey]
            .lead_status;
          if (accountType === "Select lead status") {
            leadStatus = null;
          }

          const data = {
            lead_status: leadStatus,
            title: this.$store.state.fieldStore[this.stateKey].title,
            first_name: this.$store.state.fieldStore[this.stateKey].first_name,
            middle_name: this.$store.state.fieldStore[this.stateKey]
              .middle_name,
            last_name: this.$store.state.fieldStore[this.stateKey].last_name,
            primary_contact_number:
              this.$store.state.fieldStore[this.stateKey]
                .primary_contact_number_dial_code +
              this.$store.state.fieldStore[this.stateKey]
                .primary_contact_number,
            secondary_contact_number: this.$store.state.fieldStore[
              this.stateKey
            ].secondary_contact_number,
            email: this.$store.state.fieldStore[this.stateKey].email,
            national_insurance_number: this.$store.state.fieldStore[
              this.stateKey
            ].national_insurance_number,
            nationality: this.$store.state.fieldStore[this.stateKey]
              .nationality,
            occupation: this.$store.state.fieldStore[this.stateKey].occupation,
            type: accountType,
            closed_on: this.$store.state.fieldStore[this.stateKey].closed_on,
            gdpr_optin_flag: this.$store.state.fieldStore[this.stateKey]
              .gdpr_optin_flag,
            subscribed_marketing_notifications: this.$store.state.fieldStore[
              this.stateKey
            ].subscribed_marketing_notifications,
            subscribed_product_notifications: this.$store.state.fieldStore[
              this.stateKey
            ].subscribed_product_notifications,
            subscribed_industry_news_notifications: this.$store.state
              .fieldStore[this.stateKey].subscribed_industry_news_notifications,
            subscribed_phone: this.$store.state.fieldStore[this.stateKey]
              .subscribed_phone,
            subscribed_sms: this.$store.state.fieldStore[this.stateKey]
              .subscribed_sms,
            subscribed_email: this.$store.state.fieldStore[this.stateKey]
              .subscribed_email,
            subscribed_post: this.$store.state.fieldStore[this.stateKey]
              .subscribed_post,
            uuid: this.accountUuid,
          };

          if (leadStatus === "Closed Lost") {
            let leadClosedReason = this.$store.state.fieldStore[this.stateKey]
              .lead_closed_reason;
            if (leadClosedReason === "Select lead closed reason") {
              leadClosedReason = null;
            }
            data.lead_closed_reason = leadClosedReason;
          } else {
            data.lead_closed_reason = null;
          }

          if (
            this.$store.state.fieldStore[this.stateKey].secondary_contact_number
          ) {
            data.secondary_contact_number =
              this.$store.state.fieldStore[this.stateKey]
                .secondary_contact_number_dial_code +
              this.$store.state.fieldStore[this.stateKey]
                .secondary_contact_number;
          }

          if (this.$store.state.fieldStore[this.stateKey].dob) {
            data.dob = formatDate(
              this.$store.state.fieldStore[this.stateKey].dob
            );
          }

          if (
            this.$store.state.fieldStore[this.stateKey].date_notified_of_death
          ) {
            data.date_notified_of_death = formatDate(
              this.$store.state.fieldStore[this.stateKey].date_notified_of_death
            );
          }

          if (
            this.$store.state.fieldStore[this.stateKey]
              .primary_address_country !== "United Kingdom"
          ) {
            delete data.national_insurance_number;
            data.is_international_customer = true;
          } else {
            data.is_international_customer = false;
          }

          await this.modifyAddress();

          AccountService.updateAccount(data)
            .then((response) => {
              this.submittingAccountPersonalDetails = false;
              FlashMessageService.success("Account has been updated");
            })
            .catch((error) => {
              FlashMessageService.error(error.response.data.message);
            });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },

    async modifyAddress() {
      const addressData = {
        uuid: this.$store.state.fieldStore[this.stateKey].primary_address_uuid,
        address_1: this.$store.state.fieldStore[this.stateKey]
          .primary_address_1,
        address_2: this.$store.state.fieldStore[this.stateKey]
          .primary_address_2,
        address_3: this.$store.state.fieldStore[this.stateKey]
          .primary_address_3,
        town: this.$store.state.fieldStore[this.stateKey].primary_address_town,
        county: this.$store.state.fieldStore[this.stateKey]
          .primary_address_county,
        postcode: this.$store.state.fieldStore[this.stateKey]
          .primary_address_postcode,
        country: this.$store.state.fieldStore[this.stateKey]
          .primary_address_country,
        primary_address: true,
        account_uuid: this.accountUuid,
      };

      if (addressData.uuid) {
        await AddressService.updateAddress(addressData)
          .then((response) => {
            FlashMessageService.success("Primary Address has been updated");
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      } else {
        await AddressService.createAddress(addressData)
          .then((response) => {
            this.submittingAccountPersonalDetails = false;
            FlashMessageService.success("Primary Address has been created");
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      }
    },

    callNumber(phone) {
      if (phone) {
        const phoneNumber = parsePhoneNumberFromString(
          phone,
          "GB"
        ).formatInternational();

        if (
          this.checkPermission("make_phone_calls") &&
          phoneNumber &&
          confirm("Do you wish to call:  " + phone + "?")
        ) {
          const data = {
            account_uuid: this.accountUuid,
            owner_uuid: this.ownerUuid,
            to_number: phoneNumber,
          };

          this.callInProgress = true;
          this.$store.dispatch("makePhoneCall", data).then((response) => {
            this.callInProgress = false;
            if (response.data) {
              // do something
            } else {
              console.log(response);
              return false;
            }
          });
        }
      }
    },
  },
};
</script>
