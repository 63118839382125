import HelpDeskService from "js-admin/classes/AjaxServices/HelpDeskService";

export default {
  async getSupportDetails({ commit, getters }, gid) {
    return getters.getHelpdeskTaskList.filter((t) => t.gid === gid)[0];
  },
  async submitHelpdesk({ commit, getters }) {
    return HelpDeskService.submitHelpdesk({
      user_email: getters.getUser.email_address,
      title: getters.getHelpdeskTitle,
      description: getters.getHelpdeskDescription,
      investor_type: getters.getHelpdeskInvestorType,
      issue_type: getters.getHelpdeskIssueType,
      priority: getters.getHelpdeskPriority,
      support_request_type: getters.getSupportType,
    }).then((response) => response);
  },
  async getTasklist({ commit, getters }) {
    return HelpDeskService.getTasklist().then((response) => {
      commit("setHelpdeskList", response);
      return response;
    });
  },
  async sendHelpdeskComment({ commit, getters }, data) {
    return HelpDeskService.sendHelpdeskComment(data).then((response) => {
      return response;
    });
  },
  async getStoriesForTask({ commit, getters }, task_gid) {
    return HelpDeskService.getStoriesForTask(task_gid).then((response) => {
      return response;
    });
  },
  async createStoryForTask({ commit, getters }, data) {
    return HelpDeskService.createStoryForTask(data).then((response) => {
      return response;
    });
  },
  async updateStoryForTask({ commit, getters }, data) {
    return HelpDeskService.updateStoryForTask(data).then((response) => {
      return response;
    });
  },
  async addTaskToSection({ commit, getters }, data) {
    return HelpDeskService.addTaskToSection(data).then((response) => {
      return response;
    });
  },
};
