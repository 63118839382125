<template>
  <div>
    <div class="c-admin-panel">
      <div class="c-admin-panel-section">
        <div class="row">
          <div class="col-12">
            <h3 class="c-admin-panel-title">
              Deposit Information
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="c-admin-alert-text">
              Reference:
            </div>
          </div>
          <div class="col-6">
            <div class="c-admin-alert-text">
              {{ deposit.reference }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="c-admin-alert-text">
              Provider Name:
            </div>
          </div>
          <div class="col-6">
            <div class="c-admin-alert-text">
              {{ capitaliseFirstLetter(deposit.provider_name) }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="c-admin-alert-text">
              Amount:
            </div>
          </div>
          <div class="col-6">
            <div class="c-admin-alert-text">
              {{ deposit.currency.symbol + convertToPounds(deposit.amount) }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="c-admin-alert-text">
              Amount After Correction:
            </div>
          </div>
          <div class="col-6">
            <div class="c-admin-alert-text">
              {{ deposit.currency.symbol + convertToPounds(deposit.amount_after_correction) }}
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-5">
            <div class="c-admin-alert-text">
              Status:
            </div>
          </div>
          <div class="col-6">
            <div class="c-admin-alert-text">
              {{ capitaliseFirstLetter(deposit.status) }}
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-5">
            <div>
              External Status:
            </div>
          </div>
          <div class="col-6">
            <div>
              {{ capitaliseFirstLetter(deposit.external_status) }}
            </div>
          </div>
        </div>

        <div v-if="deposit.currency" class="row mt-2">
          <div class="col-5">
            <div>
              Currency:
            </div>
          </div>
          <div class="col-6">
            <div>
              {{ deposit.currency.code }}
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-5">
            <div>
              Date:
            </div>
          </div>
          <div class="col-6">
            <div>
              {{ dateTimeFormat(deposit.created_at) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deposit: {
      required: true,
    },
  },
};
</script>
