<template>
  <div class="c-select-input" :class="['t-' + field, { '-focus': value }]">
    <div class="c-select-input-label" v-text="label" />

    <div class="c-select-input-group">
      <select
        v-model="value"
        :name="field"
        :class="{ '-error': hasErrors && !focused, '-value': value }"
        :data-vv-as="errorName"
        :disabled="disabled"
        class="c-select-input-field"
      >
        <option
          v-if="enableBlankOption"
          :value="null"
          selected
          v-text="placeholder"
        />

        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.uuid || option.value || option || option.title"
        >
          {{ option.name || option.title || option }}
        </option>
      </select>
      <div class="c-select-input-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M8 0L6.5 1.5 12.1 7H0v2h12.1l-5.6 5.5L8 16l8-8z" />
        </svg>
      </div>
    </div>
    <input
      v-model="value"
      v-validate="validation"
      :name="field"
      :data-vv-as="errorName"
      type="hidden"
    />
    <VErrorMsg
      :scope="scope"
      :field="field"
      :focused="focused"
      :center="centerError"
    />
  </div>
</template>

<script>
import VErrorMsg from "js-admin/components/form/ErrorMsg.vue";

export default {
  components: {
    VErrorMsg,
  },
  inject: ["$validator"],
  props: {
    options: {
      type: Array,
      required: false,
    },
    field: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null,
    },
    errorName: {
      type: String,
      default: null,
    },
    centerError: {
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      default: "required",
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
    enableBlankOption: {
      default: false,
    },
    customRef: {
      default: null,
    },
  },
  data() {
    return {
      focus: false,
      value: null,
    };
  },
  computed: {
    focused() {
      if (typeof this.$store.getters.getFocused !== "undefined") {
        return this.$store.getters.getFocused;
      } else {
        return this.focus;
      }
    },
    fieldRef() {
      if (this.customRef) {
        return this.customRef;
      }
      return this.scope + "-" + this.field;
    },
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
  },
  methods: {},
};
</script>
