<template>
  <div class="c-asana-status">
    <div v-if="getHelpdeskTaskList.length > 0" class="c-admin-table">
      <div v-if="sideLayout">
        <v-asana-table-side-row
          v-for="task in paginatedData"
          :key="task.gid"
          :index="task.gid"
          :task="task"
          :task-status="statusTitle(task)"
          @click.native="viewSupportPage(task.gid)"
        />
        <div class="c-admin-table-footer">
          <div class="row">
            <div class="col-12">
              <v-pagination
                v-if="pagesLength > 0"
                v-model="page"
                :page-count="pagesLength"
                :classes="bootstrapPaginationClasses"
                :labels="paginationAnchorTexts"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="c-admin-table-header">
          <div class="row align-items-center">
            <div class="col-2"> Asana GID </div>
            <div class="col-4"> Name </div>
            <div class="col-4"> Status </div>
            <div class="col-2"> Action </div>
          </div>
        </div>

        <div
          v-for="(task, index) in paginatedData"
          :key="task.gid"
          class="c-admin-table-row"
        >
          <div class="row align-items-center">
            <div class="col-2">
              {{ task.gid }}
            </div>
            <div class="col-4">
              {{ task.name }}
            </div>
            <div class="col-4">
              {{ statusTitle(task) }}
            </div>
            <div class="col-2">
              <v-button
                class="-admin-small -icon-btn"
                @click.native="handleComment(index)"
              >
                <v-comment-button :button-value="buttonArr[index]" />
              </v-button>
            </div>
            <div :ref="`comm_${index}`" class="col-12 c-asana-status-comment">
              <form
                :data-vv-scope="scope"
                @submit.prevent="submitComment(task.gid, index)"
              >
                <v-textarea
                  :scope="scope"
                  state-key="helpdesk"
                  class="mb-3"
                  field="task_comment"
                  label="comment..."
                  placeholder=""
                  validation="required"
                />
                <v-button class="-admin-small"> Send </v-button>
              </form>
            </div>
          </div>
        </div>
        <div class="c-admin-table-footer">
          <div class="row">
            <div class="col-12">
              <v-pagination
                v-if="pagesLength > 0"
                v-model="page"
                :page-count="pagesLength"
                :classes="bootstrapPaginationClasses"
                :labels="paginationAnchorTexts"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else> No support records available! </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import vPagination from "vue-plain-pagination";
import VAsanaTableSideRow from "js-admin/components/helpdesk/AsanaTableSideRow";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VCommentButton from "js-admin/components/helpdesk/CommentBtn";
export default {
  components: {
    VAsanaTableSideRow,
    vPagination,
    VCommentButton,
  },
  props: {
    sideLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scope: "taskScope",
      page: 1,
      perPage: 10,
      pages: [],
      buttonArr: [],
      asanaEl: [],
      tempPageData: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
    };
  },
  computed: {
    ...mapGetters([
      "getHelpdeskTaskList",
      "getAsanaUrl",
      "getAsanaProjectId",
      "getSupportComment",
    ]),
    pagesLength() {
      return this.pages.length;
    },
    paginatedData() {
      return this.paginate(this.getHelpdeskTaskList);
    },
  },
  watch: {
    page() {
      this.resetComments();
    },
    getHelpdeskTaskList(n, o) {
      this.setPages();
    },
  },
  mounted() {
    this.getTasklist().then(() => {
      this.asanaEl = document.querySelectorAll(".c-asana-status-comment");
      for (const i in this.asanaEl) {
        this.buttonArr[i] = false;
      }
    });
  },
  methods: {
    ...mapActions([
      "sendHelpdeskComment",
      "getStoriesForTask",
      "getTasklist",
      "createStoryForTask",
      "addTaskToSection",
      "updateStoryForTask",
    ]),
    handleNav() {
      this.$route.push();
    },
    statusTitle(task) {
      if (task.status && task.status[task.status.length - 1]) {
        return task.status[task.status.length - 1].text || "";
      }
      return "";
    },
    submitComment(task_gid, index) {
      this.createStoryForTask({
        task_gid: task_gid,
        text: this.getSupportComment,
        is_pinned: false,
      }).then(() => {
        FlashMessageService.success("Your support comment has been submitted");
        this.handleComment(index);
      });
    },
    handleComment(ind) {
      this.resetComments(ind);
      this.$refs[`comm_${ind}`][0].classList.toggle("-reveal");
      Vue.set(this.buttonArr, ind, !this.buttonArr[ind]);
    },
    resetComments(ind = null) {
      const commentsEle = document.querySelectorAll(".c-asana-status-comment");
      for (let i = 0; i < commentsEle.length; i++) {
        if (ind != null && i === ind) {
          continue;
        }
        if (typeof commentsEle[i].classList !== "undefined") {
          commentsEle[i].classList.remove("-reveal");
          Vue.set(this.buttonArr, i, false);
        }
      }
    },
    paginate(data) {
      if (this.page == 0) {
        return this.tempPageData;
      }
      const from = this.page * this.perPage - this.perPage;
      const to = this.page * this.perPage;
      this.tempPageData = data.slice(from, to);
      return this.tempPageData;
    },
    setPages() {
      const numberOfPages = Math.ceil(
        this.getHelpdeskTaskList.length / this.perPage
      );
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
  },
};
</script>
