<template>
  <div class="v-page-not-found">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Page Not Found
          </h1>
          <div class="mt-5">
            Please use the button below to go back to the home page.
          </div>
          <div class="mt-3">
            <a :href="false" class="c-button -admin-small" @click="homePage">
              Home
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    homePage() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
