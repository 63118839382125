<template>
  <div>
    <div class="row align-items-center">
      <div class="col-3">
        {{ dateFormat(repayment.repayment_date) }}
      </div>
      <div class="col-3">
        {{ currencySymbol }}{{ convertToPounds(repayment.capital) }}
      </div>
      <div class="col-3">
        {{ currencySymbol }}{{ convertToPounds(repayment.interest) }}
      </div>
      <div class="col-3">
        {{ capitaliseFirstLetter(repayment.payment_status) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    repayment: {
      required: true,
    },
    currencySymbol: {
      required: true,
      type: String,
    },
  },

  data() {
    return {};
  },
  methods: {},
};
</script>
