<template>
  <div class="c-2fsms">
    <h4 class="c-admin-panel-title mb-4">
      Enable App Two-Factor
    </h4>

    <p>
      Download the authentication app. <br />
      Tip: search Authy in your App store.
    </p>

    <div v-if="qrCodeUrl">
      <div>
        <section>
          <p>
            <strong>
              Scan QR code below and enter the 6-digit access code.
            </strong>
          </p>

          <img :src="qrCodeUrl" alt="Two-Factor" class="" />
        </section>
      </div>
      <div class="col-6">
        <form :data-vv-scope="scope" @submit.prevent="validateAppTwoFactor">
          <div>
            <v-text-input
              :ref="field"
              :scope="scope"
              :state-key="stateKey"
              label="App Code"
              :maxlength="6"
              error-name="app code"
              :field="field"
              validation="required|numeric|min:6"
              autofocus
            />
            <v-button :loading="working" class="-admin-small">
              Submit
            </v-button>
          </div>
        </form>
      </div>
    </div>

    <div
      v-if="errorMessage"
      class="type-aside h-color-error"
      v-text="errorMessage"
    />
  </div>
</template>

<script>
import TwoFactorService from "js-admin/classes/AjaxServices/TwoFactorService";
import UserService from "js-admin/classes/AjaxServices/UserService";
export default {
  name: "VTwoFactorAppSetup",
  data() {
    return {
      scope: "two-factor-sms",
      stateKey: "validateTwoFactor",

      qrCodeUrl: null,
      errorMessage: null,

      working: false,
      field: "app_security_code",
    };
  },
  computed: {
    twoFactorAppCode() {
      return this.$store.state.fieldStore[this.stateKey][this.field];
    },
  },

  watch: {
    twoFactorAppCode() {
      this.errorMessage = "";
    },
  },

  mounted() {
    this.updateAppCodeStore();

    TwoFactorService.setupTwoFactorQRCode().then(
      (response) => {
        if (response.data.data.url) {
          this.qrCodeUrl = response.data.data.url;
        } else {
          this.errorMessage =
            "There is a problem requesting a QR code, please use SMS verification.";
        }
      },
      (error) => {
        this.errorMessage = error.message;
      }
    );
  },

  methods: {
    updateAppCodeStore() {
      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.stateKey,
        field: this.field,
      });
    },

    refreshUser() {
      const loggedIn = this.$store.state.me;
      UserService.getUserInfo(loggedIn).then((response) => {
        const user = response.data.data;
        this.$store.commit("setUser", user);
      });
    },

    validateAppTwoFactor() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          TwoFactorService.dashboardValidateAppTwoFactor({
            app_security_code: this.twoFactorAppCode,
          })
            .then(() => {
              this.refreshUser();
            })
            .catch((Error) => {
              this.$refs[this.field].addError(
                "Verification code expired. Please resend and try again."
              );
            });
        }
      });
    },
  },
};
</script>
