<template>
  <transition name="fade">
    <div
      v-if="hasErrors && !focused"
      class="c-input-error scroll-error"
      :class="{ '-center': center }"
      v-text="errors.first(scope + '.' + field)"
    >
      <!-- {{errors.first(scope+'.'+field)}} -->
    </div>
  </transition>
</template>

<script>
export default {
  inject: ["$validator"],
  props: {
    scope: {
      required: true,
    },
    field: {
      required: true,
    },
    focused: {
      required: false,
      default: false,
    },
    center: {
      default: false,
    },
  },
  computed: {
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
  },
};
</script>
