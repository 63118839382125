<template>
  <div class="c-admin-panel">
    <v-accordion ref="accordion-instruments">
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title>
          Owned Instruments ({{ instrumentsCount }})
        </template>

        <div v-if="dataReady" class="c-admin-panel">
          <div class="c-admin-table">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-2">
                  Ticker
                </div>
                <div class="col-4">
                  Instrument Name
                </div>
                <div class="col-2">
                  Status
                </div>
                <div class="col-2">
                  Units Owned/Holdings
                </div>
                <div class="col-2">
                  Value
                </div>
              </div>
            </div>
            <div v-if="ownedInstruments.length">
              <div
                v-for="(instrument, index) in ownedInstruments"
                :key="index"
                class="c-admin-table-row"
              >
                <VInstrumentsRow :instrument="instrument" />
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              No Owned Instruments
            </div>
          </div>
          <div v-if="ownedInstruments.length" class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-model="page"
                  :page-count="pages.length"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VPagination from "vue-plain-pagination";
import InstrumentService from "js-admin/classes/AjaxServices/InstrumentService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VInstrumentsRow from "js-admin/components/investment-accounts/instruments/InstrumentsRow";

export default {
  components: {
    VPagination,
    VInstrumentsRow,
  },
  props: {
    investmentAccountUuid: {
      default: null,
    },
  },

  data() {
    return {
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      instruments: null,
      instrumentsCount: 0,
    };
  },

  computed: {
    ownedInstruments() {
      return this.instruments;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    this.getTotalInstruments();
  },

  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },

    paginate() {
      this.dataReady = false;

      return InstrumentService.getInstruments(
        {
          start: this.page * this.perPage - this.perPage,
          limit: this.perPage,
          only_owned: true,
        },
        this.investmentAccountUuid
      )
        .then((response) => {
          if (response.data) {
            this.instruments = response.data.data;
            this.getTotalInstruments();
            this.dataReady = true;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(
        this.instrumentsCount,
        this.perPage
      );
    },

    getTotalInstruments() {
      return InstrumentService.getInstruments(
        {
          count: true,
          only_owned: true,
        },
        this.investmentAccountUuid
      )
        .then((response) => {
          if (response.data.data) {
            this.instrumentsCount = response.data.data.instruments_count;
            this.setPages();
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },
  },
};
</script>
