<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item>
        <template v-slot:title>
          Other
        </template>

        <VLinkedAddresses />

        <VLinkedUserLogins />

        <VDocuments
          v-if="checkPermission('view_files')"
          :state-key="stateKey"
          :account-uuid="accountUuid"
        />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VLinkedUserLogins from "js-admin/components/accounts/users/LinkedUserLogins.vue";
import VLinkedAddresses from "js-admin/components/accounts/addresses/LinkedAddresses.vue";
import VDocuments from "js-admin/components/documents/Documents.vue";

export default {
  components: {
    VLinkedUserLogins,
    VLinkedAddresses,
    VDocuments,
  },
  props: {
    accountUuid: {
      required: true,
    },
    applicationUuid: {
      default: null,
    },
    investmentAccountUuid: {
      default: null,
    },
    stateKey: {
      required: true,
    },
  },
};
</script>
