var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-admin-panel"},[_c('v-accordion',{},[_c('v-accordion-item',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Communications ")]},proxy:true}])},[(!_vm.systemFeatureIsDisabled('disabled_phone_system'))?_c('VLinkedCalls'):_vm._e(),(
          _vm.checkPermission('view_emails') &&
            !_vm.systemFeatureIsDisabled('disabled_emails')
        )?_c('VEmails',{attrs:{"state-key":_vm.stateKey,"account-uuid":_vm.accountUuid,"account-email":_vm.accountEmail}}):_vm._e(),(
          _vm.checkPermission('view_secured_messages') &&
            !_vm.systemFeatureIsDisabled('disabled_secured_messages')
        )?_c('VSecuredMessages',{attrs:{"state-key":_vm.stateKey,"account-uuid":_vm.accountUuid}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }