<template>
  <div class="row c-edit-input" :class="editStyle">
    <div
      v-if="tableField == false"
      class="col-12 col-md-6 d-flex align-item-center h-pl-0"
    >
      <p class="c-edit-input-label">
        {{ label }}
      </p>
    </div>
    <div
      :class="
        tableField == true
          ? 'col-11'
          : 'col-12 col-md-6 h-pl-sm-0 h-pl-md-0 h-pr-0'
      "
    >
      <slot
        v-if="edit == false"
        name="static"
        :class="['c-edit-input-value', valueClasses]"
      />
      <slot v-if="edit == true" name="input" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: false,
      default: null,
    },
    edit: {
      required: true,
      default: false,
    },
    tableField: {
      default: false,
    },
    valueClasses: {
      default: "",
    },
  },
  computed: {
    editStyle() {
      return {
        "-edit-active": this.edit == true,
      };
    },
  },
};
</script>
