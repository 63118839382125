<template>
  <div class="mt-2">
    <div>
      <a
        :href="false"
        class="c-button -admin-small mb-3"
        @click="toggleDocumentForm"
      >
        {{ addDocumentLabel }}
      </a>
    </div>

    <div v-if="showDocumentForm" class="c-admin-form mt-0 mb-3">
      <form :data-vv-scope="scope" @submit.prevent="addDocument">
        <h3 class="c-admin-panel-title">
          {{ title }}
        </h3>

        <div class="c-file-input-error">
          <p v-html="errorMsg"></p>
        </div>

        <div v-if="fileTypeOptions.length > 1">
          <v-select-input
            :scope="scope"
            :state-key="stateKey2"
            :options="fileTypeOptions"
            :center-error="false"
            field="file_type"
            label=""
            validation="required"
            error-name="file type"
            placeholder="Select a file type"
            class="mb-4"
            :enable-blank-option="true"
          />
        </div>
        <div>
          <v-text-input
            :scope="scope"
            :state-key="stateKey2"
            class="mb-3"
            field="file_name"
            label="Filename"
            validation="required"
            error-name="file name"
          />
        </div>
        <div>
          <VFileInput
            v-if="uploadReady"
            :scope="scope"
            :state-key="stateKey2"
            field="document"
            validation="required"
          />
        </div>

        <v-button
          :loading="addInProgress"
          :disabled="addInProgress"
          class="-admin-small"
        >
          {{ submitLabel }}
        </v-button>
      </form>
    </div>
  </div>
</template>

<script>
import VFileInput from "js-admin/components/form/FileInput.vue";

export default {
  components: {
    VFileInput,
  },
  props: {
    stateKey: {
      required: true,
    },
    title: {
      required: false,
      default: "Add New Document",
    },
    label: {
      required: false,
      default: "+ Add Document",
    },
    submitLabel: {
      required: false,
      default: "Upload Document",
    },
    scope: {
      required: false,
      default: "addDocumentForm",
    },
    stateKey2: {
      required: false,
      default: "addDocument",
    },
    fileTypeOptions: {
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      errorMsg: null,
      showDocumentForm: false,
      addInProgress: false,
      uploadReady: true,
    };
  },

  computed: {
    addDocumentLabel() {
      if (this.showDocumentForm) {
        return "- Hide Form";
      } else {
        return this.label;
      }
    },
    fileType() {
      if (this.fileTypeOptions.length === 1) {
        return this.fileTypeOptions[0].value;
      }

      return this.$store.state.fieldStore[this.stateKey2].file_type;
    },
  },

  methods: {
    resetUploadForm() {
      this.addInProgress = false;
      this.uploadReady = false;

      ["file_type", "document", "file_name"].forEach((keyName) => {
        this.$store.dispatch("updateStoreItem", {
          value: null,
          stateKey: this.stateKey2,
          field: keyName,
        });
      });

      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
    addDocument() {
      this.$validator.validateAll(this.scope).then(async (result) => {
        if (result) {
          this.addInProgress = true;

          const formData = new FormData();
          formData.append(
            "file",
            this.$store.state.fieldStore[this.stateKey2].document
          );
          formData.append("type", this.fileType);
          formData.append(
            "filename",
            this.$store.state.fieldStore[this.stateKey2].file_name
          );

          await this.$emit("addDocument", formData);

          this.resetUploadForm();
          this.showDocumentForm = false;
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -150 });
          }, 400);
        }
      });
    },
    toggleDocumentForm() {
      this.showDocumentForm = !this.showDocumentForm;
    },
  },
};
</script>
