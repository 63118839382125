<template>
  <div class="c-asana-status-details">
    <div class="row">
      <div class="col-3">
        <strong>GID:</strong>
      </div>
      <div class="col-6">
        {{ taskObject.gid }}
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <strong>Name:</strong>
      </div>
      <div class="col-6">
        <h3>{{ taskObject.name }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <v-helpdesk-comment :gid="taskObject.gid" ref-id="12" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <strong>History:</strong>
      </div>
      <div class="col-12">
        <v-asana-history
          v-for="(item, index) in reverseTaskStatus"
          :key="index"
          :history-item="item"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VAsanaHistory from "js-admin/components/helpdesk/VAsanaHistory";
import VHelpdeskComment from "js-admin/components/helpdesk/HelpdeskComment";
export default {
  components: {
    VAsanaHistory,
    VHelpdeskComment,
  },
  data: () => ({
    taskObject: {
      gid: null,
      name: null,
      resource_type: null,
      status: [],
    },
  }),
  computed: {
    reverseTaskStatus() {
      const reversed = [];
      for (let i = 0; i < this.taskObject.status.length; i++) {
        reversed.unshift(this.taskObject.status[i]);
      }
      return reversed;
    },
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.getHelpdeskTaskList,
      (newValue, oldValue) => {
        this.updateSupportDetails(this.$route.params.gid);
      }
    );
  },
  beforeMount() {
    this.updateSupportDetails(this.$route.params.gid);
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    ...mapActions(["getSupportDetails"]),
    updateSupportDetails(gid) {
      this.getSupportDetails(gid).then((result) => {
        if (result == null) return;
        this.taskObject = result;
      });
    },
  },
};
</script>
