var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-reports"},[_c('section',{staticClass:"c-section"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-panel"},[_c('div',{staticClass:"c-admin-panel-title"},[_vm._v(" "+_vm._s(_vm.accountsLeadsTitle)+" ")]),_vm._l((_vm.accounts_and_leads),function(link){return _c('div',{key:link.route,staticClass:"mt-3"},[(_vm.checkPermission(link.permission))?_c('div',{staticClass:"c-admin-menu-item -clickable",on:{"click":function($event){return _vm.$router.push({ name: link.route })}}},[_c('i',{staticClass:"fas fa-clipboard-list"}),_vm._v("   "+_vm._s(link.title)+" ")]):_vm._e()])})],2)]),(!_vm.systemFeatureIsDisabled('disabled_tasks'))?_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-panel"},[_c('div',{staticClass:"c-admin-panel-title"},[_vm._v(" Tasks ")]),_vm._l((_vm.tasks),function(link){return _c('div',{key:link.route,staticClass:"mt-3"},[(_vm.checkPermission(link.permission))?_c('div',{staticClass:"c-admin-menu-item -clickable",on:{"click":() => {
                  !!link.click
                    ? link.click()
                    : _vm.$router.push({ name: link.route });
                }}},[_c('i',{staticClass:"fas fa-clipboard-list"}),_vm._v("   "+_vm._s(link.title)+" ")]):_vm._e()])})],2)]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[(_vm.checkPermission('reporting_level1'))?_c('div',{staticClass:"c-admin-panel"},[_c('div',{staticClass:"c-admin-panel-title"},[_vm._v(" Investment Accounts ")]),_c('div',{staticClass:"mt-3"},[(_vm.checkPermission('reporting_level1'))?_c('div',{staticClass:"c-admin-menu-item -clickable",on:{"click":_vm.completedApplicationsReportPage}},[_c('i',{staticClass:"fas fa-clipboard-list"}),_vm._v("   Today's Completed Applications ")]):_vm._e()])]):_vm._e()]),(!_vm.systemFeatureIsDisabled('disabled_phone_system'))?_c('div',{staticClass:"col-6"},[(_vm.checkPermission('reporting_level2'))?_c('div',{staticClass:"c-admin-panel"},[_c('div',{staticClass:"c-admin-panel-title"},[_vm._v(" Calls ")]),_c('div',{staticClass:"mt-3"},[(
                _vm.checkPermission('reporting_level2') &&
                  !_vm.checkPermission('compliance_view_call_recordings')
              )?_c('div',{staticClass:"c-admin-menu-item -clickable",on:{"click":_vm.callStatsReportPage}},[_c('i',{staticClass:"fas fa-phone-volume"}),_vm._v("   Call Stats ")]):(_vm.checkPermission('compliance_view_call_recordings'))?_c('div',{staticClass:"c-admin-menu-item -clickable",on:{"click":_vm.callStatsRecordingsReportPage}},[_c('i',{staticClass:"fas fa-phone-volume"}),_vm._v("   Call Stats ")]):_vm._e()])]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[(_vm.checkPermission('view_performance_fee_reports'))?_c('div',{staticClass:"c-admin-panel"},[_c('div',{staticClass:"c-admin-panel-title"},[_vm._v(" Performance Fees ")]),_c('div',{staticClass:"c-admin-menu-item -clickable",on:{"click":function($event){return _vm.$router.push({ name: 'performance-fee-reports' })}}},[_c('i',{staticClass:"fas fa-clipboard-list"}),_vm._v("   Performance Fee Reports ")])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"c-admin-page-title"},[_vm._v(" Reports ")])])])
}]

export { render, staticRenderFns }