<template>
  <v-slide-col>
    <template v-slot:leftCol>
      <VTaskTable
        ref="taskTable"
        :with-sort-options="true"
        :side-layout="true"
        :task-owner="getTaskOwner"
        :task-data="getOwnerTaskData"
        title="Tasks"
      />
    </template>
    <template v-slot:rightCol>
      <transition
        appear
        mode="out-in"
        :css="false"
        @before-enter="beforeNavEnter"
        @enter="enterNav"
        @before-leave="beforeNavLeave"
        @leave="leaveNav"
      >
        <router-view :key="key" />
      </transition>
    </template>
  </v-slide-col>
</template>

<script>
import gsap from "gsap";
import VSlideCol from "js-admin/components/layouts/SlideCol";
import VTaskTable from "js-admin/components/tasks/TaskTable";

export default {
  components: {
    VSlideCol,
    VTaskTable,
  },
  computed: {
    channelName() {
      return window.pusher.channel_name;
    },
    channelBroadcast() {
      return window.pusher.task_channel_broadcast;
    },
    key() {
      return this.$route.fullPath;
    },
  },
  mounted() {
    if (!this.checkPermission("view_tasks")) {
      return this.$router.push({ name: "home" });
    }

    if (this.$route.params.t_uuid) {
      this.$store.dispatch("changeSelectedTask", this.$route.params.t_uuid);
    }
  },

  methods: {
    beforeNavEnter(el, done) {
      gsap.set(el, {
        css: {
          opacity: 0,
          x: "20%",
        },
        ease: "power3.inOut",
      });
    },
    enterNav(el, done) {
      this.$store
        .dispatch("changeSelectedTask", this.$route.params.t_uuid)
        .then(() => {
          gsap.to(el, {
            duration: 0.6,
            css: {
              opacity: 1,
              x: "0%",
            },
            ease: "power3.inOut",
            onComplete: () => {
              done();
            },
          });
        });
    },
    beforeNavLeave(el, done) {},
    leaveNav(el, done) {
      gsap.to(el, {
        duration: 0.3,
        css: {
          opacity: 0,
          x: "5%",
          // scale: 0.9,
          // transformOrigin: '0% 10%'
        },
        ease: "power3.inOut",
        onComplete: () => {
          done();
        },
      });
    },
  },
};
</script>
