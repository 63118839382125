<template>
  <div class="row">
    <div class="col-2">
      {{ instrument.name }}
      <br />
      <small>
        Imported on: <br />
        {{ dateTimeFormat(instrument.created_at, "DD/MM/YYYY") }}
      </small>
    </div>
    <div class="col-2">
      {{ instrument.external_id }}
    </div>
    <div class="col-2">
      {{ instrument.issuer }}
    </div>
    <div class="col-2">
      <div v-if="instrument.status">
        <strong>
          {{ capitaliseFirstLetter(instrument.status) }}
        </strong>
      </div>
    </div>
    <div class="col-2">
      <div v-if="instrument.drawdown_failed_reason">
        <strong>
          {{ instrument.drawdown_failed_reason }}
        </strong>
      </div>
    </div>
    <div class="col-2 text-center">
      <a
        v-if="!isLoading"
        :href="false"
        class="c-button -admin-small"
        @click="retriggerInstrumentDrawdown(instrument.external_id)"
      >
        Retrigger
      </a>
      <v-loader v-else />
    </div>
  </div>
</template>

<script>
import PrimaryMarketService from "js-admin/classes/AjaxServices/PrimaryMarket/PrimaryMarketService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    instrument: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    retriggerInstrumentDrawdown(uuid) {
      this.isLoading = true;
      PrimaryMarketService.triggerInstrumentDrawdown(uuid)
        .then((response) => {
          this.isLoading = false;

          FlashMessageService.success(response.data.message);
          this.$parent.paginate();
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },
  },
};
</script>
