<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2">
        {{ dateTimeFormat(email.date_sent) }}
      </div>
      <div class="col-3 text-break">
        {{ email.email }}
      </div>
      <div class="col-3">
        {{ email.subject }}
      </div>
      <div class="col-2">
        {{ capitaliseFirstLetter(email.sender) }}
      </div>
      <div class="col-2 text-center">
        <a :href="false" class="c-button -admin-small" @click="showEmail">
          View
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: {
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    showEmail() {
      const location = this.$parent.$parent.$parent.$parent;
      location.$refs.emailMessageModal.toggle();
      this.$store.dispatch("fetchEmail", this.email.uuid).then((response) => {
        if (response.status == 200) {
          this.message = response.data.data.body;
          this.message = this.message.replace(/href/g, "data-href");

          location.$refs.messageBody.contentWindow.document.open();
          location.$refs.messageBody.contentWindow.document.write(this.message);
          location.$refs.messageBody.contentWindow.document.close();
        }
      });
      return true;
    },
  },
};
</script>
