<template>
  <v-modal
    ref="createTask"
    :overflow-visible="true"
    :container-classes="containerClasses"
  >
    <template slot="button">
      <v-button
        class="-admin-small"
        :class="{ 'mb-3': addModelButtonMargin }"
        type="button"
        @click.native="resetTaskData"
      >
        Add Task
      </v-button>
    </template>
    <div class="c-create-task">
      <section class="c-section h-pt-0">
        <div class="row">
          <div class="col-12">
            <h3 class="c-admin-page-title text-center"> Add Task </h3>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <form
              :data-vv-scope="scope"
              class="c-admin-form"
              @submit.prevent="createTask"
            >
              <div class="row">
                <div class="col-12 col-md-8 h-mt-1">
                  <div>
                    <v-text-input
                      :scope="scope"
                      :state-key="stateKey"
                      classes="-align-left"
                      field="task_name"
                      label="Task Name"
                      validation="required"
                      error-name="task name"
                    />
                  </div>
                  <div class="h-mb-1">
                    <v-select-input
                      :scope="scope"
                      :state-key="stateKey"
                      :options="ownerData"
                      :center-error="false"
                      field="assigned_to"
                      validation="required"
                      error-name="account manager"
                      label=""
                      placeholder="Select an account manager"
                      class="mb-0"
                      :enable-blank-option="true"
                    />
                  </div>
                  <div>
                    <v-textarea
                      :scope="scope"
                      :state-key="stateKey"
                      field="task_description"
                      label="Description"
                      :validation="false"
                    />
                  </div>
                  <div class="h-mb-1">
                    <v-select-input
                      :scope="scope"
                      :state-key="stateKey"
                      :options="getStatusListAsArray"
                      :center-error="false"
                      field="task_status"
                      label=""
                      error-name="status"
                      placeholder="Select a status"
                      :enable-blank-option="true"
                    />
                  </div>
                  <div>
                    <v-select-input
                      :scope="scope"
                      :state-key="stateKey"
                      :options="getPriorityListAsArray"
                      :center-error="false"
                      field="task_priority"
                      label=""
                      error-name="priority"
                      placeholder="Select a priority"
                      :enable-blank-option="true"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4 h-mt-sm-1 h-mt-md-1 h-mt-lg-0">
                  <div>
                    Due Date/Time <br />
                    <Datetime
                      key="1"
                      v-model="date"
                      type="datetime"
                      format="dd-MM-yyyy HH:mm"
                      :min-datetime="currentDate"
                      style=" width: 175px;max-width: unset;"
                      value-zone="Europe/London"
                    />
                    <v-input-hidden
                      ref="input"
                      :scope="scope"
                      :state-key="stateKey"
                      error-name="due date"
                      field="due_date"
                      validation="required"
                    />
                  </div>
                  <div class="h-mt-1">
                    Set Reminder
                    <div class="c-notifications-switches h-mb-1">
                      <div
                        class="c-notifications-switches-item justify-content-end"
                      >
                        <v-switch
                          field="set_reminder"
                          :pick-time="true"
                          :state-key="stateKey"
                          class="d-inline-block"
                        />
                      </div>
                    </div>
                    <div v-if="setReminder === true">
                      <div class="h-mb-1">
                        Reminder Date/Time <br />
                        <Datetime
                          key="2"
                          v-model="reminderDate"
                          type="datetime"
                          format="dd-MM-yyyy HH:mm"
                          :min-datetime="currentDate"
                          style=" width: 175px;max-width: unset;"
                          value-zone="Europe/London"
                        />
                        <v-input-hidden
                          ref="input"
                          :scope="scope"
                          :state-key="stateKey"
                          error-name="reminder date"
                          field="reminder_date"
                          validation="required"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 h-mt-2 text-center">
                  <v-button
                    :loading="creatingTask"
                    :disabled="creatingTask"
                    class="mt-3"
                  >
                    Add
                  </v-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </v-modal>
</template>

<script>
import { Datetime } from "vue-datetime";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import { mapMutations } from "vuex";

export default {
  components: {
    Datetime,
  },
  props: {
    addModelButtonMargin: {
      default: false,
    },
    containerClasses: {
      default: null,
    },
  },
  data() {
    return {
      scope: "taskForm",
      stateKey: "task",
      mode: "single",
      creatingTask: false,
    };
  },
  computed: {
    currentDate() {
      return new Date().toString();
    },
    ownerData() {
      return this.$store.state.accountManagers;
    },
    setReminder() {
      return this.$store.state.fieldStore.task.set_reminder;
    },
    date: {
      get() {
        if (this.$store.state.fieldStore.task.due_date == null) {
          return "2020/01/01";
        }
        return this.$store.state.fieldStore.task.due_date;
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: "due_date",
        });
      },
    },
    reminderDate: {
      get() {
        if (this.setReminder === true) {
          return this.$store.state.fieldStore.task.reminder_date;
        } else {
          return null;
        }
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: "reminder_date",
        });
      },
    },
  },
  mounted() {
    this.$store.dispatch("getAccountManagers");
    if (this.$store.state.user.owner) {
      this.$store.dispatch("updateStoreItem", {
        value: this.$store.state.user.owner.uuid,
        stateKey: this.stateKey,
        field: "assigned_to",
      });
    }
  },
  methods: {
    ...mapMutations(["resetTaskData"]),
    createTask() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          this.creatingTask = true;
          this.$store
            .dispatch("createTask", {
              account_uuid: this.$route.params.uuid,
              owner_uuid: this.getCreatedTask.assigned_to,
              priority: this.getCreatedTask.task_priority.toLowerCase(),
              status: this.prepareTaskStatus(this.getCreatedTask.task_status),
              due_date: this.date,
              reminder_date: this.reminderDate,
              title: this.getCreatedTask.task_name,
              description: this.getCreatedTask.task_description,
            })
            .then(() => this.$store.dispatch("getTasks"))
            .then(() => {
              this.creatingTask = false;
              FlashMessageService.success("Task has been created.");
              this.$refs.createTask.toggle();
            });
        }
      });
    },
  },
};
</script>
