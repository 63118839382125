<template>
  <div class="c-admin-panel">
    <form :data-vv-scope="createIssuerFormScope" @submit.prevent="create">
      <div class="c-admin-panel-section">
        <div class="row">
          <div class="col-12 col-md-5">
            <h3 class="c-admin-panel-title">
              Primary Contact
            </h3>

            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="contactStateKey"
                field="title"
                label="Title"
                validation="required"
              />
            </div>

            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="contactStateKey"
                field="first_name"
                label="First Name"
                validation="required"
              />
            </div>

            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="contactStateKey"
                field="last_name"
                label="Last Name"
                validation="required"
              />
            </div>
          </div>
          <div class="col-12 col-md-7">
            <h3 class="c-admin-panel-title">
              Contact Details
            </h3>

            <div>
              <v-phone-number-text-input
                :scope="createIssuerFormScope"
                :state-key="contactStateKey"
                field="primary_contact_number"
                type="tel"
                label="Primary Contact Number"
                error-name="primary contact number"
                autocomplete="tel"
                :maxlength="13"
                validation="required|numeric"
              />
            </div>

            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="contactStateKey"
                field="email"
                label="Email address"
                validation="required|email"
                error-name="email address"
              />
            </div>
          </div>
        </div>

        <h3 class="c-admin-panel-title">
          Registered Address
        </h3>
        <div class="row">
          <div class="col-12 col-md-5">
            <div>
              <v-select-input
                :scope="createIssuerFormScope"
                :state-key="stateKey"
                :options="['flat', 'unit']"
                :center-error="false"
                field="flat_or_unit"
                validation=""
                error-name="building type"
                placeholder="Select building type"
                class="mb-4"
                :enable-blank-option="true"
              />
            </div>
            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="stateKey"
                field="building_number"
                label="Building Number"
                validation=""
                error-name="building number"
              />
            </div>
            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="stateKey"
                field="building_name"
                label="Building Name"
                validation=""
                error-name="building name"
              />
            </div>
            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="stateKey"
                field="street_name"
                label="Street Name"
                validation="required"
                error-name="street name"
              />
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="stateKey"
                field="town"
                label="Town"
                validation="required"
                error-name="town"
              />
            </div>
            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="stateKey"
                field="county"
                label="County"
                validation="required"
                error-name="county"
              />
            </div>
            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="stateKey"
                field="postcode"
                label="Postcode"
                validation="required"
                error-name="postcode"
              />
            </div>
            <div>
              <v-combo-box
                :scope="createIssuerFormScope"
                :state-key="stateKey"
                :options="countries"
                :focus-on-mounted="false"
                class="-no-left-padding"
                field="country"
                label="Country"
                error-name="country"
                validation="required"
              />
            </div>
          </div>
        </div>

        <h3 class="c-admin-panel-title">
          Company Details
        </h3>
        <div class="row">
          <div class="col-12 col-md-5">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="originator"
              label="Originator"
              validation=""
              error-name="originator"
            />
          </div>
          <div class="col-12 col-md-7" />
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="name"
              label="Company Name"
              validation="required"
              error-name="company name"
            />
          </div>
          <div class="col-12 col-md-7">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="company_number"
              label="Company Number"
              validation="required"
              error-name="company number"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <v-combo-box
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              :options="countries"
              :focus-on-mounted="false"
              class="-no-left-padding"
              field="country_incorporation"
              label="Country of Incorporation"
              error-name="country of incorporation"
              validation="required"
            />
          </div>
          <div class="col-12 col-md-7">
            <v-combo-box
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              :options="countries"
              :focus-on-mounted="false"
              class="-no-left-padding"
              field="company_jurisdiction"
              label="Company Jurisdiction"
              error-name="company jurisdiction"
              validation="required"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="company_type"
              label="Company Type"
              validation="required"
              error-name="company type"
            />
          </div>
          <div class="col-12 col-md-7">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="company_logo"
              label="Company Logo"
              validation="required"
              error-name="company logo"
            />
            <a
              v-if="companyLogo"
              :href="companyLogo"
              class="c-button -admin-small ml-2 mb-3"
              target="_blank"
              rel="noopener"
            >
              View
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="sector"
              label="Company Sector"
              validation="required"
              error-name="company sector"
            />
          </div>
          <div class="col-12 col-md-7">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="financial_year_end"
              label="Financial Year End"
              validation="required"
              error-name="financial year end"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="corporate_adviser"
              label="Corporate Adviser"
              validation="required"
              error-name="corporate adviser"
            />
          </div>
          <div class="col-12 col-md-7">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="auditors"
              label="Auditors"
              validation="required"
              error-name="auditors"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <div>
              <v-text-input
                :scope="createIssuerFormScope"
                :state-key="stateKey"
                field="website"
                label="Website"
                validation="required"
                error-name="website"
              />
              <a
                v-if="companyWebsite"
                :href="companyWebsite"
                class="c-button -admin-small ml-2 mb-3"
                target="_blank"
                rel="noopener"
              >
                View
              </a>
            </div>
          </div>
          <div class="col-12 col-md-7">
            <div>
              Brand Colour
              <color-picker v-model="colors"></color-picker>
            </div>
          </div>
        </div>

        <h3 class="c-admin-panel-title">
          Social Media Details
        </h3>
        <div class="row">
          <div class="col-12 col-md-5">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="linkedin"
              label="Linkedin"
              validation=""
              error-name="linkedin"
            />
          </div>
          <div class="col-12 col-md-7">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="twitter"
              label="Twitter"
              validation=""
              error-name="twitter"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <v-text-input
              :scope="createIssuerFormScope"
              :state-key="stateKey"
              field="instagram"
              label="Instagram"
              validation=""
              error-name="instagram"
            />
          </div>
          <div class="col-12 col-md-7" />
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <v-button
              v-if="checkPermission('add_issuer')"
              class="mt-3"
              :loading="submittingIssuerDetails"
              :disabled="submittingIssuerDetails"
            >
              Create
            </v-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Chrome } from "vue-color";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import IssuerService from "js-admin/classes/AjaxServices/IssuerService";

export default {
  components: {
    "color-picker": Chrome,
  },
  props: {
    instrument: {
      default: null,
    },
    client: {
      default: null,
    },
  },

  data() {
    return {
      createIssuerFormScope: "issuerData",
      stateKey: "issuerData",
      contactStateKey: "issuerContactData",
      submittingIssuerDetails: false,
      colors: "#FFFFFF",
    };
  },

  computed: {
    countries() {
      return this.$store.state.config.countries;
    },
    companyLogo() {
      return this.$store.state.fieldStore[this.stateKey].company_logo;
    },
    companyWebsite() {
      return this.$store.state.fieldStore[this.stateKey].website;
    },
  },

  watch: {
    client() {
      this.loadFormWithClientData(this.client);
    },
  },

  created() {
    this.$store.dispatch("updateStoreItem", {
      value: this.getEmptyIssuer(),
      stateKey: this.stateKey,
      field: null,
    });

    this.$store.dispatch("updateStoreItem", {
      value: this.getEmptyIssuerContact(),
      stateKey: this.contactStateKey,
      field: null,
    });
  },

  methods: {
    create() {
      this.$validator
        .validateAll(this.createIssuerFormScope)
        .then(async (result) => {
          if (result) {
            this.submittingIssuerDetails = true;

            const issuerUuid = await this.createIssuer();

            if (issuerUuid) {
              await this.createIssuerContact(issuerUuid);
            }

            this.submittingIssuerDetails = false;
          } else {
            setTimeout(() => {
              this.$scrollTo(".c-input-error", 500, { offset: -100 });
            }, 400);
          }
        });
    },

    async createIssuerContact(issuerUuid) {
      const data = {
        issuer_uuid: issuerUuid,
        title: this.$store.state.fieldStore[this.contactStateKey].title,
        first_name: this.$store.state.fieldStore[this.contactStateKey]
          .first_name,
        last_name: this.$store.state.fieldStore[this.contactStateKey].last_name,
        email: this.$store.state.fieldStore[this.contactStateKey].email,
        primary_contact_number:
          this.$store.state.fieldStore[this.contactStateKey]
            .primary_contact_number_dial_code +
          this.$store.state.fieldStore[this.contactStateKey]
            .primary_contact_number,
        primary_contact: true,
      };

      await IssuerService.createIssuerContact(data)
        .then(() => {
          FlashMessageService.success("Issuer Contact has been created");

          this.$router.push("/issuer/" + issuerUuid);
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },

    async createIssuer() {
      const data = Object.fromEntries(
        Object.entries(this.$store.state.fieldStore[this.stateKey]).filter(
          ([key, value]) =>
            value !== null &&
            typeof value !== "object" &&
            key !== "uuid" &&
            value !== ""
        )
      );

      if (this.colors.hex) {
        data.brand_colour = this.colors.hex;
      } else {
        data.brand_colour = this.colors;
      }

      data.instrument_uuid = this.instrument.uuid;

      return IssuerService.createIssuer(data)
        .then((response) => {
          FlashMessageService.success("Issuer has been created");

          return response.data.data.uuid;
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },

    loadFormWithClientData(client) {
      const issuerContactObject = this.getEmptyIssuerContact();
      const issuerObject = this.getEmptyIssuer();

      delete issuerContactObject.uuid;
      delete issuerObject.uuid;

      Object.entries(client).map(([key, value]) => {
        // eslint-disable-next-line no-prototype-builtins
        if (issuerContactObject.hasOwnProperty(key)) {
          this.$store.dispatch("updateStoreItem", {
            value: value,
            stateKey: this.contactStateKey,
            field: key,
          });
        }

        // eslint-disable-next-line no-prototype-builtins
        else if (issuerObject.hasOwnProperty(key)) {
          this.$store.dispatch("updateStoreItem", {
            value: value,
            stateKey: this.stateKey,
            field: key,
          });
        }
      });

      this.$store.dispatch("updateStoreItem", {
        value: client.company_name,
        stateKey: this.stateKey,
        field: "name",
      });

      if (client.brand_colour) {
        this.colors = client.brand_colour;
      }
    },
  },
};
</script>
