var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-profile"},[_c('section',{staticClass:"c-section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"c-admin-page-title"},[(_vm.overdueReport)?_c('span',[_vm._v(" Overdue ")]):_vm._e(),_vm._v(" Tasks Report ")])])]),_c('v-date-select-checkbox-search',{ref:"searchDate",attrs:{"scope":_vm.reportScope,"report-state-key":_vm.reportStateKey,"mode":_vm.mode,"select-data":_vm.ownerData,"submit-results":_vm.submitResults},on:{"get-results":_vm.getResults}}),(_vm.reportData)?_c('v-csv-export',{staticClass:"mt-4",on:{"start-csv-export":_vm.handleExport}}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"c-admin-table c-task-table mt-4"},[_vm._m(0),(!_vm.submitResults)?_c('div',[(_vm.reportResults)?_c('div',_vm._l((_vm.reportResults),function(data){return _c('div',{key:data.owner_uuid,class:[
                  'c-admin-table-row',
                  {
                    '-overdue': data.overdue === true,
                    '-completed': data.status === 'Completed',
                  },
                ]},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-2"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(data.due_date))+" ")]),_c('div',{staticClass:"col-2"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('div',{staticClass:"col-2"},[_c('router-link',{staticClass:"c-link",attrs:{"to":`/account/${data.account.uuid}`}},[_vm._v(" "+_vm._s(data.account.full_name)+" ")])],1),_c('div',{staticClass:"col-2"},[_vm._v(" "+_vm._s(data.status)+" ")]),_c('div',{staticClass:"col-2"},[_vm._v(" "+_vm._s(data.owner.name)+" ")]),_c('div',{staticClass:"col-2"},[_c('router-link',{staticClass:"c-button -admin-small",attrs:{"to":`/tasks/task-details/${data.uuid}`}},[_vm._v(" View Task ")])],1)])])}),0):_c('div',{staticClass:"c-admin-table-row"},[_vm._m(1)])]):_c('div',{staticClass:"c-admin-table-row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-loader')],1)])]),_c('div',{staticClass:"c-admin-table-footer -bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-9 text-right"},[_vm._v(" Total: ")]),_c('div',{staticClass:"col-3"},[(!_vm.submitResults)?_c('span',[_vm._v(" "+_vm._s(_vm.reportTotal)+" ")]):_c('span',[_c('v-loader')],1)])])])])])]),(_vm.reportData)?_c('v-csv-export',{staticClass:"mt-4",on:{"start-csv-export":_vm.handleExport}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-admin-table-header -bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_vm._v(" Due Date ")]),_c('div',{staticClass:"col-2"},[_vm._v(" Title ")]),_c('div',{staticClass:"col-2"},[_vm._v(" Account ")]),_c('div',{staticClass:"col-2"},[_vm._v(" Status ")]),_c('div',{staticClass:"col-2"},[_vm._v(" Account Manager ")]),_c('div',{staticClass:"col-2"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._v(" No results found. ")])])
}]

export { render, staticRenderFns }