<template>
  <!-- Start of account owner section -->
  <div v-if="ownerDataReady" class="row">
    <div class="col-12">
      <div class="c-admin-panel">
        <h3 class="c-admin-panel-title text-center">
          Account Owner
        </h3>
        <div
          v-if="checkPermission('update_account_owner')"
          class="c-admin-panel-section"
        >
          <form :data-vv-scope="ownerScope" @submit.prevent="updateOwner">
            <div class="row">
              <div class="col-12">
                <div class="text-center">
                  <v-select-input
                    :scope="ownerScope"
                    :state-key="stateKey"
                    :options="ownerData"
                    :center-error="false"
                    field="owner_uuid"
                    validation="required"
                    error-name="owner"
                    placeholder="Select a owner"
                    class="-centered mb-4"
                    :enable-blank-option="true"
                  />
                </div>
              </div>
              <div class="col-12 text-center">
                <v-button
                  :loading="updateOwnerInProgress"
                  :disabled="updateOwnerInProgress"
                  class="-admin-small"
                >
                  <span v-if="accountOwner">
                    Update
                  </span>
                  <span v-else>
                    Assign
                  </span>
                </v-button>
              </div>
            </div>
          </form>
        </div>
        <div v-else>
          <div class="text-center">
            <span v-if="accountOwner">
              {{ accountOwner.name }}
            </span>
            <span v-else>
              Not Assigned
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End of account owner section -->
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import AccountManagerService from "js-admin/classes/AjaxServices/AccountManagerService";

export default {
  data() {
    return {
      ownerDataReady: false,
      updateOwnerInProgress: false,
      ownerScope: "ownerForm",
      stateKey: "accountData",
    };
  },

  computed: {
    accountUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
    accountOwner() {
      return this.$store.state.fieldStore[this.stateKey].owner;
    },
    accountOwnerUuid() {
      return this.$store.state.fieldStore[this.stateKey].owner_uuid;
    },
    ownerData() {
      return this.$store.state.accountManagers;
    },
  },

  watch: {
    ownerData(newVal) {
      if (typeof newVal !== "undefined") {
        this.ownerDataReady = true;
      }
    },
  },

  mounted() {
    if (this.checkPermission("update_account_owner")) {
      this.$store.dispatch("getAccountManagers");
    } else {
      this.ownerDataReady = true;
    }
  },

  methods: {
    updateOwner() {
      this.$validator.validateAll(this.ownerScope).then((result) => {
        if (result) {
          const accountUuid = this.accountUuid;
          const ownerUuid = this.accountOwnerUuid;

          this.updateOwnerInProgress = true;

          AccountManagerService.assignOwner(accountUuid, ownerUuid).then(
            (response) => {
              this.updateOwnerInProgress = false;
              if (response.data) {
                FlashMessageService.success("Owner has been update");

                this.$store.dispatch("updateStoreItem", {
                  value: response.data.owner,
                  stateKey: this.stateKey,
                  field: "owner",
                });
              } else {
                console.log(response);
                return false;
              }
            }
          );
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },
  },
};
</script>
