<template>
  <div class="row">
    <div class="col-3">
      {{ user.name }}
    </div>
    <div class="col-4">
      {{ user.email_address }}
    </div>
    <div class="col-3">
      Role: <strong> {{ roleName }} </strong> <br />
      No. of Permissions: <strong> {{ permissionsCount }} </strong>
    </div>
    <div class="col-2 text-center">
      <a
        :href="false"
        class="c-button -admin-small"
        @click="viewUser(user.uuid)"
      >
        Manage
      </a>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    user: {
      required: true,
      type: Object,
    },
  },

  computed: {
    permissionsCount() {
      var count = 0;
      if (this.user.permissions) {
        count = this.user.permissions.length;
      }

      return count;
    },

    roleName() {
      if (!this.user.roles) {
        return "No role for this user";
      }

      return this.stripUnderScoresAndCapitalise(this.user.roles[0]);
    },
  },

  methods: {
    viewUser(uuid) {
      this.$router.push({ name: "user", params: { uuid } });
      return false;
    },
  },
};
</script>
