<template>
  <div :key="index" :class="['c-asana-status-side-row', selected]">
    <p>{{ task.gid }}</p>
    <h4>{{ task.name }}</h4>
    <p v-html="taskStatusFormat"></p>
    <v-green-tick v-if="shouldShow" />
  </div>
</template>

<script>
import VGreenTick from "js-admin/components/icons/GreenTick";
export default {
  components: {
    VGreenTick,
  },
  props: {
    index: {
      type: String,
      default: null,
    },
    task: {
      type: Object,
      default: () => ({}),
    },
    taskStatus: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    shouldShow() {
      for (const i in this.task.status) {
        if (/to "Done"/.test(this.task.status[i].text)) {
          return true;
        }
      }
      return false;
    },
    selected() {
      return {
        "-selected": this.task.gid === this.$route.params.gid,
      };
    },
    taskStatusFormat() {
      const regex = /(Comment from:)/gi;
      return this.taskStatus.replace(regex, "<strong>$1</strong>");
    },
  },
};
</script>
