<template>
  <div class="c-admin-panel">
    <h2 class="c-admin-panel-title">
      Two-Factor Verification
    </h2>

    <div v-if="!twoFactorSetup" class="c-admin-panel-section">
      <div class="mb-3">
        Use one of the below methods to setup two factor verification on your
        profile.
      </div>
      <div class="c-input-group">
        <VSelectTileRadio
          ref="two-factor-form-tile"
          field="two_factor_type"
          :state-key="twoFactorTypeStateKey"
          class="-bar-layout -with-margin-bottom -small-title"
          button-class="h-mh-sm-0 h-mh-8 h-mr-1"
          :scope="twoFactorTypeStateKey"
          :options="twoFactorTypes"
        />
      </div>

      <v-two-factor-sms-setup v-if="twoFactorMethod === 'sms_code'" />
      <v-two-factor-app-setup v-if="twoFactorMethod === 'app_code'" />
    </div>

    <div v-else name="fade" mode="out-in">
      <h3 class="type-heading-body h-mb-1">
        {{
          twoFactorSetup === "sms_code"
            ? "SMS two-factor login enabled."
            : "Auth QR code two-factor login enabled."
        }}
      </h3>

      <p v-if="twoFactorSetup === 'sms_code'">
        SMS messages will be sent to
        <strong v-text="twoFactorDisplayNumber" />
      </p>

      <div>
        <a
          v-if="!updateInProgress"
          :href="false"
          class="c-button -admin-small"
          @click="disableTwoFactor"
        >
          Disable
          {{ twoFactorSetup === "sms_code" ? "SMS" : "Auth QR code" }}
          two-factor
        </a>
        <v-loader v-else />
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "js-admin/classes/AjaxServices/UserService.js";
import TwoFactorService from "js-admin/classes/AjaxServices/TwoFactorService";

import VSelectTileRadio from "js-admin/components/form/select-tile/SelectTileRadio.vue";
import VTwoFactorSmsSetup from "./TwoFactorSmsSetup";
import VTwoFactorAppSetup from "./TwoFactorAppSetup";

export default {
  components: {
    VSelectTileRadio,
    VTwoFactorSmsSetup,
    VTwoFactorAppSetup,
  },
  data() {
    return {
      twoFactorMethod: null,
      twoFactorTypes: [
        {
          title: "App Code",
          value: "app_code",
        },
        {
          title: "SMS Code",
          value: "sms_code",
        },
      ],
      twoFactorTypeStateKey: "twoFactorType",
      updateInProgress: false,
    };
  },

  computed: {
    twoFactorSetup() {
      return this.$store.state.user.two_factor_type;
    },

    twoFactorType() {
      return this.$store.state.fieldStore[this.twoFactorTypeStateKey]
        .two_factor_type;
    },

    twoFactorDisplayNumber() {
      if (this.$store.state.user.mobile_number) {
        return (
          "XXXXX XXX " +
          this.$store.state.user.mobile_number.slice(
            this.$store.state.user.mobile_number.length - 3
          )
        );
      }
      return null;
    },
  },

  watch: {
    twoFactorType(type) {
      this.twoFactorMethod = type;
    },
  },

  methods: {
    refreshUser() {
      const loggedIn = this.$store.state.me;
      UserService.getUserInfo(loggedIn).then((response) => {
        const user = response.data.data;
        this.$store.commit("setUser", user);
      });
    },
    disableTwoFactor() {
      this.updateInProgress = true;

      TwoFactorService.disableTwoFactor().then(() => {
        this.updateInProgress = false;
        this.$store.dispatch("updateStoreItem", {
          value: null,
          stateKey: this.twoFactorTypeStateKey,
          field: "two_factor_type",
        });
        this.refreshUser();
      });
    },
  },
};
</script>
