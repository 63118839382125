export default {
  getUser: (state) => state.user,
  getUserUuid: (state) => state.user.uuid,
  getHelpdeskTitle: (state) => state.fieldStore.helpdesk.title,
  getHelpdeskDescription: (state) => state.fieldStore.helpdesk.description,
  getAssignee: (state) => state.fieldStore.helpdesk.assignee,
  getAgents: (state) => state.agents,
  getHelpdeskInvestorTypeList: (state) => state.config.helpdesk_types,
  getHelpdeskIssueTypeList: (state) => state.config.helpdesk_issues,
  getHelpdeskPriorityList: (state) => state.config.helpdesk_priorities,
  getHelpdeskInvestorType: (state) => state.fieldStore.helpdesk.investor_type,
  getHelpdeskIssueType: (state) => state.fieldStore.helpdesk.issue_type,
  getHelpdeskPriority: (state) => state.fieldStore.helpdesk.priority,
  getHelpdeskTaskList: (state) => state.helpdesk.taskList,
  getAsanaUrl: (state) => state.config.helpdesk.asana_url,
  getAsanaProjectId: (state) => state.config.helpdesk.project_id,
  getSupportType: (state) => state.fieldStore.helpdesk.support_request_type,
  getSupportComment: (state) => state.fieldStore.helpdesk.task_comment,
};
