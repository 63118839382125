<template>
  <div class="v-stats">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Customer Change Requests
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <v-customer-change-requests-search
            ref="searchDate"
            :scope="reportScope"
            :report-state-key="reportStateKey"
            :mode="mode"
            :select-data="ownerData"
            :submit-results="submitResults"
            @get-results="getResults"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-3">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-1">
                  Created Date
                </div>
                <div class="col-2">
                  Account
                </div>
                <div class="col-2">
                  Type
                </div>
                <div class="col-3">
                  Details
                </div>
                <div class="col-2">
                  Status
                </div>
                <div class="col-2" />
              </div>
            </div>
            <div v-if="reportData.length">
              <div
                v-for="(data, index) in reportData"
                :key="index"
                class="c-admin-table-row"
              >
                <VChangeRequestRow :request="data" />
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              No change requests found
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VCustomerChangeRequestsSearch from "js-admin/components/Search/CustomerChangeRequestsSearch";
import VChangeRequestRow from "js-admin/router/views/customer-change-requests/ChangeRequestRow";
import CustomerChangeRequestService from "js-admin/classes/AjaxServices/CustomerChangeRequestService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  components: {
    VCustomerChangeRequestsSearch,
    VChangeRequestRow,
  },
  data() {
    return {
      reportScope: "reportSearch",
      reportStateKey: "reportSearch",
      mode: "single",
      submitResults: false,
      reportData: [],
    };
  },

  computed: {
    ownerData() {
      return this.$store.state.agents;
    },
  },

  mounted() {
    if (
      !this.checkPermission("view_change_requests") ||
      this.systemFeatureIsDisabled("disabled_change_requests")
    ) {
      return this.$router.push({ name: "home" });
    }
    this.$store.dispatch("fetchOwners");
    this.getResults();
  },

  methods: {
    async getResults() {
      this.submitResults = true;

      const data = this.formatDateData(
        this.$refs.searchDate.fromDate,
        this.$refs.searchDate.toDate
      );

      if (
        this.$store.state.fieldStore[this.reportStateKey]
          .account_manager_uuid &&
        /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/gi.test(
          this.$store.state.fieldStore[this.reportStateKey].account_manager_uuid
        )
      ) {
        data.account_manager_uuid = this.$store.state.fieldStore[
          this.reportStateKey
        ].account_manager_uuid;
      }

      if (
        this.$store.state.fieldStore[this.reportStateKey].all_change_requests
      ) {
        data.all_change_requests = this.$store.state.fieldStore[
          this.reportStateKey
        ].all_change_requests;
      }

      return CustomerChangeRequestService.getList(data)
        .then((response) => {
          this.submitResults = false;
          this.reportData = response.data.data;
        })
        .catch((error) => {
          this.submitResults = false;
          FlashMessageService.error(error.response.data.message);
          this.reportData = [];
        });
    },
  },
};
</script>
