<template>
  <div class="v-reports">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Reports
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="c-admin-panel">
            <div class="c-admin-panel-title">
              {{ accountsLeadsTitle }}
            </div>
            <div
              v-for="link in accounts_and_leads"
              :key="link.route"
              class="mt-3"
            >
              <div
                v-if="checkPermission(link.permission)"
                class="c-admin-menu-item -clickable"
                @click="$router.push({ name: link.route })"
              >
                <i class="fas fa-clipboard-list" /> &nbsp; {{ link.title }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!systemFeatureIsDisabled('disabled_tasks')" class="col-6">
          <div class="c-admin-panel">
            <div class="c-admin-panel-title">
              Tasks
            </div>
            <div v-for="link in tasks" :key="link.route" class="mt-3">
              <div
                v-if="checkPermission(link.permission)"
                class="c-admin-menu-item -clickable"
                @click="
                  () => {
                    !!link.click
                      ? link.click()
                      : $router.push({ name: link.route });
                  }
                "
              >
                <i class="fas fa-clipboard-list" /> &nbsp; {{ link.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div v-if="checkPermission('reporting_level1')" class="c-admin-panel">
            <div class="c-admin-panel-title">
              Investment Accounts
            </div>
            <div class="mt-3">
              <div
                v-if="checkPermission('reporting_level1')"
                class="c-admin-menu-item -clickable"
                @click="completedApplicationsReportPage"
              >
                <i class="fas fa-clipboard-list" /> &nbsp; Today's Completed
                Applications
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!systemFeatureIsDisabled('disabled_phone_system')"
          class="col-6"
        >
          <div v-if="checkPermission('reporting_level2')" class="c-admin-panel">
            <div class="c-admin-panel-title">
              Calls
            </div>
            <div class="mt-3">
              <div
                v-if="
                  checkPermission('reporting_level2') &&
                    !checkPermission('compliance_view_call_recordings')
                "
                class="c-admin-menu-item -clickable"
                @click="callStatsReportPage"
              >
                <i class="fas fa-phone-volume" /> &nbsp; Call Stats
              </div>
              <div
                v-else-if="checkPermission('compliance_view_call_recordings')"
                class="c-admin-menu-item -clickable"
                @click="callStatsRecordingsReportPage"
              >
                <i class="fas fa-phone-volume" /> &nbsp; Call Stats
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div
            v-if="checkPermission('view_performance_fee_reports')"
            class="c-admin-panel"
          >
            <div class="c-admin-panel-title">
              Performance Fees
            </div>
            <div
              class="c-admin-menu-item -clickable"
              @click="$router.push({ name: 'performance-fee-reports' })"
            >
              <i class="fas fa-clipboard-list" /> &nbsp; Performance Fee Reports
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accounts: [
        {
          title: "Suitability Report",
          route: "suitability-report",
          permission: "reporting_level1",
        },
        {
          title: "Audit Log Report",
          route: "audit-log-report",
          permission: "reporting_level1",
        },
        {
          title: "Cass Report",
          route: "cass-report",
          permission: "reporting_level1",
        },
        {
          title: "Fee Report",
          route: "fee-report",
          permission: "reporting_level1",
        },
        {
          title: "Accounts per Account Manager",
          route: "accounts-held",
          permission: "reporting_level2",
        },
      ],
      leads: [
        {
          title: "Leads Created",
          route: "leads-created-report",
          permission: "reporting_level1",
        },
        {
          title: "Leads Taken",
          route: "leads-taken-report",
          permission: "reporting_level1",
        },
        {
          title: "Leads Closed",
          route: "leads-closed-report",
          permission: "reporting_level1",
        },
        {
          title: "Total Leads",
          route: "total-leads",
          permission: "reporting_level1",
        },
      ],
      tasks: [
        {
          title: "Active Tasks",
          route: "tasks-report",
          permission: "reporting_level1",
        },
        {
          title: "Overdue Tasks",
          click: () => {
            this.$router.push({
              path: "/reports/tasks",
              query: { overdueTasks: "true" },
            });
          },
          permission: "reporting_level1",
        },
      ],
    };
  },

  computed: {
    accounts_and_leads() {
      if (!this.systemFeatureIsDisabled("disabled_leads"))
        return [...this.accounts, ...this.leads];

      return this.accounts;
    },
    accountsLeadsTitle() {
      if (!this.systemFeatureIsDisabled("disabled_leads"))
        return "Accounts/Leads";

      return "Accounts";
    },
  },

  mounted() {
    if (!this.checkPermission("reporting_level1")) {
      return this.$router.push({ name: "home" });
    }
  },
  methods: {
    callStatsReportPage() {
      return this.$router.push({ name: "call-stats-report" });
    },

    callStatsRecordingsReportPage() {
      return this.$router.push({ name: "calls-recordings" });
    },

    completedApplicationsReportPage() {
      return this.$router.push({ name: "completed-applications-report" });
    },
  },
};
</script>
