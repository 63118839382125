<template>
  <transition name="slideInPanel">
    <div v-if="fullscreenPanelIsOpen" class="c-fullscreen-panel">
      <transition mode="out-in" name="fade">
        <VMenu v-if="panelContent === 'menu'" />
      </transition>
    </div>
  </transition>
</template>

<script>
import VMenu from "./Menu.vue";

export default {
  components: {
    VMenu,
  },
  computed: {
    panelContent() {
      return this.$store.state.fullscreenPanel.content;
    },
  },
};
</script>
