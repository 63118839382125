<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item @toggle="accordionToggle">
        <v-modal ref="emailMessageModal" :trigger="false" size="xl">
          <h3 class="c-admin-panel-title text-center">
            Email
          </h3>
          <iframe
            ref="messageBody"
            style="border: 0;"
            class="c-secure-message-iframe"
          />
        </v-modal>

        <template v-slot:title> Emails ({{ emailCount }}) </template>

        <VSendEmail
          v-if="checkPermission('send_emails')"
          class="mt-2"
          :state-key="stateKey"
          :account-uuid="accountUuid"
          :account-email="accountEmail"
        />

        <div v-if="dataReady">
          <div class="c-admin-table">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-2">
                  Date Sent
                </div>
                <div class="col-3">
                  Recipient
                </div>
                <div class="col-3">
                  Subject
                </div>
                <div class="col-2">
                  Sender
                </div>
                <div class="col-2 text-center" />
              </div>
            </div>
            <div v-if="modelEmails.length">
              <div
                v-for="(email, index) in modelEmails"
                :key="index"
                class="c-admin-table-row"
              >
                <VEmailsRow :email="email" />
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              No emails
            </div>
          </div>
          <div class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-model="page"
                  :page-count="pages.length"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VPagination from "vue-plain-pagination";
import AccountService from "js-admin/classes/AjaxServices/AccountService";
import VEmailsRow from "js-admin/components/emails/EmailsRow.vue";
import VSendEmail from "js-admin/components/emails/SendEmail.vue";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  components: {
    VPagination,
    VEmailsRow,
    VSendEmail,
  },
  props: {
    stateKey: {
      required: true,
    },
    accountUuid: {
      required: true,
    },
    accountEmail: {
      required: true,
    },
  },

  data() {
    return {
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      emails: null,
      emailCount: null,
    };
  },

  computed: {
    modelEmails() {
      return this.emails;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    return AccountService.getAccountEmails({
      uuid: this.accountUuid,
      return_count: true,
    })
      .then((response) => {
        if (response.data.data) {
          this.emailCount = response.data.data.count;
          this.setPages();
        }
      })
      .catch((error) => {
        FlashMessageService.error(error.response.data.data.message);
      });
  },

  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },

    paginate() {
      this.dataReady = false;

      return AccountService.getAccountEmails({
        uuid: this.accountUuid,
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      })
        .then((response) => {
          if (response.data) {
            this.emails = response.data.data;
            this.dataReady = true;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(this.emailCount, this.perPage);
    },
  },
};
</script>
