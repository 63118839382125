var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"c-admin-panel"},[_c('div',{staticClass:"c-admin-panel-section"},[_vm._m(0),(_vm.investmentAccount.current_step)?_c('div',{staticClass:"row mt-2"},[_vm._m(1),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.stepName)+" ")])])]):_vm._e(),(_vm.isaType)?_c('div',{staticClass:"row mt-2"},[_vm._m(2),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.isaType)+" ")])])]):_vm._e(),(_vm.investmentAccount.amount)?_c('div',{staticClass:"row mt-2"},[_vm._m(3),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.investmentAccount.currency.symbol + _vm.convertToPounds(_vm.investmentAccount.amount))+" ")])])]):_vm._e(),(_vm.investmentAccount.top_up_monthly_amount)?_c('div',{staticClass:"row mt-2"},[_vm._m(4),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.investmentAccount.currency.symbol + _vm.convertToPounds(_vm.investmentAccount.top_up_monthly_amount))+" ")])])]):_vm._e(),(_vm.investmentAccount.terms_conditions_agreed)?_c('div',{staticClass:"row mt-2"},[_vm._m(5),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.investmentAccount.terms_conditions_agreed))+" ")])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"c-admin-panel-title"},[_vm._v(" Application Information ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Application Step: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" ISA Type: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Amount: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Top Up Amount: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Terms Agreed: ")])])
}]

export { render, staticRenderFns }