import APIService from "js-admin/classes/APIService";
import { prepUrlParameters } from "js-admin/abstract/helpers";

export default class WithdrawalService {
  static CRUD_URL = "withdrawals";

  static async getWithdrawals(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(this.CRUD_URL + "?" + extraParams);
  }

  static async updateWithdrawalStatus(data) {
    return APIService.request(
      this.CRUD_URL + "/" + data.uuid + "/status",
      "put",
      data
    );
  }

  static async getWithdrawalTotals() {
    return APIService.request(this.CRUD_URL + "/status-totals");
  }
}
