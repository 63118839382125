<template>
  <!-- Start of personal details section -->
  <div class="c-admin-panel">
    <form
      :data-vv-scope="createAccountFormScope"
      @submit.prevent="createAccount"
    >
      <div class="c-admin-panel-section">
        <div class="row">
          <div class="col-12 col-md-5">
            <h3 class="c-admin-panel-title">
              Personal Details
            </h3>

            <div>
              <v-text-input
                :scope="createAccountFormScope"
                :state-key="stateKey"
                field="title"
                label="Title"
                validation="required"
              />
            </div>

            <div>
              <v-text-input
                :scope="createAccountFormScope"
                :state-key="stateKey"
                field="first_name"
                label="First Name"
                validation="required"
              />
            </div>

            <div>
              <v-text-input
                :scope="createAccountFormScope"
                :state-key="stateKey"
                field="middle_name"
                label="Middle Name"
                validation=""
              />
            </div>

            <div>
              <v-text-input
                :scope="createAccountFormScope"
                :state-key="stateKey"
                field="last_name"
                label="Last Name"
                validation="required"
              />
            </div>
          </div>
          <div class="col-12 col-md-7">
            <h3 class="c-admin-panel-title">
              Contact Details
            </h3>

            <div>
              <v-text-input
                :scope="createAccountFormScope"
                :state-key="stateKey"
                field="primary_contact_number"
                label="Primary Contact Number"
                validation="required|phone_number"
                error-name="primary contact number"
              />
            </div>

            <div>
              <v-text-input
                :scope="createAccountFormScope"
                :state-key="stateKey"
                field="email"
                label="Email address"
                validation="required|email"
                error-name="email address"
              />
            </div>

            <div>
              <div class="c-input">
                <Datetime
                  key="1"
                  v-model="dob"
                  format="dd/MM/yyyy"
                  placeholder="date of birth"
                  value-zone="Europe/London"
                />
                <a
                  :href="false"
                  class="c-button -admin-small mt-0 ml-3"
                  @click="clearDobField"
                >
                  Clear
                </a>
                <v-input-hidden
                  ref="input"
                  :scope="createAccountFormScope"
                  :state-key="stateKey"
                  validation=""
                  error-name="date of birth"
                  field="dob"
                  :center-error="false"
                />
              </div>
            </div>
          </div>
        </div>

        <h3 class="c-admin-panel-title">
          Primary Address Details
        </h3>
        <div class="row">
          <div class="col-12">
            <VPostcodeFinder
              :scope="createAccountFormScope"
              :state-key="stateKey"
              validation=""
              prefix="primary_address_"
            />
          </div>
        </div>

        <h3 class="c-admin-panel-title">
          Application Details
        </h3>
        <div class="row">
          <div class="col-12 col-md-5">
            <div>
              <v-text-input
                :scope="createAccountFormScope"
                :state-key="stateKey"
                field="national_insurance_number"
                label="National Insurance Number"
                validation="verify_ni"
                error-name="national insurance number"
              />
            </div>
          </div>
          <div class="col-12 col-md-7">
            <v-combo-box
              :scope="createAccountFormScope"
              :state-key="stateKey"
              :options="countries"
              :focus-on-mounted="false"
              class="-no-left-padding"
              field="nationality"
              label="Nationality"
              error-name="Nationality"
            />
          </div>
        </div>

        <h3 class="c-admin-panel-title">
          Other Details
        </h3>
        <div class="row">
          <div class="col-12 col-md-5">
            <div>
              <v-select-input
                :scope="createAccountFormScope"
                :state-key="stateKey"
                :options="accountTypes"
                :center-error="false"
                field="type"
                validation=""
                error-name="account type"
                placeholder="Select a account type"
                class="mb-4"
                :enable-blank-option="true"
              />
            </div>
          </div>
          <div class="col-12 col-md-7">
            <div>
              <div class="c-input">
                <Datetime
                  key="2"
                  v-model="deceasedDate"
                  format="dd/MM/yyyy"
                  placeholder="deceased date"
                  value-zone="Europe/London"
                />
                <a
                  :href="false"
                  class="c-button -admin-small mt-0 ml-3"
                  @click="clearDeceasedField"
                >
                  Clear
                </a>
                <v-input-hidden
                  ref="input"
                  :scope="createAccountFormScope"
                  :state-key="stateKey"
                  validation=""
                  error-name="deceased date"
                  field="date_notified_of_death"
                />
              </div>
            </div>
          </div>
        </div>

        <h3 class="c-admin-panel-title">
          <i class="fas fa-bell" />
          Preferences
        </h3>
        <div class="row mb-5">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-8">
                GPDR Marketing Opt In
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="gdpr_optin_flag"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                Marketing notifications
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_marketing_notifications"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                Product updates
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_product_notifications"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                Industry news
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_industry_news_notifications"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 class="c-admin-panel-title">
          <i class="fas fa-inbox"></i>
          Contact Preferences
        </h3>
        <div class="row mb-5">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-8">
                Phone
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_phone"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                SMS
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_sms"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                Email
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_email"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                Post
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_post"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 class="c-admin-panel-title">
            <i class="fas fa-exclamation" />
            Actions
          </h3>
          <div class="row align-items-center">
            <div class="col-8">
              Do you want to create a login to the account?
            </div>
            <div class="col-4">
              <div class="c-notifications-switches">
                <div class="c-notifications-switches-item justify-content-end">
                  <v-switch
                    field="create_user_login"
                    :state-key="stateKey"
                    class="d-inline-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <v-button
              v-if="checkPermission('edit_account')"
              class="mt-3"
              :loading="submittingAccountPersonalDetails"
              :disabled="submittingAccountPersonalDetails"
            >
              Create
            </v-button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- End of personal details section -->
</template>

<script>
import { Datetime } from "vue-datetime";

import FlashMessageService from "js-admin/classes/FlashMessageService";
import VPostcodeFinder from "js-admin/components/form/PostcodeFinder.vue";
import AddressService from "js-admin/classes/AjaxServices/AddressService";
import AccountService from "js-admin/classes/AjaxServices/AccountService";

export default {
  components: {
    Datetime,
    VPostcodeFinder,
  },
  props: {
    linkedCall: {
      default: null,
    },
  },

  data() {
    return {
      createAccountFormScope: "accountData",
      stateKey: "accountData",
      submittingAccountPersonalDetails: false,
    };
  },

  computed: {
    dob: {
      get() {
        if (
          this.$store.state.fieldStore.accountData.dob != null &&
          this.$store.state.fieldStore.accountData.dob !== ""
        ) {
          return this.$store.state.fieldStore.accountData.dob;
        } else {
          return null;
        }
      },
      set(val) {
        if (val instanceof Event) {
          val = null;
        }

        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: "dob",
        });
      },
    },
    deceasedDate: {
      get() {
        if (
          this.$store.state.fieldStore.accountData.date_notified_of_death !=
            null &&
          this.$store.state.fieldStore.accountData.date_notified_of_death !== ""
        ) {
          return this.$store.state.fieldStore.accountData
            .date_notified_of_death;
        } else {
          return null;
        }
      },
      set(val) {
        if (val instanceof Event) {
          val = null;
        }

        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: "date_notified_of_death",
        });
      },
    },
    accountTypes() {
      const types = this.$store.state.config.account_types;
      const accountTypes = [];

      Object.keys(types).forEach((key) => {
        accountTypes.push(types[key]);
      });

      return accountTypes;
    },
    countries() {
      return this.$store.state.config.countries;
    },
  },

  methods: {
    createAccount() {
      this.$validator
        .validateAll(this.createAccountFormScope)
        .then(async (result) => {
          if (result) {
            this.submittingAccountPersonalDetails = true;

            const data = {
              title: this.$store.state.fieldStore[this.stateKey].title,
              first_name: this.$store.state.fieldStore[this.stateKey]
                .first_name,
              middle_name: this.$store.state.fieldStore[this.stateKey]
                .middle_name,
              last_name: this.$store.state.fieldStore[this.stateKey].last_name,
              primary_contact_number: this.$store.state.fieldStore[
                this.stateKey
              ].primary_contact_number,
              secondary_contact_number: this.$store.state.fieldStore[
                this.stateKey
              ].secondary_contact_number,
              email: this.$store.state.fieldStore[this.stateKey].email,
              national_insurance_number: this.$store.state.fieldStore[
                this.stateKey
              ].national_insurance_number,
              nationality: this.$store.state.fieldStore[this.stateKey]
                .nationality,
              type: this.$store.state.fieldStore[this.stateKey].type,
              gdpr_optin_flag: this.$store.state.fieldStore[this.stateKey]
                .gdpr_optin_flag,
              subscribed_marketing_notifications: this.$store.state.fieldStore[
                this.stateKey
              ].subscribed_marketing_notifications,
              subscribed_product_notifications: this.$store.state.fieldStore[
                this.stateKey
              ].subscribed_product_notifications,
              subscribed_industry_news_notifications: this.$store.state
                .fieldStore[this.stateKey]
                .subscribed_industry_news_notifications,
              subscribed_phone: this.$store.state.fieldStore[this.stateKey]
                .subscribed_phone,
              subscribed_sms: this.$store.state.fieldStore[this.stateKey]
                .subscribed_sms,
              subscribed_email: this.$store.state.fieldStore[this.stateKey]
                .subscribed_email,
              subscribed_post: this.$store.state.fieldStore[this.stateKey]
                .subscribed_post,
              create_user_login: this.$store.state.fieldStore[this.stateKey]
                .create_user_login,
            };

            if (
              !this.isEmptyValue(
                this.$store.state.fieldStore[this.stateKey].dob
              )
            ) {
              data.dob = this.$store.state.fieldStore[this.stateKey].dob;
            }

            if (
              !this.isEmptyValue(
                this.$store.state.fieldStore[this.stateKey]
                  .date_notified_of_death
              )
            ) {
              data.date_notified_of_death = this.$store.state.fieldStore[
                this.stateKey
              ].date_notified_of_death;
            }

            if (this.linkedCall) {
              data.call_uuid = this.linkedCall;
            }

            AccountService.createAccount(data)
              .then(async (response) => {
                FlashMessageService.success("Account has been created");

                await this.createAddress(response.data.data.uuid);

                this.submittingAccountPersonalDetails = false;

                this.$store.dispatch("updateStoreItem", {
                  value: response.data.data,
                  stateKey: this.stateKey,
                  field: null,
                });

                if (this.linkedCall) {
                  this.$store.dispatch("updateInProgressCall", this.linkedCall);
                }
                this.$router.push("/account/" + response.data.data.uuid);
                return true;
              })
              .catch((error) => {
                this.submittingAccountPersonalDetails = false;
                FlashMessageService.error(error.response.data.message);
              });
          } else {
            setTimeout(() => {
              this.$scrollTo(".c-input-error", 500, { offset: -100 });
            }, 400);
          }
        });
    },

    async createAddress(accountUuid) {
      const addressData = {
        address_1: this.$store.state.fieldStore[this.stateKey]
          .primary_address_line_1,
        address_2: this.$store.state.fieldStore[this.stateKey]
          .primary_address_line_2,
        address_3: this.$store.state.fieldStore[this.stateKey]
          .primary_address_line_3,
        town: this.$store.state.fieldStore[this.stateKey].primary_address_town,
        county: this.$store.state.fieldStore[this.stateKey]
          .primary_address_county,
        postcode: this.$store.state.fieldStore[this.stateKey]
          .primary_address_postcode,
        country: this.$store.state.fieldStore[this.stateKey]
          .primary_address_country,
        primary_address: true,
        account_uuid: accountUuid,
      };

      await AddressService.createAddress(addressData)
        .then((response) => {
          FlashMessageService.success("Primary Address has been created");
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },

    clearDobField() {
      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.stateKey,
        field: "dob",
      });
    },

    clearDeceasedField() {
      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.stateKey,
        field: "date_notified_of_death",
      });
    },
  },
};
</script>
