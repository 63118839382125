<template>
  <div class="c-two-factor">
    <section class="c-section">
      <div v-if="type === 'app_code'">
        <div class="c-admin-panel">
          <div class="c-admin-panel-section">
            <div class="row">
              <div class="col-12">
                <h1 class="c-admin-page-title">
                  Authenticator Passcode Required
                </h1>
                <h3>
                  Extra secure! You'll need the six-digit code from your
                  authenticator app to continue. code.
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <form
                  :data-vv-scope="scope"
                  @submit.prevent="validateAppTwoFactor"
                >
                  <div>
                    <v-text-input
                      :scope="scope"
                      :state-key="stateKey"
                      label="Verification Code"
                      error-name="Please enter verification code"
                      field="app_security_code"
                      validation="required|min:6"
                      autofocus
                    />
                    <v-button
                      :loading="updateInProgress"
                      :disabled="updateInProgress"
                      class="-admin-small ml-4"
                    >
                      Submit
                    </v-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="type === 'sms_code'">
        <div class="c-admin-panel">
          <div class="c-admin-panel-section">
            <div class="row">
              <div class="col-12">
                <h1 class="c-admin-page-title">
                  Check your mobile for security code
                </h1>
                <h3>
                  We have sent a 6 digit access code to
                  {{ loggedUser.mobile_number_last_digits }}
                </h3>
                <form :data-vv-scope="scope" @submit.prevent="validateSmsCode">
                  <div>
                    <v-text-input
                      :scope="scope"
                      :state-key="stateKey"
                      label="Verification Code"
                      error-name="Please enter verification code"
                      field="sms_security_code"
                      validation="required|min:6"
                      autofocus
                    />
                    <v-button
                      :loading="updateInProgress"
                      :disabled="updateInProgress"
                      class="-admin-small ml-4"
                    >
                      Submit
                    </v-button>
                  </div>
                </form>
                <div>
                  <a
                    v-if="!updateInProgress"
                    :href="false"
                    class="c-button -admin-small -re-send-sms"
                    @click="resendSmsCode"
                  >
                    Re-send security code
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        Error
      </div>
    </section>
  </div>
</template>

<script>
import ApiService from "js-admin/classes/APIService.js";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import TwoFactorService from "js-admin/classes/AjaxServices/TwoFactorService";
import AuthService from "js-admin/classes/AuthService";

export default {
  props: {
    type: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      stateKey: "validateTwoFactor",
      scope: "validateTwoFactor",
      updateInProgress: false,
    };
  },

  computed: {
    loggedUser() {
      return this.$store.state.user;
    },
  },
  mounted() {
    ApiService.request("cookie");
  },

  methods: {
    getUserHome() {
      AuthService.getUserUuid().then(() => {
        this.$router.push({ name: "home" });
      });
    },
    resendSmsCode() {
      this.updateInProgress = true;

      TwoFactorService.generateSmsTwoFactor()
        .then((response) => {
          this.updateInProgress = false;
          FlashMessageService.success(response.data.message);
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },
    validateSmsCode() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          this.updateInProgress = true;

          TwoFactorService.validateSmsTwoFactor({
            sms_security_code: this.$store.state.fieldStore[this.stateKey]
              .sms_security_code,
          })
            .then(() => {
              this.updateInProgress = false;
              this.clearFormField("sms_security_code");
              this.getUserHome();
            })
            .catch((error) => {
              this.updateInProgress = false;
              this.clearFormField("sms_security_code");
              FlashMessageService.error(error.response.data.message);
              this.clearErrors(2000);
            });
        }
      });
    },

    validateAppTwoFactor() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          this.updateInProgress = true;

          TwoFactorService.validateAppTwoFactor({
            app_security_code: this.$store.state.fieldStore[this.stateKey]
              .app_security_code,
          })
            .then(() => {
              this.updateInProgress = false;
              this.clearFormField("app_security_code");
              this.getUserHome();
            })
            .catch((error) => {
              this.updateInProgress = false;
              this.clearFormField("app_security_code");
              FlashMessageService.error(error.response.data.message);
              this.clearErrors(2000);
            });
        }
      });
    },

    clearFormField(fieldName) {
      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.stateKey,
        field: fieldName,
      });
    },
  },
};
</script>
