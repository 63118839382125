<template>
  <div class="c-combo-field">
    <v-text-input
      ref="comboField"
      :scope="scope"
      :field="field"
      :validation="validation"
      :label="label"
      :error-name="errorName"
      :state-key="stateKey"
      :namespace="namespace"
      :placeholder="placeholder"
      :focus-on-mounted="focusOnMounted"
      autocomplete="off"
      @change="change"
      @focus="focus($event)"
      @blur="handleBlur"
    />
    <span class="c-combo-field-hitbox" @click="handleDropdown" />
    <ul
      class="c-combo-field-result"
      :class="{ '-show': searchResult.length > 0 }"
    >
      <li
        v-for="(item, index) in searchResult"
        :key="stripSpaces(item)"
        :class="{ '-active': index === currentResult }"
        @click="handleClick(item, index)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    scope: {
      type: String,
      default: "",
    },
    stateKey: {
      type: String,
      required: true,
    },
    namespace: {
      default: null,
      type: String,
    },
    field: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    errorName: {
      type: String,
      default: null,
    },
    validation: {
      type: String,
      default: "required",
    },
    placeholder: {
      type: String,
      default: null,
    },
    options: {
      default: function() {
        return [];
      },
      type: Array,
    },
    preLoadValue: {
      default: "",
      type: String,
    },
    focusOnMounted: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      searchArray: [],
      searchResult: [],
      currentResult: -1,
      displayDropdown: false,
      textSelected: false,
      originalValue: "",
    };
  },
  computed: {
    storeValue() {
      return this.$store.state.fieldStore[this.stateKey][this.field];
    },
  },
  watch: {
    options(newVal) {
      this.initOptions(newVal);
    },
  },
  mounted() {
    if (this.storeValue) {
      this.textSelected = true;
      this.originalValue = this.storeValue;
    }
    this.initOptions(this.options);
  },
  methods: {
    handleBlur() {
      if (!this.textSelected) {
        this.updateFieldStore("");
      } else if (!this.storeValue && this.originalValue) {
        this.updateFieldStore(this.originalValue);
      }
    },
    focus(e) {
      this.handleDropdown(e);
    },
    handleDropdown(e = null) {
      if (e != null) {
        e.preventDefault();
        e.stopPropagation();

        if (this.displayDropdown === false) {
          this.displayDropdown = true;
          this.searchResult = [];
          this.searchResult = this.searchArray;
        } else {
          this.displayDropdown = false;
          this.searchResult = [];
        }
      }
    },
    updateFieldStore(val) {
      this.$store.dispatch("updateStoreItem", {
        value: val,
        stateKey: this.stateKey,
        field: this.field,
      });

      document.removeEventListener("keyup", this.handleKeydown, false);
    },
    initOptions(options) {
      // optimise this
      if (options != null && options.length) {
        for (const v of Object.values(options)) {
          this.searchArray.push(v.name);
        }
      }
    },
    handleKeydown(e) {
      e.preventDefault();
      e.stopPropagation();
      const key = e.key || e.keyCode;

      if (key === "ArrowUp" || key === "Up" || key === 38) {
        if (this.currentResult > 0) {
          this.currentResult = this.currentResult - 1;
        }
      }
      if (key === "ArrowDown" || key === "Down" || key === 40) {
        if (this.currentResult < this.searchResult.length - 1) {
          this.currentResult = this.currentResult + 1;
        }
      }
      if (key === "Enter" || key === 13) {
        if (typeof this.searchResult[this.currentResult] !== "undefined") {
          this.updateFieldStore(this.searchResult[this.currentResult]);
          this.searchResult = [];
          this.currentResult = -1;
        }
      }
    },
    handleClick(val) {
      for (const v of Object.values(this.options)) {
        if (v.name === val) {
          this.textSelected = true;
          this.updateFieldStore(val);
          this.$emit("selected", v);
        }
      }
      this.searchResult = [];
      this.displayDropdown = false;
    },
    change(val) {
      if (val.length === 1) {
        document.removeEventListener("keyup", this.handleKeydown, false);
        document.addEventListener("keyup", this.handleKeydown, false);
      }
      this.searchResult = [];
      if (val !== "") {
        this.searchArray.forEach((i) => {
          if (i.toLowerCase().includes(val.toLowerCase())) {
            this.searchResult.push(i);
          }
        });
        this.searchResult = Array.from(new Set(this.searchResult));
      }
    },
  },
};
</script>
