<template>
  <div class="c-phone-call-box-container">
    <a
      class="c-phone-call-box-container-close-box"
      @click="closeBox(ringingCallsStateKey, inboundCall)"
    >
      <i class="c-phone-call-box-container-close-box-icon fas fa-times" />
    </a>
    <div>
      Inbound Call From: <strong>{{ inboundCall.from }}</strong>
    </div>
    <div v-show="inboundCall.name">
      Customer: <strong>{{ inboundCall.name }}</strong>
    </div>
    <div v-show="inboundCall.account_uuid">
      <a
        class="c-button -admin-small"
        @click="viewAccount(inboundCall.account_uuid)"
      >
        View Account
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inboundCall: {
      required: true,
    },
    ringingCallsStateKey: {
      required: true,
    },
  },
  computed: {},
  methods: {
    closeBox(stateKey, call, toConfirm = false) {
      if (toConfirm) {
        if (!confirm("Are you sure?")) {
          return;
        }
      }

      this.$store.dispatch("deleteFromArrayItemStore", {
        stateKey: stateKey,
        callUuid: call.uuid,
      });
    },
    viewAccount(uuid) {
      if (this.$router.currentRoute.path !== "/account/" + uuid) {
        this.$router.push({ name: "account", params: { uuid } });
      }

      return false;
    },
  },
};
</script>
