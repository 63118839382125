<template>
  <div class="row">
    <div class="col-12">
      <div class="c-csv-export">
        <v-button
          :loading="getGenerateReportFlag"
          :disabled="getGenerateReportFlag"
          class="c-button -admin-small"
          type="button"
          name="button"
          @click.native="handleGenerate"
          >{{ getGenerateReportFlag ? "Working..." : "Generate New" }}</v-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: "cass",
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["getGenerateReportFlag"]),
  },
  methods: {
    ...mapMutations(["setGenerateReportFlag"]),
    handleGenerate() {
      this.setGenerateReportFlag(!this.getGenerateReportFlag);
      this.$emit("start-generate-report");
    },
  },
};
</script>
