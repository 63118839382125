<template>
  <div class="c-select-input" :class="['t-' + field, { '-focus': value }]">
    <div class="c-select-input-label" v-text="label" />

    <div class="c-select-input-group">
      <select
        v-model="value"
        :name="field"
        :class="{ '-error': hasErrors && !focused, '-value': value }"
        :data-vv-as="errorName"
        :disabled="disabled"
        class="c-select-input-field"
        @change="updateNotesBox"
      >
        <option :value="null" selected v-text="placeholder" />

        <option v-for="(option, index) in options" :key="index" :value="index">
          {{ option }}
        </option>
      </select>
      <div class="c-select-input-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M8 0L6.5 1.5 12.1 7H0v2h12.1l-5.6 5.5L8 16l8-8z" />
        </svg>
      </div>
    </div>
    <input
      v-model="value"
      v-validate="validation"
      :name="field"
      :data-vv-as="errorName"
      type="hidden"
    />
    <VErrorMsg
      :scope="scope"
      :field="field"
      :focused="focused"
      :center="centerError"
    />
  </div>
</template>

<script>
import VErrorMsg from "js-admin/components/form/ErrorMsg.vue";

export default {
  components: {
    VErrorMsg,
  },
  inject: ["$validator"],
  props: {
    scope: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      default: "required",
    },
    defaultValue: {
      default: null,
    },
    callUuid: {
      type: String,
    },
  },
  data() {
    return {
      focus: false,
      value: null,
      options: this.$store.state.config.call_dispositions,
      autoSaveOptions: this.$store.state.config.call_dispositions_auto_save,
      field: "call_disposition",
      label: "Call Disposition",
      errorName: "call disposition",
      placeholder: "Select Call Disposition",
      centerError: true,
    };
  },
  computed: {
    focused() {
      if (typeof this.$store.getters.getFocused !== "undefined") {
        return this.$store.getters.getFocused;
      } else {
        return this.focus;
      }
    },
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
  },
  mounted() {
    this.value = this.defaultValue;
  },
  methods: {
    updateNotesBox() {
      if (this.$parent.$refs[this.callUuid + "_call_notes"]) {
        if (this.value && this.autoSaveOptions.includes(this.value)) {
          this.$parent.$refs[this.callUuid + "_call_notes"].value =
            "Called customer, " + this.options[this.value];
        }
      }
    },
  },
};
</script>
