import APIService from "js-admin/classes/APIService";
import { prepUrlParameters } from "js-admin/abstract/helpers";

export default class IssuerService {
  static CRUD_URL = "issuers";
  static CONTACT_URL = "contacts";
  static RSS_URL = "rss";
  static FILES_URL = "files";

  static async getIssuers(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(this.CRUD_URL + "?" + extraParams);
  }

  static async getIssuerData(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid);
  }

  static async createIssuer(data) {
    return APIService.request(this.CRUD_URL, "post", this.formatData(data));
  }

  static async updateIssuer(data) {
    return APIService.request(
      this.CRUD_URL + "/" + data.uuid,
      "put",
      this.formatData(data)
    );
  }

  static async deleteIssuer(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }

  static async createIssuerContact(data) {
    return APIService.request(
      this.CRUD_URL + "/" + this.CONTACT_URL,
      "post",
      data
    );
  }

  static async updateIssuerContact(uuid, data) {
    return APIService.request(
      this.CRUD_URL + "/" + this.CONTACT_URL + "/" + uuid,
      "put",
      data
    );
  }

  static async getIssuerRssFeeds(uuid, params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + uuid + "/rss?" + extraParams
    );
  }

  static async createIssuerRssFeed(data) {
    return APIService.request(this.CRUD_URL + "/" + this.RSS_URL, "post", data);
  }

  static async deleteRssFeed(uuid) {
    return APIService.request(
      this.CRUD_URL + "/" + this.RSS_URL + "/" + uuid,
      "delete"
    );
  }

  static async getIssuerCarouselSlides(uuid, params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + uuid + "/linked-slides?" + extraParams
    );
  }

  static async getIssuerFiles(uuid, params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + uuid + "/" + this.FILES_URL + "?" + extraParams
    );
  }

  static async addFile({ uuid, formData }) {
    const header = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return APIService.request(
      this.CRUD_URL + "/" + uuid + "/" + this.FILES_URL,
      "post",
      formData,
      header
    );
  }

  static async getIssuerInstruments(uuid, params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + uuid + "/instruments?" + extraParams
    );
  }

  static formatData(data) {
    ["ticker", "stock_exchange_id"].forEach((key) => {
      if (data[key]) {
        data[key] = data[key].toUpperCase();
      }
    });

    return data;
  }
}
