export default {
  methods: {
    keypress($event) {
      $event = $event || window.event;
      const charCode = $event.which ? $event.which : $event.keyCode;
      // charcode 47 = '/'
      if (
        charCode > 31 &&
        (charCode < 47 || charCode > 57) &&
        // -
        charCode !== 45
      ) {
        $event.preventDefault();
      } else {
        return true;
      }
    },
  },
};
