<template>
  <div class="row">
    <div class="col-12">
      <div class="c-admin-panel">
        <v-accordion :open-first-item="true">
          <v-accordion-item>
            <template v-slot:title>
              Search
            </template>
            <form :data-vv-scope="scope" @submit.prevent="getResults">
              <div class="row d-flex align-items-center mb-3">
                <div class="col-6">
                  From:
                  <DatePicker key="0" v-model="fromDate" :mode="mode" />
                </div>
                <div class="col-6">
                  To:
                  <DatePicker
                    key="0"
                    v-model="toDate"
                    :mode="mode"
                    :min-date="fromDate"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center">
                <div class="col-6">
                  <div>
                    Account Manager
                  </div>
                  <v-select-input
                    :scope="scope"
                    :state-key="reportStateKey"
                    :options="selectData"
                    :center-error="false"
                    field="account_manager_uuid"
                    error-name="account manager"
                    placeholder="Select a account manager"
                    class="-centered mb-4"
                    validation=""
                    :enable-blank-option="true"
                  />
                </div>
                <div class="col-6">
                  <VCheckbox
                    field="all_change_requests"
                    error-name="show all change requests"
                    :scope="scope"
                    :state-key="reportStateKey"
                    validation=""
                  >
                    Show All Customer Change Requests
                  </VCheckbox>
                </div>
              </div>
              <div class="row d-flex">
                <div class="col-6"> </div>
                <div class="col-6">
                  <v-button
                    class="-admin-small"
                    :loading="submitResults"
                    :disabled="submitResults"
                  >
                    Search
                  </v-button>
                </div>
              </div>
            </form>
          </v-accordion-item>
        </v-accordion>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    DatePicker,
  },
  props: {
    scope: {
      type: String,
      default: null,
    },
    reportStateKey: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
    selectData: {
      type: Array,
      default: () => [],
    },
    submitResults: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentDate: new Date(),
  }),
  computed: {
    fromDate: {
      get() {
        if (this.$store.state.fieldStore[this.scope].from_date == null) {
          return new Date(
            this.currentDate.getUTCFullYear(),
            this.currentDate.getUTCMonth(),
            1
          );
        }
        return this.$store.state.fieldStore[this.scope].from_date;
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.scope,
          field: "from_date",
        });
      },
    },
    toDate: {
      get() {
        if (this.$store.state.fieldStore[this.scope].to_date == null) {
          return new Date(
            this.currentDate.getUTCFullYear(),
            this.currentDate.getUTCMonth() + 1,
            0,
            23,
            59,
            59
          );
        }
        return this.$store.state.fieldStore[this.scope].to_date;
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.scope,
          field: "to_date",
        });
      },
    },
  },
  methods: {
    getResults() {
      this.$emit("get-results");
    },
  },
};
</script>
