import "regenerator-runtime";

import "./bootstrap";

import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router/router";
import recaptcha from "./abstract/recaptcha";

import VButton from "./components/common/Button.vue";
import VLoader from "./components/common/Loader.vue";
import VAjaxLoader from "./components/common/AjaxLoader.vue";
import VModel from "./components/common/Modal.vue";
import VTextInput from "./components/form/TextInput.vue";
import VInputHidden from "./components/form/InputHidden.vue";
import VTextarea from "./components/form/Textarea.vue";
import VSelectInput from "./components/form/SelectInput.vue";
import VCheckbox from "./components/form/checkbox/Checkbox.vue";
import VComboBox from "./components/form/ComboBox";
import VSwitch from "./components/switches/Switch.vue";
import VAccordion from "./components/accordion/Accordion.vue";
import VAccordionItem from "./components/accordion/AccordionItem.vue";
import VPhoneNumberTextInput from "./components/form/PhoneNumberTextInput";
import VAmountInput from "./components/form/AmountInput";
import VWysiwyg from "./components/form/WYSIWYGEditor.vue";

Vue.component("VButton", VButton);
Vue.component("VLoader", VLoader);
Vue.component("VAjaxLoader", VAjaxLoader);
Vue.component("VModal", VModel);
Vue.component("VTextInput", VTextInput);
Vue.component("VInputHidden", VInputHidden);
Vue.component("VSelectInput", VSelectInput);
Vue.component("VCheckbox", VCheckbox);
Vue.component("VComboBox", VComboBox);
Vue.component("VSwitch", VSwitch);
Vue.component("VAccordion", VAccordion);
Vue.component("VAccordionItem", VAccordionItem);
Vue.component("VTextarea", VTextarea);
Vue.component("VPhoneNumberTextInput", VPhoneNumberTextInput);
Vue.component("VAmountInput", VAmountInput);
Vue.component("VWysiwyg", VWysiwyg);

Vue.use(recaptcha, { siteKey: window.recaptcha });

const app = new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

// If running e2e tests...
if (process.env.VUE_APP_TEST === "e2e") {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app;
}
