<template>
  <div class="c-section">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h2 class="c-admin-page-title">
            More Actions
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
