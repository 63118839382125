import APIService from "js-admin/classes/APIService";

export default class AddressService {
  static CRUD_URL = "addresses";

  static async createAddress(data) {
    return APIService.request(this.CRUD_URL, "post", data);
  }

  static async updateAddress(data) {
    return APIService.request(this.CRUD_URL + "/" + data.uuid, "put", data);
  }

  static async updatePrimaryAddress(accountUuid, addressUuid) {
    return APIService.request(
      "accounts/" + accountUuid + "/addresses/" + addressUuid + "/primary",
      "post"
    );
  }

  static async deleteAddress(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }
}
