<template>
  <a
    :href="false"
    class="c-button -admin-small mr-2"
    @click="toggleDisableLogin"
  >
    {{ toggleDisableLoginText }}
  </a>
</template>

<script>
import UserService from "js-admin/classes/AjaxServices/UserService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    disabledLogin: {
      required: true,
      type: Boolean,
    },
    userUuid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      stateKey: "accountData",
    };
  },
  computed: {
    toggleDisableLoginText() {
      return (this.disabledLogin ? "Enable" : "Disable") + " Login";
    },
    messageText() {
      return (
        "Account has been " + (this.disabledLogin ? "disabled" : "enabled")
      );
    },
  },
  methods: {
    async toggleDisableLogin() {
      const { data } = await UserService.disableLogin({
        uuid: this.userUuid,
        disable_login: !this.disabledLogin,
      });

      this.$store.dispatch("updateSubArrayItemStore", {
        value: data.data.disable_login,
        stateKey: this.stateKey,
        field: 0,
        index: "users",
        subArrayField: "disable_login",
      });

      await this.$nextTick();
      FlashMessageService.success(this.messageText);
    },
  },
};
</script>
