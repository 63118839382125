<template>
  <!-- Start of Delete Investment Account -->
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item>
        <template v-slot:title>
          Delete Investment Account
        </template>

        <div class="c-admin-panel-section">
          <a
            :href="false"
            class="c-button -admin-small"
            @click="deleteInvestmentAccount"
          >
            Delete
          </a>
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
  <!-- End of Delete Investment Account -->
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    investmentAccount: {
      required: true,
    },
  },

  methods: {
    deleteInvestmentAccount() {
      if (
        this.checkPermission("delete_investment_accounts") &&
        confirm(
          "Are you sure you want to delete this investment account and all associated data?"
        )
      ) {
        this.$store
          .dispatch("deleteInvestmentAccount", this.investmentAccount.uuid)
          .then((response) => {
            if (response.status == 204) {
              FlashMessageService.success(
                "Investment Account has been deleted."
              );

              this.$router.push(
                "/account/" + this.investmentAccount.account.uuid
              );

              return true;
            } else {
              return false;
            }
          });
      }
    },
  },
};
</script>
