var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"c-admin-panel"},[_c('v-accordion',{attrs:{"open-first-item":true}},[_c('v-accordion-item',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("title")]},proxy:true}],null,true)},[_c('div',{staticClass:"c-admin-table"},[_c('div',{staticClass:"c-admin-table-header -bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_vm._v("Date")]),_c('div',{staticClass:"col-2"},[_vm._v("Brochure")]),_c('div',{staticClass:"col-2"},[_vm._v("Callback")]),_c('div',{staticClass:"col-2"},[_vm._v("Contact")]),_c('div',{staticClass:"col-2"},[_vm._v("Investments Started")]),_c('div',{staticClass:"col-2"},[_vm._v("Total")])])]),(_vm.leads)?_c('div',_vm._l((_vm.leads),function(data){return _c('div',{key:data.owner_uuid,staticClass:"c-admin-table-row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_vm._v(" "+_vm._s(data.date)+" ")]),_c('div',{class:[
                      'col-2',
                      {
                        'h-color-greyed ': data.brochure === 0,
                        'h-color-highlight ': data.brochure > 0,
                      },
                    ]},[_vm._v(" "+_vm._s(data.brochure)+" ")]),_c('div',{class:[
                      'col-2',
                      {
                        'h-color-greyed ': data.callback === 0,
                        'h-color-highlight ': data.callback > 0,
                      },
                    ]},[_vm._v(" "+_vm._s(data.callback)+" ")]),_c('div',{class:[
                      'col-2',
                      {
                        'h-color-greyed ': data.contact === 0,
                        'h-color-highlight ': data.contact > 0,
                      },
                    ]},[_vm._v(" "+_vm._s(data.contact)+" ")]),_c('div',{class:[
                      'col-2',
                      {
                        'h-color-greyed ': data.investments === 0,
                        'h-color-highlight ': data.applications > 0,
                      },
                    ]},[_vm._v(" "+_vm._s(data.applications)+" ")]),_c('div',{class:[
                      'col-2',
                      {
                        'h-color-greyed ': data.total === 0,
                        'h-color-highlight ': data.total > 0,
                      },
                    ]},[_vm._v(" "+_vm._s(data.total)+" ")])])])}),0):_c('div',{staticClass:"c-admin-table-row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._v(" No results found. ")])])])])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }