<template>
  <div>
    <div v-if="emails.length" class="c-admin-table mb-4">
      <div class="c-admin-table-header">
        <form :data-vv-scope="scope" @submit.prevent="sendEmail">
          <div class="row align-items-end">
            <div class="col-9">
              <div>
                <v-select-input
                  state-key="sendEmailForm"
                  :options="emails"
                  :center-error="false"
                  field="user_email_template"
                  label=""
                  validation="required"
                  error-name="select email template"
                  placeholder="Select email template"
                  :enable-blank-option="true"
                />
                <a
                  :href="false"
                  class="c-button -admin-small ml-3"
                  @click="previewEmail"
                >
                  Preview Email
                </a>
              </div>
              <div>
                <v-modal ref="messageModal" :trigger="false" size="xl">
                  <h3 class="c-admin-panel-title text-center">
                    Preview
                  </h3>
                  <div ref="test" />
                  <iframe
                    ref="messageBody"
                    style="border: 0;"
                    class="c-secure-message-iframe"
                  />
                </v-modal>
              </div>
            </div>
            <div class="col-3">
              <div class="text-right">
                <v-button
                  class="-admin-small"
                  :loading="updateInProgress"
                  :disabled="updateInProgress"
                >
                  Send Email
                </v-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    stateKey: {
      required: true,
    },
    accountUuid: {
      required: true,
    },
    accountEmail: {
      required: true,
    },
  },

  data() {
    return {
      scope: "sendEmail",
      updateInProgress: false,
    };
  },

  computed: {
    emails() {
      const emails = [];

      if (this.$store.state.config.user_emails) {
        Object.keys(this.$store.state.config.user_emails).forEach((key) => {
          emails.push({
            value: this.$store.state.config.user_emails[key].uuid,
            name: this.$store.state.config.user_emails[key].title,
          });
        });
      }

      return emails;
    },
  },

  methods: {
    previewEmail() {
      if (this.$store.state.fieldStore.sendEmailForm.user_email_template) {
        this.$refs.messageModal.toggle();

        CommunicationService.previewEmail(
          this.$store.state.fieldStore.sendEmailForm.user_email_template,
          {
            model_uuid: this.accountUuid,
          }
        )
          .then((response) => {
            this.message = response.data.data.body;
            this.message = this.message.replace(/href/g, "data-href");

            this.$refs.messageBody.contentWindow.document.open();
            this.$refs.messageBody.contentWindow.document.write(this.message);
            this.$refs.messageBody.contentWindow.document.close();
          })
          .catch((error) => {
            this.$refs.messageModal.toggle();
            FlashMessageService.error(error.response.data.message);
          });
      } else {
        FlashMessageService.error("Please select an email template");
      }
    },

    sendEmail() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          if (confirm("Are you sure you want to send this email?")) {
            this.updateInProgress = true;

            CommunicationService.sendEmail(
              this.$store.state.fieldStore.sendEmailForm.user_email_template,
              {
                email: this.accountEmail,
                model_uuid: this.accountUuid,
              }
            )
              .then(() => {
                this.updateInProgress = false;
                FlashMessageService.success("Email sent");

                this.$store.dispatch("updateStoreItem", {
                  value: {
                    user_email_template: null,
                  },
                  stateKey: "sendEmailForm",
                });

                this.$parent.$parent.$parent.$parent.paginate();
              })
              .catch((error) => {
                this.updateInProgress = false;
                FlashMessageService.error(error.response.data.message);
              });
          }
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },
  },
};
</script>
