<template>
  <div>
    <div v-if="secureMessages.length" class="c-admin-table mb-4">
      <div class="c-admin-table-header">
        <form
          :data-vv-scope="sendSecureMessageForm"
          @submit.prevent="sendSecureMessage"
        >
          <div class="row align-items-end">
            <div class="col-9">
              <div>
                <v-select-input
                  :state-key="sendSecureMessageForm"
                  :options="secureMessages"
                  :center-error="false"
                  field="secure_message_template"
                  label=""
                  validation="required"
                  error-name="select secure message"
                  placeholder="Select secure message"
                  :enable-blank-option="true"
                />
                <a
                  :href="false"
                  class="c-button -admin-small ml-3"
                  @click="previewSecureMessage"
                >
                  Preview
                </a>
              </div>
              <div>
                <v-modal ref="messageModal" :trigger="false" size="xl">
                  <h3 class="c-admin-panel-title text-center">
                    Preview Secure Message
                  </h3>
                  <div ref="test" />
                  <iframe
                    ref="messageBody"
                    style="border: 0;"
                    class="c-secure-message-iframe"
                  />
                </v-modal>
              </div>
            </div>
            <div class="col-3">
              <div class="text-right">
                <v-button
                  class="-admin-small"
                  :loading="updateInProgress"
                  :disabled="updateInProgress"
                >
                  Send Secure Message
                </v-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    stateKey: {
      required: true,
    },
    accountUuid: {
      required: true,
    },
  },

  data() {
    return {
      sendSecureMessageForm: "sendSecureMessage",
      updateInProgress: false,
    };
  },

  computed: {
    secureMessages() {
      const messages = [];

      if (this.$store.state.config.secure_messages) {
        this.$store.state.config.secure_messages.forEach((item) => {
          messages.push(item);
        });
      }

      return messages;
    },
  },

  methods: {
    previewSecureMessage() {
      if (
        this.$store.state.fieldStore[this.sendSecureMessageForm]
          .secure_message_template
      ) {
        this.$refs.messageModal.toggle();
        CommunicationService.previewSecureMessage(
          this.$store.state.fieldStore[this.sendSecureMessageForm]
            .secure_message_template,
          {
            account_uuid: this.accountUuid,
          }
        )
          .then((response) => {
            this.message = response.data.data.body;
            this.message = this.message.replace(/href/g, "data-href");

            this.$refs.messageBody.contentWindow.document.open();
            this.$refs.messageBody.contentWindow.document.write(this.message);
            this.$refs.messageBody.contentWindow.document.close();
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      } else {
        FlashMessageService.error("Please select a secure message template");
      }
    },

    sendSecureMessage() {
      this.$validator.validateAll(this.sendSecureMessageForm).then((result) => {
        if (result) {
          if (confirm("Are you sure you want to send this secure message?")) {
            this.updateInProgress = true;
            CommunicationService.sendSecureMessage(
              this.$store.state.fieldStore[this.sendSecureMessageForm]
                .secure_message_template,
              {
                account_uuid: this.accountUuid,
              }
            )
              .then(() => {
                this.updateInProgress = false;
                FlashMessageService.success("Secure message sent");

                this.$store.dispatch("updateStoreItem", {
                  value: {
                    secure_message_template: null,
                  },
                  stateKey: this.sendSecureMessageForm,
                });

                this.$parent.$parent.$parent.$parent.paginate();
              })
              .catch((error) => {
                this.updateInProgress = false;
                FlashMessageService.error(error.response.data.message);
              });
          }
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },
  },
};
</script>
