<template>
  <div class="c-input-icon-wrap">
    <transition-group name="zoomfade">
      <div
        v-show="value && !hasErrors"
        key="success"
        class="c-input-icon -success"
      >
        <svg
          id="Capa_1"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 50 50"
          style="enable-background: new 0 0 50 50;"
          xml:space="preserve"
        >
          <!-- <circle style="fill:#3ed7dd;" cx="25" cy="25" r="25"/> -->
          <polyline
            style="

fill: none;
  stroke: #3ed7dd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 4;"
            points="
              38,15 22,33 12,25 "
          />
        </svg>
      </div>
      <div
        v-show="!focused && hasErrors"
        key="error"
        class="c-input-icon -error"
      >
        <svg
          id="Capa_1"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 50 50"
          style="enable-background: new 0 0 50 50;"
          xml:space="preserve"
        >
          <!-- <circle style="fill:#FF736F;" cx="25" cy="25" r="25"/> -->
          <polyline
            style="

fill: none;
  stroke: #f56b6b;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 4;"
            points="16,34 25,25 34,16
              "
          />
          <polyline
            style="

fill: none;
  stroke: #f56b6b;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 4;"
            points="16,16 25,25 34,34
              "
          />
        </svg>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    hasErrors: {
      required: true,
    },
    focused: {
      default: false,
    },
  },
};
</script>
