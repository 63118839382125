export default class Address {
  constructor(data) {
    for (const field in data) {
      if (field === "post_town") {
        this[field] = this.cap(data[field]);
      } else {
        this[field] = data[field];
      }
    }
  }

  humanize() {
    let humanize = "";

    if (this.line_1) humanize += this.line_1 + ", ";
    if (this.line_2) humanize += this.line_2 + ", ";
    if (this.line_3) humanize += this.line_3 + ", ";
    if (this.post_town) humanize += this.post_town + ", ";
    if (this.postal_county) humanize += this.postal_county + ", ";
    if (this.postcode) humanize += this.postcode;

    return humanize;
  }

  cap(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
}
