<template>
  <div class="row align-items-center">
    <div class="col-1">
      {{ dateTimeFormat(request.created_at) }}
    </div>
    <div class="col-2">
      <a
        :href="false"
        class="c-link"
        @click="viewAccount(request.account.uuid)"
      >
        {{ request.account.full_name }}
      </a>
    </div>
    <div class="col-2">
      {{ request.type }}
    </div>
    <div class="col-3">
      <div v-if="checkPermission('view_change_requests')">
        <div v-for="(data, key) in request.details" :key="key">
          <span> {{ key }} :</span>
          <strong> {{ data }}</strong>
        </div>
      </div>
      <div v-else>
        Don't have Permission
      </div>
    </div>
    <div class="col-2">
      <strong> {{ request.status }} </strong> <br />
      <div v-if="request.actioned_at" class="mt-2">
        Actioned on:
        <strong> {{ dateTimeFormat(request.actioned_at) }} </strong>
      </div>
      <div v-if="request.actioned_by" class="mt-2">
        Actioned By:
        <strong> {{ request.actioned_by }} </strong>
      </div>
    </div>
    <div class="col-2 text-center">
      <form
        v-if="
          checkPermission('update_change_requests') &&
            request.status === 'Pending'
        "
        class="mb-3"
        @submit.prevent="completeChangeRequest"
      >
        <v-button
          class="-admin-small"
          :loading="updateInProgress"
          :disabled="updateInProgress"
        >
          Complete
        </v-button>
      </form>

      <form
        v-if="
          checkPermission('update_change_requests') &&
            request.status === 'Pending'
        "
        @submit.prevent="cancelChangeRequest"
      >
        <v-button
          class="-admin-small"
          :loading="updateInProgress"
          :disabled="updateInProgress"
        >
          Cancel
        </v-button>
      </form>
    </div>
  </div>
</template>

<script>
import CustomerChangeRequestService from "js-admin/classes/AjaxServices/CustomerChangeRequestService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    request: {
      required: true,
    },
  },

  data() {
    return {
      updateInProgress: false,
    };
  },

  computed: {},

  methods: {
    viewAccount(uuid) {
      this.$router.push({ name: "account", params: { uuid } });
    },
    completeChangeRequest() {
      this.updateInProgress = true;
      CustomerChangeRequestService.updateStatus({
        uuid: this.request.uuid,
        status: "completed",
      })
        .then((response) => {
          if (response) {
            FlashMessageService.success("Request has been updated.");
            this.$parent.getResults();
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
      this.updateInProgress = false;
    },
    cancelChangeRequest() {
      this.updateInProgress = true;
      CustomerChangeRequestService.updateStatus({
        uuid: this.request.uuid,
        status: "cancelled",
      })
        .then((response) => {
          if (response) {
            FlashMessageService.success("Request has been cancelled.");
            this.$parent.getResults();
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
      this.updateInProgress = false;
    },
  },
};
</script>
