<template>
  <div class="c-admin-panel mt-2">
    <div class="c-admin-panel-section">
      <h3 class="c-admin-panel-title">
        {{ title }}
      </h3>
      <div v-if="instrument.is_paused" class="row">
        <div class="col-12">
          <h3 class="c-admin-alert-text -error">
            Instrument has been paused. <br />
            Reason - {{ instrument.paused_reason }}
          </h3>
        </div>
      </div>
      <div class="row">
        <div v-if="instrument.name" class="col-5">
          <p>
            Instrument Name:
            <strong>
              {{ instrument.name }}
            </strong>
          </p>
        </div>
        <div v-if="instrument.ticker" class="col-5">
          <p>
            Instrument Ticker:
            <strong>
              {{ instrument.ticker }}
            </strong>
          </p>
        </div>
        <div class="col-5">
          <p>
            Instrument Client:
            <strong
              v-if="
                typeof instrument.client !== 'object' &&
                  instrument.client !== null
              "
            >
              {{ capitaliseFirstLetter(instrument.client) }}
            </strong>
            <strong v-else>
              {{ capitaliseFirstLetter(instrument.client.company_name) }}
            </strong>
          </p>
        </div>
        <div v-if="instrument.currency" class="col-5">
          <p>
            Base Currency: <strong>{{ instrument.currency.code }}</strong>
          </p>
        </div>
        <div v-if="instrument.trade_currency" class="col-5">
          <p>
            Trade Currency:
            <strong>{{ instrument.trade_currency.code }}</strong>
          </p>
        </div>
        <div v-if="instrument.status" class="col-5">
          <p>
            Status:
            <strong>
              {{ instrument.status }}
            </strong>
          </p>
        </div>
        <div v-if="instrument.target_amount" class="col-5">
          <p>
            Target Amount:
            <strong>
              {{ instrument.currency.symbol
              }}{{ convertToPounds(instrument.target_amount) }}
            </strong>
          </p>
        </div>
        <div v-if="instrument.total_units" class="col-5">
          <p>
            Total Units:
            <strong>
              {{ instrument.total_units }}
            </strong>
          </p>
        </div>
        <div class="col-5">
          <p>
            Total Bids Value:
            <strong>
              {{ instrument.currency.symbol
              }}{{ convertToPounds(instrument.total_bids_value) }}
            </strong>
          </p>
        </div>
        <div class="col-5">
          <p>
            Unique Investors:
            <strong>
              {{ instrument.unique_investors_count }}
            </strong>
          </p>
        </div>
      </div>
      <div v-if="showMoreButton" class="row">
        <div class="col-12">
          <v-button
            class="c-button -admin-small mb-2"
            type="button"
            @click.native="viewPrimaryMarketInstrument(instrument.uuid)"
          >
            View More Info
          </v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    instrument: {
      required: true,
    },
    showMoreButton: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Primary Market Instrument",
    },
  },
  data() {
    return {};
  },
  methods: {
    viewPrimaryMarketInstrument(uuid) {
      this.$router.push({
        name: "primary-market-instrument",
        params: { uuid },
      });
      return false;
    },
  },
};
</script>
