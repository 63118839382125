<template>
  <div>
    <div class="row align-items-center">
      <div class="col-3">
        {{ dateTimeFormat(document.created_at) }}
      </div>
      <div class="col-3 text-break">
        {{ document.name }}
      </div>
      <div class="col-2 text-break">
        {{ stripUnderScoresAndCapitalise(document.collection_name) }}
      </div>
      <div class="col-2 text-center">
        <a
          :href="document.url"
          class="c-button -admin-small"
          target="_blank"
          rel="noopener"
        >
          View
        </a>
      </div>
      <div v-if="checkPermission('delete_files')" class="col-2 text-center">
        <a
          :href="false"
          class="c-button -admin-small"
          @click="deleteDocument(document.uuid)"
        >
          Delete
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    document: {
      required: true,
    },
    stateKey: {
      required: true,
    },
  },

  data() {
    return {};
  },
  methods: {
    deleteDocument(uuid) {
      if (confirm("Are you sure you want to delete this document?")) {
        this.$store.dispatch("deleteDocument", uuid).then((response) => {
          if (response.status == 204) {
            FlashMessageService.success("Document has been deleted.");
            this.$parent.$parent.$parent.$parent.getFilesCount(true);
          } else {
            console.log(response);
            return false;
          }
        });
      }
    },
  },
};
</script>
