<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title> Repayments ({{ repaymentsCount }}) </template>

        <div v-if="repaymentsCount" class="c-admin-table">
          <div class="c-admin-table-header">
            <div class="row align-items-center">
              <div class="col-2">
                Repayment Date
              </div>
              <div class="col-2">
                Capital
              </div>
              <div class="col-2">
                Interest
              </div>
              <div class="col-2">
                Status
              </div>
              <div class="col-2">
                Is Maturing
              </div>
              <div class="col-2">
                Repayment Lines
              </div>
            </div>
          </div>

          <div
            v-for="(repayment, index) in repaymentsData"
            :key="index"
            class="c-admin-table-row"
          >
            <VActualRepaymentsRow
              :repayment="repayment"
              :currency-symbol="instrument.currency.symbol"
            />
          </div>

          <div class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-model="page"
                  :page-count="pages.length"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          No repayments.
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VActualRepaymentsRow from "js-admin/components/instrument/primary-market/actual-repayments/ActualRepaymentsRow.vue";
import VPagination from "vue-plain-pagination";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import PrimaryMarketService from "js-admin/classes/AjaxServices/PrimaryMarket/PrimaryMarketService";

export default {
  components: {
    VActualRepaymentsRow,
    VPagination,
  },
  props: {
    instrument: {
      required: true,
    },
  },
  data() {
    return {
      repaymentsCount: 0,
      repaymentsData: null,
      dataReady: false,
      page: 1,
      perPage: 10,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
    };
  },
  computed: {},

  watch: {
    page() {
      this.paginate();
    },
  },
  mounted() {
    this.getRepaymentsCount();
  },
  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },
    getRepaymentsCount(forcePagination = false) {
      PrimaryMarketService.getRepayments(this.instrument.uuid, {
        return_count: true,
      })
        .then((response) => {
          this.repaymentsCount = response.data.data.count;
          this.setPages();
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
      if (forcePagination) {
        this.paginate();
      }
    },
    setPages() {
      this.pages = this.calculatePaginationPages(
        this.repaymentsCount,
        this.perPage
      );
    },
    paginate() {
      this.dataReady = false;
      PrimaryMarketService.getRepayments(this.instrument.uuid, {
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      }).then((response) => {
        if (response.data.data) {
          this.repaymentsData = null;
          if (response.data.data.length) {
            this.repaymentsData = response.data.data;
          }
          this.dataReady = true;
        }
      });
    },
  },
};
</script>
