<template lang="html">
  <div class="c-search row align-items-end">
    <div v-if="checkPermission('search_accounts')" class="col-12">
      <div class="mb-3">
        Search: <br />
        Please enter an email address, name or telephone number below.
      </div>
      <form :data-vv-scope="searchScope" @submit.prevent="search">
        <v-text-input
          :scope="searchScope"
          :state-key="searchStateKey"
          field="searchTerm"
          label="Search"
          validation="required"
          error-name="search term"
          class="mr-3 mb-0"
        />
        <v-button
          class="c-button -admin-small h-mr-1"
          :loading="searchInProgress"
          :disabled="searchInProgress"
        >
          Search
        </v-button>
        <a
          v-if="searchPerformed"
          :href="false"
          class="c-button -admin-small"
          @click="clearSearch"
        >
          Clear Search
        </a>
      </form>
    </div>
  </div>
</template>

<script>
import AccountService from "js-admin/classes/AjaxServices/AccountService";

export default {
  data() {
    return {
      searchScope: "searchForm",
      searchStateKey: "search",
      searchInProgress: false,
      searchPerformed: false,
      searchResults: null,
    };
  },
  computed: {
    searchTerm() {
      return this.$store.state.fieldStore[this.searchStateKey].searchTerm;
    },
  },
  methods: {
    search() {
      this.$validator.validateAll(this.searchScope).then((result) => {
        this.searchPerformed = true;
        if (result) {
          AccountService.search({
            term: this.searchTerm,
            limit: 30,
          }).then((response) => {
            if (response.data) {
              this.searchResults = response.data.data;
              this.$store.commit("updateSearchResult", this.searchResults);
            } else {
              console.log(response);
              return false;
            }
          });
        }
      });
    },
    clearSearch() {
      this.searchPerformed = false;

      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.searchStateKey,
        field: "searchTerm",
      });

      this.clearErrors(100);

      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: "search",
        field: "searchResult",
      });
    },
  },
};
</script>
