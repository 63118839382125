<template>
  <div class="row align-items-center">
    <div class="col-3">
      {{ dateTimeFormat(bid.created_at) }}
    </div>
    <div class="col-3">
      <div v-if="bid.instrument">
        <div> {{ bid.instrument.name }}</div>
        <div> Ticker: {{ bid.instrument.ticker }}</div>
      </div>
    </div>
    <div class="col-3"> {{ bid.instrument.currency.symbol + convertToPounds(bid.value) }} </div>
    <div class="col-3">
      {{ capitaliseFirstLetter(bid.status) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bid: {
      required: true,
      type: Object,
    },
  },
};
</script>
