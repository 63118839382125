import Vue from "vue";
import VueRouter from "vue-router";
import store from "js-admin/store/store";
import AuthService from "js-admin/classes/AuthService";
// views

import Auth from "./views/auth/Auth";
import Account from "./views/accounts/Account";
import Accounts from "./views/accounts/Accounts";
import AssignPhoneCall from "./views/AssignPhoneCall";
import AccountsHeldReport from "./views/reports/AccountsHeldReport";
import Administration from "./views/Administration";
import CallStatsReport from "./views/reports/CallStats";
import CompletedApplicationsReport from "./views/reports/investment-accounts/CompletedApplicationsReport";
import ComplianceCallRecordings from "./views/ComplianceCallRecordings";
import CreateAccount from "./views/accounts/CreateAccount";
import CreateIssuer from "./views/instruments/CreateIssuer";
import DrawndownInstruments from "./views/drawdown-instruments/DrawndownInstruments";
import Helpdesk from "./views/helpdesk/Helpdesk";
import Home from "./views/Home";
import InvestmentAccount from "./views/investment-accounts/InvestmentAccount";
import Issuer from "./views/instruments/Issuer";
import Issuers from "./views/instruments/Issuers";
import Instruments from "./views/instruments/Instruments";
import Instrument from "./views/instruments/Instrument";
import InstrumentAllocations from "./views/instruments/InstrumentAllocations.vue";
import CashWallet from "./views/cash-wallets/CashWallet";
import Deposit from "./views/deposits/Deposit";
import LeadsCreatedReport from "./views/reports/LeadsCreatedReport";
import LeadDistribution from "./views/lead-distribution/LeadDistribution";
import LeadDistributionManagement from "./views/lead-distribution/LeadDistributionManagement";
import LeadsTakenReport from "./views/reports/LeadsTakenReport";
import LeadsClosedReport from "./views/reports/LeadsClosedReport";
import MyProfile from "./views/Profile";
import Reports from "./views/reports/Reports";
import RolesPermissions from "./views/users/RolesPermissions";
import ManageUsers from "./views/users/ManageUsers";
import User from "./views/users/User";
import CreateUser from "./views/users/CreateUser";
import Stats from "./views/Stats";
import SuitabilityReport from "./views/reports/SuitabilityReport";
import AuditLogReport from "./views/reports/AuditLogReport";
import CassReport from "./views/reports/CassReport";
import FeeReport from "./views/reports/FeeReport";
import TotalLeads from "./views/reports/TotalLeads";
import Tasks from "./views/tasks/Tasks";
import TaskDetails from "./views/tasks/TaskDetails";
import TasksReport from "./views/reports/TasksReport";
import PageNotFound from "./views/PageNotFound";
import AccountMerge from "./views/AccountMerge/AccountMerge";
import SupportList from "./views/helpdesk/SupportList";
import SupportDetails from "./views/helpdesk/SupportDetails";
import CustomerChangeRequests from "./views/customer-change-requests/CustomerChangeRequests";
import ForgottenPassword from "./views/auth/ForgottenPassword";
import PasswordReset from "./views/auth/PasswordReset";
import ManageWithdrawals from "./views/withdrawals/ManageWithdrawals";
import PrimaryMarketInstrument from "./views/instruments/PrimaryMarketInstrument.vue";
import PrimaryMarketInstrumentRepaymentLine from "./views/instruments/PMInstrumentRepaymentLines.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Auth,
  },
  {
    path: "/forgotten-password",
    name: "forgotten-password",
    component: ForgottenPassword,
    meta: {
      publicly_visible: true,
      logged_user_visible: false,
    },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: PasswordReset,
    meta: {
      publicly_visible: true,
      logged_user_visible: false,
    },
  },
  {
    path: "/assign-phone-call/:uuid/",
    name: "AssignPhoneCall",
    component: AssignPhoneCall,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/accounts",
    name: "accounts",
    component: Accounts,
  },
  {
    path: "/account/:uuid?",
    name: "account",
    component: Account,
  },
  {
    path: "/create-new-account",
    name: "create-account",
    component: CreateAccount,
  },
  {
    path: "/instruments",
    name: "instruments",
    component: Instruments,
  },
  {
    path: "/instrument/:uuid?",
    name: "instrument",
    component: Instrument,
  },
  {
    path: "/instrument/:uuid/allocations",
    name: "instrument-allocations",
    component: InstrumentAllocations,
  },
  {
    path: "/issuers",
    name: "issuers",
    component: Issuers,
  },
  {
    path: "/issuer/:uuid?",
    name: "issuer",
    component: Issuer,
  },
  {
    path: "/create-new-issuer/:uuid",
    name: "create-issuer",
    component: CreateIssuer,
  },
  {
    path: "/primary-market-instrument/:uuid?",
    name: "primary-market-instrument",
    component: PrimaryMarketInstrument,
  },
  {
    path: "/primary-market-instrument/repayment/:uuid?",
    name: "primary-market-instrument-repayment",
    component: PrimaryMarketInstrumentRepaymentLine,
  },
  {
    path: "/stats",
    name: "stats",
    component: Stats,
  },
  {
    path: "/lead-distribution",
    name: "lead-distribution",
    component: LeadDistribution,
  },
  {
    path: "/lead-distribution-management",
    name: "lead-distribution-management",
    component: LeadDistributionManagement,
  },
  {
    path: "/helpdesk",
    name: "helpdesk",
    component: Helpdesk,
  },
  {
    path: "/support-status",
    name: "support-status",
    component: SupportList,
    children: [
      {
        path: "support-details/:gid?",
        component: SupportDetails,
      },
    ],
  },
  {
    path: "/roles-permissions",
    name: "roles-permissions",
    component: RolesPermissions,
  },
  {
    path: "/manage-users",
    name: "manage-users",
    component: ManageUsers,
  },
  {
    path: "/manage-users/:uuid?",
    name: "user",
    component: User,
  },
  {
    path: "/create-user",
    name: "create-user",
    component: CreateUser,
  },
  {
    path: "/investment-account/:uuid?",
    name: "investment-account",
    component: InvestmentAccount,
  },
  {
    path: "/cash-wallet/:uuid?",
    name: "cash-wallet",
    component: CashWallet,
  },
  {
    path: "/deposit/:uuid?",
    name: "deposit",
    component: Deposit,
  },
  {
    path: "/my-profile",
    name: "my-profile",
    component: MyProfile,
  },
  {
    path: "/tasks/:uuid?",
    name: "tasks",
    component: Tasks,
    children: [
      {
        path: "task-details/:t_uuid?",
        component: TaskDetails,
      },
    ],
  },
  {
    path: "/account-merge",
    name: "account-merge",
    component: AccountMerge,
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
  },
  {
    path: "/reports/tasks",
    name: "tasks-report",
    component: TasksReport,
  },
  {
    path: "/reports/accounts-held",
    name: "accounts-held",
    component: AccountsHeldReport,
  },
  {
    path: "/reports/leads-taken",
    name: "leads-taken-report",
    component: LeadsTakenReport,
  },
  {
    path: "/reports/leads-created",
    name: "leads-created-report",
    component: LeadsCreatedReport,
  },
  {
    path: "/reports/leads-closed",
    name: "leads-closed-report",
    component: LeadsClosedReport,
  },
  {
    path: "/reports/completed-applications-report",
    name: "completed-applications-report",
    component: CompletedApplicationsReport,
  },
  {
    path: "/reports/call-stats",
    name: "call-stats-report",
    component: CallStatsReport,
  },
  {
    path: "/reports/suitability-report",
    name: "suitability-report",
    component: SuitabilityReport,
  },
  {
    path: "/reports/audit-log-report",
    name: "audit-log-report",
    component: AuditLogReport,
  },
  {
    path: "/reports/cass-report",
    name: "cass-report",
    component: CassReport,
  },
  {
    path: "/reports/fee-report",
    name: "fee-report",
    component: FeeReport,
  },
  {
    path: "/reports/total-leads",
    name: "total-leads",
    component: TotalLeads,
  },
  {
    path: "/administration",
    name: "administration",
    component: Administration,
  },
  {
    path: "/administration/primary-market-instruments/failed-drawdowns",
    name: "primary-market-instrument-drawdowns",
    component: DrawndownInstruments,
  },
  {
    path: "/calls-recordings",
    name: "calls-recordings",
    component: ComplianceCallRecordings,
  },
  {
    path: "/customer-change-requests",
    name: "customer-change-requests",
    component: CustomerChangeRequests,
  },
  {
    path: "/manage-withdrawals",
    name: "manage-withdrawals",
    component: ManageWithdrawals,
  },
  {
    path: "/*",
    name: "page-not-found",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { selector: ".c-admin-app" };
  },
});

// scrolltop
router.afterEach((to) => {
  if (to.meta && to.meta.publicly_visible) {
    // don't trigger redirect for public pages
  } else {
    AuthService.setRedirect(to.path);
  }
  window.scrollTo(0, 0);
});

router.beforeEach((to, from, next) => {
  // used to determine whether meta is an empty object
  const toPageMeta = Object.keys(to.meta).length;

  AuthService.refreshUser()
    .then(() => {
      if (toPageMeta && !to.meta.logged_user_visible) {
        router.replace("/home");
      } else {
        if (!store.state.user.two_factor_required) {
          // redirect to profile page until two-factor setup
          if (to.path !== "/my-profile") {
            if (from.path !== "/my-profile") {
              router.replace("/my-profile");
            }
            return;
          }
        }

        next();
      }
    })
    .catch(() => {
      AuthService.userClear();

      if (toPageMeta && to.meta.publicly_visible) {
        next();
        return;
      }

      if (to.path !== "/") {
        // goto login without adding it to history
        router.replace("/");
      } else {
        next();
      }
    });
});

export default router;
