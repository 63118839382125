<template>
  <div class="c-checkbox">
    <div class="c-checkbox-container" @click="click">
      <div class="row align-items-center no-gutters">
        <div class="col-auto">
          <VCheckboxVisual :active="value" />
        </div>
        <div class="col">
          <div class="c-checkbox-content">
            <slot>Enter your checkbox content in the slot</slot>
          </div>
        </div>
      </div>

      <v-input-hidden
        ref="input"
        :field="field"
        :state-key="stateKey"
        :error-name="errorName"
        :validation="validation"
        :scope="scope"
      />
    </div>
  </div>
</template>

<script>
import VCheckboxVisual from "./CheckboxVisual.vue";

export default {
  inject: ["$validator"],

  components: {
    VCheckboxVisual,
  },
  props: {
    validation: {
      default: "required",
    },
    scope: {
      default: "",
    },
    field: {
      required: true,
    },
    stateKey: {
      required: true,
    },
    errorName: {
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
    value() {
      return this.$store.state.fieldStore[this.stateKey][this.field];
    },
  },
  methods: {
    click() {
      if (this.value !== null) {
        this.$refs.input.updateValue(!this.value);
        this.$emit("checkedBox", this.value);
      } else {
        this.$refs.input.updateValue(true);
      }
    },
  },
};
</script>
