<template>
  <div class="v-leads-distribution">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Lead Distribution Management
          </h1>
        </div>
      </div>
    </section>

    <section v-if="accountManagers" class="c-section -half-vertical-padding">
      <div class="row">
        <div class="col-12">
          <VRoundRobin :account-managers="accountManagers" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VRoundRobin from "js-admin/components/round-robin/RoundRobin.vue";

export default {
  components: {
    VRoundRobin,
  },
  computed: {
    accountManagers: {
      get() {
        return this.$store.state.accountManagers;
      },
      set(accountManagers) {
        this.$store.commit("updateAccountManagers", accountManagers);
      },
    },
  },
  mounted() {
    if (!this.checkPermission("round_robin_management")) {
      return this.$router.push({ name: "home" });
    }

    this.getOwners();
  },
  methods: {
    getOwners() {
      this.$store.dispatch("getAccountManagers");
    },
  },
};
</script>
