<template>
  <div class="v-accounts">
    <section v-if="pageReady" class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title mb-5">
            Accounts
          </h1>
        </div>
      </div>
      <div class="row align-items-end">
        <div v-if="checkPermission('filter_accounts')" class="col-4">
          <v-select-input
            class="h-mb-1"
            state-key="accountListingFilter"
            :options="filterOptions"
            :center-error="false"
            field="account_listing_filter"
            label="Filter:"
            placeholder="Filter accounts by"
            @selectionUpdated="filterAccountListing"
          />
        </div>
        <div v-if="checkPermission('search_accounts')" class="col-5">
          <div class="mb-3">
            Search: <br />
            Please enter an email address, name or telephone number below.
          </div>
          <form :data-vv-scope="searchScope" @submit.prevent="search">
            <v-text-input
              :scope="searchScope"
              :state-key="searchStateKey"
              field="searchTerm"
              label="Search"
              validation="required"
              error-name="search term"
              class="mr-3 h-mb-1"
            />
            <v-button
              class="c-button -admin-small mb-2"
              :loading="searchInProgress"
              :disabled="searchInProgress"
            >
              Search
            </v-button>

            <a
              v-if="searchPerformed"
              :href="false"
              class="c-button -admin-small mb-2 ml-1"
              @click="clearSearch"
            >
              Clear Search
            </a>
          </form>
        </div>
        <div
          v-if="
            checkPermission('create_account') &&
              !systemFeatureIsDisabled('disabled_create_account')
          "
          class="col-3 text-right pr-5"
        >
          <a
            :href="false"
            class="c-button -admin-small h-mb-1"
            @click="createNewAccount"
          >
            Create Account/Lead
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header">
              <div class="row">
                <div class="col-2">
                  Name
                </div>
                <div class="col-3">
                  Email Address
                </div>
                <div class="col-2">
                  Contact Numbers
                </div>
                <div class="col-3">
                  Details
                </div>
                <div class="col-2">
                  &nbsp;
                </div>
              </div>
            </div>
            <div v-if="accountsDataReady">
              <div v-if="displayedAccounts && displayedAccounts.length">
                <div
                  v-for="(account, index) in displayedAccounts"
                  :key="index"
                  class="c-admin-table-row"
                >
                  <div class="row">
                    <div class="col-2">
                      {{ account.full_name }} <br />
                      <span class="c-task-header">
                        <strong> {{ account.reference_number }} </strong>
                      </span> <br/>
                      <small
                        v-if="account.archived_on"
                        class="c-admin-alert-text -error"
                      >
                        Account Archived
                      </small>
                      <br />
                      <small>
                        Created at: <br />{{
                          dateTimeFormat(account.created_at)
                        }}
                      </small>
                    </div>
                    <div class="col-3">
                      <div v-if="account.email" class="text-break">
                        {{ account.email }}
                      </div>
                      <div v-else>
                        N.A.
                      </div>
                    </div>
                    <div class="col-2">
                      <div v-if="account.primary_contact_number">
                        {{ account.primary_contact_number }}
                      </div>
                      <div v-if="account.secondary_contact_number">
                        {{ account.secondary_contact_number }}
                      </div>
                    </div>
                    <div class="col-3">
                      <strong> {{ account.lead_type }} </strong> <br />
                      Pending Applications: {{ account.applications }} <br />
                      Investment Accounts: {{ account.investment_accounts }}
                      <br />
                      Submitted Leads: {{ account.leads }}
                    </div>
                    <div class="col-2 text-center">
                      <a
                        :href="false"
                        class="c-button -admin-small"
                        @click="viewAccount(account.uuid)"
                      >
                        View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="c-admin-table-row">
                <div class="row">
                  <div class="col-12">
                    No accounts found
                  </div>
                </div>
              </div>
              <div class="c-admin-table-footer">
                <div class="row">
                  <div class="col-12">
                    <v-pagination
                      v-if="displayedAccounts && displayedAccounts.length"
                      v-model="page"
                      :page-count="pages.length"
                      :classes="bootstrapPaginationClasses"
                      :labels="paginationAnchorTexts"
                    />
                  </div>
                </div>
              </div>
            </div>
            <v-ajax-loader v-else />
          </div>
        </div>
      </div>
    </section>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import vPagination from "vue-plain-pagination";
import AccountService from "js-admin/classes/AjaxServices/AccountService";

export default {
  components: {
    vPagination,
  },

  data() {
    return {
      accounts: null,
      page: 1,
      perPage: 10,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      searchScope: "searchForm",
      searchStateKey: "search",
      searchInProgress: false,
      searchPerformed: false,
      accountListingFilterStateKey: "accountListingFilter",
      pageReady: false,
      accountsDataReady: false,
      generalFilterOptions: [
        {
          value: "all",
          name: "All",
        },
        {
          value: "only_with_investments",
          name: "With Investments Only",
        },
        {
          value: "only_with_applications",
          name: "With Applications Only",
        },
        {
          value: "archived_account",
          name: "Archived Only",
        },
      ],
      leadsFilterOptions: [
        {
          value: "only_with_leads",
          name: "Leads Only",
        },
      ],
    };
  },
  computed: {
    searchTerm() {
      return this.$store.state.fieldStore[this.searchStateKey].searchTerm;
    },
    filter() {
      return this.$store.state.fieldStore[this.accountListingFilterStateKey]
        .account_listing_filter;
    },
    displayedAccounts() {
      return this.accounts;
    },
    filterOptions() {
      if (!this.systemFeatureIsDisabled("disabled_leads")) {
        return [...this.generalFilterOptions, ...this.leadsFilterOptions];
      }

      return this.generalFilterOptions;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    if (!this.checkPermission("view_accounts")) {
      return this.$router.push({ name: "home" });
    }

    if (this.searchTerm) {
      this.search();
    } else {
      AccountService.getAccounts({
        filter_by_type: this.filter,
        limit: this.perPage,
      }).then((response) => {
        this.setPages(response.data.meta.total);
        this.accounts = response.data.data;
        this.pageReady = true;
        this.accountsDataReady = true;
      });

      // reset search term
      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.searchStateKey,
        field: "searchTerm",
      });
    }

    // reset account data
    this.$store.dispatch("updateStoreItem", {
      value: this.getEmptyAccount(),
      stateKey: "accountData",
      field: null,
    });
  },

  methods: {
    viewAccount(uuid) {
      this.$router.push({ name: "account", params: { uuid } });
      return false;
    },

    setPages(total) {
      this.page = 1;
      this.pages = [];
      const numberOfPages = Math.ceil(total / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    async paginate() {
      this.accountsDataReady = false;
      let functionName = "getAccounts";
      const params = {
        page: this.page,
        limit: this.perPage,
      };

      if (this.searchTerm) {
        functionName = "search";
        params.term = this.searchTerm;
      } else {
        params.filter_by_type = this.filter;
      }

      await AccountService[functionName](params).then((response) => {
        this.accounts = response.data.data;
        this.accountsDataReady = true;
      });
    },

    filterAccountListing(filter) {
      AccountService.getAccounts({
        filter_by_type: filter,
        limit: this.perPage,
      }).then((response) => {
        this.setPages(response.data.meta.total);
        this.accounts = response.data.data;
        this.pageReady = true;
        this.accountsDataReady = true;
      });
    },

    createNewAccount() {
      this.$router.push({ name: "create-account" });
      return false;
    },

    search() {
      this.$validator.validateAll(this.searchScope).then((result) => {
        this.searchPerformed = true;
        if (result) {
          this.searchInProgress = true;
          this.accountsDataReady = false;
          AccountService.search({
            term: this.searchTerm,
            limit: this.perPage,
          }).then((response) => {
            this.setPages(response.data.meta.total);
            this.accounts = response.data.data;
            this.searchInProgress = false;
            this.accountsDataReady = true;
          });
        }
      });
    },

    clearSearch() {
      this.searchPerformed = false;
      this.accountsDataReady = false;

      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.searchStateKey,
        field: "searchTerm",
      });

      this.clearErrors(1);

      AccountService.getAccounts({
        filter_by_type: this.filter,
        page: 1,
        limit: this.perPage,
      }).then((response) => {
        this.setPages(response.data.meta.total);
        this.accounts = response.data.data;
        this.accountsDataReady = true;
      });
    },
  },
};
</script>
