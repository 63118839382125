import APIService from "js-admin/classes/APIService";

export default class LeadService {
  static CRUD_URL = "leads";

  static async createLead(data) {
    return APIService.request(
      this.CRUD_URL + "/create-admin-lead",
      "post",
      data
    );
  }
}
