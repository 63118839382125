import mergeState from "js-admin/store/state/merge-state/MergeState";
import taskState from "js-admin/store/state/task-state/TaskState";
import helpdeskState from "js-admin/store/state/helpdesk-state/HelpdeskState";
import reportState from "js-admin/store/state/report-state/ReportState";

export default {
  config: {},

  me: null,
  lastLoginCheck: null,

  user: {},

  fieldStore: {
    applicationData: null,
    investmentAccountData: null,
    cashWalletData: null,
    loginFields: {
      username: null,
      password: null,
    },

    forgottenPassword: {
      username: null,
    },

    passwordReset: {
      token: null,
      username: null,
      new_password: null,
      password_confirmation: null,
    },

    search: {
      searchTerm: null,
      searchResult: null,
    },
    postcodeSearch: {
      postcode: null,
      address_select: null,
    },
    accountData: null,
    issuerData: null,
    instrumentData: null,
    primaryMarketInstrumentData: null,
    issuerContactData: null,
    updateCompany: {
      name: null,
      registration_number: null,
      legal_entity_identifier: null,
      country_of_incorporation: null,
      nature_of_business: null,
      signatures: null,
      pdf_application: null,
    },
    updateAddress: {
      address_1: null,
      address_2: null,
      address_3: null,
      town: null,
      county: null,
      postcode: null,
      country: null,
    },
    updateBankDetails: {
      bank_account_name: null,
      bank_account_number: null,
      bank_sort_code: null,
      bank_payment_reference: null,
      bank_special_circumstances: null,
    },
    updateNote: {
      note: null,
    },
    createNote: {
      note: null,
    },
    createLead: {
      title: null,
      first_name: null,
      middle_name: null,
      last_name: null,
      primary_contact_number: null,
      secondary_contact_number: null,
      email: null,
      primary_address_1: null,
      primary_address_2: null,
      primary_address_3: null,
      primary_address_town: null,
      primary_address_county: null,
      primary_address_postcode: null,
      primary_address_country: null,
      type: null,
      description: null,
      gdpr_optin_flag: false,
      subscribed_marketing_notifications: false,
      subscribed_product_notifications: false,
      subscribed_industry_news_notifications: false,
      subscribed_phone: false,
      subscribed_sms: false,
      subscribed_email: false,
      owner_uuid: null,
    },
    addDocument: {
      document: null,
      file_type: null,
      file_name: null,
    },
    sendEmailForm: {
      user_email_template: null,
    },
    sendSecureMessage: {
      secure_message_template: null,
    },
    updateUsername: {
      username: null,
    },
    amAssignedLeads: {},
    assignedLead: {},
    leadCount: 0,
    phoneQueueDetails: {
      queueName: "tsmainqueue",
      averageWaitTime: 0,
      currentSize: 0,
    },
    role: {
      role: null,
    },
    helpdesk: {
      title: null,
      description: null,
      assignee: null,
      investor_type: null,
      issue_type: null,
      priority: null,
      task_comment: null,
      support_request_type: "support-request",
    },
    rolePermissions: [],
    userPermissions: [],
    addNewPermission: {
      permission: null,
    },
    userData: {
      uuid: null,
      name: null,
      email_address: null,
      phone_number: null,
      phone_number_country_code: null,
      phone_number_dial_code: null,
      work_phone: null,
      work_phone_country_code: null,
      work_phone_dial_code: null,
      reset_user_password: false,
      role: {
        name: null,
      },
      owner_uuid: null,
      owner_job_title: null,
    },
    createUser: {
      uuid: null,
      name: null,
      email_address: null,
      phone_number: null,
      phone_number_country_code: null,
      phone_number_dial_code: null,
      work_phone: null,
      work_phone_country_code: null,
      work_phone_dial_code: null,
      role: {
        name: null,
      },
      owner_job_title: null,
    },

    inProgressCalls: [],
    inboundRingingCalls: [],
    amStatus: {
      status: null,
    },

    task: {
      task_filter: null,
      task_priority: null,
      task_status: null,
      task_name: null,
      task_description: null,
      due_date: null,
      assigned_to: null,
      priority: null,
      set_reminder: false,
      reminder_date: null,
      reminder_hour: null,
      reminder_minute: null,
      task_owner: null,
    },
    accountListingFilter: {
      account_listing_filter: "all",
    },
    profileUpdatePassword: {
      current_password: null,
      new_password: null,
      confirm_password: null,
    },
    profileUpdateMobileNumber: {
      mobile_phone_number: null,
      mobile_phone_number_country_code: null,
      mobile_phone_number_dial_code: null,
    },
    enableAppTwoFactor: {
      secret: null,
    },
    validateTwoFactor: {
      app_security_code: null,
      sms_security_code: null,
    },
    twoFactorType: {
      two_factor_type: null,
    },

    ...mergeState,

    reportSearch: {
      from_date: null,
      to_date: null,
      for_date: null,
      account_manager_uuid: null,
      hide_completed_tasks: true,
      show_overdue_tasks_only: false,
      lead_closed_reason: null,
      all_change_requests: false,
      report_type: null,
    },

    userSearch: {
      searchTerm: null,
    },

    withdrawalFilter: {
      from_date: null,
      to_date: null,
      status: "Pending",
    },

    updateWithdrawal: {
      status: null,
    },

    passwordGeneration: {
      password: null,
    },

    createManualTransferForm: {
      amount: null,
    },

    addManualAml: {
      notes: null,
    },

    transferData: null,

    createTransferCorrectionForm: {
      amount: null,
      note: null,
    },

    instrumentsList: {
      type_filter: "all",
      status_filter: "all",
      searchTerm: null,
    },

    issuersList: {
      searchTerm: null,
    },

    createRssFeed: {
      name: null,
      url: null,
      order: null,
    },

    createSlide: {
      name: null,
      type: null,
      video_type: null,
      url: null,
      order: null,
      file: null,
    },
    linkIssuerForm: {
      issuer_uuid: null,
    },
    allocation: {
      rejection_reason: "",
    },
  },

  fullscreenPanel: {
    show: false,
    content: "menu",
  },

  accountManagers: [],

  agents: [],
  roles: [],

  ...taskState,
  ...helpdeskState,
  ...reportState,

  windowSize: null,

  loginRedirect: null,
};
