<template>
  <div class="c-wysiwyg" :class="'t-' + field">
    <div class="c-wysiwyg-group">
      <label :for="field" class="c-wysiwyg-label" v-text="label" />

      <v-button
        class="c-button -admin-small ml-4"
        type="button"
        @click.native="previewContent"
      >
        {{ previewButtonText }}
      </v-button>

      <div v-if="viewPreview" class="c-wysiwyg-preview">
        <div class="c-wysiwyg-preview-content" v-html="value" />
      </div>

      <vue-editor
        :ref="fieldRef"
        v-model="value"
        :editor-toolbar="customToolbar"
      />

      <v-input-hidden
        :ref="fieldRef + '-hidden'"
        :field="field"
        :state-key="stateKey"
        :error-name="errorName"
        :validation="validation"
        :scope="scope"
        :center-error="false"
      />
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  inject: ["$validator"],

  props: {
    field: {
      required: true,
    },
    stateKey: {
      required: true,
    },
    label: {
      default: null,
    },
    scope: {
      default: "",
    },
    validation: {
      default: "required",
    },
    errorName: {
      default: null,
    },
  },
  data() {
    return {
      viewPreview: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  computed: {
    fieldRef() {
      return this.scope + "-" + this.field;
    },
    value: {
      get() {
        return this.$store.state.fieldStore[this.stateKey][this.field];
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: this.field,
        });
      },
    },
    previewButtonText() {
      if (this.viewPreview) {
        return "Close";
      }
      return "Preview";
    },
  },
  methods: {
    previewContent() {
      this.viewPreview = !this.viewPreview;
    },
  },
};
</script>
