<template>
  <div class="v-helpdesk">
    <section key="0" class="c-section h-pt-0">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title"> Contact Support </h1>
        </div>
      </div>
      <div class="row h-mt-1">
        <div class="col-12">
          <div>
            <template>
              <form
                class="c-admin-form"
                :data-vv-scope="updateFormScope"
                @submit.prevent="submitFeatureForm"
              >
                <div class="row">
                  <div class="col-md-12 mb-2">
                    <v-select-input
                      class="h-mb-1"
                      :scope="updateFormScope"
                      :state-key="stateKey"
                      :options="helpdeskTypeList"
                      :center-error="false"
                      :enable-blank-option="false"
                      field="support_request_type"
                      label=""
                      placeholder="Select your request type"
                      error-name="support request type"
                    />
                  </div>
                  <div class="col-md-12 mb-2">
                    <v-text-input
                      :scope="updateFormScope"
                      :state-key="stateKey"
                      field="title"
                      label="Title"
                      validation="required"
                      class="mb-2"
                    />
                  </div>
                  <div class="col-md-12 mb-2">
                    <v-textarea
                      :scope="updateFormScope"
                      :state-key="stateKey"
                      field="description"
                      label="Description"
                      error-name="description"
                      validation="required"
                      class="mb-2"
                    />
                  </div>
                  <div
                    v-if="getSupportType === 'support-request'"
                    class="col-md-12"
                  >
                    <v-select-input
                      class="h-mb-1"
                      :scope="updateFormScope"
                      :state-key="stateKey"
                      :options="investorTypes"
                      :center-error="false"
                      field="investor_type"
                      label=""
                      placeholder="Select an investor type"
                      error-name="investor type"
                      :enable-blank-option="true"
                    />
                  </div>
                  <div
                    v-if="getSupportType === 'support-request'"
                    class="col-md-12"
                  >
                    <v-select-input
                      class="h-mb-1"
                      :scope="updateFormScope"
                      :state-key="stateKey"
                      :options="issueTypes"
                      :center-error="false"
                      field="issue_type"
                      label=""
                      placeholder="Select an issue type"
                      error-name="issue type"
                      :enable-blank-option="true"
                    />
                  </div>
                  <div
                    v-if="getSupportType === 'support-request'"
                    class="col-md-12"
                  >
                    <v-select-input
                      class="h-mb-1"
                      :scope="updateFormScope"
                      :state-key="stateKey"
                      :options="priorities"
                      :center-error="false"
                      field="priority"
                      label=""
                      placeholder="Select an priority type"
                      error-name="priority type"
                      :enable-blank-option="true"
                    />
                  </div>
                  <div class="col-md-12 mb-3">
                    <v-button
                      class="mt-3"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Submit
                    </v-button>
                  </div>
                </div>
              </form>
            </template>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FlashMessageService from "js-admin/classes/FlashMessageService";
export default {
  data() {
    return {
      updateFormScope: "helpdesk",
      stateKey: "helpdesk",
      loading: false,
      helpdeskTypeList: [
        {
          title: "Support Request",
          value: "support-request",
        },
        {
          title: "Improvements",
          value: "improvements",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSupportType"]),

    priorities() {
      const types = this.$store.getters.getHelpdeskPriorityList;
      const priorities = [];

      Object.keys(types).forEach((key) => {
        priorities.push({
          value: key,
          name: types[key],
        });
      });

      return priorities;
    },

    issueTypes() {
      const types = this.$store.getters.getHelpdeskIssueTypeList;
      const issueTypes = [];

      Object.keys(types).forEach((key) => {
        issueTypes.push({
          value: key,
          name: types[key],
        });
      });

      return issueTypes;
    },

    investorTypes() {
      const types = this.$store.getters.getHelpdeskInvestorTypeList;
      const investorTypes = [];

      Object.keys(types).forEach((key) => {
        investorTypes.push({
          value: key,
          name: types[key],
        });
      });

      return investorTypes;
    },
  },
  methods: {
    submitFeatureForm() {
      this.loading = true;
      this.$validator.validateAll(this.updateFormScope).then((result) => {
        if (result) {
          this.$store
            .dispatch("submitHelpdesk")
            .then((response) => {
              this.loading = false;
              FlashMessageService.success(
                "Your support query has been submitted"
              );
              this.$store.dispatch("updateStoreItem", {
                value: {
                  title: null,
                  description: null,
                  assignee: null,
                  investor_type: null,
                  issue_type: null,
                  priority: null,
                  task_comment: null,
                  support_request_type: this.getSupportType,
                },
                stateKey: "helpdesk",
              });
              // close toggle
              this.$parent.$parent.$refs.helpdesk.toggle();
            })
            .catch((error) => console.log(error));
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
