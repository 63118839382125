<template>
  <div>
    <div v-if="checkPermission('add_issuer')" class="c-admin-panel">
      <a :href="false" class="c-button" @click="showVideoForm">
        {{ addVideoLabel }}
      </a>

      <form
        v-if="showForm"
        :data-vv-scope="scope"
        class="c-admin-form mb-2"
        @submit.prevent="createSlide"
      >
        <h3 class="c-admin-panel-title">
          Add Slide
        </h3>

        <div>
          <div class="row">
            <div class="col-12 col-md-5">
              <v-select-input
                class="h-mb-1"
                :scope="scope"
                :state-key="stateKey"
                :options="getSlideTypes()"
                :center-error="false"
                field="type"
                label="Type:"
                validation="required"
                error-name="video type"
                change="showSlideType"
              />
            </div>
          </div>
        </div>
        <div v-if="slideType">
          <div class="row">
            <div class="col-12 col-md-5">
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                class="mb-3"
                field="name"
                label="Name"
                validation="required"
                error-name="video name"
              />
            </div>
            <div class="col-12 col-md-7">
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                class="mb-3"
                type="number"
                field="order"
                label="Order"
                validation="required|numeric|min_value:0"
                error-name="video order"
                :always-instructions="true"
                instructions="Higher the number, shows in carousel earlier"
              />
            </div>
          </div>
          <div v-if="slideType === 'video'" class="row">
            <div class="col-12 col-md-5">
              <v-select-input
                class="h-mb-1"
                :scope="scope"
                :state-key="stateKey"
                :options="videoTypes"
                :center-error="false"
                field="video_type"
                label="Video type:"
                validation="required"
                error-name="video type"
                :enable-blank-option="true"
              />
            </div>
            <div class="col-12 col-md-7">
              <v-text-input
                v-show="videoType"
                :scope="scope"
                :state-key="stateKey"
                class="mb-3"
                field="url"
                label="URL"
                validation="required"
                error-name="video URL"
                @blur="updateUrlLink"
              />
            </div>
          </div>

          <div v-if="slideType === 'image'" class="row">
            <div class="col-12 col-md-12">
              <VFileInput
                :scope="scope"
                :state-key="stateKey"
                field="file"
                validation="required"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <v-button
                :disabled="createInProgress"
                :loading="createInProgress"
                class="-admin-small"
              >
                Add Slide
              </v-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VFileInput from "js-admin/components/form/FileInput.vue";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import CarouselSlideService from "js-admin/classes/AjaxServices/CarouselSlideService";

export default {
  components: {
    VFileInput,
  },
  props: {
    issuerUuid: {
      required: true,
    },
  },

  data() {
    return {
      showForm: false,
      createInProgress: false,
      scope: "createSlide",
      stateKey: "createSlide",
    };
  },

  computed: {
    addVideoLabel() {
      if (this.showForm) {
        return "- Hide Form";
      } else {
        return "+ Add Slide";
      }
    },
    videoTypes() {
      const types = this.$store.state.config.video_types;
      const videoTypes = [];

      Object.keys(types).forEach((key) => {
        videoTypes.push({
          value: key,
          name: types[key],
        });
      });

      return videoTypes;
    },
    videoUrl: {
      get() {
        return this.$store.state.fieldStore[this.stateKey].url;
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: "url",
        });
      },
    },
    slideType() {
      return this.$store.state.fieldStore[this.stateKey].type;
    },
    videoType() {
      return this.$store.state.fieldStore[this.stateKey].video_type;
    },
    order() {
      return this.$store.state.fieldStore[this.stateKey].order;
    },
  },

  methods: {
    showVideoForm() {
      this.showForm = !this.showForm;
    },

    createSlide() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          const data = {
            model: "App\\Models\\SecondaryMarket\\InstrumentIssuer",
            uuid: this.issuerUuid,
            type: this.slideType,
            name: this.$store.state.fieldStore[this.stateKey].name,
            url: this.videoUrl,
            video_type: this.videoType,
            order: this.$store.state.fieldStore[this.stateKey].order,
            file: this.$store.state.fieldStore[this.stateKey].file,
          };

          this.createInProgress = true;
          CarouselSlideService.createSlide(data).then((response) => {
            this.createInProgress = false;
            if (response.data) {
              FlashMessageService.success("Slide has been created.");

              ["name", "type", "video_type", "url", "order", "file"].forEach(
                (keyName) => {
                  this.$store.dispatch("updateStoreItem", {
                    value: null,
                    stateKey: this.stateKey,
                    field: keyName,
                  });
                }
              );

              this.showForm = false;
              this.$parent.$parent.$parent.$parent.getCount(true);
            }
          });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -150 });
          }, 400);
        }
      });
    },

    getSlideTypes() {
      return [
        {
          value: "image",
          name: "Image",
        },
        {
          value: "video",
          name: "Video",
        },
      ];
    },
    updateUrlLink() {
      if (this.videoType === "youtube") {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = this.videoUrl.trim().match(regExp);
        if (match && match[2].length === 11) {
          this.videoUrl = "https://www.youtube.com/embed/" + match[2];
        } else {
          this.videoUrl = "invalid";
        }
      }
    },
  },
};
</script>
