import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";

export default {
  async updateInProgressCall({ commit, getters }, uuid) {
    commit("deleteFromArrayItemStore", {
      stateKey: "inProgressCalls",
      callUuid: uuid,
    });

    CommunicationService.getInProgressCall().then((response) => {
      if (response && response.data) {
        const call = response.data.data;
        const alreadyAddedToCollection = getters.getFormattedCalls.some(
          (item) => call.call.uuid === item.uuid
        );

        if (!alreadyAddedToCollection) {
          call.call.is_open = false;

          commit("addToArrayItemStore", {
            value: call,
            stateKey: "inProgressCalls",
            index: call.call.uuid,
          });
        }
      }
    });
  },
};
