<template>
  <div>
    <transition name="fade">
      <VTwoFactor v-if="twoFactorType" :type="twoFactorType" />
      <VLogin v-else @two-factor="twoFactor" />
    </transition>
  </div>
</template>

<script>
import VLogin from "js-admin/components/auth/Login.vue";
import VTwoFactor from "js-admin/components/auth/TwoFactorValidate.vue";

export default {
  components: {
    VLogin,
    VTwoFactor,
  },
  data() {
    return {
      twoFactorType: null,
    };
  },
  methods: {
    twoFactor(type) {
      this.twoFactorType = type;
    },
  },
};
</script>
