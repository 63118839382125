var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:[
    'c-task-pill',
    {
      '-high': _vm.priority == 'High',
      '-medium': _vm.priority == 'Normal' || _vm.priority == 'normal',
      '-low': _vm.priority == 'Low',
    },
  ]},[_vm._v(" "+_vm._s(_vm.priorityText)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }