<template lang="html">
  <div class="v-account-merge">
    <div class="c-section">
      <h1>Account Merge</h1>
      <p>
        Please select 2 records that you wish to merge into one. Use the "Search
        form" below to find the accounts.
      </p>
      <!-- search section -->
      <VSearch />
      <!-- result section -->
      <VSearchResult />
      <!-- merge section-->
      <VMergeSection />
    </div>
  </div>
</template>

<script>
import VMergeSection from "js-admin/components/MergeAccount/MergeSection.vue";
import VSearchResult from "js-admin/components/MergeAccount/SearchResult.vue";
import VSearch from "js-admin/components/MergeAccount/Search.vue";

export default {
  components: {
    VMergeSection,
    VSearch,
    VSearchResult,
  },
  mounted() {
    if (
      !this.checkPermission("merge_accounts") ||
      this.systemFeatureIsDisabled("disabled_merge_accounts")
    ) {
      return this.$router.push({ name: "home" });
    }

    this.$store.dispatch("updateStoreItem", {
      value: null,
      stateKey: "search",
      field: "searchResult",
    });

    this.$store.dispatch("updateStoreItem", {
      value: null,
      stateKey: "mergeAccount",
      field: "accountArray",
    });
  },
};
</script>
