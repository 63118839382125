<template>
  <div class="v-manage-user">
    <div v-if="dataReady">
      <section v-if="!userNotFound" class="c-section">
        <div class="row align-items-start">
          <div class="col-12 mb-3">
            <h1 class="c-admin-page-title "> User for {{ usersName }} </h1>
            <a
              :href="false"
              class="c-button -secondary -sidebar"
              @click="usersPage"
            >
              <strong>
                Back to Users List
              </strong>
            </a>
          </div>
        </div>

        <VInfoBox :visibility="!!userPassword" @closeBox="closeBox">
          <div>
            The following is the user's randomly generated password to login
            into the system: <br />
          </div>
          <div class="password-box mb-2"> {{ userPassword }} </div>
          <div>
            Please forward onto the user separately. <br />
            Please remind the user, that they should reset their password and
            setup two-factor (if haven't already) after logging in.
          </div>
        </VInfoBox>

        <div class="row">
          <div class="col-6">
            <VUpdateUserForm />
          </div>
          <div class="col-6">
            <VUserPermissions />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <VDeleteUser />
          </div>
        </div>
      </section>
      <div v-else>
        <section class="c-section">
          <div class="row">
            <div class="col-12">
              <h1 class="c-admin-page-title">
                User Not Found
              </h1>
              <div class="mt-4">
                Please use the buttons below to go back to the users list.
              </div>
              <div class="mt-4">
                <a
                  :href="false"
                  class="c-button -admin-small"
                  @click="usersPage"
                >
                  Back to Users List
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import UserService from "js-admin/classes/AjaxServices/UserService";
import VDeleteUser from "js-admin/components/users/DeleteUser";
import VUpdateUserForm from "js-admin/components/users/UpdateUserForm";
import VUserPermissions from "js-admin/components/users/Permissions.vue";
import VInfoBox from "js-admin/components/information-boxes/InformationBox.vue";

export default {
  components: {
    VDeleteUser,
    VUpdateUserForm,
    VUserPermissions,
    VInfoBox,
  },
  data() {
    return {
      dataReady: false,
      userNotFound: false,
      stateKey: "userData",
      passwordStateKey: "passwordGeneration",
    };
  },

  computed: {
    usersUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
    usersName() {
      return this.$store.state.fieldStore[this.stateKey].name;
    },
    userPassword() {
      return this.$store.state.fieldStore[this.passwordStateKey].password;
    },
  },

  watch: {},

  mounted() {
    if (!this.checkRole("superadmin")) {
      return this.$router.push({ name: "home" });
    }

    UserService.getUserInfo(this.$route.params.uuid)
      .then((response) => {
        if (response.data) {
          var data = {
            uuid: response.data.data.uuid,
            name: response.data.data.name,
            email_address: response.data.data.email_address,
            phone_number: this.stripSpaces(response.data.data.mobile_number),
            phone_number_country_code:
              response.data.data.mobile_number_country_code,
            phone_number_dial_code: response.data.data.mobile_number_dial_code,
            work_phone: null,
            owner_uuid: null,
            owner_job_title: null,
            reset_user_password: false,
            role: {
              name: response.data.data.roles[0],
            },
          };

          if (response.data.data.owner) {
            data.owner_uuid = response.data.data.owner.uuid;
            data.owner_job_title = response.data.data.owner.job_title;
            data.work_phone = this.stripSpaces(
              response.data.data.owner.formatted_phone_number
            );
            data.work_phone_country_code =
              response.data.data.owner.phone_number_country_code;
            data.work_phone_dial_code =
              response.data.data.owner.phone_number_dial_code;
          }

          this.$store.dispatch("updateStoreItem", {
            value: data,
            stateKey: this.stateKey,
          });

          this.dataReady = true;
          return;
        }

        this.userNotFound = true;
      })
      .catch(() => {
        this.userNotFound = true;
      });
  },

  methods: {
    usersPage() {
      this.$router.push({ name: "manage-users" });
      return false;
    },

    closeBox() {
      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.passwordStateKey,
        field: "password",
      });
    },
  },
};
</script>
