import axios from "axios";

export default class APIService {
  static async request(url, method = "get", params = null, header = null) {
    return await axios[method](
      window.whiteLabelClientApiUrl + "/api/v1/" + url,
      params,
      header
    );
  }

  static async login(credentials) {
    return await axios.post(
      window.whiteLabelClientApiUrl + "/login",
      credentials
    );
  }

  static async logout() {
    return await axios.get(window.whiteLabelClientApiUrl + "/logout");
  }
}
