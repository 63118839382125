<template>
  <div class="v-create-users">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title mb-5">
            Create User
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="c-admin-panel">
            <form :data-vv-scope="createFormScope" @submit.prevent="submitForm">
              <div class="c-admin-panel-section">
                <div class="row">
                  <div class="col-12">
                    <h3 class="c-admin-panel-title">
                      User Details
                    </h3>

                    <div>
                      <v-text-input
                        :scope="createFormScope"
                        :state-key="stateKey"
                        field="name"
                        label="Name"
                        error-name="name"
                        validation="required"
                      />
                    </div>

                    <div>
                      <v-text-input
                        :scope="createFormScope"
                        :state-key="stateKey"
                        field="email_address"
                        label="Email address"
                        error-name="email address"
                        validation="required|email"
                      />
                    </div>

                    <div>
                      <v-phone-number-text-input
                        :scope="createFormScope"
                        :state-key="stateKey"
                        field="phone_number"
                        type="tel"
                        label="Mobile Number"
                        error-name="mobile number"
                        autocomplete="tel"
                        :always-instructions="true"
                        instructions="This is maybe required to setup two-factor authentication"
                        :maxlength="13"
                        validation=""
                        :validate-mobile="true"
                      />
                    </div>

                    <div class="mb-4">
                      <v-select-input
                        :scope="createFormScope"
                        :state-key="stateKey"
                        :options="$store.state.roles"
                        :center-error="false"
                        enable-blank-option="true"
                        field="role"
                        placeholder="Select a role"
                        error-name="role"
                        validation="required"
                        label="Select a Role:"
                        @selectionUpdated="handleRoleUpdate"
                      />
                      <div v-if="roleError" class="c-input-error"
                        >Please select your role
                      </div>
                    </div>

                    <div v-if="showOwnerFields">
                      <div>
                        <v-text-input
                          :scope="createFormScope"
                          :state-key="stateKey"
                          field="owner_job_title"
                          label="Job Title"
                          error-name="job title"
                          validation=""
                        />
                      </div>
                      <div>
                        <v-phone-number-text-input
                          :scope="createFormScope"
                          :state-key="stateKey"
                          field="work_phone"
                          type="tel"
                          label="Work Number"
                          error-name="work number"
                          autocomplete="tel"
                          :always-instructions="true"
                          instructions="This is landline phone number which this user will use to make and receive calls"
                          :maxlength="13"
                          validation="required|numeric"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <v-button
                      v-if="checkRole('superadmin')"
                      class="mt-3"
                      :loading="submittingUserData"
                      :disabled="submittingUserData"
                    >
                      Create
                    </v-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UserService from "js-admin/classes/AjaxServices/UserService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import AccountManagerService from "js-admin/classes/AjaxServices/AccountManagerService";

export default {
  components: {},

  data() {
    return {
      createFormScope: "createUserData",
      stateKey: "createUser",
      submittingUserData: false,
      showOwnerFields: false,
      roleError: false,
      passwordStateKey: "passwordGeneration",
    };
  },

  computed: {
    userUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
    userRole() {
      return this.$store.state.fieldStore[this.stateKey].role;
    },
    accountManagerRole() {
      const role = this.$store.state.fieldStore[this.stateKey].role.name;
      return role === "account_manager" || role === "senior_account_manager";
    },
  },

  mounted() {
    if (!this.checkRole("superadmin")) {
      return this.$router.push({ name: "home" });
    }

    this.$store.dispatch("getRoles");

    this.$store.dispatch("updateStoreItem", {
      value: null,
      stateKey: this.passwordStateKey,
      field: "password",
    });
  },

  methods: {
    handleRoleUpdate(val) {
      if (!val || val === "Select a role") {
        this.roleError = true;
      } else {
        this.roleError = false;
      }

      if (
        val.name === "account_manager" ||
        val.name === "senior_account_manager"
      ) {
        this.showOwnerFields = true;
        return;
      }
      this.showOwnerFields = false;
    },

    submitForm() {
      if (!this.userRole.name || this.userRole === "Select a role") {
        this.roleError = true;
      }
      this.$validator.validateAll(this.createFormScope).then(async (result) => {
        if (result && !this.roleError) {
          this.submittingUserData = true;

          var response = await this.addUser();
          if (!response) {
            this.submittingUserData = false;
            return;
          }

          if (this.accountManagerRole) {
            response = await this.addOwner();
            if (!response) {
              this.submittingUserData = false;
              return;
            }
          }

          this.submittingUserData = false;
          this.$router.push("/manage-users/" + this.userUuid);
          // reset user data
          this.$store.dispatch("updateStoreItem", {
            value: this.getEmptyCreateUser(),
            stateKey: this.stateKey,
            field: null,
          });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },

    addUser() {
      var data = {
        name: this.$store.state.fieldStore[this.stateKey].name,
        username: this.$store.state.fieldStore[this.stateKey].email_address,
        email_address: this.$store.state.fieldStore[this.stateKey]
          .email_address,
        role: this.$store.state.fieldStore[this.stateKey].role.name,
        return_password_response: true,
      };

      if(this.$store.state.fieldStore[this.stateKey].phone_number) {
        data.phone_number = this.$store.state.fieldStore[this.stateKey].phone_number_dial_code + this.$store.state.fieldStore[this.stateKey].phone_number;
      }

      return UserService.createUser(data)
        .then((response) => {
          FlashMessageService.success("User has been created.");
          this.$store.dispatch("updateStoreItem", {
            value: response.data.data.uuid,
            stateKey: this.stateKey,
            field: "uuid",
          });

          this.$store.dispatch("updateStoreItem", {
            value: response.data.data.password,
            stateKey: this.passwordStateKey,
            field: "password",
          });
          return true;
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
          return false;
        });
    },

    addOwner() {
      var ownerData = {
        user_uuid: this.$store.state.fieldStore[this.stateKey].uuid,
        name: this.$store.state.fieldStore[this.stateKey].name,
        email: this.$store.state.fieldStore[this.stateKey].email_address,
        phone_number:
          this.$store.state.fieldStore[this.stateKey].work_phone_dial_code +
          this.$store.state.fieldStore[this.stateKey].work_phone,
        job_title: this.$store.state.fieldStore[this.stateKey].owner_job_title,
      };

      return AccountManagerService.createAMOwner(ownerData)
        .then(() => {
          return true;
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
          return false;
        });
    },
  },
};
</script>
