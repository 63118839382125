<template>
  <button
    :disabled="disabled"
    class="c-sidebar-item"
    :class="{ '-active': active }"
    @click="clicked"
  >
    <slot />
    <img
      v-if="item.icon"
      class="c-sidebar-item-icon"
      :src="'/images/customer-dash/icons/' + item.icon"
      :alt="item.route"
    />
    {{ item.text }}
  </button>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
      //
      // e.g.
      //  {
      //    text: 'item text', //optional
      //    icon: 'icon-payments.svg', //optional, file in customer dash icons folder
      //    route: 'payments' // *REQ route name
      // }
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    active() {
      return this.$route.name === this.item.route;
    },
  },
  methods: {
    clicked() {
      this.$router.push({ name: this.item.route });
    },
  },
};
</script>
