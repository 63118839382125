<template>
  <div class="c-admin-table">
    <div class="c-admin-table-header">
      <div class="row align-items-center">
        <div class="col-2">
          Created At
        </div>
        <div class="col-2">
          Bank Transfer Reference
        </div>
        <div class="col-2">
          Amount
        </div>
        <div class="col">
          Note
        </div>
      </div>
    </div>

    <v-bank-transfer-correction-row
      v-for="(correction, index) in corrections"
      :key="correction.uuid"
      :correction="correction"
      :index="index"
    />
  </div>
</template>

<script>
import VBankTransferCorrectionRow from "js-admin/components/cash-wallet/deposits/corrections/BankTransferCorrectionRow";

export default {
  components: {
    VBankTransferCorrectionRow,
  },
  props: {
    corrections: {
      required: true,
      type: Array,
    },
  },
};
</script>
