<template>
  <div class="mt-2">
    <div v-if="amlsCount" class="c-admin-table mt-2">
      <div class="c-admin-table-header">
        <div class="row align-items-center">
          <div class="col-2">
            Report Ran
          </div>
          <div class="col-2">
            Provider
          </div>
          <div class="col-3">
            Reference
          </div>
          <div class="col-3">
            Key Info
          </div>
          <div class="col-2">
            PDF
          </div>
        </div>
      </div>
      <div
        v-for="(aml, index) in amls"
        :key="aml.uuid"
        class="c-admin-table-row"
      >
        <VAmlsRow :aml="aml" :index="index" />
      </div>
    </div>
    <div v-else>
      No linked AMLs.
    </div>
  </div>
</template>

<script>
import VAmlsRow from "js-admin/components/accounts/amls/AmlsRow";

export default {
  components: {
    VAmlsRow,
  },
  props: {
    amls: {
      required: true,
    },
    amlsCount: {
      required: true,
    },
  },
};
</script>
