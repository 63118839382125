<template>
  <div class="mt-2">
    <div v-if="accountAddressesCount" class="c-admin-table">
      <div class="c-admin-table-header">
        <div class="row align-items-center">
          <div class="col-2 text-center">
            Primary Address?
          </div>
          <div class="col-1" />
          <div class="col-7">
            Address
          </div>
          <div
            v-if="checkPermission('delete_address')"
            class="col-2 text-center"
          />
        </div>
      </div>
      <div
        v-for="(address, index) in accountAddresses"
        :key="address.uuid"
        class="c-admin-table-row"
      >
        <VLinkedAddressRow :address="address" :index="index" />
      </div>
    </div>
    <div v-else>
      No linked other addresses.
    </div>
  </div>
</template>

<script>
import VLinkedAddressRow from "js-admin/components/accounts/addresses/LinkedAddressRow.vue";

export default {
  components: {
    VLinkedAddressRow,
  },
  data() {
    return {
      stateKey: "accountData",
    };
  },

  computed: {
    accountAddressesCount() {
      if (this.$store.state.fieldStore[this.stateKey].addresses) {
        return this.$store.state.fieldStore[this.stateKey].addresses.length;
      } else {
        return 0;
      }
    },
    accountAddresses() {
      return this.$store.state.fieldStore[this.stateKey].addresses;
    },
  },

  methods: {
    toggle(selectedItem) {
      this.$children.forEach((item) => {
        item.showEditForm = item._uid == selectedItem._uid;
      });
    },
  },
};
</script>
