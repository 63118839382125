<template>
  <div>
    <div class="row align-items-center">
      <div class="col-3">
        {{ dateTimeFormat(document.uploaded_at) }}
      </div>
      <div class="col-2">
        {{ collectionName }}
      </div>
      <div class="col-3">
        {{ document.file_name }}
      </div>
      <div class="col-2 text-center">
        <a
          :href="document.url"
          class="c-button -admin-small"
          target="_blank"
          rel="noopener"
        >
          View
        </a>
      </div>
      <div v-if="checkPermission('delete_files')" class="col-2 text-center">
        <a
          :href="false"
          class="c-button -admin-small"
          @click="deleteDocument(document.uuid, index)"
        >
          Delete
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    document: {
      required: true,
    },
    index: {
      required: true,
    },
    stateKey: {
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    collectionName() {
      switch (this.document.collection_name) {
        case "POI":
          return "Proof of ID";
        case "POA":
          return "Proof of Address";
        case "PDFApplication":
          return "PDF Application";
        case "ISATransferForm":
          return "ISA TAF";
        default:
          return this.document.collection_name;
      }
    },
  },

  methods: {
    deleteDocument(uuid, index) {
      if (confirm("Are you sure you want to delete this document?")) {
        this.$store.dispatch("deleteDocument", uuid).then((response) => {
          if (response.status == 204) {
            FlashMessageService.success("Document has been deleted.");

            this.$store.dispatch("deleteArrayItemFromStore", {
              stateKey: this.stateKey,
              field: "documents",
              index: index,
            });
          } else {
            console.log(response);
            return false;
          }
        });
      }
    },
  },
};
</script>
