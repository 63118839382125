var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[(_vm.checkPermission('view_accounts'))?_c('VSidebarItem',{attrs:{"disabled":_vm.disabled,"item":{ text: 'Customer Accounts', route: 'accounts' }}}):_vm._e(),(_vm.checkPermission('leads_distribution'))?_c('VSidebarItem',{attrs:{"disabled":_vm.disabled,"item":{ text: 'Lead Distribution', route: 'lead-distribution' }}}):_vm._e(),(
      _vm.checkPermission('view_tasks') &&
        !_vm.systemFeatureIsDisabled('disabled_tasks')
    )?_c('VSidebarItem',{attrs:{"disabled":_vm.disabled,"item":{ text: 'Tasks', route: 'tasks' }}}):_vm._e(),(
      _vm.checkPermission('compliance_view_call_recordings') &&
        !_vm.systemFeatureIsDisabled('disabled_phone_system')
    )?_c('VSidebarItem',{attrs:{"disabled":_vm.disabled,"item":{ text: 'Calls & Recordings', route: 'calls-recordings' }}}):_vm._e(),_c('VSidebarItem',{attrs:{"disabled":_vm.disabled,"item":{ text: 'SM Instruments', route: 'instruments' }}}),(
      _vm.checkPermission('reporting_level1') &&
        !_vm.systemFeatureIsDisabled('disabled_reports')
    )?_c('VSidebarItem',{attrs:{"disabled":_vm.disabled,"item":{ text: 'Reports', route: 'reports' }}}):_vm._e(),_c('VSidebarItem',{attrs:{"disabled":_vm.disabled,"item":{ text: 'My Profile', route: 'my-profile' }}}),(
      _vm.checkRole([
        'superadmin',
        'admin',
        'senior_account_manager',
        'compliance',
      ])
    )?_c('VSidebarItem',{attrs:{"disabled":_vm.disabled,"item":{ text: 'Administration', route: 'administration' }}}):_vm._e(),(
      _vm.checkRole([
        'superadmin',
        'senior_account_manager',
        'account_manager',
      ]) && !_vm.systemFeatureIsDisabled('disabled_support_tickets')
    )?_c('VSidebarItem',{attrs:{"disabled":_vm.disabled,"item":{ text: 'View Support Tickets', route: 'support-status' }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }