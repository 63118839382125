import APIService from "js-admin/classes/APIService";

export default class InvestmentAccountService {
  static CRUD_URL = "investment-accounts";

  static async getInvAccountData(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid);
  }

  static async getInvAccountTransactions(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid + "/transactions");
  }

  static async deleteInvestmentAccount(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }
}
