<template>
  <transition-group name="zoomfade">
    <div
      v-show="value && !focused && !hasErrors"
      key="success"
      class="c-input-icon -success"
    >
      <svg
        id=""
        version="1.2"
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        xml:space="preserve"
      >
        <g>
          <path
            fill="#8fead0"
            d="M16,35c-0.6,0-1.2-0.2-1.7-0.6L1,23.5c-1.1-0.9-1.3-2.7-0.4-3.8c0.9-1.2,2.6-1.4,3.7-0.4l11.4,9.3L35.3,5.9
            c1-1.1,2.7-1.2,3.8-0.2c1.1,1,1.2,2.7,0.2,3.8L18,34.1C17.5,34.7,16.7,35,16,35z"
          />
        </g>
      </svg>
    </div>
    <div
      v-show="value && !focused && hasErrors"
      key="error"
      class="c-input-icon -error"
    >
      <svg
        id=""
        version="1.2"
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        xml:space="preserve"
      >
        <g>
          <path
            fill="#F56B6B"
            d="M34.2,30.3c1.1,1.1,1.1,2.8,0,3.8C33.7,34.7,33,35,32.3,35s-1.4-0.3-1.9-0.8L20,23.8L9.7,34.2
            C9.1,34.7,8.4,35,7.7,35s-1.4-0.3-1.9-0.8c-1.1-1.1-1.1-2.8,0-3.8L16.2,20L5.8,9.7c-1.1-1.1-1.1-2.8,0-3.8c1.1-1.1,2.8-1.1,3.8,0
            L20,16.2L30.3,5.8c1.1-1.1,2.8-1.1,3.8,0c1.1,1.1,1.1,2.8,0,3.8L23.8,20L34.2,30.3z"
          />
        </g>
      </svg>
    </div>
    <div
      v-show="(icon && !value && !focused) || focused"
      key="default"
      class="c-input-icon"
    >
      <img
        :src="xGetImageSrc(iconPath)"
        :class="'-' + iconClass"
        :alt="iconName"
      />
    </div>
  </transition-group>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
      type: [String, Number],
    },
    hasErrors: {
      required: true,
      type: Boolean,
    },
    focused: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: "",
      type: String,
    },
  },
  computed: {
    iconName() {
      if (this.icon?.length > 0) {
        return this.icon.split(".")[0];
      }
      return null;
    },
    iconPath() {
      if (this.icon) {
        return "/images/shared/icons/" + this.icon;
      }
      return null;
    },
    iconClass() {
      if (this.icon) {
        return this.icon.split(".")[0];
      }
      return null;
    },
  },
  methods: {
    xGetImageSrc(path, imgEnding = "-white") {
      if (!path) {
        return "";
      }

      return path;
    },
  },
};
</script>
