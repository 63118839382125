<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2">
        {{ user.name }}
      </div>
      <div class="col-3">
        <div v-if="!showEditForm">
          <div> {{ user.username }}</div>
          <a
            v-if="checkPermission('edit_account')"
            :href="false"
            class="c-button -admin-small mt-3"
            @click="showForm"
          >
            Edit
          </a>
        </div>
        <div v-else>
          <form :data-vv-scope="scope" @submit.prevent="updateUsername">
            <div>
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                label="Username"
                error-name="username"
                field="username"
                validation="required"
              />
            </div>

            <v-button
              v-if="checkPermission('edit_account')"
              :loading="updateInProgress"
              :disabled="updateInProgress"
              class="-admin-small"
            >
              Update
            </v-button>
          </form>
        </div>
      </div>
      <div class="col-3">
        {{ user.email_address }}
      </div>
      <div class="col-2 text-center">
        <a
          v-if="!resetPasswordInProgress"
          :href="false"
          class="c-button -admin-small"
          @click="resetPassword"
        >
          Reset Password
        </a>
        <v-loader v-else />
      </div>
      <div class="col-2 text-center">
        <div v-if="toggleResetTwoFactor">
          <a
            v-if="!resetTwoFactorInProgress"
            :href="false"
            class="c-button -admin-small"
            @click="resetTwoFactor(user.uuid)"
          >
            Reset Two-Factor
          </a>
          <v-loader v-else />
        </div>
        <div v-else>
          Not Setup
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    user: {
      required: true,
    },
    index: {
      required: true,
    },
  },

  data() {
    return {
      scope: "updateBankDetailsForm",
      stateKey: "updateUsername",
      stateKey2: "accountData",
      updateInProgress: false,
      showEditForm: false,
      resetPasswordInProgress: false,
      resetTwoFactorInProgress: false,
      updatedTwoFactor: false,
    };
  },

  computed: {
    toggleResetTwoFactor() {
      if (this.updatedTwoFactor) {
        return false;
      }
      return this.user.two_factor_type;
    },
  },

  methods: {
    resetPassword() {
      this.resetPasswordInProgress = true;
      this.$store
        .dispatch("requestPasswordReset", { username: this.user.username })
        .then(() => {
          this.resetPasswordInProgress = false;
          FlashMessageService.success(
            "A reset password email has been sent to the client."
          );
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },

    resetTwoFactor() {
      this.resetTwoFactorInProgress = true;
      this.$store
        .dispatch("resetUserTwoFactor", this.user.uuid)
        .then((response) => {
          this.resetTwoFactorInProgress = false;
          this.updatedTwoFactor = true;
          FlashMessageService.success(response.message);
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },

    showForm() {
      this.$store.dispatch("updateStoreItem", {
        value: this.user,
        stateKey: this.stateKey,
        field: null,
      });

      this.$parent.toggle(this);
    },

    updateUsername() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          const data = {
            username: this.$store.state.fieldStore[this.stateKey].username,
            uuid: this.$store.state.fieldStore[this.stateKey].uuid,
          };

          this.updateInProgress = true;
          this.$store
            .dispatch("updateAccountUserLogin", data)
            .then((response) => {
              FlashMessageService.success("Username has been updated.");
              this.$store.dispatch("updateArrayItemInStore", {
                value: response.data,
                stateKey: this.stateKey2,
                field: "users",
                index: this.index,
              });
              this.showEditForm = false;
              this.updateInProgress = false;
            })
            .catch((error) => {
              this.updateInProgress = false;
              FlashMessageService.error(error.response.data.message);
            });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },
  },
};
</script>
