<template>
  <div class="c-admin-table">
    <div class="c-admin-table-header">
      <div class="row align-items-center">
        <div class="col-2">
          Created At
        </div>
        <div class="col-2">
          Reference
        </div>
        <div class="col-2">
          Provider Name
        </div>
        <div class="col-1">
          Amount
        </div>
        <div class="col-1">
          Status
        </div>
        <div class="col-2">
          Acknowledgement Email Send
        </div>
        <div class="col-2 text-center">
          Corrections
        </div>
      </div>
    </div>

    <v-bank-transfers-row
      v-for="(payment, index) in payments"
      :key="payment.uuid"
      :payment="payment"
      :index="index"
    />
  </div>
</template>

<script>
import VBankTransfersRow from "js-admin/components/cash-wallet/deposits/payments/BankTransfersRow";

export default {
  components: {
    VBankTransfersRow,
  },
  props: {
    payments: {
      required: true,
      type: Array,
    },
  },
};
</script>
