// imported getters
import helpdeskGetters from "js-admin/store/getters/helpdesk-getters/HelpdeskGetters";
import taskGetters from "js-admin/store/getters/task-getters/TaskGetters";
import phoneGetters from "js-admin/store/getters/PhoneGetters";
import mergeAccountGetters from "js-admin/store/getters/merge-account-getters/MergeAccountGetters";
import reportGetters from "js-admin/store/getters/report-getters/ReportGetters";

export default {
  getScreenSize: (state) => {
    const size = state.windowSize;
    if (size < 480) {
      return "xxs";
    } else if (size < 576) {
      return "xs";
    } else if (size < 768) {
      return "sm";
    } else if (size < 992) {
      return "md";
    } else if (size < 1200) {
      return "lg";
    } else {
      return "xl";
    }
  },
  getCompanyName: (state) => state.config.company_info.name,
  defaultCurrency: (state) => state.config.system_settings.default_currency,
  defaultDialCode: (state) => state.config.system_settings.default_dial_code,

  ...phoneGetters,
  ...helpdeskGetters,
  ...taskGetters,
  ...mergeAccountGetters,
  ...reportGetters,
};
