<template>
  <transition
    appear
    mode="out-in"
    :css="false"
    @before-enter="beforeNavEnter"
    @enter="enterNav"
    @before-leave="beforeNavLeave"
    @leave="leaveNav"
  >
    <div v-if="buttonValueState == false" key="0">
      <slot name="open">
        <i class="far fa-comment-dots"></i>
      </slot>
    </div>
    <div v-else key="1">
      <slot name="close">
        <i class="far fa-times-circle"></i>
      </slot>
    </div>
  </transition>
</template>

<script>
import gsap from "gsap";
export default {
  props: {
    buttonValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonValueState() {
      return this.buttonValue;
    },
  },
  methods: {
    beforeNavEnter(el) {
      gsap.set(el, {
        css: {
          opacity: 0,
        },
        ease: "power3.inOut",
      });
    },
    enterNav(el, done) {
      gsap.to(el, {
        duration: 0.3,
        css: {
          opacity: 1,
        },
        ease: "power3.inOut",
        onComplete: () => {
          done();
        },
      });
    },
    beforeNavLeave() {},
    leaveNav(el, done) {
      gsap.to(el, {
        duration: 0.3,
        css: {
          opacity: 0,
        },
        ease: "power3.inOut",
        onComplete: () => {
          done();
        },
      });
    },
  },
};
</script>
