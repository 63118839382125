<template>
  <div>
    <div class="c-admin-panel">
      <div class="c-admin-panel-section">
        <div class="row">
          <div class="col-12">
            <h3 class="c-admin-panel-title">
              Application Information
            </h3>
          </div>
        </div>

        <div v-if="investmentAccount.current_step" class="row mt-2">
          <div class="col-5">
            <div>
              Application Step:
            </div>
          </div>
          <div class="col-1" />
          <div class="col-6">
            <div> {{ stepName }} </div>
          </div>
        </div>

        <div v-if="isaType" class="row mt-2">
          <div class="col-5">
            <div>
              ISA Type:
            </div>
          </div>
          <div class="col-1" />
          <div class="col-6">
            <div>
              {{ isaType }}
            </div>
          </div>
        </div>

        <div v-if="investmentAccount.amount" class="row mt-2">
          <div class="col-5">
            <div>
              Amount:
            </div>
          </div>
          <div class="col-1" />
          <div class="col-6">
            <div> {{investmentAccount.currency.symbol + convertToPounds(investmentAccount.amount) }} </div>
          </div>
        </div>

        <div v-if="investmentAccount.top_up_monthly_amount" class="row mt-2">
          <div class="col-5">
            <div>
              Top Up Amount:
            </div>
          </div>
          <div class="col-1" />
          <div class="col-6">
            <div>
              {{investmentAccount.currency.symbol + convertToPounds(investmentAccount.top_up_monthly_amount) }}
            </div>
          </div>
        </div>

        <div v-if="investmentAccount.terms_conditions_agreed" class="row mt-2">
          <div class="col-5">
            <div>
              Terms Agreed:
            </div>
          </div>
          <div class="col-1" />
          <div class="col-6">
            <div>
              {{ dateTimeFormat(investmentAccount.terms_conditions_agreed) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    investmentAccount: {
      required: true,
    },
  },

  computed: {
    isaType() {
      if (this.investmentAccount.isa_type) {
        if (this.investmentAccount.isa_type === "isa") {
          const type = this.investmentAccount.isa_type;
          return type.toUpperCase();
        }

        const type = this.investmentAccount.isa_type;
        return this.capitaliseFirstLetter(type);
      } else {
        return null;
      }
    },
    stepName() {
      if (this.investmentAccount.current_step) {
        return this.xProcessStepName(this.investmentAccount.current_step);
      }
      return null;
    },
  },
};
</script>
