<template>
  <div class="c-checkbox">
    <div class="c-checkbox-container" @click="click">
      <div class="row align-items-center no-gutters">
        <div class="col-auto">
          <VCheckboxVisual :active="confirmed" />
        </div>
        <div class="col">
          <div class="c-checkbox-content">
            <slot>Enter your checkbox content in the slot</slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VCheckboxVisual from "js-admin/components/form/checkbox/CheckboxVisual.vue";
import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";

export default {
  components: {
    VCheckboxVisual,
  },
  inject: ["$validator"],

  props: {
    call: {
      required: true,
    },
    field: {
      required: true,
    },
    index: {
      required: true,
    },
    stateKey: {
      required: true,
    },
  },
  data() {
    return {
      confirmed: this.call.identity_verified_at,
    };
  },
  computed: {},
  methods: {
    click() {
      if (!this.confirmed && !this.call.identity_verified_at) {
        CommunicationService.verifiedPhoneCall(this.call.uuid);

        this.confirmed = true;

        this.$store.dispatch("updateSubArrayItemStore", {
          value: this.confirmed,
          stateKey: this.stateKey,
          field: "call",
          index: this.index,
          subArrayField: this.field,
        });
      }
    },
  },
};
</script>
