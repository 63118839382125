<template>
  <div class="c-enable-two-factor-container">
    <div class="c-enable-two-factor-box">
      <h4 class="c-enable-two-factor-box-title">
        Enable Two-Factor Verification
      </h4>
      Due to the sensitive information stored in this site, please ensure you
      have enabled two-factor verification to keep your account secure. <br />
      You will not be able to access other system functionalities until setup.
    </div>
  </div>
</template>

<script>
export default {};
</script>
