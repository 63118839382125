<template>
  <div class="c-admin-table-row">
    <div class="row align-items-center">
      <div class="col-2" v-text="dateTimeFormat(correction.created_at)" />
      <div class="col-2" v-text="correction.bank_transfer_ref" />
      <div class="col-2" v-text="correction.currency.symbol + convertToPounds(correction.amount)" />
      <div class="col" v-text="capitaliseFirstLetter(correction.note)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    correction: {
      required: true,
      type: Object,
    },
  },
};
</script>
