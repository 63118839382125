<template>
  <div class="v-accounts">
    <section v-if="pageReady" class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title mb-5">
            Assign Call to an Account
          </h1>
        </div>
      </div>
      <div class="row align-items-end">
        <div v-if="checkPermission('filter_accounts')" class="col-4">
          <v-select-input
            class="h-mb-1"
            state-key="accountListingFilter"
            :options="filterOptions"
            :center-error="false"
            field="account_listing_filter"
            label="Filter:"
            placeholder="Filter accounts by"
            @selectionUpdated="filterAccountListing"
          />
        </div>
        <div v-if="checkPermission('search_accounts')" class="col-5">
          <div class="mb-3">
            Search: <br />
            Please enter an email address, name or telephone number below.
          </div>
          <form :data-vv-scope="searchScope" @submit.prevent="search">
            <v-text-input
              :scope="searchScope"
              :state-key="searchStateKey"
              field="searchTerm"
              label="Search"
              validation="required"
              error-name="search term"
              class="mr-3 h-mb-1"
            />
            <v-button
              class="c-button -admin-small mb-2"
              :loading="searchInProgress"
              :disabled="searchInProgress"
            >
              Search
            </v-button>

            <a
              v-if="searchPerformed"
              :href="false"
              class="c-button -admin-small mb-2"
              @click="clearSearch"
            >
              Clear Search
            </a>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header">
              <div class="row">
                <div class="col-2">
                  Name
                </div>
                <div class="col-3">
                  Email Address
                </div>
                <div class="col-2">
                  Contact Numbers
                </div>
                <div class="col-2">
                  &nbsp;
                </div>
              </div>
            </div>

            <div v-if="accountsDataReady">
              <div v-if="displayedAccounts && displayedAccounts.length">
                <div
                  v-for="(account, index) in displayedAccounts"
                  :key="index"
                  class="c-admin-table-row"
                >
                  <div class="row">
                    <div class="col-2">
                      {{ account.title }} {{ account.first_name }}
                      {{ account.last_name }} <br /><br />
                      <small>
                        Created at: <br />{{
                          dateTimeFormat(account.created_at)
                        }}
                      </small>
                    </div>
                    <div class="col-3">
                      <div v-if="account.email" class="text-break">
                        {{ account.email }}
                      </div>
                      <div v-else>
                        N.A.
                      </div>
                    </div>
                    <div class="col-2">
                      <div v-if="account.primary_contact_number">
                        {{ account.primary_contact_number }}
                      </div>
                      <div v-if="account.secondary_contact_number">
                        {{ account.secondary_contact_number }}
                      </div>
                    </div>
                    <div class="col-2 text-center">
                      <a
                        class="c-button -admin-small"
                        :loading="isLoading"
                        :disabled="isLoading"
                        @click="assignPhoneCall(account.uuid)"
                      >
                        Assign Call
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="c-admin-table-row">
                <div class="row">
                  <div class="col-12">
                    No accounts found
                  </div>
                </div>
              </div>
              <div class="c-admin-table-footer">
                <div class="row">
                  <div class="col-12">
                    <VPagination
                      v-if="displayedAccounts && displayedAccounts.length"
                      v-model="page"
                      :page-count="pages.length"
                      :classes="bootstrapPaginationClasses"
                      :labels="paginationAnchorTexts"
                    />
                  </div>
                </div>
              </div>
            </div>
            <v-ajax-loader v-else />
          </div>
        </div>
      </div>
    </section>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import VPagination from "vue-plain-pagination";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";
import AccountService from "js-admin/classes/AjaxServices/AccountService";

export default {
  components: {
    VPagination,
  },

  data() {
    return {
      isLoading: false,
      inProgressCallsStateKey: "inProgressCalls",
      accounts: null,
      page: 1,
      perPage: 10,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      searchScope: "searchForm",
      searchStateKey: "search",
      searchInProgress: false,
      searchPerformed: false,
      accountListingFilterStateKey: "accountListingFilter",
      accountsCount: null,
      searchResults: null,
      pageReady: false,
      accountsDataReady: false,
      generalFilterOptions: [
        {
          value: "all",
          name: "All",
        },
        {
          value: "only_with_investments",
          name: "With Investments Only",
        },
        {
          value: "only_with_applications",
          name: "With Applications Only",
        },
        {
          value: "archived_account",
          name: "Archived Only",
        },
      ],
      leadsFilterOptions: [
        {
          value: "only_with_leads",
          name: "Leads Only",
        },
      ],
    };
  },
  computed: {
    searchTerm() {
      return this.$store.state.fieldStore[this.searchStateKey].searchTerm;
    },
    filter() {
      return this.$store.state.fieldStore[this.accountListingFilterStateKey]
        .account_listing_filter;
    },
    displayedAccounts() {
      return this.accounts;
    },
    filterOptions() {
      if (!this.systemFeatureIsDisabled("disabled_leads")) {
        return [...this.generalFilterOptions, ...this.leadsFilterOptions];
      }

      return this.generalFilterOptions;
    },
    formattedInProgressCalls() {
      return this.$store.getters.getFormattedCalls;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    if (
      !this.checkPermission("view_accounts") ||
      this.systemFeatureIsDisabled("disabled_phone_system")
    ) {
      return this.$router.push({ name: "home" });
    }

    if (this.searchTerm) {
      this.search();
    } else {
      AccountService.getAccounts({
        filter_by_type: this.filter,
        limit: this.perPage,
      }).then((response) => {
        this.setPages(response.data.meta.total);
        this.accounts = response.data.data;
        this.pageReady = true;
        this.accountsDataReady = true;
      });

      // reset search term
      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.searchStateKey,
        field: "searchTerm",
      });
    }

    // reset account data
    this.$store.dispatch("updateStoreItem", {
      value: this.getEmptyAccount(),
      stateKey: "accountData",
      field: null,
    });
  },

  methods: {
    assignPhoneCall(accountUuid) {
      this.isLoading = true;
      CommunicationService.assignCallToAccount(
        this.$route.params.uuid,
        accountUuid
      )
        .then((response) => {
          FlashMessageService.success(response.data.message);

          this.$store
            .dispatch("updateInProgressCall", this.$route.params.uuid)
            .then(() => {
              this.isLoading = false;
            });
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },

    setPages(total) {
      this.page = 1;
      this.pages = [];
      const numberOfPages = Math.ceil(total / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    async paginate() {
      this.accountsDataReady = false;
      let functionName = "getAccounts";
      const params = {
        page: this.page,
        limit: this.perPage,
      };

      if (this.searchTerm) {
        functionName = "search";
        params.term = this.searchTerm;
      } else {
        params.filter_by_type = this.filter;
      }

      await AccountService[functionName](params).then((response) => {
        this.accounts = response.data.data;
        this.accountsDataReady = true;
      });
    },

    filterAccountListing(filter) {
      AccountService.getAccounts({
        filter_by_type: filter,
        limit: this.perPage,
      }).then((response) => {
        this.setPages(response.data.meta.total);
        this.accounts = response.data.data;
        this.pageReady = true;
        this.accountsDataReady = true;
      });
    },

    search() {
      this.$validator.validateAll(this.searchScope).then((result) => {
        this.searchPerformed = true;
        if (result) {
          this.searchInProgress = true;
          this.accountsDataReady = false;
          AccountService.search({
            term: this.searchTerm,
            limit: this.perPage,
          }).then((response) => {
            this.setPages(response.data.meta.total);
            this.accounts = response.data.data;
            this.searchInProgress = false;
            this.accountsDataReady = true;
          });
        }
      });
    },

    clearSearch() {
      this.searchPerformed = false;
      this.accountsDataReady = false;

      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.searchStateKey,
        field: "searchTerm",
      });

      this.clearErrors(1);

      AccountService.getAccounts({
        filter_by_type: this.filter,
        page: 1,
        limit: this.perPage,
      }).then((response) => {
        this.setPages(response.data.meta.total);
        this.accounts = response.data.data;
        this.accountsDataReady = true;
      });
    },
  },
};
</script>
