<template>
  <div class="c-accordion">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    openFirstItem: {
      default: false,
    },
  },
  data() {
    return { items: [] };
  },

  created() {
    this.items = this.$children;
  },

  mounted() {
    this.items.forEach((item, index) => {
      item.number = index + 1;
    });

    if (this.openFirstItem) {
      this.items[0].isActive = true; // set the first item to open
    }
  },

  methods: {
    toggle(selectedItem) {
      this.items.forEach((item) => {
        item.isActive = item._uid == selectedItem._uid;
      });
    },

    closeAll() {
      this.items.forEach((item) => {
        item.isActive = false;
      });
    },
  },
};
</script>
