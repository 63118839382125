<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item @toggle="accordionToggle">
        <v-modal ref="messageModal" :trigger="false" size="xl">
          <h3 class="c-admin-panel-title text-center">
            Secured Message
          </h3>
          <iframe
            ref="messageBody"
            style="border: 0;"
            class="c-secure-message-iframe"
          />
        </v-modal>

        <template v-slot:title>
          Secured Messages ({{ securedMessagesCount }})
        </template>

        <VSendSecureMessage
          v-if="checkPermission('send_secured_messages')"
          class="mt-2"
          :state-key="stateKey"
          :account-uuid="accountUuid"
        />

        <div v-if="dataReady">
          <div class="c-admin-table">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-2">
                  Date Sent
                </div>
                <div class="col-3">
                  From
                </div>
                <div class="col-3">
                  Subject
                </div>
                <div class="col-2">
                  Opened
                </div>
                <div class="col-2 text-center" />
              </div>
            </div>
            <div v-if="modelSecureMessages.length">
              <div
                v-for="(message, index) in modelSecureMessages"
                :key="index"
                class="c-admin-table-row"
              >
                <VSecuredMessagesRow :secured-message="message" />
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              No secure messages
            </div>
          </div>
          <div class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-model="page"
                  :page-count="pages.length"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VPagination from "vue-plain-pagination";
import VSendSecureMessage from "js-admin/components/secured-messages/SendSecureMessage.vue";
import VSecuredMessagesRow from "js-admin/components/secured-messages/SecureMessagesRow.vue";
import AccountService from "js-admin/classes/AjaxServices/AccountService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  components: {
    VPagination,
    VSendSecureMessage,
    VSecuredMessagesRow,
  },
  props: {
    stateKey: {
      required: true,
    },
    accountUuid: {
      required: true,
    },
  },

  data() {
    return {
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      secureMessages: null,
      securedMessagesCount: null,
    };
  },

  computed: {
    modelSecureMessages() {
      return this.secureMessages;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    return AccountService.getAccountSecureMessages({
      uuid: this.accountUuid,
      return_count: true,
    })
      .then((response) => {
        if (response.data.data) {
          this.securedMessagesCount = response.data.data.count;
          this.setPages();
        }
      })
      .catch((error) => {
        FlashMessageService.error(error.response.data.data.message);
      });
  },

  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },

    paginate() {
      this.dataReady = false;

      return AccountService.getAccountSecureMessages({
        uuid: this.accountUuid,
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      })
        .then((response) => {
          if (response.data) {
            this.secureMessages = response.data.data;
            this.dataReady = true;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(
        this.securedMessagesCount,
        this.perPage
      );
    },
  },
};
</script>
