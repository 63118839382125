import APIService from "js-admin/classes/APIService";

export default class ReportingService {
  static CRUD_URL = "admin/reports";

  static async getTasksReport(data) {
    return APIService.request(this.CRUD_URL + "/tasks", "post", data);
  }

  static async getAccountsHeldReport(data) {
    return APIService.request(this.CRUD_URL + "/accounts-held", "post", data);
  }

  static async getLeadsTakenReport(data) {
    return APIService.request(this.CRUD_URL + "/leads-taken", "post", data);
  }

  static async getLeadsCreatedReport(data) {
    return APIService.request(this.CRUD_URL + "/leads-created", "post", data);
  }

  static async getLeadsClosedReport(data) {
    return APIService.request(this.CRUD_URL + "/leads-closed", "post", data);
  }

  static async getCallStats(data) {
    return APIService.request(this.CRUD_URL + "/call-stats", "post", data);
  }

  static async getComplianceCallStats(data) {
    return APIService.request(
      this.CRUD_URL + "/compliance-call-stats",
      "post",
      data
    );
  }

  static async getSuitabilityReport(data) {
    return APIService.request(
      this.CRUD_URL + "/suitability-report",
      "post",
      data
    );
  }

  static async getAuditLogsReport(data) {
    return APIService.request(
      this.CRUD_URL + "/audit-logs-report",
      "post",
      data
    );
  }

  static async getCompletedApplications(data) {
    return APIService.request(
      this.CRUD_URL + "/completed-applications-report",
      "post",
      data
    );
  }

  static async generateCassReport() {
    return APIService.request(this.CRUD_URL + "/cass-report", "post");
  }

  static async generateFeeReport(data) {
    return APIService.request(this.CRUD_URL + "/fee-report", "post", data);
  }

  static async getTotalLeadsReport(data) {
    return APIService.request(this.CRUD_URL + "/total-leads", "post", data);
  }
}
