export default {
  getFormattedCalls: (state) => {
    const formattedCalls = [];

    state.fieldStore.inProgressCalls.forEach((inProgressCall) => {
      const formattedCall = {
        uuid: inProgressCall.call.uuid,
        from: inProgressCall.call.from,
        call_uuid: inProgressCall.call.uuid,
        direction: inProgressCall.call.direction,
        is_open: inProgressCall.call.is_open,
        call_disposition: inProgressCall.call.call_disposition,
      };

      formattedCall.identity_verified_at = false;
      if (inProgressCall.call.identity_verified_at) {
        formattedCall.identity_verified_at = true;
      }

      if (inProgressCall.account) {
        formattedCall.name =
          inProgressCall.account.first_name +
          " " +
          inProgressCall.account.last_name;
        formattedCall.account_uuid = inProgressCall.account.uuid;
      }

      if (inProgressCall.note) {
        formattedCall.note = inProgressCall.note.note;
        formattedCall.note_uuid = inProgressCall.note.uuid;
      }

      formattedCalls.push(formattedCall);
    });

    return formattedCalls;
  },
};
