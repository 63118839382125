<template>
  <div>
    <div class="row align-items-center">
      <div class="col-1">
        {{ dateFormat(videoDetails.created_at) }}
      </div>
      <div class="col-2 text-break">
        {{ videoDetails.name }}
      </div>
      <div class="col-2 text-break">
        {{ capitaliseFirstLetter(videoDetails.type) }}
      </div>
      <div class="col-4 text-break">
        {{ videoDetails.url }}
      </div>
      <div class="col-3 text-center">
        <a
          :href="videoDetails.url"
          target="_blank"
          class="c-button -admin-small mr-3"
        >
          View
        </a>
        <a
          v-if="checkPermission('delete_issuer')"
          :href="false"
          class="c-button -admin-small"
          @click="deleteVideo(videoDetails.uuid)"
        >
          Delete
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import CarouselSlideService from "js-admin/classes/AjaxServices/CarouselSlideService";

export default {
  props: {
    videoDetails: {
      required: true,
    },
    index: {
      required: true,
    },
  },

  methods: {
    deleteVideo(uuid) {
      if (confirm("Are you sure you want to delete this slide?")) {
        CarouselSlideService.deleteSlide(uuid)
          .then(() => {
            FlashMessageService.success("Video has been deleted.");
            this.$parent.$parent.$parent.$parent.getCount(true);
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      }
    },
  },
};
</script>
