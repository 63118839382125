<template>
  <div class="c-admin-table-row">
    <div class="row align-items-center">
      <div class="col-2" v-text="dateTimeFormat(withdrawal.created_at)" />
      <div class="col-2">
        <router-link class="c-link" :to="`/account/${withdrawal.account.uuid}`">
          {{ withdrawal.account.full_name }}
        </router-link>
      </div>
      <div class="col-2" v-text="withdrawal.reference" />
      <div
        class="col-2"
        v-text="withdrawal.currency.symbol + convertToPounds(withdrawal.value)"
      />
      <div class="col-2" v-text="capitaliseFirstLetter(withdrawal.status)" />
      <div class="col-2">
        <div v-if="withdrawal.status === 'completed'">
          Completed On: {{ dateTimeFormat(withdrawal.completed_on) }}
        </div>
        <div
          v-else-if="
            withdrawal.status === 'pending' ||
              withdrawal.status === 'processing'
          "
        >
          <div v-if="checkPermission('edit_withdrawals')">
            <div v-if="!showEditForm">
              <a :href="false" class="c-button -admin-small" @click="showForm">
                Update Status
              </a>
            </div>
            <div v-else>
              <form :data-vv-scope="scope" @submit.prevent="updateWithdrawal">
                <div>
                  <v-select-input
                    :scope="scope"
                    :state-key="stateKey"
                    :options="withdrawalStatuses"
                    :center-error="false"
                    field="status"
                    label=""
                    error-name="Select withdrawal status"
                    placeholder="Select withdrawal status"
                    validation="required"
                    :enable-blank-option="false"
                  />
                </div>
                <v-button
                  :loading="updateInProgress"
                  :disabled="updateInProgress"
                  class="-admin-small mt-2"
                >
                  Save
                </v-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import WithdrawalService from "js-admin/classes/AjaxServices/WithdrawalService";

export default {
  props: {
    withdrawal: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      scope: "updateWithdrawalForm",
      stateKey: "updateWithdrawal",
      updateInProgress: false,
      showEditForm: false,
    };
  },

  computed: {
    withdrawalStatuses() {
      const statuses = this.$store.state.config.withdrawal_statuses;
      const withdrawalStatuses = [];

      Object.keys(statuses).forEach((key) => {
        withdrawalStatuses.push({
          value: key,
          name: statuses[key],
        });
      });

      return withdrawalStatuses;
    },
  },

  methods: {
    showForm() {
      this.$parent.$children.forEach((item) => {
        item.showEditForm = false;
      });

      this.showEditForm = !this.showEditForm;

      if (this.showEditForm) {
        this.$store.dispatch("updateStoreItem", {
          value: this.withdrawal.status,
          stateKey: this.stateKey,
          field: "status",
        });
      }
    },

    updateWithdrawal() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          const data = {
            status: this.$store.state.fieldStore[this.stateKey].status,
            uuid: this.withdrawal.uuid,
          };

          this.$toasted.info(
            "<div class='toasted-text-box'> Are you sure you want to update the status of this withdrawal to " +
              this.capitaliseFirstLetter(data.status) +
              "? </div>",
            {
              duration: null,
              action: [
                {
                  text: "Yes",
                  class: "c-button -admin-small",
                  onClick: (e, toastObject) => {
                    WithdrawalService.updateWithdrawalStatus(data)
                      .then(() => {
                        toastObject.goAway(0);
                        this.updateInProgress = false;
                        FlashMessageService.success(
                          "Withdrawal has been updated."
                        );
                        this.showEditForm = false;
                        this.$parent.paginate();
                      })
                      .catch((error) => {
                        toastObject.goAway(0);
                        this.updateInProgress = false;
                        FlashMessageService.error(error.response.data.message);
                      });
                  },
                },
                {
                  text: "No",
                  class: "c-button -admin-small",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                    this.updateInProgress = false;
                  },
                },
              ],
            }
          );
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },
  },
};
</script>
