<template>
  <div class="v-stats">
    <section class="c-section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="c-admin-page-title mb-4">
              {{ companyName }} Admin Dashboard - Forgotten Password
            </h1>

            <div>
              <transition name="fade" mode="out-in">
                <form
                  v-if="!completed"
                  :data-vv-scope="forgottenPasswordForm"
                  @submit.prevent="submitForm"
                >
                  <h3 class="c-admin-page-subtitle mb-4">
                    Enter your account email below to reset your password.
                  </h3>

                  <div>
                    <v-text-input
                      :scope="forgottenPasswordForm"
                      :state-key="stateKey"
                      field="username"
                      label="Email address"
                      error-name="email address"
                      validation="required|email"
                    />
                  </div>

                  <div>
                    <v-button
                      class="mt-3"
                      :loading="submittingData"
                      :disabled="submittingData"
                    >
                      Send password reset link
                    </v-button>
                    <a
                      :href="false"
                      class="c-button -secondary mt-3 ml-4"
                      @click="cancelReset"
                    >
                      Back to login
                    </a>
                  </div>
                </form>

                <div v-else>
                  <h3 class="c-admin-page-subtitle mb-4">
                    We have e-mailed your password reset link.
                  </h3>
                  <p
                    >If we have this email on record you will receive an email
                    shortly</p
                  >
                  <p>Click on the link in your email to reset your password.</p>
                  <a
                    :href="false"
                    class="c-button -secondary"
                    @click="cancelReset"
                  >
                    Back to login
                  </a>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UserService from "js-admin/classes/AjaxServices/UserService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      stateKey: "forgottenPassword",
      forgottenPasswordForm: "forgottenPasswordForm",
      completed: false,
      submittingData: false,
    };
  },

  computed: {
    ...mapGetters({
      companyName: "getCompanyName",
    }),
  },

  methods: {
    cancelReset() {
      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.stateKey,
        field: "username",
      });

      this.$router.push({ name: "login" });
    },
    submitForm() {
      this.$validator
        .validateAll(this.forgottenPasswordForm)
        .then(async (result) => {
          if (result) {
            this.submittingData = true;

            UserService.requestPasswordReset({
              username: this.$store.state.fieldStore[this.stateKey].username,
            })
              .then(() => {
                this.completed = true;
              })
              .catch((error) => {
                FlashMessageService.error(error.response.data.message);
              });
            this.submittingData = false;
          } else {
            setTimeout(() => {
              this.$scrollTo(".c-input-error", 500, { offset: -100 });
            }, 400);
          }
        });
    },
  },
};
</script>
