<template>
  <div class="row">
    <div class="col-12">
      <div class="c-admin-panel">
        <v-accordion :open-first-item="true">
          <v-accordion-item>
            <template v-slot:title>
              {{ title }}
            </template>
            <form :data-vv-scope="scope" @submit.prevent="submit">
              <div class="row d-flex align-items-end">
                <div class="col-6">
                  For date:
                  <DatePicker
                    key="0"
                    v-model="forDate"
                    :mode="mode"
                    class="c-date-picker"
                  />
                </div>
                <div class="col-6">
                  <slot></slot>
                </div>
              </div>
            </form>
          </v-accordion-item>
        </v-accordion>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    DatePicker,
  },
  props: {
    scope: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "Select a date",
    },
  },
  data: () => ({
    currentDate: new Date(),
  }),
  computed: {
    forDate: {
      get() {
        if (this.$store.state.fieldStore[this.scope].for_date == null) {
          return new Date(
            this.currentDate.getUTCFullYear(),
            this.currentDate.getUTCMonth(),
            this.currentDate.getDate()
          );
        }
        return this.$store.state.fieldStore[this.scope].for_date;
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.scope,
          field: "for_date",
        });
      },
    },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
