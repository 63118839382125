import APIService from "js-admin/classes/APIService";
import { prepUrlParameters } from "js-admin/abstract/helpers";

export default class NoteService {
  static CRUD_URL = "notes";

  static async createNote(data) {
    return APIService.request(this.CRUD_URL, "post", data);
  }

  static async updateNote(data) {
    return APIService.request(this.CRUD_URL + "/" + data.uuid, "put", data);
  }

  static async deleteNote(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }

  static async getModelNotes(params, modelType) {
    const extraParams = prepUrlParameters(params);

    var urlPath = "accounts";
    if (modelType === "Application") {
      urlPath = "applications";
    } else if (modelType === "InvestmentAccount") {
      urlPath = "investment-accounts";
    }

    return APIService.request(
      urlPath + "/" + params.uuid + "/notes?" + extraParams
    );
  }
}
