<template>
  <div class="v-reports">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Administration
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div
            v-if="
              (checkPermission('merge_accounts') ||
                checkPermission('create_account') ||
                checkPermission('view_change_requests')) &&
                !systemFeatureIsDisabled([
                  'disabled_merge_accounts',
                  'disabled_create_account',
                  'disabled_change_requests',
                ])
            "
            class="c-admin-panel"
          >
            <div class="c-admin-panel-title">
              Accounts
            </div>
            <div
              v-if="
                !systemFeatureIsDisabled('disabled_merge_accounts') &&
                  checkPermission('merge_accounts')
              "
              class="mt-3"
            >
              <div
                class="c-admin-menu-item -clickable"
                @click="$router.push({ name: 'account-merge' })"
              >
                <i class="fas fa-object-group" /> Merge Accounts
              </div>
            </div>
            <div
              v-if="
                !systemFeatureIsDisabled('disabled_create_account') &&
                  checkPermission('create_account')
              "
              class="mt-3"
            >
              <div
                class="c-admin-menu-item -clickable"
                @click="$router.push({ name: 'create-account' })"
              >
                <i class="fas fa-users" /> Create Account
              </div>
            </div>
            <div
              v-if="
                !systemFeatureIsDisabled('disabled_change_requests') &&
                  checkPermission('view_change_requests')
              "
              class="mt-3"
            >
              <div
                class="c-admin-menu-item -clickable"
                @click="$router.push({ name: 'customer-change-requests' })"
              >
                <i class="fas fa-user-edit" /> Customer Change Requests
              </div>
            </div>
          </div>

          <div
            v-if="checkPermission('round_robin_management')"
            class="c-admin-panel"
          >
            <div class="c-admin-panel-title">
              Lead Distribution
            </div>
            <div class="mt-3">
              <div
                class="c-admin-menu-item -clickable"
                @click="$router.push({ name: 'lead-distribution-management' })"
              >
                <i class="fas fa-dove" />
                Lead Distribution Management
              </div>
            </div>
          </div>

          <div class="c-admin-panel">
            <div class="c-admin-panel-title">
              Primary Market Administration
            </div>
            <div class="mt-3">
              <div class="c-admin-menu-item -clickable">
                <a
                  class="c-admin-menu-item-link"
                  :href="primaryMarketUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fa-solid fa-gauge-simple" />
                  Dashboard
                </a>
              </div>
            </div>
            <div v-if="checkPermission('retrigger_drawdown')" class="mt-3">
              <div
                class="c-admin-menu-item -clickable"
                @click="
                  $router.push({ name: 'primary-market-instrument-drawdowns' })
                "
              >
                <i class="fa-solid fa-guitar" />
                Failed Drawdown Instruments
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div v-if="checkPermission('view_withdrawals')" class="c-admin-panel">
            <div class="c-admin-panel-title">
              Withdrawals
            </div>
            <div class="mt-3">
              <div
                class="c-admin-menu-item -clickable"
                @click="$router.push({ name: 'manage-withdrawals' })"
              >
                <i
                  class="fa-solid fa-money-bill-transfer c-admin-menu-item-icon"
                />
                Manage Withdrawals
              </div>
            </div>
          </div>

          <div v-if="checkRole('superadmin')" class="c-admin-panel">
            <div class="c-admin-panel-title">
              Users
            </div>
            <div class="mt-3">
              <div
                class="c-admin-menu-item -clickable"
                @click="$router.push({ name: 'manage-users' })"
              >
                <i class="fas fa-user-tie c-admin-menu-item-icon" />
                Manage Users
              </div>
            </div>

            <div class="mt-3">
              <div
                class="c-admin-menu-item -clickable"
                @click="$router.push({ name: 'roles-permissions' })"
              >
                <i class="fas fa-chess-rook c-admin-menu-item-icon" />
                Roles & Permissions
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    primaryMarketUrl() {
      return this.$store.state.config.primary_market_admin_url;
    },
  },
  mounted() {
    if (
      !this.checkRole([
        "superadmin",
        "admin",
        "senior_account_manager",
        "compliance",
      ])
    ) {
      this.$router.push({ name: "home" });
    }
  },
};
</script>
