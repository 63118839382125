<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2">
        {{ dateTimeFormat(note.created_at) }}
      </div>
      <div class="col-2">
        {{ note.author }}
      </div>
      <div class="col-4">
        <div v-if="!showEditForm">
          <div v-html="note.note" />
          <div v-if="note.investment_account_uuid">
            <small>
              <a
                :href="false"
                class="c-admin-link"
                @click="viewInvAccount(note.investment_account_uuid)"
              >
                View Investment Account
              </a>
            </small>
          </div>
          <div v-if="note.call_uuid">
            <small>
              <span class="c-admin-link">
                Call Notes
              </span>
            </small>
          </div>
          <div v-if="note.task_uuid">
            <small>
              <a
                :href="false"
                class="c-admin-link"
                @click="viewTask(note.task_uuid)"
              >
                View Task
              </a>
            </small>
          </div>
        </div>
        <div v-else>
          <form :data-vv-scope="scope" @submit.prevent="updateNote">
            <div>
              <v-textarea
                :scope="scope"
                :state-key="stateKey2"
                class="mb-3"
                field="note"
                label="Note"
                placeholder="Note"
                validation="required"
              />
            </div>
            <v-button
              :loading="updateInProgress"
              :disabled="updateInProgress"
              class="-admin-small"
            >
              Update
            </v-button>
          </form>
        </div>
      </div>
      <div v-if="canEditNotes" class="col-2 text-center">
        <a :href="false" class="c-button -admin-small" @click="showForm">
          Edit
        </a>
      </div>
      <div v-if="canDeleteNotes" class="col-2 text-center">
        <a
          :href="false"
          class="c-button -admin-small"
          @click="deleteNote(note.uuid)"
        >
          Delete
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    note: {
      required: true,
    },
    index: {
      required: true,
    },
    stateKey: {
      required: true,
    },
  },

  data() {
    return {
      scope: "updateNoteForm",
      stateKey2: "updateNote",
      updateInProgress: false,
      showEditForm: false,
    };
  },

  computed: {
    canEditNotes() {
      if (!this.note.author_uuid) {
        return false;
      } else if (this.note.author_uuid !== this.$store.state.user.uuid) {
        return false;
      }
      return this.checkPermission("edit_notes");
    },
    canDeleteNotes() {
      if (!this.note.author_uuid) {
        return false;
      } else if (this.note.author_uuid !== this.$store.state.user.uuid) {
        return false;
      }
      return this.checkPermission("delete_notes");
    },
  },

  methods: {
    showForm() {
      this.$parent.$children.forEach((item) => {
        item.showEditForm = false;
      });

      this.showEditForm = !this.showEditForm;

      if (this.showEditForm) {
        this.$store.dispatch("updateStoreItem", {
          value: this.note,
          stateKey: this.stateKey2,
          field: null,
        });
      }
    },

    updateNote() {
      if (!this.canEditNotes) {
        return;
      }

      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          const data = {
            note: this.$store.state.fieldStore[this.stateKey2].note,
            uuid: this.$store.state.fieldStore[this.stateKey2].uuid,
          };

          this.updateInProgress = true;
          this.$store.dispatch("updateNote", data).then((response) => {
            this.updateInProgress = false;
            if (response.data) {
              FlashMessageService.success("Note has been updated.");
              this.$parent.$parent.$parent.$parent.paginate();
              this.showEditForm = false;
            }
          });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },

    deleteNote(uuid) {
      if (!this.canDeleteNotes) {
        return;
      }

      if (confirm("Are you sure you want to delete this note?")) {
        this.$store.dispatch("deleteNote", uuid).then((response) => {
          if (response.status === 204) {
            FlashMessageService.success("Note has been deleted.");
            this.$parent.$parent.$parent.$parent.paginate();
          }
        });
      }
    },

    viewInvAccount(uuid) {
      this.$router.push({ name: "investment-account", params: { uuid } });
    },

    viewTask(uuid) {
      this.$router.push("/tasks/task-details/" + uuid);
      return false;
    },
  },
};
</script>
