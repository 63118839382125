<template>
  <div class="c-sidebar-container">
    <div class="c-sidebar">
      <img class="c-sidebar-logo" :src="siteLogo" @click="logoClicked" />
      <div v-if="environment !== 'production'" class="c-environment-title">
        {{ capitaliseFirstLetter(environment) }}
      </div>

      <div class="c-sidebar-start">
        <div
          v-if="checkRole(['senior_account_manager', 'account_manager'])"
          class="c-sidebar-am-status"
        >
          <div class="text-center">
            <v-select-input
              scope="updateOwnerStatusScope"
              :state-key="amStatusStateKey"
              :options="this.$store.state.config.owner_phone_statuses"
              :center-error="false"
              field="status"
              validation="required"
              error-name="status"
              placeholder="Status"
              class="-centered mb-2"
              @selectionUpdated="updateAMStatus"
            />
          </div>

          <div
            v-if="!systemFeatureIsDisabled('disabled_phone_system')"
            class="text-center"
          >
            <div class="c-sidebar-queue-details">
              <div class="c-sidebar-queue-details-size">
                {{ calls_in_queue.currentSize }}
              </div>
              Calls in Queue
            </div>
            <button
              :loading="callingQueue"
              :disabled="callingQueue"
              type="button"
              class="c-button -admin-small"
              @click="callQueue"
            >
              Call Queue
            </button>
          </div>
        </div>

        <VSidebarItems v-if="showMenuLinks" :disabled="sidebarDisabled" />
      </div>
      <div v-if="showMenuLinks" class="c-sidebar-end">
        <VHelpdeskModal />
        <VSidebarLogoutButton />
      </div>
    </div>
  </div>
</template>

<script>
import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

import VHelpdeskModal from "js-admin/components/helpdesk/HelpdeskModal.vue";
import VSidebarItems from "./SidebarItems.vue";
import VSidebarLogoutButton from "./SidebarLogoutButton.vue";

export default {
  components: {
    VSidebarItems,
    VSidebarLogoutButton,
    VHelpdeskModal,
  },
  data() {
    return {
      amStatusStateKey: "amStatus",
      callingQueue: false,
      phoneQueueStateKey: "phoneQueueDetails",
      environment: window.apiUrls.environment,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    sidebarDisabled() {
      return this.quickPanelIsOpen || this.fullscreenPanelIsOpen;
    },

    showMenuLinks() {
      return this.$store.state.user.uuid;
    },

    amStatus() {
      return this.$store.state.fieldStore[this.amStatusStateKey].status;
    },

    loggedUserOwnerUuid() {
      if (this.$store.state.user.owner) {
        return this.$store.state.user.owner.uuid;
      }

      return null;
    },
    calls_in_queue: {
      get() {
        return this.$store.state.fieldStore[this.phoneQueueStateKey];
      },
      set(data) {
        this.$store.commit("updatePhoneQueueDetails", data);
      },
    },

    siteLogo() {
      if (this.$store.state.config.company_info.admin_logo) {
        return (
          window.whiteLabelClientPlatformUrl +
          this.$store.state.config.company_info.admin_logo
        );
      }

      return "/images/investup-logo.svg";
    },
  },

  watch: {
    user() {
      if (!this.systemFeatureIsDisabled("disabled_phone_system")) {
        this.getPhoneQueueDetails();
      }
    },
  },

  mounted() {
    if (!this.systemFeatureIsDisabled("disabled_phone_system")) {
      const channel = this.$pusher.subscribe(window.pusher.channel_name);
      channel.bind(
        window.pusher.queue_channel_broadcast,
        ({ queueDetails }) => {
          this.calls_in_queue = queueDetails;
        }
      );
    }
  },
  methods: {
    callQueue() {
      if (!confirm("Are you sure?")) {
        return;
      }

      if (!this.loggedUserOwnerUuid) {
        alert(
          "Your user account is not setup correctly, please contact support."
        );
        return;
      }

      this.callingQueue = true;
      CommunicationService.callQueue({
        owner_uuid: this.loggedUserOwnerUuid,
      }).then((response) => {
        this.callingQueue = false;
      });
    },
    updateAMStatus(val) {
      this.$store.dispatch("updateAMOwner", {
        owner_uuid: this.loggedUserOwnerUuid,
        status: val,
      });

      FlashMessageService.success(
        "Status updated to: " + this.capitaliseFirstLetter(val)
      );
    },
    logoClicked() {
      if (this.$store.state.user.uuid) {
        if (this.$route.name !== "home") {
          this.$router.push({ name: "home" });
        }
      } else {
        if (this.$route.name !== "login") {
          this.$router.push({ name: "login" });
        }
      }
    },
    getPhoneQueueDetails() {
      if (this.checkRole(["senior_account_manager", "account_manager"])) {
        CommunicationService.getPhoneQueueDetails().then((response) => {
          if (response.data) {
            this.$store.dispatch("updateStoreItem", {
              value: response.data.data,
              stateKey: this.phoneQueueStateKey,
              field: null,
            });
          }
        });
      }
    },
  },
};
</script>
