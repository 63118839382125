<template>
  <div class="c-file-input">
    <label v-if="label" class="c-file-input-label" v-text="label" />

    <input
      ref="file"
      class="c-file-input-field"
      type="file"
      multiple
      @change="handleFilesUpload"
    />
    <v-button
      class="c-button -admin-small"
      type="button"
      @click.native="$refs.file.click"
    >
      <span v-text="buttonText" />
    </v-button>
    <div class="c-file-input-name">
      <i v-text="fileName" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      required: true,
    },
    stateKey: {
      required: true,
    },
    scope: {
      default: "",
    },
    label: {
      default: "",
    },
  },

  computed: {
    buttonText() {
      return this.$store.state.fieldStore[this.stateKey][this.field]
        ? "Update file"
        : "Select file";
    },

    fileName() {
      const file = this.$store.state.fieldStore[this.stateKey][this.field];

      return file ? file.name : "No file added";
    },
  },

  methods: {
    handleFilesUpload() {
      if (this.$refs.file.files.length == 1) {
        this.$store.dispatch("updateStoreItem", {
          value: this.$refs.file.files[0],
          stateKey: this.stateKey,
          field: this.field,
        });
      }
    },
  },
};
</script>
