<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2">
        {{ line.reference }}
      </div>
      <div class="col-2">
        <div v-if="line.account_name">
          {{ line.account_name }}
        </div>
        <div v-if="line.cash_wallet">
          Cash Wallet Reference: {{ line.cash_wallet.reference_number }}
        </div>
        <div v-if="line.investment_account">
          Cash Wallet Reference: {{ line.investment_account.reference_number }}
        </div>
      </div>
      <div class="col-3">
        {{ currencySymbol }}{{ convertToPounds(line.total_capital_payment) }}
      </div>
      <div class="col-3">
        {{ currencySymbol }}{{ convertToPounds(line.total_interest_payment) }}
      </div>
      <div class="col-2">
        {{ capitaliseFirstLetter(line.status) }}
        <div v-if="line.processed_on">
          Processed On:
          {{ dateTimeFormat(line.processed_on) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    line: {
      required: true,
    },
    currencySymbol: {
      required: false,
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
