export default {
  getTaskOwner: (state) => {
    if (typeof state.taskState.taskOwner !== "undefined") {
      return state.taskState.taskOwner;
    }
    return {};
  },
  getOwnerTaskData: (state) => {
    if (typeof state.taskState.ownerTaskData !== "undefined") {
      return state.taskState.ownerTaskData;
    }
    return [];
  },
  getSelectedTask: (state) => {
    if (
      typeof state.taskState.selectedTask !== "undefined" &&
      state.taskState.selectedTask != null
    ) {
      return state.taskState.selectedTask;
    }
    return {};
  },
  getPriorityListAsArray: (state) => {
    if (
      typeof state.taskState.taskPriorityListCached !== "undefined" &&
      state.taskState.taskPriorityListCached != null
    ) {
      return state.taskState.taskPriorityListCached;
    }
    return [];
  },
  getStatusListAsArray: (state) => {
    if (
      typeof state.taskState.taskStatusListCached !== "undefined" &&
      state.taskState.taskStatusListCached != null
    ) {
      return state.taskState.taskStatusListCached;
    }
    return [];
  },
  getCreatedTask: (state) => {
    return state.fieldStore.task;
  },
};
