<template>
  <div class="v-profile">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Fee Report
          </h1>
        </div>
      </div>

      <!-- Search -->
      <v-date-search
        ref="searchDate"
        :scope="reportScope"
        :mode="mode"
        :submit-results="submitResults"
        @get-results="getResults"
      />

      <!-- CSV Export -->
      <v-single-date-search
        v-if="reportData"
        ref="singleSearchDate"
        title="Generate new report"
        :scope="reportScope"
        :mode="mode"
      >
        <v-generate-report
          class="mt-4"
          @start-generate-report="handleGenerate('Generating...')"
        />
      </v-single-date-search>

      <!-- Full Report -->
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header -bold">
              <div class="row">
                <div class="col-2">
                  Date
                </div>
                <div class="col-4">
                  Name
                </div>
                <div class="col-1">
                  Type
                </div>
                <div class="col-2">
                  Author Name
                </div>
                <div class="col text-center">
                  Download Url
                </div>
              </div>
            </div>
            <div v-if="!submitResults">
              <div v-if="reportResults">
                <div
                  v-for="data in reportResults"
                  :key="data.uuid"
                  class="c-admin-table-row"
                >
                  <div class="row">
                    <div class="col-2">
                      {{ dateTimeFormat(data.created_at) }}
                    </div>
                    <div class="col-4">
                      {{ data.name }}
                    </div>
                    <div class="col-1">
                      {{ data.type }}
                    </div>
                    <div class="col-2">
                      {{ data.author_name }}
                    </div>
                    <div class="col-3 text-center">
                      <a
                        v-if="data.download_url"
                        :href="data.download_url"
                        class="c-admin-link"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="c-admin-table-row">
                <div class="row">
                  <div class="col-12">
                    No results found.
                  </div>
                </div>
              </div>
              <div class="c-admin-table-footer -bold">
                <div class="row">
                  <div class="col-10 text-right">
                    Total:
                  </div>
                  <div class="col-2">
                    {{ reportTotal }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  <v-loader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CSV Export -->
      <v-generate-report
        v-if="reportData"
        class="mt-4"
        @start-generate-report="() => handleGenerate('Generating...')"
      />
    </section>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VDateSearch from "js-admin/components/Search/DateSearch";
import VSingleDateSearch from "js-admin/components/Search/SingleDateSearch";
import VGenerateReport from "js-admin/components/generate-report/GenerateReport";
import mixinReport from "js-admin/mixins/mixinReport";
import ReportService from "js-admin/classes/AjaxServices/ReportService";
import ReportingService from "js-admin/classes/AjaxServices/ReportingService";

export default {
  components: {
    VGenerateReport,
    VDateSearch,
    VSingleDateSearch,
  },
  mixins: [mixinReport],
  data() {
    return {
      reportScope: "reportSearch",
      reportStateKey: "reportSearch",
      reportData: null,
      submitResults: false,
      currentDate: new Date(),
      mode: "single",
    };
  },
  computed: {
    reportResults() {
      return this.reportData;
    },
    reportTotal() {
      let total = 0;
      if (this.reportResults) {
        total = this.reportResults.length;
      }
      return total;
    },
  },
  mounted() {
    if (!this.checkPermission("reporting_level2")) {
      return this.$router.push({ name: "home" });
    }
  },
  methods: {
    generateReport() {
      const { forDate } = this.$refs.singleSearchDate;
      const data = this.formatDateData(forDate, forDate);

      return ReportingService.generateFeeReport(data);
    },
    async getResults(e = null) {
      this.submitResults = true;
      const data = this.formatDateData(
        this.$refs.searchDate.fromDate,
        this.$refs.searchDate.toDate
      );
      data.type = "fee";

      return ReportService.getReportsList(data)
        .then((response) => {
          this.submitResults = false;
          if (response.status === 204) {
            this.reportData = [];
            return;
          }

          this.reportData = response.data.data;
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },
  },
};
</script>
