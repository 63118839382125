<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2">
        {{ instrument.ticker }}
      </div>
      <div class="col-4">
        {{ instrument.name }}
      </div>
      <div class="col-2">
        {{ capitaliseFirstLetter(instrument.status) }}
      </div>
      <div class="col-2">
        {{ instrument.holding }}
      </div>
      <div class="col-2">
        {{ instrument.currency.symbol }}{{ convertToPounds(instrument.value) }}
        <div v-if="instrument.value_date">
          at {{ dateFormat(instrument.value_date) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    instrument: {
      required: true,
    },
  },
};
</script>
