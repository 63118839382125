<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title>
          Account History ({{ accountHistoryCount }})
        </template>

        <div v-if="dataReady" class="c-admin-panel">
          <div class="c-admin-table">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-3">
                  Date
                </div>
                <div class="col-2">
                  Causer
                </div>
                <div class="col-2">
                  Type
                </div>
                <div class="col-5">
                  Details
                </div>
              </div>
            </div>
            <div v-if="historyLogs.length">
              <div
                v-for="(data, index) in historyLogs"
                :key="index"
                class="c-admin-table-row"
              >
                <div class="row align-items-center">
                  <div class="col-3">
                    {{ dateTimeFormat(data.created_at) }}
                  </div>
                  <div class="col-2 text-break">
                    <div v-if="data.causer">
                      {{ data.causer.name }}
                    </div>
                    <div v-else>
                      System Generated
                    </div>
                  </div>
                  <div class="col-2 text-break">
                    {{ capitaliseFirstLetter(data.type) }}d
                    {{ data.model }}
                  </div>
                  <div class="col-5 text-break">
                    <div v-if="data.model == 'BankDetails'">
                      Hidden for security issues.
                    </div>
                    <div v-else>
                      <div
                        v-if="data.type == 'create' || data.type == 'delete'"
                      >
                        With following data:
                      </div>
                      <div v-if="data.type == 'update'">
                        Changed data:
                      </div>
                      <div v-if="data.details">
                        <div v-for="(field, key) in data.details" :key="key">
                          <span v-if="key">
                            {{ capitaliseFirstLetter(key) }} =
                          </span>
                          <b> {{ field }} </b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              No account history
            </div>
          </div>
          <div v-if="historyLogs.length" class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-model="page"
                  :page-count="pages.length"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VPagination from "vue-plain-pagination";

export default {
  components: {
    VPagination,
  },

  data() {
    return {
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      accountHistory: null,
      accountHistoryCount: null,
      accountUuid: this.$store.state.fieldStore.accountData.uuid,
    };
  },

  computed: {
    historyLogs() {
      return this.accountHistory;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    this.$store
      .dispatch("fetchAccountHistoryLogs", {
        uuid: this.accountUuid,
        return_count: true,
      })
      .then((response) => {
        if (response.data) {
          this.accountHistoryCount = response.data.history_count;
          this.setPages();
        }
      });
  },

  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },

    paginate() {
      this.dataReady = false;
      this.$store
        .dispatch("fetchAccountHistoryLogs", {
          uuid: this.accountUuid,
          start: this.page * this.perPage - this.perPage,
          limit: this.perPage,
        })
        .then((response) => {
          if (response.data) {
            this.accountHistory = response.data;
            this.dataReady = true;
          }
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(
        this.accountHistoryCount,
        this.perPage
      );
    },
  },
};
</script>
