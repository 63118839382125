<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item>
        <template v-slot:title>
          Documents ({{ accountDocumentsCount }})
        </template>

        <VDocumentsForm
          v-if="checkPermission('upload_files')"
          :account-uuid="accountUuid"
          :application-uuid="applicationUuid"
          :investment-account-uuid="investmentAccountUuid"
          :state-key="stateKey"
        />

        <VDocumentsTable :state-key="stateKey" />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VDocumentsTable from "js-admin/components/documents/DocumentsTable.vue";
import VDocumentsForm from "js-admin/components/documents/DocumentsForm.vue";

export default {
  components: {
    VDocumentsTable,
    VDocumentsForm,
  },
  props: {
    accountUuid: {
      required: true,
    },
    applicationUuid: {
      default: null,
    },
    investmentAccountUuid: {
      default: null,
    },
    stateKey: {
      required: true,
    },
  },

  computed: {
    accountDocumentsCount() {
      if (this.$store.state.fieldStore[this.stateKey].documents) {
        return this.$store.state.fieldStore[this.stateKey].documents.length;
      } else {
        return 0;
      }
    },
  },
};
</script>
