<template>
  <div class="v-profile">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title"> Total Leads </h1>
        </div>
      </div>
      <!-- Search -->
      <v-date-search
        ref="searchDate"
        :scope="reportScope"
        :mode="mode"
        :submit-results="submitResults"
        @get-results="getResults"
      />

      <v-report-all-table v-if="totalTableLength > 0" :leads="totalTable">
        <template v-slot:title>
          <h3>Leads</h3>
        </template>
      </v-report-all-table>
    </section>
  </div>
</template>

<script>
import VDateSearch from "js-admin/components/Search/DateSearch";
import VReportAllTable from "js-admin/components/layout/tables/reportAllTable";
import mixinReport from "js-admin/mixins/mixinReport";
import ReportingService from "js-admin/classes/AjaxServices/ReportingService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  components: {
    VDateSearch,
    VReportAllTable,
  },
  mixins: [mixinReport],
  data: () => ({
    reportScope: "reportSearch",
    reportStateKey: "reportSearch",
    currentDate: new Date(),
    mode: "single",
    dateRange: [],
    totalTable: [],
    submitResults: false,
  }),
  computed: {
    totalTableLength() {
      if (this.totalTable) {
        return this.totalTable.length;
      }
      return 0;
    },
  },
  mounted() {
    if (
      !this.checkPermission("reporting_level1") ||
      this.systemFeatureIsDisabled("disabled_leads")
    ) {
      return this.$router.push({ name: "home" });
    }
  },
  methods: {
    getDaysArray(start, end) {
      for (
        var arr = [], dt = new Date(start);
        dt <= end;
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    },
    async getResults(e = null) {
      this.dateRange = this.getDaysArray(
        this.$refs.searchDate.fromDate,
        this.$refs.searchDate.toDate
      );

      const data = this.formatDateData(
        this.$refs.searchDate.fromDate,
        this.$refs.searchDate.toDate
      );

      const leads = await ReportingService.getTotalLeadsReport(data).catch(
        (error) => {
          FlashMessageService.error(error.response.data.message);
        }
      );

      const resultsArr = leads.data.data.leads;
      const investmentsArr = leads.data.data.investments;

      this.totalTable = [];

      this.dateRange.forEach((date, i) => {
        const dateLine = this.dateFormat(this.formattedDateForApi(date));

        this.totalTable.push({
          date: dateLine,
          brochure: 0,
          callback: 0,
          contact: 0,
          applications: 0,
          total: 0,
        });

        investmentsArr.forEach((item) => {
          if (item.date === dateLine) {
            this.totalTable[i].applications = item.total;
            this.totalTable[i].total += item.total || 0;
          }
        });

        resultsArr.forEach((item) => {
          if (item.date === dateLine) {
            if (item.type === "contact_form") {
              this.totalTable[i].contact = item.total;
            }
            if (item.type === "callback_request") {
              this.totalTable[i].callback = item.total;
            }
            if (item.type === "brochure_download") {
              this.totalTable[i].brochure = item.total;
            }
            this.totalTable[i].total += item.total || 0;
          }
        });
      });
    },
  },
};
</script>
