<template>
  <div class="v-account">
    <div v-if="instrumentLoadedData">
      <section v-if="!instrument404" class="c-section">
        <div class="row align-items-start">
          <div class="col-lg-7 col-sm-12">
            <h1 class="c-admin-page-title">
              {{ title }}
            </h1>
            <button
              :href="false"
              class="c-button -secondary -sidebar"
              @click="instrumentPage"
            >
              <strong>
                Back to Instruments List
              </strong>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="c-admin-panel mt-2">
              <div class="c-admin-panel-section">
                <h3 class="c-admin-panel-title">
                  Key Instrument Information
                </h3>
                <div class="row">
                  <div class="col-5">
                    <p>
                      Name: <strong>{{ instrument.name }}</strong>
                    </p>
                  </div>
                  <div class="col-7">
                    <p v-if="instrument.type">
                      Type:
                      <strong>
                        {{ stripUnderScoresAndCapitalise(instrument.type) }}
                      </strong>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <p>
                      Ticker (Symbol):
                      <strong>{{ instrument.ticker }}</strong>
                    </p>
                  </div>
                  <div class="col-7">
                    <p>
                      External Id:
                      <strong>{{ instrument.external_id }}</strong>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div v-if="instrument.currency" class="col-5">
                    <p>
                      Base Currency:
                      <strong>{{ instrument.currency.code }}</strong>
                    </p>
                  </div>
                  <div
                    v-if="instrument.trade_currency || instrument.currency"
                    class="col-7"
                  >
                    <p>
                      Trade Currency:
                      <strong>
                        {{
                          instrument.trade_currency
                            ? instrument.trade_currency.code
                            : instrument.currency.code
                        }}
                      </strong>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div v-if="instrument.source" class="col-5">
                    <p>
                      Source:
                      <strong>
                        {{ capitaliseFirstLetter(instrument.source) }}
                      </strong>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <p>
                      Status:
                      <strong>
                        {{ capitaliseFirstLetter(instrument.status) }}
                      </strong>
                    </p>
                  </div>
                  <div class="col-7">
                    <div v-if="instrument.claimable_on">
                      <small>
                        Made Claimable On:
                        <strong>{{
                          dateTimeFormat(instrument.claimable_on)
                        }}</strong>
                      </small>
                    </div>
                    <p v-if="instrument.live_on">
                      <small>
                        Made Live On:
                        <strong>{{
                          dateTimeFormat(instrument.live_on)
                        }}</strong>
                      </small>
                    </p>
                  </div>
                </div>
                <div v-if="instrument.cancelled_on" class="row">
                  <div class="col-12">
                    <h3 class="c-admin-alert-text -error">
                      Instrument has been cancelled. <br />
                    </h3>
                  </div>
                </div>
                <div v-else-if="instrument.is_paused" class="row">
                  <div class="col-12">
                    <h3 class="c-admin-alert-text -error">
                      Instrument has been paused. <br />
                      Reason - {{ instrument.paused_reason }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="instrument.unit_price" class="c-admin-panel mt-2">
              <div class="c-admin-panel-section">
                <h3 class="c-admin-panel-title">
                  Latest Unit Price
                </h3>
                <div class="row">
                  <div class="col-12">
                    <strong>
                      {{ instrument.currency.symbol
                      }}{{ convertToPounds(instrument.unit_price) }}
                    </strong>
                    <small>
                      as of {{ dateFormat(instrument.unit_price_date) }}
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div class="c-admin-panel mt-2">
              <div class="c-admin-panel-section">
                <h3 class="c-admin-panel-title">
                  Instrument Details
                </h3>
                <div class="row">
                  <div v-if="instrument.instrument_number" class="col-5">
                    <p>
                      Instrument Number:
                      <strong>
                        {{ instrument.instrument_number }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.cfi_code" class="col-5">
                    <p>
                      CFI Code:
                      <strong>
                        {{ instrument.cfi_code }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.investor_category" class="col-5">
                    <p>
                      Instrument Client Category:
                      <strong>
                        {{
                          stripUnderScoresAndCapitalise(
                            instrument.investor_category
                          )
                        }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.instrument_category" class="col-5">
                    <p>
                      Instrument Category:
                      <strong>
                        {{
                          stripUnderScoresAndCapitalise(
                            instrument.instrument_category
                          )
                        }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.instrument_sub_category" class="col-5">
                    <p>
                      Instrument Sub-Category:
                      <strong>
                        {{
                          stripUnderScoresAndCapitalise(
                            instrument.instrument_sub_category
                          )
                        }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.exchange" class="col-5">
                    <p>
                      Exchange:
                      <strong>
                        {{ stripUnderScoresAndCapitalise(instrument.exchange) }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.exchange_market_place" class="col-5">
                    <p>
                      Exchange Market Place:
                      <strong>
                        {{
                          capitaliseFirstLetter(
                            instrument.exchange_market_place
                          )
                        }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.platform" class="col-5">
                    <p>
                      Platform:
                      <strong>
                        {{ stripUnderScoresAndCapitalise(instrument.platform) }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.isin" class="col-5">
                    <p>
                      ISIN:
                      <strong>
                        {{ capitaliseFirstLetter(instrument.isin) }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.mic" class="col-5">
                    <p>
                      MIC:
                      <strong>
                        {{ capitaliseFirstLetter(instrument.mic) }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.distributions" class="col-5">
                    <p>
                      Distributions:
                      <strong>
                        {{ capitaliseFirstLetter(instrument.distributions) }}
                      </strong>
                    </p>
                  </div>
                  <div v-if="instrument.market_cap" class="col-5">
                    <p>
                      Market Cap:
                      <strong>
                        {{ instrument.market_cap }}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!instrument.issuer">
              <div class="c-admin-panel mt-2">
                <div class="c-admin-panel-section">
                  <h3 class="c-admin-panel-title">
                    Instrument does not have linked Issuer (company)
                  </h3>
                  <div class="row">
                    <div :class="linkIssuerSectionsClass">
                      <p>
                        Please use the button below to create a new issuer.
                      </p>
                      <v-button
                        class="c-button -admin-small mb-2"
                        @click.native="addIssuer(instrument.uuid)"
                      >
                        + Add Issuer
                      </v-button>
                    </div>
                    <div :class="linkIssuerSectionsClass">
                      <p>
                        or link to an existing issuer using the following
                      </p>
                      <form
                        :data-vv-scope="linkIssuerScope"
                        @submit.prevent="linkToIssuer"
                      >
                        <v-select-input
                          :scope="linkIssuerScope"
                          :state-key="stateKey2"
                          :options="issuers"
                          :center-error="false"
                          field="issuer_uuid"
                          validation="required"
                          error-name="issuer"
                          placeholder="Select an issuer"
                          class="mb-4"
                          :enable-blank-option="true"
                        />

                        <v-button
                          class="c-button -admin-small mb-2"
                          :loading="loading"
                          :disbaled="loading"
                        >
                          Link to Issuer
                        </v-button>
                      </form>
                    </div>
                    <div
                      v-if="instrument.primary_market_instrument"
                      :class="linkIssuerSectionsClass"
                    >
                      <p>
                        or create issuer using Primary Market client details
                      </p>
                      <v-button
                        class="c-button -admin-small mb-2"
                        @click.native="
                          linkToClient(
                            instrument.uuid,
                            instrument.primary_market_instrument.uuid
                          )
                        "
                      >
                        + Create Issuer from PM Client
                      </v-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="c-admin-panel mt-2">
                <div class="c-admin-panel-section">
                  <h3 class="c-admin-panel-title">
                    Issuer
                  </h3>
                  <div class="row">
                    <v-button
                      class="c-button -admin-small mb-2"
                      @click.native="viewIssuer(instrument.issuer.uuid)"
                    >
                      View Issuer {{ instrument.issuer.name }}
                    </v-button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!hasPMInstrument" class="c-admin-panel mt-2">
              <div class="c-admin-panel-section">
                <h3 class="c-admin-panel-title">
                  Actions:
                </h3>
                <div class="row">
                  <div v-if="allocationsCount" class="mr-3">
                    <v-button
                      class="c-button -admin-small mb-2"
                      type="button"
                      @click.native="viewAllocations(instrument.uuid)"
                    >
                      View Share Allocations
                    </v-button>
                  </div>
                  <div v-if="instrument.status === 'pending'" class="mr-3">
                    <v-button
                      v-if="instrument.issuer && allocationsCount"
                      class="c-button -admin-small mb-2"
                      type="button"
                      :disabled="
                        makeClaimableReady ||
                          instrument.cancelled_on ||
                          instrument.is_paused
                      "
                      :loading="makeClaimableReady"
                      @click.native="makeClaimable(instrument.uuid)"
                    >
                      Allow Shareholders to claim shares
                    </v-button>
                    <div v-else class="c-input-error scroll-error -center mt-2">
                      {{ errorMsg }}
                    </div>
                  </div>
                  <div v-if="instrument.status === 'claimable'" class="mr-3">
                    <v-button
                      class="c-button -admin-small mb-2"
                      type="button"
                      :disabled="
                        postInstrumentReady ||
                          instrument.cancelled_on ||
                          instrument.is_paused
                      "
                      :loading="postInstrumentReady"
                      @click.native="postInstrument(instrument.uuid)"
                    >
                      Post Instrument
                    </v-button>
                  </div>
                  <div v-if="instrument.cancelled_on" class="row">
                    <div class="col-12">
                      <h3 class="c-admin-alert-text -error">
                        Disabled because instrument is cancelled. <br />
                      </h3>
                    </div>
                  </div>
                  <div v-else-if="instrument.is_paused" class="row">
                    <div class="col-12">
                      <h3 class="c-admin-alert-text -error">
                        Disabled because instrument is paused. <br />
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <PrimaryMarketInfo
              v-if="hasPMInstrument"
              :instrument="instrument.primary_market_instrument"
            />
          </div>
        </div>
      </section>
      <div v-else>
        <section class="c-section">
          <div class="row">
            <div class="col-12">
              <h1 class="c-admin-page-title">
                Instrument Not Found
              </h1>
              <div class="mt-4">
                Please use the buttons below to go back to the instrument list.
              </div>
              <div class="mt-4">
                <a
                  :href="false"
                  class="c-button -admin-small"
                  @click="instrumentPage"
                >
                  Back to Instruments List
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import InstrumentService from "js-admin/classes/AjaxServices/InstrumentService";
import IssuerService from "js-admin/classes/AjaxServices/IssuerService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import PrimaryMarketInfo from "js-admin/components/instrument/primary-market/PrimaryMarketInfo.vue";

export default {
  components: { PrimaryMarketInfo },
  data() {
    return {
      scope: "instrumentData",
      linkIssuerScope: "linkIssuerScope",
      stateKey: "instrumentData",
      stateKey2: "linkIssuerForm",
      instrumentDataReady: false,
      instrumentNotFound: false,
      issuers: null,
      loading: false,
      allocationsCount: 0,
      makeClaimableReady: false,
      postInstrumentReady: false,
    };
  },

  computed: {
    instrument() {
      return this.$store.state.fieldStore[this.stateKey];
    },
    instrumentLoadedData() {
      return this.instrumentDataReady;
    },
    instrument404() {
      return this.instrumentNotFound;
    },
    title() {
      if (this.instrument.cancelled_on) {
        return this.instrument.name + " (Cancelled)";
      }
      if (this.instrument.is_paused) {
        return this.instrument.name + " (Paused)";
      }

      return this.instrument.name;
    },
    hasPMInstrument() {
      return this.instrument.primary_market_instrument;
    },
    linkIssuerSectionsClass() {
      if (this.hasPMInstrument) {
        return "col-4";
      }

      return "col-6";
    },
    errorMsg() {
      if (!this.instrument.issuer && !this.allocationsCount) {
        return "Instrument has no Issuer and no Shareholders";
      }

      if (!this.instrument.issuer) {
        return "Instrument has no Issuer";
      }
      if (!this.allocationsCount) {
        return "Instrument has no Shareholders";
      }

      return "";
    },
  },

  async mounted() {
    if (!this.checkPermission("view_issuer")) {
      return this.$router.push({ name: "home" });
    }

    await InstrumentService.getInstrument(this.$route.params.uuid)
      .then((response) => {
        this.$store.dispatch("updateStoreItem", {
          value: response.data.data,
          stateKey: this.stateKey,
          field: null,
        });
      })
      .catch(() => {
        this.instrumentNotFound = true;
      });

    await IssuerService.getIssuers({}).then((response) => {
      this.issuers = response.data.data;
    });

    if (!this.instrumentNotFound && !this.hasPMInstrument) {
      await InstrumentService.getInstrumentAllocations(
        this.$route.params.uuid,
        {
          return_count: true,
        }
      ).then((response) => {
        this.allocationsCount = response.data.data.count;
      });
    }

    this.instrumentDataReady = true;
  },

  methods: {
    addIssuer(uuid) {
      this.$router.push({ name: "create-issuer", params: { uuid } });
      return false;
    },
    viewIssuer(uuid) {
      this.$router.push({ name: "issuer", params: { uuid } });
      return false;
    },
    instrumentPage() {
      this.$router.push({ name: "instruments" });
      return false;
    },
    linkToClient(uuid, instrumentUuid) {
      this.$router.push({
        name: "create-issuer",
        params: { uuid: uuid },
        query: { instrument_uuid: instrumentUuid },
      });
      return false;
    },
    linkToIssuer() {
      this.$validator.validateAll(this.linkIssuerScope).then(async (result) => {
        if (result) {
          this.loading = true;

          InstrumentService.updateInstrumentIssuer(
            this.$route.params.uuid,
            this.$store.state.fieldStore[this.stateKey2].issuer_uuid
          )
            .then((response) => {
              FlashMessageService.success("Issuer has been linked");
              this.$store.dispatch("updateStoreItem", {
                value: response.data.data,
                stateKey: this.stateKey,
                field: null,
              });

              this.$store.dispatch("updateStoreItem", {
                value: null,
                stateKey: this.stateKey2,
                field: "issuer_uuid",
              });
            })
            .catch((error) => {
              FlashMessageService.error(error.response.data.message);
            });
          this.loading = false;
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -150 });
          }, 400);
        }
      });
    },

    makeClaimable(uuid) {
      if (!this.instrument.issuer) {
        return FlashMessageService.error(
          "Please link an issuer to this instrument first."
        );
      }

      if (!this.allocationsCount) {
        return FlashMessageService.error(
          "There are no shareholders for this instrument."
        );
      }

      this.$toasted.info(
        "<div class='toasted-text-box'> This will send an invite email to all shareholders (to those which we have an email address for) to notify them they can register and claim their shares. <br> <br> Are you sure you want to make this instrument claimable? </div>",
        {
          duration: null,
          action: [
            {
              text: "Yes",
              class: "c-button -admin-small",
              onClick: (e, toastObject) => {
                this.makeClaimableReady = true;

                InstrumentService.updateInstrument(uuid, {
                  status: "claimable",
                })
                  .then((response) => {
                    FlashMessageService.success("Instrument has been updated");
                    this.$store.dispatch("updateStoreItem", {
                      value: response.data.data,
                      stateKey: this.stateKey,
                      field: null,
                    });
                  })
                  .catch((error) => {
                    FlashMessageService.error(error.response.data.message);
                  });

                this.makeClaimableReady = true;
                toastObject.goAway(0);
              },
            },
            {
              text: "No",
              class: "c-button -admin-small",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        }
      );
    },
    postInstrument(uuid) {
      this.$toasted.info(
        "<div class='toasted-text-box'> Are you sure you want to post this instrument to ASMX Pro and allocate the approved share holdings to shareholders? </div>",
        {
          duration: null,
          action: [
            {
              text: "Yes",
              class: "c-button -admin-small",
              onClick: (e, toastObject) => {
                this.postInstrumentReady = true;

                InstrumentService.updateInstrument(uuid, {
                  status: "live",
                })
                  .then((response) => {
                    FlashMessageService.success("Instrument has been posted.");
                    this.$store.dispatch("updateStoreItem", {
                      value: response.data.data,
                      stateKey: this.stateKey,
                      field: null,
                    });
                  })
                  .catch((error) => {
                    FlashMessageService.error(error.response.data.message);
                  });

                this.postInstrumentReady = true;
                toastObject.goAway(0);
              },
            },
            {
              text: "No",
              class: "c-button -admin-small",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        }
      );
    },
    viewAllocations(uuid) {
      this.$router.push({ name: "instrument-allocations", params: { uuid } });
      return false;
    },
  },
};
</script>
