<template>
  <div class="v-profile">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            My Profile
          </h1>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="c-admin-panel">
            <div class="c-admin-panel-section">
              <h2 class="c-admin-panel-title">
                My Details
              </h2>

              <div class="row">
                <div class="col-4">
                  Name:
                </div>
                <div class="col-8">
                  {{ user.name }}
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-4">
                  Mobile Number:
                </div>
                <div class="col-8">
                  <form
                    :data-vv-scope="updateMobileNumberScope"
                    @submit.prevent="updateMobileNumber"
                  >
                    <v-phone-number-text-input
                      :scope="updateMobileNumberScope"
                      :state-key="updateMobileNumberStateKey"
                      field="mobile_phone_number"
                      type="tel"
                      label="Mobile Number"
                      error-name="mobile number"
                      autocomplete="tel"
                      :maxlength="13"
                    />
                    <v-button
                      class="c-button -admin-small h-mr-1 mb-3"
                      :loading="updateMobileNumberInProgress"
                      :disabled="updateMobileNumberInProgress"
                    >
                      Update
                    </v-button>
                  </form>
                </div>
              </div>

              <div v-if="user.owner">
                <div class="row mt-3">
                  <div class="col-4">
                    Email Address:
                  </div>
                  <div class="col-8">
                    {{ user.owner.email }}
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-4">
                    Work Number:
                  </div>
                  <div class="col-8">
                    {{ user.owner.formatted_phone_number }}
                  </div>
                </div>
              </div>

              <div
                v-if="
                  !checkRole('compliance') &&
                    !systemFeatureIsDisabled('disabled_bcc_email')
                "
                class="c-admin-panel"
              >
                <div class="c-admin-panel-section">
                  <h2 class="c-admin-panel-title">
                    BCC Email Address
                  </h2>

                  <div class="mb-2">
                    <small>
                      Add the following email address to the bcc to get the
                      email recorded automatically on the system.
                    </small>
                  </div>
                  <div>
                    {{ bccEmail }}
                  </div>
                </div>
              </div>

              <VTwoFactorSetup />
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="c-admin-panel">
            <div class="c-admin-panel-section">
              <h2 class="c-admin-panel-title">
                Change Password
              </h2>

              <div class="mb-3">
                Your password must be a minimum of 8 characters
              </div>

              <form
                :data-vv-scope="updatePasswordScope"
                @submit.prevent="updatePassword"
              >
                <div>
                  <VPasswordInput
                    :scope="updatePasswordScope"
                    :state-key="updatePasswordStateKey"
                    field="current_password"
                    label="Current Password"
                    error-name="current password"
                    :placeholder="null"
                    instructions=""
                  />
                </div>

                <div>
                  <VPasswordInput
                    :scope="updatePasswordScope"
                    :state-key="updatePasswordStateKey"
                    field="new_password"
                    label="New Password"
                    error-name="new password"
                    :placeholder="null"
                    instructions=""
                  />
                </div>

                <div>
                  <VPasswordInput
                    :scope="updatePasswordScope"
                    :state-key="updatePasswordStateKey"
                    field="confirm_password"
                    label="Confirm Password"
                    error-name="password"
                    :validation="{ required: true, confirmed: newPassword }"
                    :placeholder="null"
                    instructions=""
                  />
                </div>

                <v-button
                  :loading="updatePasswordInProgress"
                  :disabled="updatePasswordInProgress"
                  class="-admin-small"
                >
                  Change Password
                </v-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AuthService from "js-admin/classes/AuthService";
import UserService from "js-admin/classes/AjaxServices/UserService.js";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VTwoFactorSetup from "js-admin/components/two-factor/TwoFactorSetUp.vue";
import VPasswordInput from "js-admin/components/form/PasswordInput.vue";

export default {
  components: {
    VTwoFactorSetup,
    VPasswordInput,
  },
  data() {
    return {
      amStatusStateKey: "amStatus",
      updatePasswordStateKey: "profileUpdatePassword",
      updatePasswordScope: "updatePasswordScope",
      updatePasswordInProgress: false,
      updateMobileNumberScope: "profileUpdateMobileNumber",
      updateMobileNumberStateKey: "profileUpdateMobileNumber",
      updateMobileNumberInProgress: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    newPassword() {
      return this.$store.state.fieldStore[this.updatePasswordStateKey]
        .new_password;
    },
    amStatus() {
      return this.$store.state.fieldStore[this.amStatusStateKey].status;
    },
    bccEmail() {
      return this.$store.state.config.bcc_inbound_email;
    },
    mobilePhoneNumber() {
      const {
        mobile_phone_number,
        mobile_phone_number_dial_code,
      } = this.$store.state.fieldStore[this.updateMobileNumberStateKey];

      return mobile_phone_number_dial_code + mobile_phone_number;
    },
  },
  mounted() {
    if (this.$store.state.user.mobile_number) {
      this.$store.dispatch("updateStoreItem", {
        value: this.$store.state.user.mobile_number,
        stateKey: this.updateMobileNumberStateKey,
        field: "mobile_phone_number",
      });
    }
  },

  methods: {
    updatePassword() {
      this.$validator.validateAll(this.updatePasswordScope).then((result) => {
        if (result) {
          const data = {
            user_uuid: this.user.uuid,
            password: this.$store.state.fieldStore[this.updatePasswordStateKey]
              .current_password,
            password_new: this.$store.state.fieldStore[
              this.updatePasswordStateKey
            ].new_password,
            password_new_confirmation: this.$store.state.fieldStore[
              this.updatePasswordStateKey
            ].confirm_password,
          };

          this.updatePasswordInProgress = true;
          this.$store
            .dispatch("changeProfilePassword", data)
            .then(() => {
              this.updatePasswordInProgress = false;
              FlashMessageService.success("Your password has been updated.");

              this.$store.dispatch("updateStoreItem", {
                value: {
                  current_password: null,
                  new_password: null,
                  confirm_password: null,
                },
                stateKey: this.updatePasswordStateKey,
                field: null,
              });
              this.clearErrors(200);
            })
            .catch((error) => {
              this.updatePasswordInProgress = false;
              FlashMessageService.error(error.response.data.message);
            });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },

    updateMobileNumber() {
      this.$validator
        .validateAll(this.updateMobileNumberScope)
        .then((result) => {
          if (result) {
            UserService.updateUser({
              uuid: this.user.uuid,
              phone_number: this.mobilePhoneNumber,
            })
              .then((response) => {
                this.updateMobileNumberInProgress = false;
                FlashMessageService.success("Mobile number has been updated");
                AuthService.refreshUser();
              })
              .catch((error) => {
                FlashMessageService.error(error.response.data.message);
              });
          } else {
            setTimeout(() => {
              this.$scrollTo(".c-input-error", 500, { offset: -100 });
            }, 400);
          }
        });
    },
  },
};
</script>
