import store from "js-admin/store/store";
import Vue from "vue";

// imported mutations
import amlMutations from "js-admin/store/mutations/aml-mutations/AmlMutations";
import taskMutations from "js-admin/store/mutations/task-mutations/TaskMutations";
import mergeMutations from "js-admin/store/mutations/merge-mutations/MergeMutations";
import helpdeskMutations from "js-admin/store/mutations/helpdesk-mutations/HelpdeskMutations";
import reportMutations from "js-admin/store/mutations/report-mutations/ReportMutations";

export default {
  setConfig(state, config) {
    if (config) {
      state.config = config;
    }
    // convert priorities/status lists from object to flattened array of objects for select input
    if (state.taskState.taskPriorityListCached.length === 0) {
      for (const i in state.config.task_priorities) {
        state.taskState.taskPriorityListCached.push({
          title: state.config.task_priorities[i],
          value: i,
        });
      }
    }
    if (state.taskState.taskStatusListCached.length === 0) {
      for (const i in state.config.task_statuses) {
        state.taskState.taskStatusListCached.push({
          title: state.config.task_statuses[i],
          value: i,
        });
      }
    }
  },

  setUser(state, user) {
    if (user) {
      Vue.set(state, "user", user);

      if (user.owner) {
        store.dispatch("updateStoreItem", {
          value: user.owner.status,
          stateKey: "amStatus",
          field: "status",
        });
      }
    }
  },

  loggedIn(state, id, dayJsObject) {
    state.me = id;
    state.lastLoginCheck = dayJsObject;
  },

  updateUserField(state, { value, field }) {
    state.user[field] = value;
  },

  updateStore(state, { value, stateKey, field }) {
    if (field) {
      state.fieldStore[stateKey][field] = value;
    } else {
      state.fieldStore[stateKey] = value;
    }
  },

  deleteArrayItemStore(state, { stateKey, field, index }) {
    const array = state.fieldStore[stateKey][field];
    array.splice(index, 1);
  },

  updateArrayItemStore(state, { value, stateKey, field, index }) {
    state.fieldStore[stateKey][field][index] = value;
  },

  updateSubArrayItemStore(
    state,
    { value, stateKey, field, index, subArrayField }
  ) {
    state.fieldStore[stateKey][index][field][subArrayField] = value;
  },

  addToArrayItemStore(state, { value, stateKey, index }) {
    const array = state.fieldStore[stateKey];
    const newArray = [];

    if (array.length) {
      array.forEach((inboundCall) => {
        if (index === inboundCall.call.uuid) {
          newArray.push(value);
        } else {
          newArray.push(inboundCall);
        }
      });
    } else {
      newArray.push(value);
    }

    state.fieldStore[stateKey] = newArray;
  },

  deleteFromArrayItemStore(state, { stateKey, callUuid }) {
    const array = state.fieldStore[stateKey];
    const newArray = [];

    array.forEach((inboundCall) => {
      if (callUuid !== inboundCall.call.uuid) {
        newArray.push(inboundCall);
      }
    });

    state.fieldStore[stateKey] = newArray;
  },

  fullscreenPanelShow(state) {
    state.fullscreenPanel.show = true;
  },

  fullscreenPanelHide(state) {
    state.fullscreenPanel.show = false;
  },

  fullscreenPanelContent(state, content) {
    state.fullscreenPanel.content = content;
  },

  updateLeadCount(state, val) {
    state.fieldStore.leadCount = val;
  },

  updatePhoneQueueDetails(state, val) {
    state.fieldStore.phoneQueueDetails = val;
  },

  updateAccountManagers(state, accountManagers) {
    state.accountManagers = accountManagers;
  },

  updateRoles(state, roles) {
    state.roles = roles;
  },

  storeLoginRedirect(state, loginRedirect) {
    state.loginRedirect = loginRedirect;
  },

  setAddress(state, { value, stateKey }) {
    state.fieldStore[stateKey].primary_address_1 = value.line_1;
    state.fieldStore[stateKey].primary_address_2 = value.line_2;
    state.fieldStore[stateKey].primary_address_town = value.post_town;
    state.fieldStore[stateKey].primary_address_county = value.postal_county;
    state.fieldStore[stateKey].primary_address_postcode = value.postcode;
    state.user.uuid = value;
  },

  setAgents(state, value) {
    state.agents = value;
  },

  ...amlMutations,
  ...taskMutations,
  ...mergeMutations,
  ...helpdeskMutations,
  ...reportMutations,
};
