<template>
  <div class="c-admin-table">
    <div class="c-admin-table-header">
      <div class="row align-items-center">
        <div class="col-2">
          Company Name
        </div>
        <div class="col-2">
          Registration Number
        </div>
        <div class="col-2">
          Legal Entity Identifier
        </div>
        <div class="col-2">
          Country of Incorporation
        </div>
        <div class="col-2">
          Nature of Business
        </div>
        <div v-if="checkPermission('edit_account')" class="col-2 text-center" />
      </div>
    </div>
    <div v-if="companies.length">
      <div
        v-for="(company, index) in companies"
        :key="company.uuid"
        class="c-admin-table-row"
      >
        <VLinkedCompanyRow :company-data="company" :index="index" />
      </div>
    </div>
    <div v-else class="c-admin-table-row">
      No Companies
    </div>
  </div>
</template>

<script>
import VLinkedCompanyRow from "js-admin/components/accounts/companies/LinkedCompanyRow.vue";

export default {
  components: {
    VLinkedCompanyRow,
  },
  props: {
    companies: {
      required: true,
    },
  },
  data() {
    return {};
  },

  computed: {},

  methods: {
    toggle(selectedItem) {
      this.$children.forEach((item) => {
        item.showEditForm = item._uid === selectedItem._uid;
      });
    },
  },
};
</script>
