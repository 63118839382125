import APIService from "js-admin/classes/APIService";

export default class ConfigDataService {
  static async getConfigData() {
    try {
      const { data } = await APIService.request("config-data");

      return data;
    } catch (error) {
      console.log(error.reponse);
    }
  }
}
