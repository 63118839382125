<template>
  <div class="c-notifications-container">
    <div
      v-for="(notification, index) in notificationArr"
      v-show="notificationVisibleArr[index]"
      :ref="'note_' + index"
      :key="'key_' + index"
      class="c-notifications-box"
    >
      <div class="c-notifications-box-container">
        <a
          class="c-notifications-box-container-close-box"
          @click="closeBox(index)"
        >
          <i
            class="c-notifications-box-container-close-box-icon fas fa-times"
          />
        </a>
        <h3 class="">
          <slot name="title">
            {{ notificationOf }}: <strong>{{ notification.owner }}</strong>
          </slot>
        </h3>
        <p>
          <slot name="description">
            {{ notification.task_description }}
          </slot>
        </p>
        <div class="d-flex flex-row">
          <div>
            <a
              v-show="notification.task_uuid"
              class="c-button -admin-small mr-2"
              @click="viewTaskPage(notification.task_uuid)"
            >
              View {{ notificationName }}
            </a>
          </div>
          <div>
            <a
              class="c-button -admin-small mr-2"
              @click="viewAccount(notification.account_uuid)"
            >
              View Account
            </a>
          </div>
          <form
            :scope="notification.task_uuid + '_reminder_extra_time'"
            @submit.prevent="
              extendReminder(
                notification.task_uuid,
                notification.task_uuid + '_reminder_extra_time',
                index
              )
            "
          >
            <div class="c-select-input -reminder-option">
              <div class="c-select-input-group">
                <select
                  :ref="notification.task_uuid + '_reminder_extra_time'"
                  :name="notification.task_uuid + '_reminder_extra_time'"
                  class="c-select-input-field -reminder-option"
                >
                  <option :value="0">
                    Select Time
                  </option>
                  <option
                    v-for="(option, x) in reminderOptions"
                    :key="x"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
                <div class="c-select-input-arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                    <path d="M8 0L6.5 1.5 12.1 7H0v2h12.1l-5.6 5.5L8 16l8-8z" />
                  </svg>
                </div>
              </div>
            </div>
            <v-button>
              Update Task
            </v-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import Vue from "vue";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    notificationName: {
      required: true,
      default: null,
    },
    notificationOf: {
      required: true,
      default: null,
    },
    viewAccountFlag: {
      default: false,
    },
    channelName: {
      required: true,
      default: null,
    },
    channelBroadcast: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      notificationArr: [],
      notificationVisibleArr: [],
      taskDescription: null,
      val: true,
      reminderOptions: [
        {
          label: "10 mins",
          value: 10,
        },
        {
          label: "20 mins",
          value: 20,
        },
        {
          label: "30 mins",
          value: 30,
        },
        {
          label: "1 hour",
          value: 60,
        },
        {
          label: "2 hours",
          value: 120,
        },
      ],
    };
  },
  computed: {
    ownerUserUuid() {
      return this.getUser.uuid;
    },
  },
  watch: {
    ownerUserUuid(n) {
      this.initEvents();
    },
  },
  destroyed() {
    this.$pusher.unsubscribe(this.channelName);
  },
  methods: {
    initEvents() {
      const channel = this.$pusher.subscribe(
        this.channelName + "." + this.ownerUserUuid
      );
      channel.bind(
        this.channelBroadcast + "-" + this.ownerUserUuid,
        (result) => {
          this.notificationArr.push(result);
          this.notificationVisibleArr.push(true);
          const ind = this.notificationVisibleArr.length - 1;
          setTimeout(() => {
            this.openBox(ind);
            this.$store.dispatch("updateTask", {
              uuid: result.task_uuid,
              [result.type]: 1,
            });
          }, 1000);
        }
      );
    },
    viewAccount(uuid) {
      if (this.$router.currentRoute.path !== "/account/" + uuid) {
        this.$router.push({
          name: "account",
          params: {
            uuid,
          },
        });
      }
    },
    // notification animation process
    openBox(ind) {
      if (this.$refs["note_" + ind] != null) {
        gsap.set(this.$refs["note_" + ind], {
          css: {
            opacity: 0,
            y: "100%",
          },
        });
        gsap.to(this.$refs["note_" + ind], {
          duration: 0.3,
          css: {
            opacity: 1,
            y: "0%",
          },
          ease: "elastic.out(1.0, 0.5)",
          onComplete: () => {},
        });
      }
    },
    closeBox(ind) {
      gsap.to(this.$refs["note_" + ind], {
        duration: 0.3,
        css: {
          opacity: 0,
          x: "5%",
        },
        ease: "power3.inOut",
        onComplete: () => {
          Vue.set(this.notificationVisibleArr, ind, false);
        },
      });
    },

    extendReminder(taskUuid, scope, index) {
      const value = this.$refs[scope][0].value;
      if (value * 1) {
        this.$store
          .dispatch("updateTask", {
            uuid: taskUuid,
            extra_reminder_minutes: value,
          })
          .then(() => {
            FlashMessageService.success("Task Updated.");
            this.closeBox(index);
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      }
    },
  },
};
</script>
