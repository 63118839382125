<template lang="html">
  <div class="c-call-stats">
    <v-loader v-if="callStatResults" class="-admin" />
    <div v-else class="c-admin-table">
      <div class="c-admin-table-header -bold">
        <div class="row">
          <div class="col-3">
            Account Manager
          </div>
          <div class="col-3">
            Inbound Calls
          </div>
          <div class="col-3">
            Outbound Calls
          </div>
          <div class="col-3">
            Total Duration
          </div>
        </div>
      </div>
      <div v-if="reportSummary">
        <div v-for="(data, index) in reportSummary" :key="index" class="">
          <div
            class="c-admin-table-row"
            :class="{ 'c-admin-table-footer -bold': index === 'Total' }"
          >
            <div class="row">
              <div class="col-3">
                {{ data.owner_name }}
              </div>
              <div class="col-3">
                {{ data.inbound_calls }}
              </div>
              <div class="col-3">
                {{ data.outbound_calls }}
              </div>
              <div class="col-3">
                <div v-if="data.status !== 'Missed call'">
                  {{ formattedDuration(data.duration) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="c-admin-table-row">
        <div class="row">
          <div class="col-12">
            No results found.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    summaryData: {
      default: null,
    },
    callStatResults: {
      default: false,
    },
  },
  data() {
    return {
      submitResults: false,
    };
  },
  computed: {
    reportSummary() {
      return this.summaryData;
    },
    fromDate() {
      const yesterday = new Date();
      return new Date(yesterday.setDate(yesterday.getDate() - 1));
    },
    toDate() {
      return new Date();
    },
  },
};
</script>
