import APIService from "js-admin/classes/APIService";

export default class RolePermissionService {
  static ROLES_CRUD_URL = "roles";
  static PERMISSIONS_CRUD_URL = "permissions";
  static USERS_CRUD_URL = "users";

  static async getRoles() {
    return APIService.request(this.ROLES_CRUD_URL);
  }

  static async getRolePermissions(role) {
    return APIService.request(
      this.ROLES_CRUD_URL + "/" + role + "/permissions"
    );
  }

  static async setRolePermissions(data) {
    return APIService.request(
      this.ROLES_CRUD_URL + "/" + data.role + "/permissions",
      "post",
      data
    );
  }

  static async getUserPermissions(uuid) {
    return APIService.request(
      this.USERS_CRUD_URL + "/" + uuid + "/permissions"
    );
  }

  static async setUserPermissions(data) {
    return APIService.request(
      this.USERS_CRUD_URL + "/" + data.user_uuid + "/permissions",
      "post",
      data
    );
  }

  static async addNewPermission(data) {
    return APIService.request(this.PERMISSIONS_CRUD_URL, "post", data);
  }
}
