import APIService from "js-admin/classes/APIService";

export default class BankDetailsService {
  static CRUD_URL = "bank-details";

  static async updateBankDetails(data) {
    return APIService.request(this.CRUD_URL + "/" + data.uuid, "put", data);
  }

  static async updatePrimaryBankDetails(accountUuid, bankDetailsUuid) {
    return APIService.request(
      "accounts/" +
        accountUuid +
        "/bank-details/" +
        bankDetailsUuid +
        "/primary",
      "post"
    );
  }

  static async deleteBankDetails(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }
}
