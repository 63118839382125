<template lang="html">
  <div>
    <div class="row align-items-center">
      <div class="col-1">
        {{ dateTimeFormat(data.call_started) }}
      </div>
      <div class="col-1">
        <div v-if="data.account_uuid">
          {{ data.account_name }}
        </div>
        <div v-else> Unknown Client </div>
      </div>
      <div class="col-1">
        {{ data.phone_number }}
      </div>
      <div class="col-1">
        <div v-if="data.status !== 'Missed call'">
          {{ formattedDuration(data.duration) }}
        </div>
      </div>
      <div class="col-1">
        {{ data.direction }}
      </div>
      <div class="col-1">
        {{ data.status }}
      </div>
      <div class="col-2">
        {{ data.call_disposition }}
      </div>
      <div class="col-2">
        <div v-if="data.status !== 'Missed call'">
          {{ data.owner_name }}
        </div>
      </div>
      <div class="col-2">
        <div v-if="!recordingUrl && showRecording">
          <a
            v-if="!loadingRecording"
            :href="false"
            class="c-button -admin-small"
            @click="fetchRecording"
          >
            Get recording
          </a>
          <v-loader v-else />
        </div>
      </div>
      <div v-if="recordingUrl" class="col-12 mt-2">
        <audio controls class="c-admin-audio">
          <source :src="recordingUrl" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";

export default {
  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      loadingRecording: false,
      recordingUrl: null,
    };
  },
  computed: {
    showRecording() {
      if (
        this.data.status !== "Missed call" &&
        this.data.status !== "No answer"
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    fetchRecording() {
      if (this.checkPermission("listen_to_recordings")) {
        this.loadingRecording = true;

        CommunicationService.fetchCallRecording(this.data.call_uuid).then(
          (response) => {
            this.loadingRecording = false;
            if (response.data.data) {
              if (response.data.data.url) {
                this.recordingUrl = response.data.data.url;

                FlashMessageService.success(
                  "Recording has been retrieved, you can to listen to the recording."
                );
                return true;
              }
            }

            FlashMessageService.error(
              "There was an error in retrieving the recording. Try again later or contact support."
            );
            return false;
          }
        );
      }
    },
  },
};
</script>
