<template>
  <div class="v-leads-distribution">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title"> Lead Distribution </h1>
        </div>
      </div>
    </section>
    <section class="c-section">
      <div class="row">
        <div class="col-6">
          <h3 class="c-heading-xl">
            Available Unassigned Leads:
            <span class="c-select-tile-title">{{ lead_count }}</span>
          </h3>
          <div>
            <div v-show="lead_count">
              <form @submit.prevent="assignNewLead">
                <v-button :loading="isLoading" :disabled="isLoading">
                  Assign New Lead
                </v-button>
              </form>
            </div>
          </div>
        </div>
        <div
          v-show="lead_count && checkPermission('view_leads_distribution_pot')"
          class="col-6"
        >
          <form @submit.prevent="viewLeadsInPot">
            <v-button :loading="loadingPotLeads" :disabled="loadingPotLeads">
              View Leads In Pot
            </v-button>
          </form>
        </div>
      </div>
    </section>
    <section
      v-if="unassignedLeadsList"
      class="c-section -half-vertical-padding"
    >
      <div class="row">
        <div class="col-12">
          <VAssignedAccountsListing
            :accounts="unassignedLeadsList"
            :hide-account-btn="true"
            title="Leads In Pot"
          />
        </div>
      </div>
    </section>
    <section v-show="newAssignedLead.uuid" class="c-section">
      <div class="row">
        <div class="col-12">
          <h3 class="c-heading-xl"> Newly Assigned Lead/Account Details </h3>
          <div class="c-admin-table">
            <div class="c-admin-table-header">
              <div class="row">
                <div class="col-2"> Type </div>
                <div class="col-2"> Name </div>
                <div class="col-2"> Email Address </div>
                <div class="col-2"> Contact Numbers </div>
                <div class="col-2"> Assigned On </div>
                <div class="col-2"> &nbsp; </div>
              </div>
            </div>
            <div class="c-admin-table-row">
              <div class="row">
                <div class="col-2">
                  {{ newAssignedLead.lead_type }}
                </div>
                <div class="col-2">
                  {{ newAssignedLead.title }} {{ newAssignedLead.first_name }}
                  {{ newAssignedLead.last_name }}
                </div>
                <div class="col-2">
                  <div v-if="newAssignedLead.email" class="text-break">
                    {{ newAssignedLead.email }}
                  </div>
                  <div v-else> N.A. </div>
                </div>
                <div class="col-2">
                  <div v-if="newAssignedLead.primary_contact_number">
                    {{ newAssignedLead.primary_contact_number }}
                  </div>
                  <div v-if="newAssignedLead.secondary_contact_number">
                    {{ newAssignedLead.secondary_contact_number }}
                  </div>
                </div>
                <div class="col-2">
                  {{ newAssignedLead.assigned_on }}
                </div>
                <div class="col-2 text-center">
                  <a
                    :href="false"
                    class="c-button -admin-small"
                    @click="viewAccount(newAssignedLead.uuid)"
                  >
                    View
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="loadingListIsReady" class="c-section -half-vertical-padding">
      <div class="row">
        <div class="col-12">
          <VAssignedAccountsListing :accounts="assignedLeads" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VAssignedAccountsListing from "js-admin/components/layout/tables/AssignedAccountsListing.vue";
import AccountManagerService from "js-admin/classes/AjaxServices/AccountManagerService";
export default {
  components: {
    VAssignedAccountsListing,
  },
  data() {
    return {
      assignedLeadsStateKey: "amAssignedLeads",
      leadCountStateKey: "leadCount",
      assignedLeadStateKey: "assignedLead",
      isLoading: false,
      loadingListIsReady: false,
      loadingPotLeads: false,
      leadsInPot: null,
    };
  },
  computed: {
    lead_count: {
      get() {
        return this.$store.state.fieldStore[this.leadCountStateKey];
      },
      set(count) {
        this.$store.commit("updateLeadCount", count);
      },
    },
    assignedLeads() {
      return this.$store.state.fieldStore[this.assignedLeadsStateKey];
    },
    newAssignedLead() {
      return this.$store.state.fieldStore[this.assignedLeadStateKey];
    },
    unassignedLeadsList() {
      return this.leadsInPot;
    },
    ownerUuid() {
      return this.$store.state.user.owner.uuid;
    },
  },
  watch: {
    assignedLeads(newVal) {
      if (typeof newVal !== "undefined") {
        this.loadingListIsReady = true;
      }
    },
  },
  mounted() {
    if (!this.checkPermission("leads_distribution")) {
      return this.$router.push({
        name: "home",
      });
    }
    const channel = this.$pusher.subscribe(window.pusher.channel_name);
    channel.bind(window.pusher.channel_broadcast, ({ leadCount }) => {
      this.lead_count = leadCount;
    });
    this.getAssignedLeads();
    this.getLeadsCount();
  },
  methods: {
    viewAccount(uuid) {
      this.$router.push({
        name: "account",
        params: {
          uuid,
        },
      });
    },
    assignNewLead() {
      this.isLoading = true;
      AccountManagerService.assignNewLead(this.ownerUuid).then((response) => {
        if (response.data) {
          this.$store.dispatch("updateStoreItem", {
            value: response.data.data,
            stateKey: this.assignedLeadStateKey,
            field: null,
          });
          this.isLoading = false;
          this.getLeadsCount();
          this.getAssignedLeads();
          this.leadsInPot = null;
        }
      });
    },
    viewLeadsInPot() {
      this.loadingPotLeads = true;
      AccountManagerService.getLeadsInPot().then((response) => {
        if (response.data) {
          this.loadingPotLeads = false;
          this.leadsInPot = response.data.data;
        }
      });
    },
    getAssignedLeads() {
      AccountManagerService.getAssignedLeads(this.ownerUuid).then(
        (response) => {
          if (response.data) {
            this.$store.dispatch("updateStoreItem", {
              value: response.data.data,
              stateKey: this.assignedLeadsStateKey,
              field: null,
            });
          }
        }
      );
    },
    getLeadsCount() {
      AccountManagerService.getLeadCount().then((response) => {
        if (response.data) {
          this.$store.dispatch("updateStoreItem", {
            value: response.data.data.accounts_count,
            stateKey: this.leadCountStateKey,
            field: null,
          });
        }
      });
    },
  },
};
</script>
