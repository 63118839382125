import APIService from "js-admin/classes/APIService";
import { prepUrlParameters } from "js-admin/abstract/helpers";

export default class CashWalletService {
  static CRUD_URL = "cash-wallets";

  static async getCashWalletsData(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid);
  }

  static async getTransactions(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid + "/transactions");
  }

  static async getWithdrawals(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid + "/withdrawals");
  }

  static async getPayments(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid + "/bank-transfers");
  }

  static async getAuditLogs(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid + "/audit-logs");
  }

  static async getPrimaryMarketBids(uuid, params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + uuid + "/primary-market-bids?" + extraParams
    );
  }
}
