<template>
  <div>
    <div v-if="checkPermission('add_issuer')" class="c-admin-panel">
      <a :href="false" class="c-button" @click="showRssFeedForm">
        {{ addRssFeedLabel }}
      </a>

      <form
        v-if="showCreateRssForm"
        :data-vv-scope="scope"
        class="c-admin-form mb-2"
        @submit.prevent="createNote"
      >
        <h3 class="c-admin-panel-title">
          Add RSS Feed
        </h3>
        <div class="row">
          <div class="col-12 col-md-5">
            <v-text-input
              :scope="scope"
              :state-key="stateKey"
              class="mb-3"
              field="name"
              label="Title"
              validation="required"
              error-name="RSS feed title"
            />
          </div>
          <div class="col-12 col-md-7">
            <v-text-input
              :scope="scope"
              :state-key="stateKey"
              class="mb-3 -full-width"
              field="url"
              label="URL"
              validation="required"
              error-name="RSS feed URL"
              :always-instructions="true"
              instructions="For example https://feeds.bbci.co.uk/news/rss.xml"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <v-text-input
              :scope="scope"
              :state-key="stateKey"
              class="mb-3"
              type="number"
              field="order"
              label="Order"
              validation="required|numeric|min_value:0"
              error-name="video order"
              :always-instructions="true"
              instructions="Higher the number, shows in carousel earlier"
            />
          </div>
          <div class="col-12 col-md-7" />
        </div>
        <v-button
          :loading="createInProgress"
          :disabled="createInProgress"
          class="-admin-small"
        >
          Add RSS Feed
        </v-button>
      </form>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import IssuerService from "js-admin/classes/AjaxServices/IssuerService";

export default {
  props: {
    issuerUuid: {
      required: true,
    },
  },

  data() {
    return {
      showCreateRssForm: false,
      createInProgress: false,
      scope: "createRssFeed",
      stateKey: "createRssFeed",
    };
  },

  computed: {
    addRssFeedLabel() {
      if (this.showCreateRssForm) {
        return "- Hide Form";
      } else {
        return "+ Add RSS Feed";
      }
    },
  },

  methods: {
    showRssFeedForm() {
      this.showCreateRssForm = !this.showCreateRssForm;
    },

    createNote() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          const data = {
            name: this.$store.state.fieldStore[this.stateKey].name,
            url: this.$store.state.fieldStore[this.stateKey].url,
            order: this.$store.state.fieldStore[this.stateKey].order,
            issuer_uuid: this.issuerUuid,
          };

          this.createInProgress = true;
          IssuerService.createIssuerRssFeed(data).then((response) => {
            this.createInProgress = false;
            if (response.data) {
              FlashMessageService.success("RSS Feed has been created.");

              ["name", "url", "order"].forEach((keyName) => {
                this.$store.dispatch("updateStoreItem", {
                  value: null,
                  stateKey: this.stateKey,
                  field: keyName,
                });
              });

              this.showCreateRssForm = false;
              this.$parent.$parent.$parent.$parent.getRssFeedsCount(true);
            }
          });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -150 });
          }, 400);
        }
      });
    },
  },
};
</script>
