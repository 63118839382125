<template>
  <!-- Start of Delete User -->
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item>
        <template v-slot:title>
          Delete User
        </template>

        <div class="c-admin-panel-section">
          <a :href="false" class="c-button -admin-small" @click="deleteUser">
            Delete
          </a>
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
  <!-- End of Delete User -->
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import AccountManagerService from "js-admin/classes/AjaxServices/AccountManagerService";
import UserService from "js-admin/classes/AjaxServices/UserService";

export default {
  data() {
    return {
      stateKey: "userData",
    };
  },

  computed: {
    userUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
    ownerUuid() {
      return this.$store.state.fieldStore[this.stateKey].owner_uuid;
    },
    accountManagerRole() {
      const role = this.$store.state.fieldStore[this.stateKey].role.name;
      return role === "account_manager" || role === "senior_account_manager";
    },
    confirmationText() {
      var message = "Are you sure you want to delete this user?";
      if (this.accountManagerRole) {
        message +=
          " \nPlease make sure all client accounts have be reassigned before deleting the user.";
      }
      return message;
    },
  },

  methods: {
    async deleteUser() {
      if (this.checkRole("superadmin") && confirm(this.confirmationText)) {
        var response = true;
        if (this.accountManagerRole && this.ownerUuid) {
          response = await this.deleteOwner();
        }

        if (response) {
          UserService.deleteUser(this.userUuid)
            .then(() => {
              FlashMessageService.success("User has been deleted.");
              this.$router.push({ name: "manage-users" });
            })
            .catch((error) => {
              FlashMessageService.error(error.response.data.message);
            });
        }
      }
    },

    deleteOwner() {
      return AccountManagerService.deleteOwner(this.ownerUuid)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
          return false;
        });
    },
  },
};
</script>
