import APIService from "js-admin/classes/APIService";

export default class CommunicationService {
  static CALLS_CRUD_URL = "calls";
  static EMAILS_CRUD_URL = "emails";
  static SECURE_MESSAGES_CRUD_URL = "secure-messages";

  static async makePhoneCall(data) {
    return APIService.request(
      this.CALLS_CRUD_URL + "/make-outbound-call",
      "post",
      data
    );
  }

  static async getInProgressCall() {
    return APIService.request(this.CALLS_CRUD_URL + "/call-in-progress");
  }

  static async getCallsWaitingOnQueue() {
    return APIService.request(this.CALLS_CRUD_URL + "/calls-in-queue");
  }

  static async transferPhoneCall(uuid, ownerUUid) {
    return APIService.request(
      this.CALLS_CRUD_URL + "/" + uuid + "/transfer/" + ownerUUid,
      "post"
    );
  }

  static async fetchCallRecording(uuid) {
    return APIService.request(this.CALLS_CRUD_URL + "/" + uuid + "/recording");
  }

  static async callQueue(data) {
    return APIService.request(
      this.CALLS_CRUD_URL + "/call-queue",
      "post",
      data
    );
  }

  static async verifiedPhoneCall(uuid) {
    return APIService.request(
      this.CALLS_CRUD_URL + "/" + uuid + "/verified",
      "post"
    );
  }

  static async getEmail(uuid) {
    return APIService.request(this.EMAILS_CRUD_URL + "/" + uuid);
  }

  static async previewEmail(uuid, data) {
    return APIService.request(
      this.EMAILS_CRUD_URL + "/" + uuid + "/preview",
      "post",
      data
    );
  }

  static async sendEmail(uuid, data) {
    return APIService.request(
      this.EMAILS_CRUD_URL + "/" + uuid + "/send",
      "post",
      data
    );
  }

  static async getSecuredMessage(uuid) {
    return APIService.request(this.SECURE_MESSAGES_CRUD_URL + "/" + uuid);
  }

  static async sendSecureMessage(uuid, data) {
    return APIService.request(
      this.SECURE_MESSAGES_CRUD_URL + "/" + uuid + "/send",
      "post",
      data
    );
  }

  static async previewSecureMessage(uuid, data) {
    return APIService.request(
      this.SECURE_MESSAGES_CRUD_URL + "/" + uuid + "/preview",
      "post",
      data
    );
  }

  static async assignCallToAccount(uuid, accountUuid) {
    return APIService.request(
      this.CALLS_CRUD_URL + "/" + uuid + "/assign/" + accountUuid,
      "post"
    );
  }

  static async updateCallDisposition(uuid, params) {
    return APIService.request(
      this.CALLS_CRUD_URL + "/" + uuid + "/disposition",
      "post",
      params
    );
  }

  static async getPhoneQueueDetails() {
    return APIService.request("/phone-calls/queue-details");
  }
}
