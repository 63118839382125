<template lang="html">
  <div class="c-available-agents">
    <h2 class="c-admin-page-title">Withdrawals</h2>
    <div class="row">
      <div class="col-12">
        <div class="c-admin-table">
          <div class="c-admin-table-header">
            <div class="row">
              <div class="col-6">
                Status
              </div>
              <div class="col-6">
                Total
              </div>
            </div>
          </div>

          <div v-if="withdrawals">
            <div
              v-for="(status, index) in withdrawals"
              :key="index"
              class="c-admin-table-row"
            >
              <div class="row">
                <div class="col-6 text-break">
                  {{ capitaliseFirstLetter(status.status) }}
                </div>
                <div class="col-6 text-break">
                  {{ status.count }}
                </div>
              </div>
            </div>
            <div class="c-admin-table-footer">
              <div class="row">
                <div class="col-6">
                  <strong> Total </strong>
                </div>
                <div class="col-6">
                  <strong> {{ total }} </strong>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="c-admin-table-row">
            No withdrawals requiring attention
          </div>
        </div>
        <a
          v-if="checkPermission('view_withdrawals')"
          :href="false"
          class="c-button -admin-small mt-2 mb-2"
          @click="$router.push({ name: 'manage-withdrawals' })"
        >
          Manage Withdrawals
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import WithdrawalService from "js-admin/classes/AjaxServices/WithdrawalService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  data() {
    return {
      withdrawals: [],
      total: 0,
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      await WithdrawalService.getWithdrawalTotals()
        .then((response) => {
          if (response.data.data) {
            this.withdrawals = response.data.data.results;
            this.total = response.data.data.total;
            return;
          }
          this.withdrawals = [];
          this.total = 0;
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },
  },
};
</script>
