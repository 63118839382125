<template>
  <div class="c-input-group">
    <input
      v-model="value"
      v-validate="validation"
      :name="field"
      :data-vv-as="errorName"
      :maxlength="maxlength"
      autocomplete="shipping postal-code"
      type="hidden"
    />
    <VErrorMsg
      :scope="scope"
      :field="field"
      :focused="focused"
      :center="centerError"
    />
    <aside
      v-show="focused || alwaysInstructions"
      v-if="instructions"
      class="c-input-instructions"
      v-html="instructions"
    />
  </div>
</template>

<script>
import VErrorMsg from "js-admin/components/form/ErrorMsg.vue";

export default {
  components: {
    VErrorMsg,
  },
  inject: ["$validator"],

  props: {
    field: {
      required: true,
    },
    stateKey: {
      required: true,
    },
    focused: {
      required: false,
    },
    type: {
      default: "text",
    },
    validation: {
      default: "required",
    },
    scope: {
      default: "",
    },
    instructions: {
      type: String,
      required: false,
    },
    alwaysInstructions: {
      type: Boolean,
      default: false,
    },
    errorName: {
      default: null,
    },
    maxlength: {
      default: null,
    },
    centerError: {
      default: true,
    },
  },

  computed: {
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
    value: {
      get() {
        return this.$store.state.fieldStore[this.stateKey][this.field];
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: this.field,
        });
      },
    },
  },
  methods: {
    updateValue(value) {
      this.$store.dispatch("updateStoreItem", {
        value: value,
        stateKey: this.stateKey,
        field: this.field,
      });
    },
  },
};
</script>
