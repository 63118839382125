<template>
  <nav>
    <VSidebarItem
      v-if="checkPermission('view_accounts')"
      :disabled="disabled"
      :item="{ text: 'Customer Accounts', route: 'accounts' }"
    />

    <VSidebarItem
      v-if="checkPermission('leads_distribution')"
      :disabled="disabled"
      :item="{ text: 'Lead Distribution', route: 'lead-distribution' }"
    />

    <VSidebarItem
      v-if="
        checkPermission('view_tasks') &&
          !systemFeatureIsDisabled('disabled_tasks')
      "
      :disabled="disabled"
      :item="{ text: 'Tasks', route: 'tasks' }"
    />

    <VSidebarItem
      v-if="
        checkPermission('compliance_view_call_recordings') &&
          !systemFeatureIsDisabled('disabled_phone_system')
      "
      :disabled="disabled"
      :item="{ text: 'Calls & Recordings', route: 'calls-recordings' }"
    />

    <VSidebarItem
      :disabled="disabled"
      :item="{ text: 'SM Instruments', route: 'instruments' }"
    />

    <VSidebarItem
      v-if="
        checkPermission('reporting_level1') &&
          !systemFeatureIsDisabled('disabled_reports')
      "
      :disabled="disabled"
      :item="{ text: 'Reports', route: 'reports' }"
    />

    <VSidebarItem
      :disabled="disabled"
      :item="{ text: 'My Profile', route: 'my-profile' }"
    />

    <VSidebarItem
      v-if="
        checkRole([
          'superadmin',
          'admin',
          'senior_account_manager',
          'compliance',
        ])
      "
      :disabled="disabled"
      :item="{ text: 'Administration', route: 'administration' }"
    />

    <VSidebarItem
      v-if="
        checkRole([
          'superadmin',
          'senior_account_manager',
          'account_manager',
        ]) && !systemFeatureIsDisabled('disabled_support_tickets')
      "
      :disabled="disabled"
      :item="{ text: 'View Support Tickets', route: 'support-status' }"
    />
  </nav>
</template>

<script>
import VSidebarItem from "./SidebarItem.vue";

export default {
  components: {
    VSidebarItem,
  },
  props: {
    disabled: {
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
