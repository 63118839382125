export default {
  taskState: {
    taskOwner: null,
    taskPriorityListCached: [],
    taskStatusListCached: [],
    ownerTaskData: [
      {
        uuid: null,
        priority: null,
        status: null,
        due_date: null,
        description: null,
        created_at: null,
        account: {
          uuid: null,
          first_name: null,
          last_name: null,
          email: null,
          primary_contact_number: null,
          secondary_contact_number: null,
        },
        owner: {
          uuid: null,
          name: null,
          job_title: null,
          email: null,
          phone_number: null,
        },
      },
    ],
    selectedTask: {
      account: {
        email: null,
        first_name: null,
        last_name: null,
        primary_contact_number: null,
        secondary_contact_number: null,
        uuid: null,
      },
      created_at: null,
      description: null,
      due_date: null,
      reminder_date: null,
      priority: null,
      status: null,
      uuid: null,
      owner: {
        email: null,
        job_title: null,
        name: null,
        phone_number: null,
        uuid: null,
      },
    },
  },
};
