<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item>
        <template v-slot:title> AML Checks ({{ amlsCount }})</template>

        <v-amls-table :amls="amls" :amls-count="amlsCount" />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VAmlsTable from "js-admin/components/accounts/amls/AmlsTable";
import AccountService from "js-admin/classes/AjaxServices/AccountService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  components: {
    VAmlsTable,
  },
  props: {
    accountUuid: {
      required: true,
    },
  },

  data() {
    return {
      stateKey: "accountData",
      amls: [],
    };
  },

  computed: {
    amlsCount() {
      if (this.amls) {
        return this.amls.length;
      }
      return 0;
    },
  },

  mounted() {
    return AccountService.getAccountAmls({
      uuid: this.accountUuid,
    })
      .then((response) => {
        if (response.data.data) {
          this.amls = response.data.data;
        }
      })
      .catch((error) => {
        FlashMessageService.error(error.response.data.data.message);
      });
  },
};
</script>
