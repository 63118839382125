<template>
  <!-- Start of System info -->
  <div class="row">
    <div class="col-12">
      <div class="c-admin-panel">
        <h3 class="c-admin-panel-title text-center">
          System Info
        </h3>
        <div class="c-admin-panel-section">
          <div class="row">
            <div class="col-5">
              Created At:
            </div>
            <div class="col-7">
              {{ dateTimeFormat(accountCreateAt) }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              Last Updated At:
            </div>
            <div class="col-7">
              {{ dateTimeFormat(accountLastUpdatedAt) }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              Last Updated By:
            </div>
            <div class="col-7">
              {{ accountLastUpdatedUser }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End of System info -->
</template>

<script>
export default {
  data() {
    return {
      stateKey: "accountData",
    };
  },

  computed: {
    accountCreateAt() {
      return this.$store.state.fieldStore[this.stateKey].created_at;
    },
    accountLastUpdatedAt() {
      return this.$store.state.fieldStore[this.stateKey].last_updated_at;
    },
    accountLastUpdatedUser() {
      if (this.$store.state.fieldStore[this.stateKey].last_updated_user) {
        return this.$store.state.fieldStore[this.stateKey].last_updated_user;
      } else {
        return "N.A.";
      }
    },
  },
};
</script>
