// this is a parital component - // always import into a parent checkbox
component or things like hover wont work

<template>
  <div class="c-checkbox-visual" :class="{ '-active': active }">
    <div class="c-checkbox-visual-check">
      <svg
        id=""
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 448.8 448.8"
        style="enable-background: new 0 0 448.8 448.8;"
        xml:space="preserve"
      >
        <g class="c-checkbox-visual-shape">
          <polygon
            points="142.8,323.85 35.7,216.75 0,252.45 142.8,395.25 448.8,89.25 413.1,53.55 		"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
