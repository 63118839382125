<template>
  <v-modal ref="helpdesk" :overflow-visible="true">
    <template slot="button">
      <button class="c-sidebar-item">
        Contact Support
      </button>
    </template>
    <VHelpdesk />
  </v-modal>
</template>

<script>
import VHelpdesk from "js-admin/router/views/helpdesk/Helpdesk.vue";

export default {
  components: {
    VHelpdesk,
  },
};
</script>
