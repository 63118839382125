<template>
  <div class="c-switch" :class="{ '-active': value }" @click="value = !value">
    <div class="c-switch-ball" />
  </div>
</template>

<script>
export default {
  props: {
    field: {
      required: true,
    },
    stateKey: {
      required: true,
    },
  },

  computed: {
    value: {
      get() {
        return this.$store.state.fieldStore[this.stateKey][this.field];
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: this.field,
        });
        this.$emit("change", val);
      },
    },
  },
};
</script>
