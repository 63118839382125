import Vue from "vue";
import { mapMutations, mapGetters } from "vuex";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default Vue.mixin({
  computed: {
    ...mapGetters(["getCsvExportFlag", "getGenerateReportFlag"]),
  },
  methods: {
    ...mapMutations([
      "setCsvExportFlag",
      "setOverdueTasksOnly",
      "setHideCompletedTasks",
      "setGenerateReportFlag",
    ]),
    forceFileDownload({ response, label }) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${label}.csv`);
      document.body.appendChild(link);
      link.click();
      this.setCsvExportFlag(!this.getCsvExportFlag);
    },
    handleExport() {
      this.getResults(null, true)
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch(() => this.setCsvExportFlag(!this.getCsvExportFlag));
    },
    handleGenerate(
      message = "Report is being generated, you will receive an email once done."
    ) {
      this.generateReport()
        .then(() => {
          FlashMessageService.success(message);
          this.setGenerateReportFlag(!this.getGenerateReportFlag);
          this.getResults();
        })
        .catch(() => this.setGenerateReportFlag(!this.getGenerateReportFlag));
    },
    formatDateData(from, to) {
      return {
        from_date: this.formattedDateForApi(from, "00:00:00"),
        to_date: this.formattedDateForApi(to, "23:59:59"),
      };
    },
  },
});
