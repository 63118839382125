<template>
  <div class="v-manage-users">
    <section v-if="dataReady" class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title mb-5">
            Manage Users
          </h1>
        </div>
      </div>
      <div class="row align-items-end">
        <div class="col-8">
          <div class="mb-3">
            Search: <br />
            Please enter an email address, name or telephone number below.
          </div>
          <form :data-vv-scope="searchScope" @submit.prevent="search">
            <v-text-input
              :scope="searchScope"
              :state-key="searchStateKey"
              field="searchTerm"
              label="Search"
              validation="required"
              error-name="search term"
              class="mr-3 h-mb-1"
            />
            <v-button
              class="c-button -admin-small mb-2"
              :loading="searchInProgress"
              :disabled="searchInProgress"
            >
              Search
            </v-button>

            <a
              v-if="searchPerformed"
              :href="false"
              class="c-button -admin-small ml-2 mb-2"
              @click="clearSearch"
            >
              Clear Search
            </a>
          </form>
        </div>
        <div class="col-4 text-right">
          <a
            :href="false"
            class="c-button -admin-small h-mb-1"
            @click="createNewUser"
          >
            Create User
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header">
              <div class="row">
                <div class="col-3">
                  Name
                </div>
                <div class="col-4">
                  Email Address
                </div>
                <div class="col-3">
                  Role/Permissions
                </div>
                <div class="col-2" />
              </div>
            </div>

            <div v-if="users.length">
              <div
                v-for="(user, index) in users"
                :key="index"
                class="c-admin-table-row"
              >
                <VManageUsersRow :user="user" />
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  No users found
                </div>
              </div>
            </div>

            <div v-if="users.length" class="c-admin-table-footer">
              <div class="row">
                <div class="col-12">
                  <v-pagination
                    v-model="page"
                    :page-count="pages.length"
                    :classes="bootstrapPaginationClasses"
                    :labels="paginationAnchorTexts"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import vPagination from "vue-plain-pagination";
import UserService from "js-admin/classes/AjaxServices/UserService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VManageUsersRow from "js-admin/components/users/ManageUsersRow";

export default {
  components: {
    vPagination,
    VManageUsersRow,
  },
  data() {
    return {
      users: [],
      usersTotal: 0,
      dataReady: false,
      page: 1,
      perPage: 10,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      searchScope: "searchForm",
      searchStateKey: "userSearch",
      searchInProgress: false,
      searchPerformed: false,
      passwordStateKey: "passwordGeneration",
    };
  },

  computed: {
    searchTerm() {
      return this.$store.state.fieldStore[this.searchStateKey].searchTerm;
    },
  },

  watch: {
    page() {
      this.paginate(this.searchTerm);
    },
    users() {
      this.setPages();
    },
  },

  mounted() {
    if (!this.checkRole("superadmin")) {
      return this.$router.push({ name: "home" });
    }

    // reset user data
    this.$store.dispatch("updateStoreItem", {
      value: this.getEmptyUser(),
      stateKey: "userData",
      field: null,
    });

    this.$store.dispatch("updateStoreItem", {
      value: null,
      stateKey: this.passwordStateKey,
      field: "password",
    });

    this.paginate(this.searchTerm);
  },

  created() {},

  methods: {
    paginate(term) {
      this.dataReady = false;

      return UserService.getNonCustomerUsers({
        limit: this.perPage,
        page: this.page,
        term: term,
      })
        .then((response) => {
          if (response.data) {
            this.users = response.data.data;
            this.usersTotal = response.data.meta.total;
            this.dataReady = true;
            return;
          }
          this.users = [];
          this.usersTotal = 0;
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(this.usersTotal, this.perPage);
    },

    search() {
      this.$validator.validateAll(this.searchScope).then((result) => {
        if (result) {
          this.searchPerformed = true;
          if (this.page !== 1) {
            this.page = 1;
          } else {
            this.paginate(this.searchTerm);
          }
        }
      });
    },

    clearSearch() {
      this.searchPerformed = false;

      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.searchStateKey,
        field: "searchTerm",
      });

      this.clearErrors(1);

      if (this.page !== 1) {
        this.page = 1;
      } else {
        this.paginate(this.searchTerm);
      }
    },

    createNewUser() {
      this.$router.push({ name: "create-user" });
    },
  },
};
</script>
