<template>
  <div class="mt-2">
    <div v-if="manualAmlsCount" class="c-admin-table mt-2">
      <div class="c-admin-table-header">
        <div class="row align-items-center">
          <div class="col-3">
            Created
          </div>
          <div class="col-3">
            Decision
          </div>
          <div class="col-2">
            Author
          </div>
          <div class="col-4">
            Notes
          </div>
        </div>
      </div>
      <div
        v-for="(aml, index) in manualAmls"
        :key="aml.uuid"
        class="c-admin-table-row"
      >
        <VManualAmlsRow :manual-aml="aml" :index="index" />
      </div>
    </div>
    <div v-else>
      No linked Manual AMLs.
    </div>
  </div>
</template>

<script>
import VManualAmlsRow from "js-admin/components/accounts/manual-amls/ManualAmlsRow";

export default {
  components: {
    VManualAmlsRow,
  },
  props: {
    manualAmls: {
      required: true,
    },
    manualAmlsCount: {
      required: true,
    },
  },
};
</script>
