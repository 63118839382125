<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item>
        <template v-slot:title> Withdrawals ({{ withdrawalsCount }})</template>
        <div v-if="withdrawalsCount" class="c-admin-table">
          <div class="c-admin-table-header">
            <div class="row align-items-center">
              <div class="col-2">
                Created Date
              </div>
              <div class="col-2">
                Reference
              </div>
              <div class="col-2">
                Value
              </div>
              <div class="col-3">
                Status
              </div>
              <div class="col-3">
                Completed On
              </div>
            </div>
          </div>

          <v-withdrawals-row
            v-for="(withdrawal, index) in withdrawals"
            :key="withdrawal.uuid"
            :withdrawal="withdrawal"
            :index="index"
          />
        </div>
        <div v-else>
          No withdrawals.
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VWithdrawalsRow from "js-admin/components/cash-wallet/withdrawals/WithdrawalsRow.vue";
import CashWalletService from "js-admin/classes/AjaxServices/CashWalletService";

export default {
  components: {
    VWithdrawalsRow,
  },

  data() {
    return {
      withdrawals: [],
    };
  },
  computed: {
    cashWalletUuid() {
      return this.$store.state.fieldStore.cashWalletData.uuid;
    },
    withdrawalsCount() {
      return this.withdrawals.length;
    },
  },
  mounted() {
    CashWalletService.getWithdrawals(this.cashWalletUuid).then((response) => {
      this.withdrawals = response.data.data;
    });
  },
};
</script>
