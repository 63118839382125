<template>
  <div>
    <div class="row align-items-center">
      <div class="col-3">
        {{ allocation.name }}
        <div v-if="allocation.email" class="text-break">
          {{ allocation.email }}
        </div>
      </div>
      <div class="col-2">
        <router-link
          v-if="allocation.account"
          class="c-link"
          :to="`/account/${allocation.account.uuid}`"
        >
          {{ allocation.account.full_name }}
        </router-link>
      </div>
      <div class="col-2">
        {{ allocation.shares }}
      </div>
      <div class="col-2">
        {{ capitaliseFirstLetter(allocation.status) }}
      </div>
      <div class="col-3">
        <div v-if="allocation.status === 'pending'">
          <v-button
            class="c-button -admin-small mb-3"
            :loading="loadingApprove"
            :disabled="submit"
            @click.native="approveAllocation"
          >
            Approve
          </v-button>
          <v-button
            v-if="!rejectionReasonIsOpen"
            class="c-button -admin-small mb-3"
            :disabled="submit"
            @click.native="toggleRejectionReason"
          >
            Reject
          </v-button>
          <div v-else>
            <v-textarea
              :scope="scope"
              :state-key="stateKey"
              field="rejection_reason"
              label="Rejection reason"
              validation="required"
            />
            <v-button
              class="c-button -admin-small mb-3"
              :loading="loadingReject"
              :disabled="submit"
              @click.native="rejectAllocation"
            >
              Reject
            </v-button>
          </div>
        </div>
        <div
          v-if="allocation.status === 'rejected' && allocation.rejection_reason"
        >
          {{ allocation.rejection_reason }}
        </div>
        <div
          v-if="allocation.status === 'allocated' && allocation.allocated_on"
        >
          Allocated On: {{ dateTimeFormat(allocation.allocated_on) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstrumentService from "js-admin/classes/AjaxServices/InstrumentService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    allocation: {
      required: true,
    },
  },
  data() {
    return {
      scope: "allocation",
      stateKey: "allocation",
      rejectionReasonIsOpen: false,
      loadingApprove: false,
      loadingReject: false,
      submit: false,
    };
  },
  methods: {
    approveAllocation() {
      this.loadingApprove = true;
      this.submit = true;

      InstrumentService.approveAllocations(this.allocation.uuid)
        .then((response) => {
          FlashMessageService.success(response.data.message);

          setTimeout(() => {
            this.loadingApprove = false;

            window.location.reload();
          }, 200);
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },
    toggleRejectionReason() {
      this.rejectionReasonIsOpen = !this.rejectionReasonIsOpen;
    },
    rejectAllocation() {
      this.loadingReject = true;
      this.submit = true;

      const data = {
        rejection_reason: this.$store.state.fieldStore[this.scope]
          .rejection_reason,
      };

      InstrumentService.rejectAllocations(this.allocation.uuid, data)
        .then((response) => {
          FlashMessageService.success(response.data.message);

          setTimeout(() => {
            this.loadingReject = false;

            window.location.reload();
          }, 200);
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },
  },
};
</script>
