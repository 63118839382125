<template>
  <v-text-input
    :scope="scope"
    :field="field"
    :validation="validation"
    :label="label"
    :state-key="stateKey"
    maxlength="10"
    :data-vv-as="errorName"
    :date-field="true"
    @change="change"
    @keypress="keypress"
  />
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import mixin_date from "./mixins/mixin_date";

export default {
  mixins: [mixin_date],

  inject: ["$validator"],
  props: {
    field: {
      default: "dob",
    },
    stateKey: {
      required: true,
    },

    validation: {
      default: "required|dob_format|over_18|min:10",
    },
    scope: {
      default: "",
    },
    label: {
      default: "DD/MM/YYYY",
    },
    errorName: {
      default: "Date of Birth",
    },
    disabled: {
      default: false,
    },
  },

  methods: {
    format() {
      let finalVal = null;
      const dob = this.$store.state.fieldStore[this.stateKey][this.field];

      if (dob != null) {
        finalVal = dob.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
      }
      return finalVal;
    },
    change(val) {
      if (
        /^(\d){2}\/(\d){2}\/(\d){2}$/.test(val) ||
        /^(\d){2}(\d){2}(\d){2}$/.test(val)
      ) {
        dayjs.extend(customParseFormat);
        const date = dayjs(val, "DD/MM/YY");

        if (
          date.format("YY") != 19 &&
          date.format("YY") != 20 &&
          date.isValid()
        ) {
          val = date.format("DD/MM/YYYY");
        }
      } else {
        if (/^(\d){2}(\d){2}(\d){4}$/.test(val)) {
          val = this.format();
        }
      }

      this.$store.dispatch("updateStoreItem", {
        value: val,
        stateKey: this.stateKey,
        field: this.field,
      });
    },
  },
};
</script>
