<template>
  <div class="mb-4">
    <a :href="false" class="c-button" @click="showManualTransferForm">
      {{ addManualTransferLabel }}
    </a>
    <div v-if="showCreateForm" class="c-admin-panel">
      <form
        :data-vv-scope="scope"
        class="c-admin-form mb-2"
        @submit.prevent="createManualAml"
      >
        <h3 class="c-admin-panel-title">
          Create Manual AML for {{ accountName }}
        </h3>

        <div class="mb-3">
          <strong> Please note: </strong> This should be only used after you
          have verified and are satisfied with the customer details and
          documents they have provided. <br />
          No additional checks are done by the system. So external checks maybe
          required.<br /><br />
          This tool is available to allow you to update the account so the
          client can continue with their application.
        </div>

        <div>
          <v-textarea
            :scope="scope"
            :state-key="stateKey"
            class="mb-3"
            field="notes"
            label="Notes"
            placeholder="Notes"
            validation=""
          />
        </div>

        <v-button
          class="-admin-small"
          :disabled="createInProgress"
          :loading="createInProgress"
        >
          Submit
        </v-button>
      </form>
    </div>
  </div>
</template>

<script>
import AccountService from "js-admin/classes/AjaxServices/AccountService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    accountUuid: {
      default: null,
    },
  },
  data() {
    return {
      scope: "addManualAml",
      stateKey: "addManualAml",
      showCreateForm: false,
      createInProgress: false,
    };
  },
  computed: {
    accountName() {
      return this.$store.state.fieldStore.accountData.full_name;
    },
    addManualTransferLabel() {
      if (this.showCreateForm) {
        return "- Hide Form";
      } else {
        return "+ Create Manual AML";
      }
    },
  },
  mounted() {},
  methods: {
    showManualTransferForm() {
      this.showCreateForm = !this.showCreateForm;
    },
    createManualAml() {
      this.createInProgress = true;
      const data = {
        account_uuid: this.accountUuid,
        notes: this.$store.state.fieldStore[this.stateKey].notes,
      };

      this.$toasted.info(
        "<div class='toasted-text-box'> Confirm: Are you sure you want to manually override the customer's AML/identity verification status? </div>",
        {
          duration: null,
          action: [
            {
              text: "Yes",
              class: "c-button -admin-small",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
                AccountService.createManualAml(data)
                  .then(() => {
                    this.createInProgress = false;
                    FlashMessageService.success(
                      "Manual AML has been created. The page will reload shortly to update info"
                    );

                    this.showCreateForm = false;

                    setTimeout(() => {
                      window.location.reload();
                    }, 200);
                  })
                  .catch((error) => {
                    toastObject.goAway(0);
                    this.createInProgress = false;
                    FlashMessageService.error(error.response.data.message);
                  });
              },
            },
            {
              text: "No",
              class: "c-button -admin-small",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
                this.createInProgress = false;
              },
            },
          ],
        }
      );
    },
  },
};
</script>
