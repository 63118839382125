export default {
  setTaskOwner(state, value) {
    state.taskState.taskOwner = value;
  },
  setOwnerTaskData(state, value) {
    state.taskState.ownerTaskData = value;
  },
  setSelectedTask(state, value) {
    if (typeof value === "undefined" && value === null) return;
    state.taskState.selectedTask = value;
    state.fieldStore.task.task_name = value.title;
    state.fieldStore.task.task_priority = value.priority;
    state.fieldStore.task.task_status = value.status;
    state.fieldStore.task.task_owner = value.owner.uuid;
    state.fieldStore.task.task_description = value.description;
    state.fieldStore.task.due_date = value.due_date;
    state.fieldStore.task.reminder_date = value.reminder_date;
  },
  updateNotes(state, value) {
    if (state.taskState.selectedTask.notes == null) {
      state.taskState.selectedTask.notes = [];
    }
    state.taskState.selectedTask.notes.splice(0, 0, value);
  },
  resetTaskData(state) {
    state.fieldStore.task.task_name = null;
    state.fieldStore.task.task_priority = null;
    state.fieldStore.task.task_status = null;
    state.fieldStore.task.task_owner = null;
    state.fieldStore.task.task_description = null;
    state.fieldStore.task.due_date = null;
    state.fieldStore.task.reminder_date = null;
  },
};
