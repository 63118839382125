<template>
  <div class="row align-items-center">
    <div class="col-2">
      {{ cashWallets.currency.code }}
    </div>
    <div class="col-2">
      {{ cashWallets.reference_number }}
    </div>
    <div class="col-2">
      {{
        cashWallets.currency.symbol + convertToPounds(cashWallets.total_balance)
      }}
    </div>
    <div class="col-2">
      {{
        cashWallets.currency.symbol +
          convertToPounds(cashWallets.available_balance)
      }}
    </div>
    <div class="col-2">
      {{ hasPendingWithdrawal }}
    </div>
    <div class="col-2 text-center">
      <a
        :href="false"
        class="c-button -admin-small"
        @click="viewCashWallet(cashWallets.uuid)"
      >
        View
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cashWallets: {
      required: true,
    },
  },

  data() {
    return {
      stateKey: "accountData",
    };
  },

  computed: {
    hasPendingWithdrawal() {
      return this.cashWallets.hasPendingWithdrawal ? "Yes" : "No";
    },
  },

  methods: {
    viewCashWallet(uuid) {
      this.$router.push({ name: "cash-wallet", params: { uuid } });
    },
  },
};
</script>
