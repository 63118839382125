import Vue from "vue";

export default class FlashMessageService {
  static success(
    message,
    icon = "check",
    onClick = (e, toastObject) => {
      toastObject.goAway(0);
    }
  ) {
    Vue.toasted.success(message, {
      icon: icon,
      action: {
        icon: "fa-times-circle",
        onClick: onClick,
      },
    });
  }

  static error(message, icon = "exclamation-triangle") {
    Vue.toasted.error(message, {
      icon: icon,
      action: {
        icon: "fa-times-circle",
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    });
  }

  static info(message, options) {
    Vue.toasted.info(message, options);
  }
}
