<template>
  <div class="c-input -textarea" :class="'t-' + field">
    <div class="c-input-group">
      <textarea
        :ref="fieldRef"
        v-model="value"
        v-validate="validation"
        :name="field"
        class="c-input-field"
        :class="{ '-error': hasErrors && !focused, '-value': value }"
        :data-vv-as="errorName"
        :rows="rows"
        :disabled="disabled"
        :maxlength="maxlength"
        @focus="focused = true"
        @blur="focused = false"
      />
      <label
        :for="field"
        class="c-input-label"
        :class="{ '-focus': focused || value }"
        @click="labelClick"
        v-text="label"
      />
      <VValidationIcon
        :has-errors="hasErrors"
        :value="value"
        :focused="focused"
      />
      <VErrorMsg :scope="scope" :field="field" :focused="focused" />
    </div>
  </div>
</template>

<script>
import VValidationIcon from "js-admin/components/form/ValidationIcon.vue";
import VErrorMsg from "js-admin/components/form/ErrorMsg.vue";

export default {
  components: {
    VValidationIcon,
    VErrorMsg,
  },
  inject: ["$validator"],

  props: {
    field: {
      required: true,
    },
    stateKey: {
      required: true,
    },
    label: {
      default: null,
    },
    scope: {
      default: "",
    },
    validation: {
      default: "required",
    },
    errorName: {
      default: null,
    },
    maxlength: {
      default: null,
    },
    rows: {
      default: 3,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    fieldRef() {
      return this.scope + "-" + this.field;
    },
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
    value: {
      get() {
        return this.$store.state.fieldStore[this.stateKey][this.field];
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: this.field,
        });
      },
    },
  },
  methods: {
    labelClick() {
      this.$refs[this.fieldRef].focus();
    },
  },
};
</script>
