<template>
  <div>
    <div class="row align-items-center">
      <div class="col-3">
        {{ instrument.name }}
      </div>
      <div class="col-3">
        {{ instrument.external_id }}
      </div>
      <div class="col-2">
        <div v-if="instrument.type">
          {{ stripUnderScoresAndCapitalise(instrument.type) }}
        </div>
      </div>
      <div class="col-2 text-center">
        {{ capitaliseFirstLetter(instrument.status) }}
      </div>
      <div v-if="checkPermission('view_issuer')" class="col-2 text-center">
        <a
          :href="false"
          class="c-button -admin-small"
          @click="viewInstrument(instrument.uuid)"
        >
          View
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    instrument: {
      required: true,
    },
  },

  data() {
    return {};
  },
  methods: {
    viewInstrument(uuid) {
      this.$router.push({ name: "instrument", params: { uuid } });
      return false;
    },
  },
};
</script>
