<template>
  <div class="mb-4">
    <a :href="false" class="c-button" @click="showManualTransferForm">
      {{ addTransferCorrectionLabel }}
    </a>
    <div v-if="showCreateForm" class="c-admin-panel">
      <form
        :data-vv-scope="scope"
        class="c-admin-form mb-2"
        @submit.prevent="createManualTransfer"
      >
        <h3 class="c-admin-panel-title">
          Add Bank Transfer Correction
        </h3>
        <div>
          <v-amount-input
            ref="TransferAmount"
            :state-key="stateKey"
            :scope="scope"
            field="amount"
            label="Correction Amount"
            validation="required"
            :allow-float="true"
            :allow-negative="true"
            :currency-symbol-override="currency.symbol"
          />
        </div>
        <div>
          <v-textarea
            :state-key="stateKey"
            :scope="scope"
            field="note"
            label="Correction Note"
            validation="required"
            error-name="correction note"
          />
        </div>
        <v-button
          :loading="createInProgress"
          :disabled="createInProgress"
          class="-admin-small"
        >
          Add
        </v-button>
      </form>
    </div>
  </div>
</template>

<script>
import PaymentService from "js-admin/classes/AjaxServices/PaymentService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    bankTransferUuid: {
      required: true,
      type: String,
    },
    currency: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      scope: "createTransferCorrectionForm",
      stateKey: "createTransferCorrectionForm",
      showCreateForm: false,
      createInProgress: false,
    };
  },

  computed: {
    addTransferCorrectionLabel() {
      if (this.showCreateForm) {
        return "- Hide Form";
      }

      return "+ Add Bank Transfer Correction";
    },
  },

  methods: {
    showManualTransferForm() {
      this.showCreateForm = !this.showCreateForm;
    },

    createManualTransfer() {
      this.$validator.validateAll(this.scope).then(async (result) => {
        if (!result) {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -150 });
          }, 400);

          return;
        }

        const data = {
          amount: this.$store.state.fieldStore[this.stateKey].amount,
          note: this.$store.state.fieldStore[this.stateKey].note,
          bank_transfer_uuid: this.bankTransferUuid,
        };

        this.createInProgress = true;

        try {
          await PaymentService.createBankTransferCorrection(data);

          this.createInProgress = false;
          FlashMessageService.success(
            "Bank Transfer Correction has been created. The page will reload shortly to update info"
          );

          this.$store.dispatch("updateStoreItem", {
            value: null,
            stateKey: this.stateKey,
            field: "amount",
          });

          this.$store.dispatch("updateStoreItem", {
            value: null,
            stateKey: this.stateKey,
            field: "note",
          });

          this.showCreateForm = false;

          setTimeout(() => {
            window.location.reload();
          }, 200);
        } catch (error) {
          this.createInProgress = false;
          FlashMessageService.error(error.response.data.message);
        }
      });
    },
  },
};
</script>
