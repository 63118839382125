var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-input",class:['t-' + _vm.field, { '-value': _vm.value }]},[_c('div',{staticClass:"c-input-group"},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"},{name:"validate",rawName:"v-validate",value:(_vm.validation),expression:"validation"}],ref:_vm.fieldRef,staticClass:"c-input-field",class:{
        '-error': _vm.hasErrors && !_vm.focused,
        '-value': _vm.value,
        '-highlight': _vm.placeholder != null && _vm.value == null,
        '-icon': _vm.icon,
        '-uppercase': _vm.uppercaseField,
        '-readonly': _vm.readonly,
      },attrs:{"name":_vm.field,"data-vv-as":_vm.errorName,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"max":_vm.max,"min":_vm.min,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"pattern":_vm.pattern,"readonly":_vm.readonly,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keypress":function($event){return _vm.keypress($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.handleDelete.apply(null, arguments)},"change":[function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}},_vm.handleChange]}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"},{name:"validate",rawName:"v-validate",value:(_vm.validation),expression:"validation"}],ref:_vm.fieldRef,staticClass:"c-input-field",class:{
        '-error': _vm.hasErrors && !_vm.focused,
        '-value': _vm.value,
        '-highlight': _vm.placeholder != null && _vm.value == null,
        '-icon': _vm.icon,
        '-uppercase': _vm.uppercaseField,
        '-readonly': _vm.readonly,
      },attrs:{"name":_vm.field,"data-vv-as":_vm.errorName,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"max":_vm.max,"min":_vm.min,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"pattern":_vm.pattern,"readonly":_vm.readonly,"type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keypress":function($event){return _vm.keypress($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.handleDelete.apply(null, arguments)},"change":[function($event){_vm.value=null},_vm.handleChange]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"},{name:"validate",rawName:"v-validate",value:(_vm.validation),expression:"validation"}],ref:_vm.fieldRef,staticClass:"c-input-field",class:{
        '-error': _vm.hasErrors && !_vm.focused,
        '-value': _vm.value,
        '-highlight': _vm.placeholder != null && _vm.value == null,
        '-icon': _vm.icon,
        '-uppercase': _vm.uppercaseField,
        '-readonly': _vm.readonly,
      },attrs:{"name":_vm.field,"data-vv-as":_vm.errorName,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"max":_vm.max,"min":_vm.min,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"pattern":_vm.pattern,"readonly":_vm.readonly,"type":_vm.type},domProps:{"value":(_vm.value)},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keypress":function($event){return _vm.keypress($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.handleDelete.apply(null, arguments)},"change":_vm.handleChange,"input":function($event){if($event.target.composing)return;_vm.value=$event.target.value}}}),_c('label',{staticClass:"c-input-label",class:{ '-focus': _vm.focused || _vm.value != null },attrs:{"for":_vm.field},domProps:{"textContent":_vm._s(_vm.label)},on:{"click":_vm.labelClick}}),_c('VValidationIcon',{attrs:{"has-errors":_vm.hasErrors,"value":_vm.value,"focused":_vm.focused}}),_c('VErrorMsg',{attrs:{"scope":_vm.scope,"field":_vm.field,"focused":_vm.focused}})],1),(_vm.instructions)?_c('aside',{directives:[{name:"show",rawName:"v-show",value:(_vm.focused || _vm.alwaysInstructions),expression:"focused || alwaysInstructions"}],staticClass:"c-input-instructions",domProps:{"innerHTML":_vm._s(_vm.instructions)}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }