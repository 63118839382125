<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title> RSS Feeds ({{ rssCount }})</template>

        <VCreateRssFeed
          v-if="checkPermission('add_issuer')"
          :issuer-uuid="issuerUuid"
        />

        <div v-if="dataReady" class="c-admin-panel">
          <div v-if="issuerRss" class="c-admin-table">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-2">
                  Date
                </div>
                <div class="col-3">
                  Title
                </div>
                <div class="col-4">
                  URL
                </div>
                <div
                  v-if="checkPermission('delete_issuer')"
                  class="col-3 text-center"
                />
              </div>
            </div>

            <div
              v-for="(rss, index) in issuerRss"
              :key="rss.uuid"
              class="c-admin-table-row"
            >
              <VLinkedRssFeedRow :rss-details="rss" :index="index" />
            </div>

            <div class="c-admin-table-footer">
              <div class="row">
                <div class="col-12">
                  <VPagination
                    v-model="page"
                    :page-count="pages.length"
                    :classes="bootstrapPaginationClasses"
                    :labels="paginationAnchorTexts"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            No rss feeds.
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import IssuerService from "js-admin/classes/AjaxServices/IssuerService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VPagination from "vue-plain-pagination";
import VLinkedRssFeedRow from "js-admin/components/issuers/issuer-rss/LinkedRssFeedRow.vue";
import VCreateRssFeed from "js-admin/components/issuers/issuer-rss/CreateRssFeed.vue";

export default {
  components: {
    VCreateRssFeed,
    VLinkedRssFeedRow,
    VPagination,
  },
  props: {
    issuerUuid: {
      required: true,
    },
  },
  data() {
    return {
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      rssData: null,
      rssCount: 0,
    };
  },
  computed: {
    issuerRss() {
      return this.rssData;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    this.getRssFeedsCount();
  },

  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },

    getRssFeedsCount(forcePagination = false) {
      IssuerService.getIssuerRssFeeds(this.issuerUuid, {
        return_count: true,
      })
        .then((response) => {
          this.rssCount = response.data.data.count;
          this.setPages();
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
      if (forcePagination) {
        this.paginate();
      }
    },

    setPages() {
      this.pages = this.calculatePaginationPages(this.rssCount, this.perPage);
    },

    paginate() {
      this.dataReady = false;
      IssuerService.getIssuerRssFeeds(this.issuerUuid, {
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      }).then((response) => {
        if (response.data.data) {
          this.rssData = null;
          if (response.data.data.length) {
            this.rssData = response.data.data;
          }
          this.dataReady = true;
        }
      });
    },
  },
};
</script>
