var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-input c-phone-number-input",class:[
    't-' + _vm.field,
    {
      '-readonly': _vm.readonly,
      '-value': _vm.value,
      '-disabled': _vm.disabled,
      '-error': _vm.hasErrors && !_vm.focused,
      '-focus': _vm.focused,
    },
  ]},[_c('div',{staticClass:"c-input-group",class:{
      '-error': _vm.hasErrors,
      '-focus': _vm.focused,
    }},[_c('vue-country-code',{attrs:{"preferred-countries":_vm.preferredCountries,"enabled-country-code":true,"disabled-fetching-country":true,"default-country":_vm.countryCode},on:{"onSelect":_vm.countryCodeSelected}}),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"},{name:"validate",rawName:"v-validate",value:(_vm.phoneValidation()),expression:"phoneValidation()"}],ref:_vm.fieldRef,staticClass:"c-input-field",class:{
        '-error': _vm.hasErrors,
        '-value': _vm.value,
        '-readonly': _vm.readonly,
        '-icon': _vm.icon,
        '-uppercase': _vm.uppercaseField,
      },attrs:{"name":_vm.field,"data-vv-as":_vm.errorName,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"aria-label":_vm.label ? _vm.label : 'text input',"readonly":_vm.readonly,"spellcheck":_vm.spellcheck,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keypress":function($event){return _vm.keypress($event)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.handleDelete.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('keyup-enter')}],"change":function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"},{name:"validate",rawName:"v-validate",value:(_vm.phoneValidation()),expression:"phoneValidation()"}],ref:_vm.fieldRef,staticClass:"c-input-field",class:{
        '-error': _vm.hasErrors,
        '-value': _vm.value,
        '-readonly': _vm.readonly,
        '-icon': _vm.icon,
        '-uppercase': _vm.uppercaseField,
      },attrs:{"name":_vm.field,"data-vv-as":_vm.errorName,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"aria-label":_vm.label ? _vm.label : 'text input',"readonly":_vm.readonly,"spellcheck":_vm.spellcheck,"type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keypress":function($event){return _vm.keypress($event)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.handleDelete.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('keyup-enter')}],"change":function($event){_vm.value=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"},{name:"validate",rawName:"v-validate",value:(_vm.phoneValidation()),expression:"phoneValidation()"}],ref:_vm.fieldRef,staticClass:"c-input-field",class:{
        '-error': _vm.hasErrors,
        '-value': _vm.value,
        '-readonly': _vm.readonly,
        '-icon': _vm.icon,
        '-uppercase': _vm.uppercaseField,
      },attrs:{"name":_vm.field,"data-vv-as":_vm.errorName,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"aria-label":_vm.label ? _vm.label : 'text input',"readonly":_vm.readonly,"spellcheck":_vm.spellcheck,"type":_vm.type},domProps:{"value":(_vm.value)},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keypress":function($event){return _vm.keypress($event)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.handleDelete.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('keyup-enter')}],"input":function($event){if($event.target.composing)return;_vm.value=$event.target.value}}}),_c('label',{staticClass:"c-input-label",class:{
        '-focus': _vm.focused || (_vm.value !== null && _vm.value !== '') || _vm.placeholder,
        '-readonly': _vm.readonly,
      },attrs:{"for":_vm.field},domProps:{"textContent":_vm._s(_vm.label)},on:{"click":function($event){this.$nextTick(() => _vm.$refs[_vm.fieldRef].focus)}}}),(_vm.iconVisible)?_c('v-text-input-icons',{attrs:{"has-errors":_vm.hasErrors,"value":_vm.value,"focused":_vm.focused && !_vm.showErrorsOnFocus,"icon":_vm.icon}}):_vm._e(),(_vm.withError)?_c('v-error-msg',{attrs:{"scope":_vm.scope,"field":_vm.field,"focused":_vm.focused && !_vm.showErrorsOnFocus}}):_vm._e(),(_vm.instructions)?_c('aside',{directives:[{name:"show",rawName:"v-show",value:(_vm.focused || _vm.alwaysInstructions),expression:"focused || alwaysInstructions"}],staticClass:"c-input-instructions",domProps:{"innerHTML":_vm._s(_vm.instructions)}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }