<template>
  <div class="v-stats">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Stats
          </h1>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  mounted() {
    if (!this.checkPermission("lead_stats")) {
      return this.$router.push({ name: "home" });
    }
  },
  methods: {},
};
</script>
