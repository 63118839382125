import APIService from "js-admin/classes/APIService";
import { prepUrlParameters } from "js-admin/abstract/helpers";

export default class UserService {
  static CRUD_URL = "users";

  static async getMe() {
    return APIService.request(this.CRUD_URL + "/me");
  }

  static async disableLogin({ uuid, disable_login }) {
    return APIService.request(
      this.CRUD_URL + "/" + uuid + "/disable-account-login",
      "post",
      { disable_login }
    );
  }

  static async requestPasswordReset(username) {
    return APIService.request(
      this.CRUD_URL + "/forgotten-password",
      "post",
      username
    );
  }

  static async verifyResetToken(params) {
    return APIService.request(
      this.CRUD_URL + "/verify-password-reset-token",
      "post",
      params
    );
  }

  static async resetMyPassword(params) {
    return APIService.request(
      this.CRUD_URL + "/reset-users-password",
      "post",
      params
    );
  }

  // User Model
  static async getUserInfo(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "get");
  }

  static async updateAccountUserLogin(data) {
    return APIService.request(
      this.CRUD_URL + "/" + data.uuid + "/update-username",
      "put",
      data
    );
  }

  static async changeProfilePassword(data) {
    return APIService.request(
      this.CRUD_URL + "/" + data.user_uuid + "/update-password",
      "post",
      data
    );
  }

  static async resetUserTwoFactor(uuid) {
    return APIService.request(
      this.CRUD_URL + "/" + uuid + "/disable-two-factor",
      "put"
    );
  }

  static async getNonCustomerUsers(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/list?permission_list=1&" + extraParams
    );
  }

  static async updateUser(data) {
    return APIService.request(this.CRUD_URL + "/" + data.uuid, "put", data);
  }

  static async deleteUser(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }

  static async createUser(data) {
    return APIService.request(this.CRUD_URL + "/", "post", data);
  }
}
