<template lang="html">
  <span
    :class="[
      'c-task-pill',
      {
        '-high': priority == 'High',
        '-medium': priority == 'Normal' || priority == 'normal',
        '-low': priority == 'Low',
      },
    ]"
  >
    {{ priorityText }}
  </span>
</template>

<script>
export default {
  props: {
    priority: {
      required: true,
      default: null,
    },
  },
  computed: {
    priorityText() {
      if (this.priority) {
        return this.capitaliseFirstLetter(this.priority);
      }
      return null;
    },
  },
};
</script>
