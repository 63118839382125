<template>
  <div class="mt-2">
    <div>
      <a
        :href="false"
        class="c-button -admin-small mb-3"
        @click="toggleDocumentForm"
      >
        {{ addDocumentLabel }}
      </a>
    </div>

    <div v-if="showDocumentForm" class="c-admin-form mt-0 mb-3">
      <form :data-vv-scope="scope" @submit.prevent="addDocument">
        <h3 class="c-admin-panel-title">
          Add New Document
        </h3>

        <div class="c-file-input-error">
          <p v-html="errorMsg"></p>
        </div>

        <div>
          <v-select-input
            :scope="scope"
            :state-key="stateKey2"
            :options="fileTypeOptions"
            :center-error="false"
            field="file_type"
            label=""
            validation="required"
            error-name="file type"
            placeholder="Select a file type"
            class="mb-4"
            :enable-blank-option="true"
          />
        </div>
        <div>
          <VFileInput
            v-if="uploadReady"
            :scope="scope"
            :state-key="stateKey2"
            field="document"
            validation="required"
          />
        </div>

        <v-button
          :loading="addInProgress"
          :disabled="addInProgress"
          class="-admin-small"
        >
          Upload Document
        </v-button>
      </form>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VFileInput from "js-admin/components/form/FileInput.vue";

export default {
  components: {
    VFileInput,
  },
  props: {
    stateKey: {
      required: true,
    },
    accountUuid: {
      required: true,
    },
    applicationUuid: {
      default: null,
    },
    investmentAccountUuid: {
      default: null,
    },
  },

  data() {
    return {
      scope: "addDocumentForm",
      stateKey2: "addDocument",
      errorMsg: null,
      showDocumentForm: false,
      addInProgress: false,
      uploadReady: true,
    };
  },

  computed: {
    addDocumentLabel() {
      if (this.showDocumentForm) {
        return "- Hide Form";
      } else {
        return "+ Add Document";
      }
    },

    fileTypeOptions() {
      let options = this.$store.state.config.file_types;

      if (this.applicationUuid || this.investmentAccountUuid) {
        options = options.filter(
          (type) => type !== "ProofOfIdentity" && type !== "ProofOfAddress"
        );
      } else {
        options = options.filter(
          (type) => type !== "PDFApplication" && type !== "ISATransferForm"
        );
      }

      return options;
    },
  },

  methods: {
    resetUploadForm() {
      this.addInProgress = false;
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
    addDocument() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          const formData = new FormData();
          formData.append(
            "file",
            this.$store.state.fieldStore[this.stateKey2].document
          );
          formData.append(
            "type",
            this.$store.state.fieldStore[this.stateKey2].file_type
          );
          formData.append("account_uuid", this.accountUuid);

          if (this.applicationUuid) {
            formData.append("application_uuid", this.applicationUuid);
            formData.append("return_application_documents", 1);
          }

          if (this.investmentAccountUuid) {
            formData.append(
              "investment_account_uuid",
              this.investmentAccountUuid
            );
            formData.append("return_inv_account_documents", 1);
          }

          if (!this.applicationUuid && !this.investmentAccountUuid) {
            formData.append("return_account_documents", 1);
          }

          this.addInProgress = true;

          this.$store
            .dispatch("addDocument", formData)
            .then((response) => {
              this.resetUploadForm();

              if (response.data) {
                FlashMessageService.success("Document has been uploaded.");

                this.$store.dispatch("updateStoreItem", {
                  value: null,
                  stateKey: this.stateKey2,
                  field: "file_type",
                });

                this.$store.dispatch("updateStoreItem", {
                  value: null,
                  stateKey: this.stateKey2,
                  field: "document",
                });

                this.showDocumentForm = false;

                this.$store.dispatch("updateStoreItem", {
                  value: response.data,
                  stateKey: this.stateKey,
                  field: "documents",
                });
              } else {
                return false;
              }
            })
            .catch((error) => {
              this.resetUploadForm();

              FlashMessageService.error(`
              ${error.response.data.message} <br/>
              ${error.response.data.errors?.file.join("<br/>")}
            `);

              this.$store.dispatch("updateStoreItem", {
                value: null,
                stateKey: this.stateKey2,
                field: "file_type",
              });

              this.$store.dispatch("updateStoreItem", {
                value: null,
                stateKey: this.stateKey2,
                field: "document",
              });
            });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -150 });
          }, 400);
        }
      });
    },
    toggleDocumentForm() {
      this.showDocumentForm = !this.showDocumentForm;
    },
  },
};
</script>
