<template>
  <div class="c-admin-panel ref-notes-panel">
    <v-accordion ref="accordion-notes" class="">
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title> Notes ({{ notesCount }}) </template>

        <div v-if="checkPermission('create_notes')">
          <a :href="false" class="c-button" @click="showNoteForm">
            {{ addNoteLabel }}
          </a>
        </div>

        <div
          v-if="showCreateForm && checkPermission('create_notes')"
          class="c-admin-panel"
        >
          <form
            :data-vv-scope="scope"
            class="c-admin-form mb-2"
            @submit.prevent="createNote"
          >
            <h3 class="c-admin-panel-title">
              Add Note
            </h3>
            <div>
              <v-textarea
                :scope="scope"
                :state-key="stateKey2"
                class="mb-3"
                field="note"
                label="Note"
                placeholder="Note"
                validation="required"
              />
            </div>
            <v-button
              v-if="!createInProgress"
              :loading="createInProgress"
              :disabled="createInProgress"
              class="-admin-small"
            >
              Add
            </v-button>
          </form>
        </div>

        <div v-if="dataReady" class="c-admin-panel">
          <div class="c-admin-table">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-2">
                  Date
                </div>
                <div class="col-2">
                  Author
                </div>
                <div class="col-4">
                  Note
                </div>
                <div v-if="checkPermission('edit_notes')" class="col-2" />
                <div v-if="checkPermission('delete_notes')" class="col-2" />
              </div>
            </div>
            <div v-if="modelNotes.length">
              <div
                v-for="(note, index) in modelNotes"
                :key="index"
                class="c-admin-table-row"
              >
                <VNotesRow :note="note" :index="index" :state-key="stateKey" />
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              No Notes
            </div>
          </div>
          <div v-if="modelNotes.length" class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-model="page"
                  :page-count="pages.length"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VPagination from "vue-plain-pagination";
import NoteService from "js-admin/classes/AjaxServices/NoteService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VNotesRow from "js-admin/components/notes/NotesRow";

export default {
  components: {
    VPagination,
    VNotesRow,
  },
  props: {
    accountUuid: {
      required: true,
    },
    applicationUuid: {
      default: null,
    },
    investmentAccountUuid: {
      default: null,
    },
    stateKey: {
      required: true,
    },
  },

  data() {
    return {
      scope: "createNoteForm",
      stateKey2: "createNote",
      showCreateForm: false,
      createInProgress: false,
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      notes: null,
      notesCount: null,
    };
  },

  computed: {
    addNoteLabel() {
      if (this.showCreateForm) {
        return "- Hide Form";
      } else {
        return "+ Add Note";
      }
    },
    modelNotes() {
      return this.notes;
    },
    modelUuid() {
      if (this.investmentAccountUuid) {
        return this.investmentAccountUuid;
      }
      if (this.applicationUuid) {
        return this.applicationUuid;
      }
      return this.accountUuid;
    },
    modelType() {
      if (this.investmentAccountUuid) {
        return "InvestmentAccount";
      }
      if (this.applicationUuid) {
        return "Application";
      }
      return "Account";
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    this.getTotalNotes();
  },

  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },

    paginate() {
      this.dataReady = false;

      return NoteService.getModelNotes(
        {
          uuid: this.modelUuid,
          start: this.page * this.perPage - this.perPage,
          limit: this.perPage,
        },
        this.modelType
      )
        .then((response) => {
          if (response.data) {
            this.notes = response.data.data;
            this.getTotalNotes();
            this.dataReady = true;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(this.notesCount, this.perPage);
    },

    showNoteForm() {
      this.showCreateForm = !this.showCreateForm;
    },

    getTotalNotes() {
      return NoteService.getModelNotes(
        {
          uuid: this.modelUuid,
          return_count: true,
        },
        this.modelType
      )
        .then((response) => {
          if (response.data.data) {
            this.notesCount = response.data.data.note_count;
            this.setPages();
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },

    createNote() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          const data = {
            note: this.$store.state.fieldStore[this.stateKey2].note,
            account_uuid: this.accountUuid,
            author_uuid: this.$store.state.user.uuid,
          };

          if (this.applicationUuid) {
            data.application_uuid = this.applicationUuid;
          }

          if (this.investmentAccountUuid) {
            data.investment_account_uuid = this.investmentAccountUuid;
          }

          this.createInProgress = true;
          this.$store.dispatch("createNote", data).then((response) => {
            this.createInProgress = false;
            if (response.data) {
              FlashMessageService.success("Note has been created.");

              this.$store.dispatch("updateStoreItem", {
                value: null,
                stateKey: this.stateKey2,
                field: "note",
              });

              this.showCreateForm = false;
              this.paginate();
            }
          });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -150 });
          }, 400);
        }
      });
    },
  },
};
</script>
