import APIService from "js-admin/classes/APIService";
import { prepUrlParameters } from "js-admin/abstract/helpers";

export default class AccountService {
  static CRUD_URL = "accounts";

  static async getAccounts(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(this.CRUD_URL + "?" + extraParams);
  }

  static async getAccountData(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid);
  }

  static async createAccount(data) {
    return APIService.request(this.CRUD_URL, "post", data);
  }

  static async updateAccount(data) {
    return APIService.request(this.CRUD_URL + "/" + data.uuid, "put", data);
  }

  static async deleteAccount(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }

  static async mergeAccounts(masterUuid, childUuid, data) {
    return APIService.request(
      this.CRUD_URL + "/" + masterUuid + "/merge/" + childUuid,
      "post",
      data
    );
  }

  static async search(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request("search?" + extraParams);
  }

  static async getAccountInvestmentAccounts(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/investment-accounts?" + extraParams
    );
  }

  static async getAccountCashWallets(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/cash-wallets?" + extraParams
    );
  }

  static async getAccountLeads(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/leads?" + extraParams
    );
  }

  static async getAccountCalls(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/calls?" + extraParams
    );
  }

  static async getAccountHistoryLogs(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/history-logs?" + extraParams
    );
  }

  static async getAccountLogins(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/logins?" + extraParams
    );
  }

  static async getAccountChangeRequests(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/change-requests?" + extraParams
    );
  }

  static async closeAccount(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid + "/close", "post");
  }

  static async getAccountBankDetails(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/bank-details?" + extraParams
    );
  }

  static async getAccountEmails(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/emails?" + extraParams
    );
  }

  static async getAccountSecureMessages(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/secure-messages?" + extraParams
    );
  }

  static async getAccountCompanies(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/companies?" + extraParams
    );
  }

  static async getAccountAmls(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/amls?" + extraParams
    );
  }

  static async getAccountManualAmls(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + params.uuid + "/manual_amls?" + extraParams
    );
  }

  static async createManualAml(data) {
    return APIService.request(
      this.CRUD_URL + "/create-manual-aml",
      "post",
      data
    );
  }

  static archiveAccount({ uuid, archive_account }) {
    return APIService.request(this.CRUD_URL + "/" + uuid + "/archive", "post", {
      archive_account,
    });
  }
}
