<template>
  <div class="v-investment-account">
    <div v-if="invAccountDataReady">
      <section v-if="!invAccountNotFound" class="c-section">
        <div class="row">
          <div class="col-12">
            <h1 class="c-admin-page-title">
              Investment Account {{ invAccountRef }} for {{ accountName }}
            </h1>

            <a
              :href="false"
              class="c-button -admin-small mt-3"
              @click="backToAccountPage(accountUuid)"
            >
              Back to Account
            </a>
          </div>
        </div>

        <div class="row -column-reverse">
          <!-- Start of left section -->
          <div class="col-lg-7 col-sm-12">
            <VInvAccountDetails :investment-account="investmentAccount" />
          </div>
          <!-- End of left section -->

          <!-- Start of right section -->
          <div class="col-lg-5 col-sm-12">
            <VApplicationDetails :investment-account="investmentAccount" />

            <VInvAccountSystemInformation
              :investment-account="investmentAccount"
            />
          </div>
          <!-- End of right section -->
        </div>

        <div class="row">
          <div class="col-12">
            <VNotes
              v-if="checkPermission('view_notes')"
              :account-uuid="accountUuid"
              :investment-account-uuid="invAccountUuid"
              :state-key="stateKey"
            />

            <!-- <v-inv-account-transactions /> -->

            <VInstruments :investment-account-uuid="invAccountUuid" />

            <VDocuments
              v-if="checkPermission('view_files')"
              :account-uuid="accountUuid"
              :investment-account-uuid="invAccountUuid"
              :state-key="stateKey"
            />

            <VDeleteInvestmentAccount
              v-if="checkPermission('delete_investment_accounts')"
              :investment-account="investmentAccount"
            />
          </div>
        </div>
      </section>
      <div v-else>
        <section class="c-section">
          <div class="row">
            <div class="col-12">
              <h1 class="c-admin-page-title">
                Investment Account Not Found
              </h1>
              <div class="mt-5">
                Please use the buttons below to go back to the accounts list.
              </div>
              <div class="mt-3">
                <a
                  :href="false"
                  class="c-button -admin-small"
                  @click="accountsPage"
                >
                  Accounts List
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import VApplicationDetails from "js-admin/components/investment-accounts/ApplicationDetails";
import VInvAccountDetails from "js-admin/components/investment-accounts/InvAccountDetails.vue";
import VDeleteInvestmentAccount from "js-admin/components/investment-accounts/DeleteInvestmentAccount.vue";
import VInvAccountSystemInformation from "js-admin/components/investment-accounts/SystemInformation.vue";
import VNotes from "js-admin/components/notes/Notes.vue";
import VDocuments from "js-admin/components/documents/Documents.vue";
// import VInvAccountTransactions from "js-admin/components/investment-accounts/transactions/Transactions.vue";
import VInstruments from "js-admin/components/investment-accounts/instruments/Instruments.vue";

export default {
  components: {
    VApplicationDetails,
    VInvAccountDetails,
    VDeleteInvestmentAccount,
    VInvAccountSystemInformation,
    // VInvAccountTransactions,
    VNotes,
    VDocuments,
    VInstruments,
  },
  data() {
    return {
      invAccountDataReady: false,
      invAccountNotFound: false,
      stateKey: "investmentAccountData",
    };
  },

  computed: {
    investmentAccount() {
      return this.$store.state.fieldStore[this.stateKey];
    },
    invAccountUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
    invAccountRef() {
      if (this.$store.state.fieldStore[this.stateKey].reference_number) {
        return (
          "(" +
          this.$store.state.fieldStore[this.stateKey].reference_number +
          ")"
        );
      } else {
        return null;
      }
    },
    accountUuid() {
      return this.$store.state.fieldStore[this.stateKey].account.uuid;
    },
    accountName() {
      return this.$store.state.fieldStore[this.stateKey].account.full_name;
    },
  },

  mounted() {
    if (!this.checkPermission("view_investment_accounts")) {
      return this.$router.push({ name: "home" });
    }

    this.$store
      .dispatch("fetchInvAccountData", this.$route.params.uuid)
      .then((response) => {
        if (response.status === 200) {
          this.$store.dispatch("updateStoreItem", {
            value: response.data.data,
            stateKey: this.stateKey,
            field: null,
          });
          this.invAccountDataReady = true;
        } else {
          this.invAccountNotFound = true;
        }
      });
  },

  methods: {
    backToAccountPage(uuid) {
      this.$router.push({ name: "account", params: { uuid } });
    },

    accountsPage() {
      this.$router.push({ name: "accounts" });
      return false;
    },
  },
};
</script>
