<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title> Instruments ({{ instrumentsCount }}) </template>

        <div v-if="instrumentsCount" class="c-admin-table">
          <div class="c-admin-table-header">
            <div class="row align-items-center">
              <div class="col-3">
                Name
              </div>
              <div class="col-3">
                Symbol
              </div>
              <div class="col-2">
                Type
              </div>
              <div class="col-2 text-center">
                Status
              </div>
              <div
                v-if="checkPermission('view_issuer')"
                class="col-2 text-center"
              />
            </div>
          </div>

          <div
            v-for="(instrument, index) in instrumentData"
            :key="instrument.uuid"
            class="c-admin-table-row"
          >
            <VInstrumentsRow :instrument="instrument" :index="index" />
          </div>

          <div class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-model="page"
                  :page-count="pages.length"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          No linked documents.
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VInstrumentsRow from "js-admin/components/issuers/instruments/InstrumentsRow.vue";
import IssuerService from "js-admin/classes/AjaxServices/IssuerService";
import VPagination from "vue-plain-pagination";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  components: {
    VInstrumentsRow,
    VPagination,
  },
  props: {
    issuerUuid: {
      required: true,
    },
  },
  data() {
    return {
      instrumentsCount: 0,
      instrumentData: null,
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
    };
  },
  computed: {},

  watch: {
    page() {
      this.paginate();
    },
  },
  mounted() {
    this.getInstrumentCount();
  },
  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },
    getInstrumentCount(forcePagination = false) {
      IssuerService.getIssuerInstruments(this.issuerUuid, {
        return_count: true,
      })
        .then((response) => {
          this.instrumentsCount = response.data.data.count;
          this.setPages();
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
      if (forcePagination) {
        this.paginate();
      }
    },
    setPages() {
      this.pages = this.calculatePaginationPages(
        this.instrumentsCount,
        this.perPage
      );
    },
    paginate() {
      this.dataReady = false;
      IssuerService.getIssuerInstruments(this.issuerUuid, {
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      }).then((response) => {
        if (response.data.data) {
          this.instrumentData = null;
          if (response.data.data.length) {
            this.instrumentData = response.data.data;
          }
          this.dataReady = true;
        }
      });
    },
  },
};
</script>
