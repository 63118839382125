import APIService from "js-admin/classes/APIService";

export default class AccountManagerService {
  static CRUD_URL = "owners";
  static ACCOUNT_CRUD_URL = "accounts";

  static async getLeadCount() {
    return APIService.request(
      this.ACCOUNT_CRUD_URL + "/count?filter_by_type=unassigned_leads"
    );
  }

  static async getLeadsInPot() {
    return APIService.request(
      this.ACCOUNT_CRUD_URL + "?filter_by_type=unassigned_leads"
    );
  }

  static async getAccountManagers() {
    return APIService.request(this.CRUD_URL + "?type=account_manager");
  }

  static async moveAMToTopRoundRobin(ownerUuid) {
    return APIService.request(this.CRUD_URL + "/" + ownerUuid + "/move-to-top");
  }

  static async assignNewLead(ownerUuid) {
    return APIService.request(this.CRUD_URL + "/" + ownerUuid + "/assign-lead");
  }

  static async getAssignedLeads(ownerUuid) {
    return APIService.request(this.CRUD_URL + "/" + ownerUuid + "/leads");
  }

  static async updateAMOwner(data) {
    return APIService.request(
      this.CRUD_URL + "/" + data.owner_uuid,
      "put",
      data
    );
  }

  static async assignOwner(accountUuid, ownerUuid) {
    return APIService.request(
      this.ACCOUNT_CRUD_URL +
        "/" +
        accountUuid +
        "/owner/" +
        ownerUuid +
        "/assign",
      "post"
    );
  }

  static async getOwners() {
    return APIService.request(this.CRUD_URL);
  }

  static async createAMOwner(data) {
    return APIService.request(this.CRUD_URL + "/", "post", data);
  }

  static async deleteOwner(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }
}
