<template>
  <div class="row align-items-center">
    <div class="col-2">
      {{ dateTimeFormat(lead.created_at) }}
    </div>
    <div class="col-3">
      {{ lead.type }}
    </div>
    <div class="col-3">
      <div v-if="lead.notified_owner_on">
        Assigned on {{ dateTimeFormat(lead.notified_owner_on) }}
      </div>
      <div v-else>
        In queue
      </div>
    </div>
    <div class="col-4">
      {{ lead.details.message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lead: {
      required: true,
    },
  },

  computed: {
    accountUuid() {
      return this.$store.state.fieldStore.accountData.uuid;
    },
  },
};
</script>
