<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item>
        <template v-slot:title> Deposits ({{ paymentsCount }})</template>

        <v-add-manual-transfer-form
          v-if="checkPermission('create_manual_bank_transfers')"
          :cash-wallet-uuid="cashWalletUuid"
          :currency="cashWalletCurrency"
        />

        <v-bank-transfer-table
          v-if="paymentsCount"
          ref="depositsTable"
          :payments="payments"
        />
        <div v-else>
          No payments.
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VAddManualTransferForm from "js-admin/components/cash-wallet/deposits/payments/AddManualTransferForm";
import VBankTransferTable from "js-admin/components/cash-wallet/deposits/payments/BankTransferTable";
import CashWalletService from "js-admin/classes/AjaxServices/CashWalletService";

export default {
  components: {
    VAddManualTransferForm,
    VBankTransferTable,
  },

  data() {
    return {
      payments: [],
    };
  },
  computed: {
    cashWalletUuid() {
      return this.$store.state.fieldStore.cashWalletData.uuid;
    },
    cashWalletCurrency() {
      return this.$store.state.fieldStore.cashWalletData.currency;
    },
    paymentsCount() {
      return this.payments.length;
    },
  },
  mounted() {
    CashWalletService.getPayments(this.cashWalletUuid).then((response) => {
      this.payments = response.data.data;
    });
  },
};
</script>
