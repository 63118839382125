import APIService from "js-admin/classes/APIService";

export default class TwoFactorService {
  static CRUD_URL = "users/two-factor";

  static async disableTwoFactor() {
    return APIService.request(this.CRUD_URL + "/disable", "post");
  }

  static async setupTwoFactorQRCode() {
    return APIService.request(this.CRUD_URL + "/app-code/generate", "post");
  }

  static async validateAppTwoFactor(data) {
    return APIService.request(
      this.CRUD_URL + "/app-code/validate",
      "post",
      data
    );
  }

  static async dashboardValidateAppTwoFactor(data) {
    return APIService.request(
      this.CRUD_URL + "/app-code/validate-for-logged-user",
      "post",
      data
    );
  }

  static async generateSmsTwoFactor(data) {
    return APIService.request(this.CRUD_URL + "/sms/generate", "post", data);
  }

  static async validateSmsTwoFactor(data) {
    return APIService.request(this.CRUD_URL + "/sms/validate", "post", data);
  }

  static async dashboardGenerateSmsTwoFactor(data) {
    return APIService.request(
      this.CRUD_URL + "/sms/generate-for-logged-user",
      "post",
      data
    );
  }

  static async dashboardValidateSmsTwoFactor(data) {
    return APIService.request(
      this.CRUD_URL + "/sms/validate-for-logged-user",
      "post",
      data
    );
  }
}
