<template>
  <div class="v-cash-wallet">
    <div v-if="cashWalletDataReady">
      <section v-if="!cashWalletNotFound" class="c-section">
        <div class="row">
          <div class="col-12">
            <h1 class="c-admin-page-title">
              Cash Wallet {{ cashWalletRef }}
              <span v-if="accountName">for {{ accountName }} </span>
            </h1>

            <a
              :href="false"
              class="c-button -admin-small mt-3"
              @click="backToAccountPage(accountUuid)"
            >
              Back to Account
            </a>
          </div>
        </div>

        <div class="row -column-reverse">
          <!-- Start of left section -->
          <div class="col-lg-7 col-sm-12">
            <VCashWalletDetails :cash-wallet="cashWallet" />
          </div>
          <!-- End of left section -->
        </div>

        <div class="row">
          <div class="col-12">
            <v-cash-wallet-transactions />

            <v-cash-wallet-withdrawals
              v-if="checkPermission('view_withdrawals')"
            />

            <v-deposits v-if="checkPermission('view_manual_bank_transfers')" />

            <v-audit-logs />

            <v-primary-market-bids />
          </div>
        </div>
      </section>
      <div v-else>
        <section class="c-section">
          <div class="row">
            <div class="col-12">
              <h1 class="c-admin-page-title">
                Cash Wallets Not Found
              </h1>
              <div class="mt-5">
                Please use the buttons below to go back to the accounts list.
              </div>
              <div class="mt-3">
                <a
                  :href="false"
                  class="c-button -admin-small"
                  @click="accountsPage"
                >
                  Accounts List
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import VCashWalletDetails from "js-admin/components/cash-wallet/CashWalletDetails.vue";
import VDeposits from "js-admin/components/cash-wallet/deposits/Deposits.vue";
import VCashWalletTransactions from "js-admin/components/cash-wallet/transactions/Transactions.vue";
import VCashWalletWithdrawals from "js-admin/components/cash-wallet/withdrawals/Withdrawals.vue";
import VAuditLogs from "js-admin/components/cash-wallet/audit-logs/AuditLogs.vue";
import VPrimaryMarketBids from "js-admin/components/cash-wallet/primary-market-bids/PrimaryMarketBids.vue";

export default {
  components: {
    VCashWalletDetails,
    VDeposits,
    VCashWalletTransactions,
    VCashWalletWithdrawals,
    VAuditLogs,
    VPrimaryMarketBids,
  },
  data() {
    return {
      cashWalletDataReady: false,
      cashWalletNotFound: false,
      stateKey: "cashWalletData",
    };
  },

  computed: {
    cashWallet() {
      return this.$store.state.fieldStore[this.stateKey];
    },
    accountUuid() {
      return this.$store.state.fieldStore[this.stateKey].account_uuid;
    },
    accountName() {
      if (this.$store.state.fieldStore.accountData) {
        return this.$store.state.fieldStore.accountData.full_name;
      }
      return null;
    },
    cashWalletRef() {
      if (this.$store.state.fieldStore[this.stateKey].reference_number) {
        return (
          "(" +
          this.$store.state.fieldStore[this.stateKey].reference_number +
          ")"
        );
      } else {
        return null;
      }
    },
  },

  mounted() {
    if (!this.checkPermission("view_investment_accounts")) {
      return this.$router.push({ name: "home" });
    }

    this.$store
      .dispatch("fetchCashWalletData", this.$route.params.uuid)
      .then((response) => {
        if (response.status === 200) {
          this.$store.dispatch("updateStoreItem", {
            value: response.data.data,
            stateKey: this.stateKey,
            field: null,
          });
          this.cashWalletDataReady = true;
        } else {
          this.cashWalletNotFound = true;
        }
      });
  },

  methods: {
    backToAccountPage(uuid) {
      this.$router.push({ name: "account", params: { uuid } });
    },

    accountsPage() {
      this.$router.push({ name: "accounts" });
      return false;
    },
  },
};
</script>
