<template>
  <div class="c-phone-call-container" :class="{ '-show': showPhoneCallBox }">
    <div>
      <div
        v-for="(inboundCall, index2) in ringingInboundCalls"
        v-show="ringingInboundCalls"
        :key="index2"
        class="c-phone-call-box"
      >
        <VPhoneCallInboundCall
          :inbound-call="inboundCall"
          :ringing-calls-state-key="ringingCallsStateKey"
        ></VPhoneCallInboundCall>
      </div>
    </div>
    <div>
      <div
        v-for="(inProgressCall, index) in formattedInProgressCalls"
        v-show="formattedInProgressCalls.length"
        :key="index"
        class="c-phone-call-box"
      >
        <VInProgressCall
          :in-progress-call="inProgressCall"
          :index="index"
          :in-progress-calls-state-key="inProgressCallsStateKey"
          :account-managers="accountManagers"
        ></VInProgressCall>
      </div>
    </div>
  </div>
</template>

<script>
import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";

import VPhoneCallInboundCall from "js-admin/components/phoneCallBox/InBoundCall.vue";
import VInProgressCall from "js-admin/components/phoneCallBox/InProgressCall.vue";

export default {
  components: {
    VPhoneCallInboundCall,
    VInProgressCall,
  },
  data() {
    return {
      ringingCallsStateKey: "inboundRingingCalls",
      inProgressCallsStateKey: "inProgressCalls",
      transferCallInProgress: false,
      createInProgress: false,
    };
  },
  computed: {
    accountManagers() {
      return this.$store.state.accountManagers;
    },
    ringingInboundCalls() {
      const inboundRingingCalls = [];

      this.$store.state.fieldStore[this.ringingCallsStateKey].forEach(
        (inboundCall) => {
          const formattedCall = {
            uuid: inboundCall.call.uuid,
            from: inboundCall.call.from,
            call_uuid: inboundCall.call.uuid,
            direction: inboundCall.call.direction,
          };

          if (inboundCall.account) {
            formattedCall.name =
              inboundCall.account.first_name +
              " " +
              inboundCall.account.last_name;
            formattedCall.account_uuid = inboundCall.account.uuid;
          }

          inboundRingingCalls.push(formattedCall);
        }
      );

      return inboundRingingCalls;
    },
    formattedInProgressCalls() {
      return this.$store.getters.getFormattedCalls;
    },

    showPhoneCallBox() {
      return (
        this.$store.state.fieldStore[this.ringingCallsStateKey].length ||
        this.$store.state.fieldStore[this.inProgressCallsStateKey].length
      );
    },

    loggedUserPhoneNumber() {
      if (this.$store.state.user.owner) {
        return this.$store.state.user.owner.phone_number;
      }

      return null;
    },
    loggedUser() {
      return this.$store.state.user;
    },

    channelName() {
      return window.pusher.channel_name;
    },

    inboundChannelBroadcast() {
      return window.pusher.phone_calls_inbound_channel_broadcast;
    },

    inProgressChannelBroadcast() {
      return window.pusher.phone_calls_in_progress_channel_broadcast;
    },
  },
  watch: {
    loggedUser(user) {
      this.loadUser(user);
    },
  },
  mounted() {
    if (this.loggedUser) {
      this.loadUser(this.loggedUser);
    }
  },
  methods: {
    loadUser(user) {
      if (
        user.email_address &&
        this.checkRole(["account_manager", "senior_account_manager"])
      ) {
        this.$store.dispatch("getAccountManagers");

        CommunicationService.getInProgressCall().then((response) => {
          if (response && response.data) {
            const alreadyAddedToCollection = this.formattedInProgressCalls.some(
              (item) => response.data.data.call.uuid === item.uuid
            );

            if (!alreadyAddedToCollection) {
              response.data.data.call.is_open = false;

              this.$store.dispatch("addToArrayItemStore", {
                value: response.data.data,
                stateKey: this.inProgressCallsStateKey,
                index: response.data.data.call.uuid,
              });
            }
          }
        });

        CommunicationService.getCallsWaitingOnQueue().then((response) => {
          if (response && response.data) {
            response.data.data.forEach((inboundCall) => {
              const alreadyAddedToCollection = this.ringingInboundCalls.some(
                (addedInboundCall) =>
                  addedInboundCall.uuid === inboundCall.call.uuid
              );

              if (!alreadyAddedToCollection) {
                this.$store.dispatch("addToArrayItemStore", {
                  value: inboundCall,
                  stateKey: this.ringingCallsStateKey,
                  index: inboundCall.call.uuid,
                });
              }
            });
          }
        });

        const channel = this.$pusher.subscribe(this.channelName);
        channel.bind(this.inboundChannelBroadcast, ({ inboundCall }) => {
          if (inboundCall.call.status === "ringing") {
            this.$store.dispatch("addToArrayItemStore", {
              value: inboundCall,
              stateKey: this.ringingCallsStateKey,
              index: inboundCall.call.uuid,
            });
          } else {
            this.$store.dispatch("deleteFromArrayItemStore", {
              stateKey: this.ringingCallsStateKey,
              callUuid: inboundCall.call.uuid,
            });
          }
        });

        channel.bind(this.inProgressChannelBroadcast, ({ inProgressCall }) => {
          if (inProgressCall.call.to !== this.loggedUserPhoneNumber) {
            return;
          }

          if (inProgressCall.call.status === "in-progress") {
            if (
              !this.arrayValueExists(
                this.formattedInProgressCalls,
                "uuid",
                inProgressCall.call.uuid
              )
            ) {
              inProgressCall.call.is_open = false;
              this.$store.dispatch("addToArrayItemStore", {
                value: inProgressCall,
                stateKey: this.inProgressCallsStateKey,
              });
            }
          }
        });
      }
    },
  },
};
</script>
