import APIService from "js-admin/classes/APIService";
import { prepUrlParameters } from "js-admin/abstract/helpers";

export default class PaymentService {
  static CRUD_URL = "payments";

  static async createManualBankTransfer(data) {
    return APIService.request(
      this.CRUD_URL + "/manual-bank-transfer/create",
      "post",
      data
    );
  }

  static async createBankTransferCorrection(data) {
    return APIService.request(
      this.CRUD_URL + "/bank-transfer-correction",
      "post",
      data
    );
  }

  static async getBankTransfer(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid);
  }

  static async getBankTransferCorrections(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL +
        "/" +
        params.uuid +
        "/bank-transfer-corrections?" +
        extraParams
    );
  }
}
