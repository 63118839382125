<template>
  <div class="c-admin-panel">
    <div class="c-admin-panel-title">
      Role
    </div>
    <div>
      <v-select-input
        :scope="rolePermissionsFormScope"
        :state-key="roleStateKey"
        :options="this.$store.state.roles"
        :center-error="false"
        field="role"
        placeholder="Select a role"
        error-name="role"
        validation="required"
        @selectionUpdated="handleRoleUpdate"
      />
    </div>
    <div class="c-admin-panel mt-4">
      <div v-if="!submittingRolePermissionsForm">
        <div v-if="objectLength(rolePermissionData)" class="c-admin-table">
          <form @submit.prevent="setRolePermissions">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-8">
                  Permission
                </div>
                <div class="col-4 text-right">
                  Has Permission?
                </div>
              </div>
            </div>
            <div
              v-for="(permission, index) in rolePermissionData"
              :key="index"
              class="c-admin-table-row"
            >
              <div class="row align-items-center">
                <div class="col-8">
                  {{ index }}
                </div>
                <div class="col-4 text-right">
                  <div class="c-notifications-switches">
                    <div
                      class="c-notifications-switches-item justify-content-end"
                    >
                      <v-switch
                        :field="index"
                        :state-key="rolePermissionsStateKey"
                        class="d-inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-admin-table-footer">
              <div class="row align-items-center">
                <div class="col-12 text-right">
                  <v-button
                    v-if="checkRole('superadmin')"
                    class=""
                    :loading="submittingSetRolePermissionsForm"
                    :disabled="submittingSetRolePermissionsForm"
                  >
                    Update
                  </v-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <v-loader v-else />
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import RolePermissionService from "js-admin/classes/AjaxServices/RolePermissionService";

export default {
  data() {
    return {
      rolePermissionsFormScope: "rolePermissionsFormScope",
      roleStateKey: "role",
      submittingRolePermissionsForm: false,
      rolePermissionsStateKey: "rolePermissions",
      submittingSetRolePermissionsForm: false,
    };
  },

  computed: {
    roleName() {
      if (this.$store.state.fieldStore[this.roleStateKey].role) {
        return this.$store.state.fieldStore[this.roleStateKey].role.name;
      }
      return null;
    },
    rolePermissionData() {
      return this.$store.state.fieldStore[this.rolePermissionsStateKey];
    },
  },

  mounted() {
    this.$store.dispatch("getRoles");
  },

  methods: {
    async handleRoleUpdate(val) {
      if (!val) {
        this.$store.dispatch("updateStoreItem", {
          value: null,
          stateKey: this.rolePermissionsStateKey,
        });
        return;
      }

      this.submittingRolePermissionsForm = true;

      await RolePermissionService.getRolePermissions(val.name)
        .then((response) => {
          this.$store.dispatch("updateStoreItem", {
            value: response.data.data,
            stateKey: this.rolePermissionsStateKey,
          });
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });

      this.submittingRolePermissionsForm = false;
    },

    setRolePermissions() {
      this.submittingSetRolePermissionsForm = true;

      RolePermissionService.setRolePermissions({
        role: this.roleName,
        permissions: this.rolePermissionData,
      })
        .then(() => {
          FlashMessageService.success("Role permissions have been updated.");
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });

      this.submittingSetRolePermissionsForm = false;
    },
  },
};
</script>
