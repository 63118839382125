<template>
  <div class="v-manage-withdrawals">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title mb-5">
            Manage Withdrawals
          </h1>
        </div>
      </div>
      <div class="row align-items-end">
        <div class="col-12">
          <!-- Search -->
          <v-date-select-search
            ref="withdrawalSearch"
            :scope="scope"
            :report-state-key="stateKey"
            field-name="status"
            select-title="Status"
            :select-data="withdrawalStatuses"
            :select-enable-blank-option="false"
            :mode="mode"
            :submit-results="filterInProgress"
            :open-accordion="false"
            @get-results="filterWithdrawals"
          />
        </div>
      </div>
      <div v-if="dataReady" class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header">
              <div class="row">
                <div class="col-2">
                  Created Date
                </div>
                <div class="col-2">
                  Name
                </div>
                <div class="col-2">
                  Reference
                </div>
                <div class="col-2">
                  Amount
                </div>
                <div class="col-2">
                  Status
                </div>
                <div class="col-2" />
              </div>
            </div>
            <div v-if="withdrawals.length">
              <div v-for="(withdrawal, index) in withdrawals" :key="index">
                <v-withdrawals-row :withdrawal="withdrawal" />
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  No withdrawals found
                </div>
              </div>
            </div>
            <div v-if="withdrawals.length" class="c-admin-table-footer">
              <div class="row">
                <div class="col-12">
                  <v-pagination
                    v-model="page"
                    :page-count="pages.length"
                    :classes="bootstrapPaginationClasses"
                    :labels="paginationAnchorTexts"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-loader v-else class="-admin" />
    </section>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VDateSelectSearch from "js-admin/components/Search/DateSelectSearch";
import VPagination from "vue-plain-pagination";
import VWithdrawalsRow from "js-admin/components/withdrawals/ManageWithdrawalsRow";
import WithdrawalService from "js-admin/classes/AjaxServices/WithdrawalService";

export default {
  components: {
    VDateSelectSearch,
    VPagination,
    VWithdrawalsRow,
  },
  data() {
    return {
      withdrawals: [],
      withdrawalsTotal: 0,
      dataReady: false,
      page: 1,
      perPage: 10,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      scope: "withdrawalFilter",
      stateKey: "withdrawalFilter",
      filterInProgress: false,
      mode: "single",
    };
  },

  computed: {
    withdrawalStatuses() {
      const statuses = this.$store.state.config.withdrawal_statuses;
      const withdrawalStatuses = [];

      withdrawalStatuses.push("All");
      Object.keys(statuses).forEach((key) => {
        withdrawalStatuses.push(statuses[key]);
      });

      return withdrawalStatuses;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
    withdrawals() {
      this.setPages();
    },
  },

  mounted() {
    if (!this.checkPermission("view_withdrawals")) {
      return this.$router.push({ name: "home" });
    }

    this.paginate();
  },

  methods: {
    paginate(searchData = { status: "pending" }) {
      this.dataReady = false;

      const data = {
        limit: this.perPage,
        page: this.page,
        ...searchData,
      };

      return WithdrawalService.getWithdrawals(data)
        .then((response) => {
          if (response.data) {
            this.withdrawals = response.data.data;
            this.withdrawalsTotal = response.data.meta.total;
            this.dataReady = true;
            return;
          }
          this.withdrawals = [];
          this.withdrawalsTotal = 0;
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(
        this.withdrawalsTotal,
        this.perPage
      );
    },

    filterWithdrawals() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          if (this.page !== 1) {
            this.page = 1;
          } else {
            const data = {
              status: this.$store.state.fieldStore[
                this.stateKey
              ].status.toLowerCase(),
              from_date: this.formattedDateForApi(
                this.$refs.withdrawalSearch.fromDate,
                "00:00:00"
              ),
              to_date: this.formattedDateForApi(
                this.$refs.withdrawalSearch.toDate,
                "23:59:59"
              ),
            };
            this.paginate(data);
          }
        }
      });
    },
  },
};
</script>
