import Vue from "vue";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { mapGetters, mapMutations } from "vuex";
import numeral from "numeral";

Vue.mixin({
  computed: {
    fullscreenPanelIsOpen() {
      return this.$store.state.fullscreenPanel.show;
    },
    ...mapGetters([
      "getTaskOwner",
      "getOwnerTaskData",
      "getSelectedTask",
      "getUser",
      "getUserUuid",
      "getPriorityListAsArray",
      "getStatusListAsArray",
      "getCreatedTask",
    ]),
  },
  methods: {
    ...mapMutations(["updateStore"]),
    viewSupportPage(gid) {
      if (
        this.$router.currentRoute.path !==
        "/support-status/support-details/" + gid
      ) {
        this.$router.push("/support-status/support-details/" + gid);
      }
    },
    viewTaskPage(uuid) {
      if (this.$router.currentRoute.path !== "/tasks/task-details/" + uuid) {
        this.$router.push("/tasks/task-details/" + uuid);
      }
    },
    random(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return min + Math.floor(Math.random() * (max - min + 1));
    },
    objectLength(obj) {
      let size = 0;
      let key;
      for (key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          size++;
        }
      }
      return size;
    },
    // clear rogue errors
    clearErrors(milliseconds) {
      setTimeout(() => {
        this.$validator.errors.clear();
      }, milliseconds);
    },

    stripUnderScoresAndCapitalise(string) {
      const word = string.replace(/_/g, " ");
      return this.capitaliseFirstLetter(word);
    },

    capitaliseFirstLetter(string) {
      const word = string;
      return word.charAt(0).toUpperCase() + word.slice(1);
    },

    getDayExtension(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    },

    addSpacesBeforeEachCapitalLetter(string) {
      return string.replace(/([A-Z])/g, " $1").trim();
    },

    stripSpaces(string) {
      if (string) {
        return string.replace(/ +/g, "");
      }
      return null;
    },

    xProcessStepName(stepname) {
      if (stepname === "TermsGIA" || stepname === "TermsISA") {
        stepname = "Terms";
      }
      return this.addSpacesBeforeEachCapitalLetter(stepname);
    },

    prepareTaskStatus(string) {
      let status = string.replace(/\s+/g, "-").toLowerCase();
      if (status === "waiting-on-information") {
        status = "waiting-info";
      }
      return status;
    },

    checkPermission(permission) {
      const usersAllowedPermissions = this.$store.state.user.permissions;
      if (!usersAllowedPermissions || usersAllowedPermissions.length === 0)
        return false;

      if (Array.isArray(permission)) {
        permission.forEach((p) => {
          if (!usersAllowedPermissions.includes(p)) return false;
        });

        return true;
      } else {
        return usersAllowedPermissions.includes(permission);
      }
    },

    checkRole(roleName) {
      const roles = this.$store.state.user.roles;

      if (!roles) {
        return false;
      }

      if (Array.isArray(roleName)) {
        for (const currRole of roleName) {
          if (roles.includes(currRole)) {
            return true;
          }
        }
      }

      if (roles.includes(roleName)) {
        return true;
      }

      return false;
    },

    systemFeatureIsDisabled(systemFeature) {
      const adminFeatureSettings = this.$store.state.config
        .admin_feature_settings;

      if (Array.isArray(systemFeature)) {
        return systemFeature.reduce(
          (acc, feature) => acc && this.systemFeatureIsDisabled(feature),
          true
        );
      }

      if (Object.hasOwnProperty.call(adminFeatureSettings, systemFeature)) {
        return adminFeatureSettings[systemFeature];
      }

      return true;
    },

    dateFormat(date, format = "DD/MM/YYYY") {
      dayjs.extend(customParseFormat);
      return dayjs(date, "YYYY-MM-DD").format(format);
    },

    dateTimeFormat(date, format = "DD/MM/YYYY HH:mm:ss") {
      dayjs.extend(customParseFormat);
      return dayjs(date, "YYYY-MM-DD HH:mm:ss").format(format);
    },

    formattedDateForApi(date, time = null) {
      let formattedDate = "";
      if (date != null) {
        if (time) {
          formattedDate =
            date.getFullYear() +
            "-" +
            ((date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1)) +
            "-" +
            ((date.getDate() < 10 ? "0" : "") + date.getDate()) +
            " " +
            time;
        } else {
          formattedDate =
            date.getFullYear() +
            "-" +
            ((date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1)) +
            "-" +
            ((date.getDate() < 10 ? "0" : "") + date.getDate()) +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes() +
            ":" +
            date.getSeconds();
        }
        return formattedDate;
      }
      return false;
    },

    arrayValueExists(array, arrayKey, givenValue, returnMatched = false) {
      let match = false;

      array.forEach((currentElement) => {
        const currValue = currentElement[arrayKey];

        if (currValue === givenValue) {
          match = true;
          if (returnMatched) {
            match = currentElement;
          }
        }
      });

      return match;
    },

    isEmptyValue(str) {
      return !str || str.length === 0;
    },

    calculatePaginationPages(total, pePage) {
      const pages = [];
      const numberOfPages = Math.ceil(total / pePage);
      for (let index = 1; index <= numberOfPages; index++) {
        pages.push(index);
      }
      return pages;
    },

    convertToPounds(pence) {
      return numeral((pence / 100).toFixed(2)).format("0,0.00");
    },

    formattedDuration(time) {
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;
      return minutes + "m " + seconds + "s ";
    },

    getEmptyUser() {
      return {
        uuid: null,
        name: null,
        email_address: null,
        phone_number: null,
        work_phone: null,
        reset_user_password: false,
        role: {
          name: null,
        },
        owner_uuid: null,
        owner_job_title: null,
      };
    },

    getEmptyCreateUser() {
      return {
        uuid: null,
        name: null,
        email_address: null,
        phone_number: null,
        work_phone: null,
        role: {
          name: null,
        },
        owner_job_title: null,
      };
    },

    getEmptyAccount(contactNo = null) {
      let primaryNumber = null;
      if (contactNo) {
        primaryNumber = contactNo;
      }

      return {
        title: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        primary_contact_number: primaryNumber,
        email: null,
        dob: null,
        national_insurance_number: null,
        nationality: null,
        type: "Individual",
        date_notified_of_death: null,
        closed_on: false,
        gdpr_optin_flag: false,
        subscribed_marketing_notifications: false,
        subscribed_product_notifications: false,
        subscribed_industry_news_notifications: false,
        subscribed_phone: false,
        subscribed_sms: false,
        subscribed_email: false,
        subscribed_post: false,
        uuid: null,
        create_user_login: true,
        primary_address_address_select: null,
        primary_address_uuid: null,
        primary_address_line_1: null,
        primary_address_line_2: null,
        primary_address_line_3: null,
        primary_address_town: null,
        primary_address_county: null,
        primary_address_postcode: null,
        primary_address_country: null,
      };
    },

    getEmptyIssuer() {
      return {
        uuid: null,
        name: null,
        flat_or_unit: null,
        building_number: null,
        building_name: null,
        street_name: null,
        town: null,
        county: null,
        postcode: null,
        country: null,
        country_code: null,
        company_number: null,
        company_jurisdiction: null,
        country_incorporation: null,
        company_type: null,
        company_logo: null,
        sector: null,
        financial_year_end: null,
        corporate_adviser: null,
        auditors: null,
        website: null,
        linkedin: null,
        twitter: null,
        instagram: null,
        brand_colour: null,
        created_at: null,
      };
    },

    getEmptyIssuerContact() {
      return {
        uuid: null,
        title: null,
        first_name: null,
        last_name: null,
        email: null,
        primary_contact_number: null,
        primary_contact_number_country_code: null,
        primary_contact_number_dial_code: null,
        dob: null,
        primary_contact: null,
        created_at: null,
      };
    },

    getEmptyLead(contactNo = null) {
      let primaryNumber = null;
      let leadType = null;
      if (contactNo) {
        primaryNumber = contactNo;
        leadType = "inbound_call";
      }

      return {
        title: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        primary_contact_number: primaryNumber,
        email: null,
        type: leadType,
        description: null,
        gdpr_optin_flag: false,
        subscribed_marketing_notifications: false,
        subscribed_product_notifications: false,
        subscribed_industry_news_notifications: false,
        subscribed_phone: false,
        subscribed_sms: false,
        subscribed_email: false,
        subscribed_post: false,
        create_user_login: true,
        primary_address_address_select: null,
        primary_address_line_1: null,
        primary_address_line_2: null,
        primary_address_line_3: null,
        primary_address_town: null,
        primary_address_county: null,
        primary_address_postcode: null,
        primary_address_country: null,
        owner_uuid: null,
      };
    },

    getEmptyTask() {
      return {
        task_priority: null,
        task_status: null,
        task_name: null,
        task_description: null,
        due_date: null,
        assigned_to: null,
        priority: null,
        set_reminder: false,
        reminder_date: null,
        reminder_hour: null,
        reminder_minute: null,
        task_owner: null,
      };
    },
    async hideRecaptcha() {
      await this.$recaptchaLoaded();
      this.$recaptchaInstance.hideBadge();
    },
    generateRecaptchaToken(action) {
      return this.$recaptcha(action);
    },
  },
});
