<template>
  <button
    tabindex="0"
    class="c-select-tile-item"
    :class="[buttonClass, { '-active': active, '-multi': multi }]"
    @click.prevent="select(item.value)"
  >
    <div
      v-if="index !== undefined"
      class="c-select-tile-index"
      v-text="labelLetter"
    />
    <img v-if="item.icon" :src="item.icon" alt="" class="c-select-tile-icon" />
    <h2 class="c-select-tile-title" v-text="item.title" />
    <h3
      v-if="item.subtitle"
      class="c-select-tile-subtitle"
      v-text="item.subtitle"
    />
    <div
      v-if="item.description"
      class="c-select-tile-desc"
      v-text="item.description"
    />
    <div
      v-if="item.sub_description"
      class="c-select-tile-sub-desc"
      v-text="item.sub_description"
    />
    <div v-if="item.note" class="c-select-tile-note" v-text="item.note" />

    <div v-if="multi" class="c-select-tile-check-wrap">
      <svg
        version="1.1"
        class="c-select-tile-check"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 448.8 448.8"
        style="enable-background: new 0 0 448.8 448.8;"
        xml:space="preserve"
      >
        <g id="check">
          <polygon
            fill="#ffffff"
            points="142.8,323.85 35.7,216.75 0,252.45 142.8,395.25 448.8,89.25 413.1,53.55 		"
          />
        </g>
      </svg>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: false,
    },
    active: {
      default: false,
    },
    multi: {
      default: false,
    },
    buttonClass: {
      default: "",
    },
    item: {
      // requires item object with 'title', 'value',
      // optional item attributes are 'subtitle', 'icon', 'description'.
      required: true,
    },
  },
  data() {
    return {
      alphabet: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
    };
  },
  computed: {
    labelLetter() {
      return this.alphabet[this.index];
    },
  },
  methods: {
    select(value) {
      this.$emit("select", value);
    },
  },
};
</script>
