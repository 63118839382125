<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2 text-center">
        <div v-if="!address.primary_address">
          <a
            v-if="!makePrimaryInProgress"
            :href="false"
            class="c-button -admin-small"
            @click="makePrimary(address.uuid)"
          >
            Make Primary Address
          </a>
          <v-loader v-else />
        </div>
        <div v-else>
          Primary Address
        </div>
      </div>
      <div class="col-1" />
      <div class="col-7">
        <div v-if="!showEditForm" class="row align-items-center">
          <div class="col-9">
            <span v-if="address.address_1"> {{ address.address_1 }}, </span>
            <span v-if="address.address_2"> {{ address.address_2 }}, </span>
            <span v-if="address.address_3"> {{ address.address_3 }}, </span>
            <span v-if="address.town"> {{ address.town }}, </span>
            <span v-if="address.county"> {{ address.county }}, </span>
            <span v-if="address.postcode"> {{ address.postcode }}, </span>
            <span v-if="address.country"> {{ address.country }} </span>
          </div>
          <div v-if="checkPermission('edit_address')" class="col-3">
            <a :href="false" class="c-button -admin-small" @click="showForm">
              Edit
            </a>
          </div>
        </div>
        <div v-else>
          <form :data-vv-scope="scope" @submit.prevent="updateAddress(index)">
            <div>
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                field="address_1"
                label="Address 1"
                validation="required"
              />
            </div>

            <div>
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                field="address_2"
                label="Address 2"
                validation=""
              />
            </div>

            <div>
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                field="address_3"
                label="Address 3"
                validation=""
              />
            </div>

            <div>
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                field="town"
                label="City/Town"
                validation="required"
              />
            </div>

            <div>
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                field="county"
                label="County"
                validation="required"
              />
            </div>

            <div>
              <v-text-input
                :scope="scope"
                :state-key="stateKey"
                field="postcode"
                label="Postcode"
                validation="required"
              />
            </div>

            <div>
              <v-combo-box
                :scope="scope"
                :state-key="stateKey"
                :options="countries"
                :focus-on-mounted="false"
                class="-no-left-padding"
                field="country"
                label="Country"
                error-name="country"
                validation="required"
              />
            </div>

            <v-button
              :loading="updateInProgress"
              :disabled="updateInProgress"
              class="-admin-small"
            >
              Update
            </v-button>
          </form>
        </div>
      </div>
      <div v-if="checkPermission('delete_address')" class="col-2 text-center">
        <a
          :href="false"
          class="c-button -admin-small"
          @click="deleteAddress(address.uuid, index)"
        >
          Delete
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import AddressService from "js-admin/classes/AjaxServices/AddressService";

export default {
  props: {
    address: {
      required: true,
    },
    index: {
      required: true,
    },
  },

  data() {
    return {
      scope: "updateAddressForm",
      stateKey: "updateAddress",
      stateKey2: "accountData",
      updateInProgress: false,
      showEditForm: false,
      makePrimaryInProgress: false,
    };
  },

  computed: {
    accountUuid() {
      return this.$store.state.fieldStore[this.stateKey2].uuid;
    },
    countries() {
      return this.$store.state.config.countries;
    },
  },

  methods: {
    makePrimary(uuid) {
      this.makePrimaryInProgress = true;

      AddressService.updatePrimaryAddress(this.accountUuid, uuid).then(
        (response) => {
          this.makePrimaryInProgress = false;
          if (response.data) {
            FlashMessageService.success("Addresses have been updated.");

            window.location.reload();
          } else {
            console.log(response);
            return false;
          }
        }
      );
    },

    showForm() {
      this.$store.dispatch("updateStoreItem", {
        value: this.address,
        stateKey: this.stateKey,
        field: null,
      });

      this.$parent.toggle(this);
    },

    updateAddress(index) {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          const data = {
            address_1: this.$store.state.fieldStore[this.stateKey].address_1,
            address_2: this.$store.state.fieldStore[this.stateKey].address_2,
            address_3: this.$store.state.fieldStore[this.stateKey].address_3,
            town: this.$store.state.fieldStore[this.stateKey].town,
            county: this.$store.state.fieldStore[this.stateKey].county,
            postcode: this.$store.state.fieldStore[this.stateKey].postcode,
            country: this.$store.state.fieldStore[this.stateKey].country,
            uuid: this.$store.state.fieldStore[this.stateKey].uuid,
          };

          this.updateInProgress = true;
          this.$store.dispatch("updateAddress", data).then((response) => {
            this.updateInProgress = false;
            if (response.data) {
              FlashMessageService.success("Address has been updated.");
              this.$store.dispatch("updateArrayItemInStore", {
                value: response.data,
                stateKey: this.stateKey2,
                field: "addresses",
                index: index,
              });
              this.showEditForm = false;
            } else {
              console.log(response);
              return false;
            }
          });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },

    deleteAddress(uuid, index) {
      if (confirm("Are you sure you want to delete this address?")) {
        this.$store.dispatch("deleteAddress", uuid).then((response) => {
          if (response.status == 204) {
            FlashMessageService.success("Address has been deleted.");
            this.$store.dispatch("deleteArrayItemFromStore", {
              stateKey: this.stateKey2,
              field: "addresses",
              index: index,
            });
          } else {
            console.log(response);
            return false;
          }
        });
      }
    },
  },
};
</script>
