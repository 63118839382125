import AuthService from "js-admin/classes/AuthService";
import AddressService from "js-admin/classes/AjaxServices/AddressService";
import CompanyService from "js-admin/classes/AjaxServices/CompanyService";
import AccountService from "js-admin/classes/AjaxServices/AccountService";
import BankDetailsService from "js-admin/classes/AjaxServices/BankDetailsService";
import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";
import ConfigDataService from "js-admin/classes/AjaxServices/ConfigDataService";
import DocumentService from "js-admin/classes/AjaxServices/DocumentService";
import InvestmentAccountService from "js-admin/classes/AjaxServices/InvestmentAccountService";
import NoteService from "js-admin/classes/AjaxServices/NoteService";
import RolePermissionService from "js-admin/classes/AjaxServices/RolePermissionService";
import UserService from "js-admin/classes/AjaxServices/UserService";
import AccountManagerService from "js-admin/classes/AjaxServices/AccountManagerService";
import CashWalletService from "js-admin/classes/AjaxServices/CashWalletService";
import IssuerService from "js-admin/classes/AjaxServices/IssuerService";
import dayjs from "dayjs";
// imported actions
import helpdeskActions from "js-admin/store/actions/helpdesk-actions/HelpdeskActions";
import phoneActions from "js-admin/store/actions/phone-actions/PhoneActions";
import taskActions from "js-admin/store/actions/task-actions/TaskActions";

export default {
  async setUserId({ commit }) {
    try {
      const response = await UserService.getMe();
      const uuid = response.data.data.user_uuid;
      commit("loggedIn", uuid);
    } catch {
      commit("loggedIn", null);
    }
  },
  async setConfigData({ commit, dispatch }) {
    const now = dayjs();
    const expiry = window.localStorage.getItem("config_data_expiry");

    if (expiry && now < expiry) {
      return commit(
        "setConfig",
        JSON.parse(window.localStorage.getItem("config_data"))
      );
    }

    ConfigDataService.getConfigData().then((response) => {
      if (response.data) {
        commit("setConfig", response.data);

        window.localStorage.setItem(
          "config_data",
          JSON.stringify(response.data)
        );
        window.localStorage.setItem(
          "config_data_expiry",
          dayjs()
            .add(5, "days")
            .valueOf()
        );
      }
      return response;
    });
  },

  setUserAction({ commit }, user) {
    commit("setUser", user);
  },

  updateUserField({ commit }, value, field) {
    commit("updateUserField", value, field);
  },

  updateStoreItem({ commit }, value, stateKey = null) {
    commit("updateStore", value, stateKey);
  },

  deleteArrayItemFromStore({ commit }, stateKey, field, index) {
    commit("deleteArrayItemStore", stateKey, field, index);
  },

  updateArrayItemInStore({ commit }, value, stateKey, field, index) {
    commit("updateArrayItemStore", value, stateKey, field, index);
  },

  updateSubArrayItemStore(
    { commit },
    value,
    stateKey,
    field,
    index,
    subArrayField
  ) {
    commit(
      "updateSubArrayItemStore",
      value,
      stateKey,
      field,
      index,
      subArrayField
    );
  },

  addToArrayItemStore({ commit }, value) {
    commit("addToArrayItemStore", value);
  },

  deleteFromArrayItemStore({ commit }, value) {
    commit("deleteFromArrayItemStore", value);
  },

  storeLoginRedirect({ commit }, loginRedirect) {
    commit("storeLoginRedirect", loginRedirect);
  },

  updateLeadCount({ commit }, value) {
    commit("updateLeadCount", value);
  },

  fullscreenPanelOpen({ commit }, content) {
    commit("fullscreenPanelContent", content);
    commit("fullscreenPanelShow");
  },

  async logout() {
    return AuthService.logout();
  },

  async fetchAccountData({ commit }, uuid) {
    return AccountService.getAccountData(uuid)
      .then((response) => response)
      .catch((error) => error);
  },

  async fetchIssuerData({ commit }, uuid) {
    return IssuerService.getIssuerData(uuid)
      .then((response) => response)
      .catch((error) => error);
  },

  async fetchInvAccountData({ commit }, uuid) {
    return InvestmentAccountService.getInvAccountData(uuid).then(
      (response) => response
    );
  },

  async fetchCashWalletData({ commit }, uuid) {
    return CashWalletService.getCashWalletsData(uuid).then(
      (response) => response
    );
  },

  async fetchOwners({ commit }) {
    return AccountManagerService.getOwners().then((response) => {
      commit("setAgents", response.data.data);
      return response;
    });
  },

  async requestPasswordReset({ commit }, username) {
    return UserService.requestPasswordReset(username).then(
      (response) => response.data
    );
  },

  async resetUserTwoFactor({ commit }, uuid) {
    return UserService.resetUserTwoFactor(uuid).then(
      (response) => response.data
    );
  },

  async updateAddress({ commit }, data) {
    return AddressService.updateAddress(data).then((response) => response.data);
  },

  async deleteAddress({ commit }, uuid) {
    return AddressService.deleteAddress(uuid).then((response) => response);
  },

  async updateCompany({ commit }, data) {
    return CompanyService.updateCompany(data).then((response) => response.data);
  },

  async updateAMOwner({ commit, state }, data) {
    AccountManagerService.updateAMOwner({
      status: data.status,
      owner_uuid: data.owner_uuid,
    }).then((response) => {
      if (response.data.data) {
        const updatedOwners = JSON.parse(JSON.stringify(state.accountManagers));

        updatedOwners.forEach((owner) => {
          if (data.owner_uuid === owner.uuid) {
            owner.status = data.status;
          }
        });

        commit("updateAccountManagers", updatedOwners);
      }
    });
  },

  async getAccountManagers({ commit }) {
    try {
      const response = await AccountManagerService.getAccountManagers();
      commit("updateAccountManagers", response.data.data);
    } catch {
      throw new Error();
    }
  },

  async moveAMToTopRoundRobin({ commit }, ownerUuid) {
    return AccountManagerService.moveAMToTopRoundRobin(ownerUuid).then(
      (response) => {
        if (response.data) {
          commit("updateAccountManagers", response.data.data);

          return response;
        }

        return null;
      }
    );
  },

  async search({ commit }, params) {
    return AccountService.search(params).then((response) => response);
  },

  async updateBankDetails({ commit }, data) {
    return BankDetailsService.updateBankDetails(data).then(
      (response) => response.data
    );
  },

  async updateAccountUserLogin({ commit }, data) {
    return UserService.updateAccountUserLogin(data).then(
      (response) => response
    );
  },

  async deleteAccount({ commit }, uuid) {
    return AccountService.deleteAccount(uuid).then((response) => response);
  },

  async fetchAccountHistoryLogs({ commit }, data) {
    return AccountService.getAccountHistoryLogs(data).then(
      (response) => response.data
    );
  },

  async getRoles({ commit }) {
    RolePermissionService.getRoles().then((response) => {
      if (response.data.data) {
        commit("updateRoles", response.data.data);
      }
    });
  },

  async fetchSecuredMessage({ commit }, data) {
    return CommunicationService.getSecuredMessage(data).then(
      (response) => response
    );
  },

  async fetchEmail({ commit }, data) {
    return CommunicationService.getEmail(data).then((response) => response);
  },

  async createNote({ commit }, data) {
    return NoteService.createNote(data).then((response) => {
      if (response.data.data) {
        const note = {
          author: response.data.data.author,
          created_at: response.data.data.created_at,
          last_updated_at: null,
          last_updated_user: null,
          note: response.data.data.note,
          uuid: response.data.data.uuid,
        };
        commit("updateNotes", note);
      }
      return response.data;
    });
  },

  async updateNote({ commit }, data) {
    return NoteService.updateNote(data).then((response) => response);
  },

  async deleteNote({ commit }, uuid) {
    return NoteService.deleteNote(uuid).then((response) => response);
  },

  async addDocument({ commit }, data) {
    return DocumentService.addDocument(data).then((response) => response.data);
  },

  async addIssuerFile({ commit }, data) {
    return IssuerService.addFile(data).then((response) => response.data);
  },

  async deleteDocument({ commit }, uuid) {
    return DocumentService.deleteDocument(uuid).then((response) => response);
  },

  async deleteInvestmentAccount({ commit }, uuid) {
    return InvestmentAccountService.deleteInvestmentAccount(uuid).then(
      (response) => response
    );
  },

  async makePhoneCall({ commit }, data) {
    return CommunicationService.makePhoneCall(data).then(
      (response) => response
    );
  },

  async changeProfilePassword({ commit }, data) {
    return UserService.changeProfilePassword(data).then((response) => response);
  },

  ...taskActions,
  ...helpdeskActions,
  ...phoneActions,
};
