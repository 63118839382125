<template>
  <div class="v-account">
    <div v-if="repaymentLoadedData">
      <section v-if="!repayment404" class="c-section">
        <div class="row align-items-start">
          <div class="col-lg-9 col-sm-12">
            <h1 class="c-admin-page-title">
              {{ repayment.instrument.name }} - Repayment
            </h1>
            <button
              :href="false"
              class="c-button -secondary -sidebar"
              @click="viewInstrument(repayment.instrument.uuid)"
            >
              <strong>
                Back
              </strong>
            </button>
          </div>
        </div>

        <div class="c-admin-panel mt-2 mb-4">
          <div class="c-admin-panel-section">
            <h3 class="c-admin-panel-title">
              Repayment Info
            </h3>
            <div class="row">
              <div v-if="repayment.repayment_date" class="col-5">
                <p>
                  Repayment Date:
                  <strong>
                    {{ dateFormat(repayment.repayment_date) }}
                  </strong>
                </p>
              </div>
              <div v-if="repayment.status" class="col-5">
                <p>
                  Status:
                  <strong>
                    {{ capitaliseFirstLetter(repayment.status) }}
                  </strong>
                </p>
              </div>
              <div v-if="repayment.capital" class="col-5">
                <p>
                  Capital Amount:
                  <strong>
                    {{ currencySymbol }}{{ convertToPounds(repayment.capital) }}
                  </strong>
                </p>
              </div>
              <div v-if="repayment.interest" class="col-5">
                <p>
                  Interest Amount:
                  <strong>
                    {{ currencySymbol
                    }}{{ convertToPounds(repayment.interest) }}
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h3 class="c-admin-panel-title">
              Repayment Lines ({{ repayment.no_of_repayments }})
            </h3>
            <div v-if="repaymentLinesDataReady">
              <div v-if="repaymentLines" class="c-admin-table mt-2">
                <div class="c-admin-table-header">
                  <div class="row align-items-center">
                    <div class="col-2">
                      Reference
                    </div>
                    <div class="col-2">
                      Account
                    </div>
                    <div class="col-3">
                      Capital Repayment
                    </div>
                    <div class="col-3">
                      Interest Repayment
                    </div>
                    <div class="col-2">
                      Status
                    </div>
                  </div>
                </div>
                <div
                  v-for="line in repaymentLines"
                  :key="line.uuid"
                  class="c-admin-table-row"
                >
                  <VInstrumentRepaymentLinesRow
                    :line="line"
                    :currency-symbol="currencySymbol"
                  />
                </div>
                <div class="c-admin-table-footer">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <div class="c-admin-table-footer-text">
                        <VPagination
                          v-model="page"
                          :page-count="pages.length"
                          :classes="bootstrapPaginationClasses"
                          :labels="paginationAnchorTexts"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                No found repayment lines.
              </div>
            </div>
            <v-ajax-loader v-else />
          </div>
        </div>
      </section>
      <div v-else>
        <section class="c-section">
          <div class="row">
            <div class="col-12">
              <h1 class="c-admin-page-title">
                Repayment Not Found
              </h1>
              <div class="mt-4">
                Please use the buttons below to go back to the instrument page.
              </div>
              <div class="mt-4">
                <a
                  :href="false"
                  class="c-button -admin-small"
                  @click="viewInstrument(repayment.instrument.uuid)"
                >
                  Back to Instrument
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import VInstrumentRepaymentLinesRow from "js-admin/components/instrument/primary-market/InstrumentRepaymentLinesRow.vue";
import VPagination from "vue-plain-pagination";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import PrimaryMarketService from "js-admin/classes/AjaxServices/PrimaryMarket/PrimaryMarketService";

export default {
  components: {
    VInstrumentRepaymentLinesRow,
    VPagination,
  },
  data() {
    return {
      repayment: null,
      repaymentDataReady: false,
      repaymentNotFound: false,
      repaymentLinesDataReady: false,
      repaymentLinesCount: null,
      repaymentLines: null,

      page: 1,
      perPage: 25,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
    };
  },

  computed: {
    repaymentLoadedData() {
      return this.repaymentDataReady;
    },
    repayment404() {
      return this.repaymentNotFound;
    },
    currencySymbol() {
      return this.repayment.instrument.currency.symbol;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  async mounted() {
    if (!this.checkPermission("view_issuer")) {
      return this.$router.push({ name: "home" });
    }

    await PrimaryMarketService.getInstrumentRepayment(this.$route.params.uuid)
      .then((response) => {
        this.repayment = response.data.data;
      })
      .catch(() => {
        this.repayment = null;
        this.repaymentNotFound = true;
      });

    this.repaymentDataReady = true;

    if (!this.repaymentNotFound) {
      await PrimaryMarketService.getInstrumentRepaymentLines(
        this.$route.params.uuid,
        { return_count: true }
      ).then((response) => {
        this.repaymentLinesCount = response.data.data.count;
        this.setPages();
        this.paginate();
      });
    }
  },

  methods: {
    viewInstrument(uuid) {
      this.$router.push({
        name: "primary-market-instrument",
        params: { uuid },
      });
      return false;
    },
    paginate() {
      this.repaymentLinesDataReady = false;
      return PrimaryMarketService.getInstrumentRepaymentLines(
        this.$route.params.uuid,
        {
          start: this.page * this.perPage - this.perPage,
          limit: this.perPage,
        }
      )
        .then((response) => {
          this.repaymentLines = response.data.data;
          this.repaymentLinesDataReady = true;
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
          this.repaymentLinesDataReady = false;
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(
        this.repaymentLinesCount,
        this.perPage
      );
    },
  },
};
</script>
