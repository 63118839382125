<template>
  <div class="v-account">
    <div>
      <section class="c-section">
        <div class="row">
          <div class="col-12">
            <h1 class="c-admin-page-title">
              Create New Issuer for {{ instrument ? instrument.name : "" }}
            </h1>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-9 col-lg-12">
            <v-loader v-if="loadingClientData" />
            <VCreateIssuerForm :instrument="instrument" :client="client" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VCreateIssuerForm from "js-admin/components/issuers/CreateIssuerForm.vue";
import InstrumentService from "js-admin/classes/AjaxServices/InstrumentService";
import PrimaryMarketService from "js-admin/classes/AjaxServices/PrimaryMarket/PrimaryMarketService";

export default {
  components: {
    VCreateIssuerForm,
  },
  data() {
    return {
      instrument: null,
      stateKey: "instrumentData",
      client: null,
      loadingClientData: false,
    };
  },

  created() {
    this.clearErrors(1);
  },

  mounted() {
    if (!this.checkPermission("add_issuer")) {
      return this.$router.push({ name: "home" });
    }

    if (this.$route.query.instrument_uuid) {
      this.loadingClientData = true;
      PrimaryMarketService.getInstrument(
        this.$route.query.instrument_uuid
      ).then((response) => {
        this.client = response.data.data.client;
        this.client.brand_colour = response.data.data.brand_colour;
        this.loadingClientData = false;
      });
    }

    InstrumentService.getInstrument(this.$route.params.uuid)
      .then((response) => {
        this.instrument = response.data.data;

        this.loading = false;
      })
      .catch(() => {
        this.notFound = true;
      });
  },
};
</script>
