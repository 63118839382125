<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item>
        <template v-slot:title>
          Transaction History ({{ transHistoryCount }})
        </template>
        <div v-if="transHistoryCount" class="c-admin-table">
          <div class="c-admin-table-header">
            <div class="row align-items-center">
              <div class="col-2">
                Date
              </div>
              <div class="col-3">
                Reference
              </div>
              <div class="col-2">
                Type
              </div>
              <div class="col-2">
                Status
              </div>
              <div class="col">
                Amount
              </div>
            </div>
          </div>

          <v-transactions-row
            v-for="(transaction, index) in history"
            :key="transaction.uuid"
            :transaction="transaction"
            :index="index"
          />
        </div>
        <div v-else>
          No transactions.
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VTransactionsRow from "js-admin/components/cash-wallet/transactions/TransactionsRow.vue";
import CashWalletService from "js-admin/classes/AjaxServices/CashWalletService";

export default {
  components: {
    VTransactionsRow,
  },

  data() {
    return {
      history: [],
    };
  },
  computed: {
    cashWalletUuid() {
      return this.$store.state.fieldStore.cashWalletData.uuid;
    },
    transHistoryCount() {
      return this.history.length;
    },
  },
  mounted() {
    CashWalletService.getTransactions(this.cashWalletUuid).then((response) => {
      this.history = response.data.data;
    });
  },
};
</script>
