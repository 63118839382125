<template>
  <div class="v-accounts">
    <section v-if="issuersReady" class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title mb-5">
            Secondary Market Issuers
          </h1>
        </div>
      </div>

      <div class="row align-items-end">
        <div v-if="checkPermission('view_issuer')" class="col-6">
          <div class="mb-3">
            Search: <br />
            Please enter instrument name.
          </div>
          <form :data-vv-scope="searchScope" @submit.prevent="search">
            <v-text-input
              :scope="searchScope"
              :state-key="searchStateKey"
              field="searchTerm"
              label="Search"
              validation="required"
              error-name="search term"
              class="mr-3 h-mb-1"
            />
            <v-button
              class="c-button -admin-small mb-2"
              :loading="searchInProgress"
              :disabled="searchInProgress"
            >
              Search
            </v-button>

            <a
              v-if="searchPerformed"
              :href="false"
              class="c-button -admin-small mb-2 ml-1"
              @click="clearSearch"
            >
              Clear Search
            </a>
          </form>
        </div>
        <div v-if="checkPermission('view_issuer')" class="col-3" />
        <div v-if="checkPermission('view_issuer')" class="col-3">
          <a
            :href="false"
            class="c-button -admin-small h-mb-1"
            @click="viewInstruments"
          >
            View Secondary <br />
            Market Instruments
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header">
              <div class="row">
                <div class="col-3">
                  Name
                </div>
                <div class="col-3">
                  Active?
                </div>
                <div class="col-3">
                  Linked Instruments
                </div>
                <div class="col-3">
                  &nbsp;
                </div>
              </div>
            </div>

            <div v-if="issuersCount">
              <div
                v-for="(issuer, index) in displayedIssuers"
                :key="index"
                class="c-admin-table-row"
              >
                <div class="row">
                  <div class="col-3">
                    {{ issuer.name }}
                    <br />
                    <small>
                      Imported on: <br />
                      {{ dateTimeFormat(issuer.created_at, "DD/MM/YYYY") }}
                    </small>
                  </div>
                  <div class="col-3">
                    <div v-if="issuer.active"> Yes </div>
                    <div v-else> No </div>
                  </div>
                  <div class="col-3">
                    <div v-if="issuer.instruments.length">
                      <div
                        v-for="(instrument, index2) in issuer.instruments"
                        :key="index2"
                      >
                        <router-link
                          class="c-link"
                          :to="`/instrument/${instrument.uuid}`"
                        >
                          {{ instrument.instrument_name }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 text-center">
                    <a
                      :href="false"
                      class="c-button -admin-small"
                      @click="viewIssuer(issuer.uuid)"
                    >
                      View
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  No issuers found
                </div>
              </div>
            </div>

            <div class="c-admin-table-footer">
              <div class="row">
                <div class="col-12">
                  <v-pagination
                    v-model="page"
                    :page-count="pages.length"
                    :classes="bootstrapPaginationClasses"
                    :labels="paginationAnchorTexts"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import vPagination from "vue-plain-pagination";
import IssuerService from "js-admin/classes/AjaxServices/IssuerService";

export default {
  components: {
    vPagination,
  },

  data() {
    return {
      issuers: null,
      page: 1,
      perPage: 10,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      searchScope: "searchForm",
      searchStateKey: "issuersList",
      searchInProgress: false,
      searchPerformed: false,
      issuersCount: null,
      issuersReady: false,
    };
  },
  computed: {
    searchTerm() {
      return this.$store.state.fieldStore[this.searchStateKey].searchTerm;
    },
    displayedIssuers() {
      return this.issuers;
    },
  },

  watch: {
    page() {
      this.paginate();
    },

    issuers() {
      this.issuersReady = true;
    },

    issuersCount() {
      this.setPages();
    },
  },

  mounted() {
    if (!this.checkPermission("view_issuer")) {
      return this.$router.push({ name: "home" });
    }

    this.clearSearch();
  },

  methods: {
    viewIssuer(uuid) {
      this.$router.push({ name: "issuer", params: { uuid } });
      return false;
    },

    viewInstruments() {
      this.$router.push({ name: "instruments" });
      return false;
    },

    setPages() {
      this.pages = [];
      const numberOfPages = Math.ceil(this.issuersCount / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
      if (this.page > numberOfPages) {
        this.page = 1;
      }
    },

    loadData() {
      this.page = 1;
      this.paginate();
    },

    paginate() {
      const paginateParams = {
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      };

      const params = {};

      if (this.searchTerm) {
        params.term = this.searchTerm;
      }

      IssuerService.getIssuers({
        count: true,
        ...params,
      }).then((response) => {
        if (response.data) {
          this.issuersCount = response.data.data.count;
        }
      });

      IssuerService.getIssuers({
        ...paginateParams,
        ...params,
      }).then((response) => {
        if (response.data) {
          this.issuers = response.data.data;
        }
      });
    },

    search() {
      this.$validator.validateAll(this.searchScope).then(async (result) => {
        this.searchPerformed = true;
        if (result) {
          this.$nextTick(() => {
            this.loadData();
          });
        }
      });
    },

    clearSearch() {
      this.searchPerformed = false;

      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.searchStateKey,
        field: "searchTerm",
      });

      this.clearErrors(1);

      this.$nextTick(() => {
        this.loadData();
      });
    },
  },
};
</script>
