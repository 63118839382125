<template>
  <div class="c-task-table">
    <Datetime
      key="1"
      ref="taskCalendar"
      v-model="calendarDate"
      type="date"
      style="position: absolute; margin-left:-100%;"
      value-zone="Europe/London"
    />
    <v-loader :loading="dataLoading" />
    <div v-if="showTitle" class="row">
      <div class="col-12 h-mb-1">
        <h2 class="c-admin-page-title">
          {{ tableTitle }}
        </h2>
      </div>
    </div>
    <div v-if="withSortOptions" class="row">
      <div class="col-auto h-mb-1">
        <v-select-input
          :options="filterList"
          :enable-blank-option="true"
          state-key="task"
          field="task_filter"
          center-error="false"
          label="Filter"
          error-name="task filter"
          placeholder="Select a filter"
          validation="required"
          @updated="handleFilter"
        />
      </div>
      <div class="col-auto h-mb-1 d-flex align-items-center">
        <svg
          aria-hidden="true"
          data-prefix="far"
          data-icon="calendar-alt"
          class="image-calendar h-cursor-pointer svg-inline--fa fa-calendar-alt fa-w-14"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          @click="handleCalendar"
        >
          <path
            fill="currentColor"
            d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
          />
        </svg>
        <span v-if="calendarDate" class="mt-3 ml-3 mr-3">
          <strong>
            {{ displayCalendarDate }}
          </strong>
        </span>
        <a
          v-if="calendarDate"
          :href="false"
          class="c-button -admin-small mt-3"
          @click="clearDate"
        >
          Clear Date
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="c-admin-table">
          <div v-if="sideLayout === false" class="c-admin-table-header">
            <div class="row">
              <div class="col-3">
                <div
                  v-if="withSortOptions"
                  class="-pointer -clickable"
                  @click="handleOrder('title', orderToggle(0))"
                >
                  Title
                  <div :class="['c-task-icon-wrap', titleFlip]">
                    <i class="fas fa-chevron-down" />
                  </div>
                </div>
                <div v-else> Title</div>
              </div>
              <div class="col-3">
                <div
                  v-if="withSortOptions"
                  class="-pointer -clickable"
                  @click="handleOrder('due_date', orderToggle(1))"
                >
                  Due Date
                  <div :class="['c-task-icon-wrap', dateFlip]">
                    <i class="fas fa-chevron-down" />
                  </div>
                </div>
                <div v-else> Due Date</div>
              </div>
              <div class="col-2">
                <div
                  v-if="withSortOptions"
                  class="-pointer -clickable"
                  @click="handleOrder('priority', orderToggle(2))"
                >
                  Priority
                  <div :class="['c-task-icon-wrap', priorityFlip]">
                    <i class="fas fa-chevron-down" />
                  </div>
                </div>
                <div v-else> Priority</div>
              </div>
              <div class="col-2">
                <div
                  v-if="withSortOptions"
                  class="-pointer -clickable"
                  @click="handleOrder('status', orderToggle(3))"
                >
                  Status
                  <div :class="['c-task-icon-wrap', statusFlip]">
                    <i class="fas fa-chevron-down" />
                  </div>
                </div>
                <div v-else> Status</div>
              </div>
              <div v-if="!checkRole('account_manager')" class="col-2">
                Owner
              </div>
            </div>
          </div>
          <div v-if="paginatedData.length">
            <div
              v-for="(task, index) in paginatedData"
              :key="index"
              :class="[
                'c-admin-table-row',
                {
                  '-selected': task.uuid === $route.params.t_uuid,
                  '-overdue': task.overdue === true,
                  '-completed': task.status === 'Completed',
                },
              ]"
            >
              <!-- FULL LAYOUT -->
              <div v-if="sideLayout === false" class="row align-items-center">
                <div class="col-3">
                  <router-link
                    class="c-link"
                    :to="`/tasks/task-details/${task.uuid}`"
                  >
                    {{ task.title }}
                  </router-link>
                </div>
                <div class="col-3">
                  {{ dateTimeFormat(task.due_date) }}
                </div>
                <div class="col-2">
                  <VTaskPill :priority="task.priority" />
                </div>
                <div class="col-2">
                  {{ task.status }}
                </div>
                <div v-if="!checkRole('account_manager')" class="col-2">
                  {{ task.owner.name || null }}
                </div>
              </div>
              <!-- SIDEBAR LAYOUT -->
              <div v-else class="-pointer" @click="viewTaskPage(task.uuid)">
                <div class="row">
                  <div class="col-1 c-task-header h-mb-0">
                    <i class="far fa-clipboard" />
                  </div>
                  <div class="col-11 col-xl-8 c-task-header">
                    <h2>{{ task.title }}</h2>
                  </div>
                  <div class="col-12 col-xl-3 c-task-priority text-right">
                    <VTaskPill :priority="task.priority" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 c-task-owner">
                    {{ task.owner.name }}
                  </div>
                  <div class="col-6 c-task-status text-right">
                    {{ task.status }}
                  </div>
                  <div class="col-12 col-xl-8 c-task-owner">
                    {{ task.account.full_name }}
                  </div>
                  <div class="col-12 col-xl-4 c-task-date text-right">
                    {{ dateTimeFormat(task.due_date) }}
                  </div>
                  <div class="col-12 c-task-header">
                    <strong>{{ noteCount(index) }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="c-admin-table-row"> No tasks</div>
          <div v-if="paginatedData.length" class="c-admin-table-footer">
            <div class="row">
              <div class="col-12">
                <VPagination
                  v-if="pagesLength > 0"
                  v-model="page"
                  :page-count="pagesLength"
                  :classes="bootstrapPaginationClasses"
                  :labels="paginationAnchorTexts"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VPagination from "vue-plain-pagination";
import VTaskPill from "js-admin/components/tasks/TaskPill.vue";
import VLoader from "js-admin/components/common/Loader";
import dayjs from "dayjs";
import { Datetime } from "vue-datetime";

export default {
  components: {
    VPagination,
    VTaskPill,
    VLoader,
    Datetime,
  },
  props: {
    showTitle: {
      default: true,
    },
    withSortOptions: {
      default: true,
    },
    sideLayout: {
      default: false,
    },
    taskOwner: {
      default: null,
    },
    taskData: {
      default: null,
    },
    title: {
      default: null,
    },
  },
  data() {
    return {
      dataLoading: false,
      showCompletedTask: false,
      dueDateVal: null,
      page: 1,
      perPage: 5,
      calendarDate: null,
      pages: [],
      orderArr: ["ASC", "ASC", "ASC", "ASC"],
      orderInd: null,
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      filterList: [
        {
          value: "all",
          title: "All tasks",
        },
        {
          value: "open",
          title: "Open tasks",
        },
        {
          value: "overdue",
          title: "Overdue tasks",
        },
        {
          value: "today",
          title: "Today's tasks",
        },
        {
          value: "tomorrow",
          title: "Tomorrow's tasks",
        },
      ],
      todaysDate: new Date(),
    };
  },
  computed: {
    pagesLength() {
      return this.pages.length;
    },
    titleFlip() {
      return {
        "h-flip-icon-0": this.orderArr[0] == "ASC",
        "h-flip-icon-180": this.orderArr[0] == "DESC",
      };
    },
    dateFlip() {
      return {
        "h-flip-icon-0": this.orderArr[1] == "ASC",
        "h-flip-icon-180": this.orderArr[1] == "DESC",
      };
    },
    priorityFlip() {
      return {
        "h-flip-icon-0": this.orderArr[2] == "ASC",
        "h-flip-icon-180": this.orderArr[2] == "DESC",
      };
    },
    statusFlip() {
      return {
        "h-flip-icon-0": this.orderArr[3] == "ASC",
        "h-flip-icon-180": this.orderArr[3] == "DESC",
      };
    },
    paginatedData() {
      return this.paginate(this.taskData);
    },
    tableTitle() {
      if (this.showCompletedTask) {
        return "All Tasks";
      }
      return this.title;
    },
    taskFilter() {
      return this.$store.state.fieldStore.task.task_filter;
    },
    displayCalendarDate() {
      return dayjs(this.calendarDate)
        .set("hour", 0)
        .set("minute", 0)
        .format("DD/MM/YYYY");
    },
  },
  watch: {
    taskData(n, o) {
      this.setPages();
    },
    calendarDate(n) {
      this.filterTaskList();
    },
  },
  created() {
    this.setPages();
  },
  mounted() {
    this.filterTaskList();
  },
  methods: {
    handleCalendar() {
      const cal = document.querySelector(".vdatetime-input");
      cal.click();
    },

    clearDate() {
      this.calendarDate = null;
    },

    handleFilter(value) {
      this.filterTaskList();
    },

    noteCount(ind) {
      if (
        typeof this.taskData[ind].notes !== "undefined" &&
        this.taskData[ind].notes != null
      ) {
        return `${this.taskData[ind].notes.length} Notes`;
      }
    },

    orderToggle(ind) {
      if (this.orderArr[ind] === "ASC") {
        this.orderArr[ind] = "DESC";
      } else {
        this.orderArr[ind] = "ASC";
      }
      return this.orderArr[ind];
    },

    async filterTaskList(fields = {}) {
      this.dataLoading = true;

      const params = fields;

      if (this.calendarDate) {
        const endDay = dayjs(this.calendarDate)
          .set("hour", 23)
          .set("minute", 59);
        params.from_date = this.dateTimeFormat(
          this.calendarDate,
          "YYYY-MM-DD HH:mm:ss"
        );
        params.to_date = this.dateTimeFormat(endDay, "YYYY-MM-DD HH:mm:ss");
      }

      if (this.taskFilter) {
        const tomorrowsDate = new Date();
        tomorrowsDate.setDate(this.todaysDate.getDate() + 1);
        switch (this.taskFilter) {
          case "all":
            params.include_completed_tasks = 1;
            break;
          case "overdue":
            params.show_overdue_tasks_only = 1;
            break;
          case "today":
            params.from_date = this.formattedDateForApi(
              this.todaysDate,
              "00:00:00"
            );
            params.to_date = this.formattedDateForApi(
              this.todaysDate,
              "23:59:59"
            );
            break;
          case "tomorrow":
            params.from_date = this.formattedDateForApi(
              tomorrowsDate,
              "00:00:00"
            );
            params.to_date = this.formattedDateForApi(
              tomorrowsDate,
              "23:59:59"
            );
            break;
        }
      }

      await this.$store.dispatch("getTasks", params);
      this.dataLoading = false;
    },

    handleOrder(order, direction) {
      this.filterTaskList({
        order_field: order,
        order_direction: direction,
      });
    },

    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;
      const from = page * perPage - perPage;
      const to = page * perPage;
      return data.slice(from, to);
    },

    setPages() {
      const numberOfPages = Math.ceil(this.taskData.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
  },
};
</script>
