<template>
  <div>
    <h3 class="c-heading-xl">
      {{ title }}
    </h3>
    <div class="c-admin-table">
      <div class="c-admin-table-header">
        <div class="row">
          <div v-if="hideAccountBtn" class="col-1">
            Order
          </div>
          <div class="col-2">
            Type
          </div>
          <div class="col-2">
            Name
          </div>
          <div class="col-2">
            Email Address
          </div>
          <div class="col-2">
            Contact Numbers
          </div>
          <div v-if="!hideAccountBtn" class="col-2">
            Assigned On
          </div>
          <div v-if="!hideAccountBtn" class="col-2">
            &nbsp;
          </div>
        </div>
      </div>

      <div v-for="(account, index) in accounts" :key="index">
        <div class="c-admin-table-row">
          <div class="row">
            <div v-if="hideAccountBtn" class="col-1">
              {{ index + 1 }}
            </div>
            <div class="col-2">
              {{ account.lead_type }}
            </div>
            <div class="col-2">
              {{ account.title }} {{ account.first_name }}
              {{ account.last_name }}
            </div>
            <div class="col-2">
              <div v-if="account.email" class="text-break">
                {{ account.email }}
              </div>
              <div v-else>
                N.A.
              </div>
            </div>
            <div class="col-2">
              <div v-if="account.primary_contact_number">
                {{ account.primary_contact_number }}
              </div>
              <div v-if="account.secondary_contact_number">
                {{ account.secondary_contact_number }}
              </div>
            </div>
            <div v-if="!hideAccountBtn" class="col-2">
              {{ dateFormat(account.assigned_on) }}
            </div>
            <div v-if="!hideAccountBtn" class="col-2 text-center">
              <a
                :href="false"
                class="c-button -admin-small"
                @click="viewAccount(account.uuid)"
              >
                View
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    accounts: {
      required: true,
    },
    hideAccountBtn: {
      default: false,
    },
    title: {
      default: "Previously Assigned Leads/Accounts",
    },
  },

  methods: {
    viewAccount(uuid) {
      this.$router.push({ name: "account", params: { uuid } });
    },
  },
};
</script>
