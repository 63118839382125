<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item>
        <template v-slot:title>
          Audit Activities ({{ auditLogsCount }})
        </template>
        <div v-if="auditLogsCount" class="c-admin-table">
          <div class="c-admin-table-header">
            <div class="row align-items-center">
              <div class="col">
                Created at
              </div>
              <div class="col">
                Description
              </div>
            </div>
          </div>

          <v-audit-logs-row
            v-for="(auditLog, index) in auditLogs"
            :key="auditLog.uuid"
            :audit-log="auditLog"
            :index="index"
          />
        </div>
        <div v-else>
          No audit logs.
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VAuditLogsRow from "js-admin/components/cash-wallet/audit-logs/AuditLogsRow.vue";
import CashWalletService from "js-admin/classes/AjaxServices/CashWalletService";

export default {
  components: {
    VAuditLogsRow,
  },

  data() {
    return {
      auditLogs: [],
    };
  },
  computed: {
    cashWalletUuid() {
      return this.$store.state.fieldStore.cashWalletData.uuid;
    },
    auditLogsCount() {
      return this.auditLogs.length;
    },
  },
  mounted() {
    CashWalletService.getAuditLogs(this.cashWalletUuid).then((response) => {
      this.auditLogs = response.data.data;
    });
  },
};
</script>
