import TaskService from "js-admin/classes/AjaxServices/TaskService";

export default {
  async changeSelectedTask({ commit, getters }, uuid) {
    return TaskService.changeSelectedTask(uuid).then((response) => {
      if (response.data) {
        commit("setSelectedTask", response.data.data);
        return true;
      } else {
        return response;
      }
    });
  },

  async getTasks({ commit, getters }, params) {
    if (!params) {
      params = {};
    }

    if (!params.order_field) {
      params.order_field = "due_date";
    }

    if (!params.order_direction) {
      params.order_direction = "asc";
    }

    if (
      (getters.getUser.roles.includes("account_manager") ||
        getters.getUser.roles.includes("senior_account_manager")) &&
      getters.getUser.owner
    ) {
      params.owner_uuid = getters.getUser.owner.uuid;
    }

    return TaskService.getTasks(params).then((response) => {
      if (response.data) {
        commit("setOwnerTaskData", response.data.data);
      }
      return response;
    });
  },

  async updateTask({ commit, getters }, params) {
    if (!params) {
      params = {};
    }

    return TaskService.updateTask(params).then((response) => {
      if (response.data) {
        commit("setSelectedTask", response.data.data);
      }
      return response;
    });
  },

  async createTask({ commit, getters }, params) {
    return TaskService.createTask(params).then((response) => {
      if (response.data) {
        commit("resetTaskData");
      }
      return response;
    });
  },
};
