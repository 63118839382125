// set size prop to 'large' or 'xl' for bigger modals
<template>
  <div :class="containerClasses">
    <transition name="fade">
      <div v-if="open" class="c-modal-wrap">
        <div
          ref="modal"
          tabindex="-1"
          class="c-modal"
          :class="[{ '-open': open }, sizeClass]"
          @keydown.esc="toggle"
        >
          <div
            ref="content"
            :class="[
              'c-modal-content',
              { '-overflow-visible': overflowVisible },
            ]"
          >
            <slot>Modal Content Slot</slot>
          </div>
          <button type="button" class="c-modal-close" @click="toggle">
            <svg
              width="12"
              height="12"
              version="1.1"
              class="c-modal-close-icon"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 47.971 47.971"
              style="enable-background: new 0 0 47.971 47.971;"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                  c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                  C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                  s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
                />
              </g>
            </svg>
          </button>
        </div>
        <div class="c-modal-bg" @click="toggle" />
      </div>
    </transition>

    <div
      v-if="trigger"
      class="c-modal-toggle"
      :class="{ '-center': centerToggle }"
      @click="toggle"
    >
      <slot name="button">
        <v-button type="button">
          Open Modal
        </v-button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["$validator"],
  props: {
    large: {
      default: false,
    },
    centerToggle: {
      default: false,
    },
    size: {
      default: null,
    },
    trigger: {
      default: true,
    },
    overflowVisible: {
      default: false,
    },
    containerClasses: {
      default: null,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    sizeClass() {
      switch (this.size) {
        case null:
          return "";
        case "square":
          return "-square";
        case "large":
          return "-large";
        case "xl":
          return "-xl";
        default:
          return "";
      }
    },
  },
  watch: {
    // open(newVal, oldVal) {
    //     if (newVal == true) {
    //         this.$nextTick(() => {
    //             this.resizeContent();
    //         })
    //     }
    // }
  },
  methods: {
    toggle() {
      this.open = !this.open;

      this.$nextTick(() => {
        if (this.open) {
          this.$refs.modal.focus();
        }
      });
    },
    resizeContent() {
      this.$refs.content.style.height =
        this.$refs.modal.offsetHeight - 30 + "px";
    },
  },
};
</script>
