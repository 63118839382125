import APIService from "js-admin/classes/APIService";
import { prepUrlParameters } from "js-admin/abstract/helpers";

export default class InstrumentService extends APIService {
  static CRUD_URL = "secondary-market/instruments";

  static ALLOCATIONS_URL = "/allocations";

  static async getInstruments(params, investmentAccountUuid = null) {
    const extraParams = prepUrlParameters(params);

    let url = this.CRUD_URL + "/list?" + extraParams;
    if (investmentAccountUuid) {
      url =
        this.CRUD_URL + "/list/" + investmentAccountUuid + "?" + extraParams;
    }

    return APIService.request(url);
  }

  static async getInstrument(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid);
  }

  static async updateInstrument(instrumentUuid, data) {
    return APIService.request(
      this.CRUD_URL + "/" + instrumentUuid,
      "put",
      data
    );
  }

  static async updateInstrumentIssuer(instrumentUuid, issuerUuid) {
    return APIService.request(
      this.CRUD_URL + "/" + instrumentUuid + "/update-issuer",
      "put",
      {
        issuer_uuid: issuerUuid,
      }
    );
  }

  static async getInstrumentAllocations(uuid, params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/" + uuid + "/share-allocations?" + extraParams
    );
  }

  static async bulkApproveAllocations(uuid) {
    return APIService.request(
      this.CRUD_URL + "/" + uuid + "/approve-pending-allocations",
      "post"
    );
  }

  static async approveAllocations(uuid) {
    return APIService.request(
      this.CRUD_URL + this.ALLOCATIONS_URL + "/" + uuid + "/" + "approve",
      "post"
    );
  }

  static async rejectAllocations(uuid, data) {
    return APIService.request(
      this.CRUD_URL + this.ALLOCATIONS_URL + "/" + uuid + "/" + "reject",
      "post",
      data
    );
  }
}
