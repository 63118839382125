<template>
  <div class="v-home">
    <section class="c-section -third-vertical-padding">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Welcome {{ fullname }}, to the Admin Dashboard
          </h1>
        </div>
      </div>
    </section>

    <section class="c-section -third-vertical-padding">
      <div class="row">
        <div v-if="!checkRole('compliance')" class="col-12 col-lg-6">
          <VAvailableAgents />
        </div>
        <div class="col-12 col-lg-6 h-mt-lg-1 h-mt-xl-0">
          <VHomeWithdrawalWidget />
        </div>
      </div>
      <div class="row">
        <div v-if="checkPermission('view_withdrawals')" class="col-12 col-lg-6">
          <h2 class="c-admin-page-title">Primary Market</h2>
          <a
            v-if="checkRole(['superadmin', 'senior_account_manager', 'admin'])"
            :href="primaryMarketUrl"
            class="c-button -admin-small mr-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Primary Market Dashboard
          </a>
          <!--          <h2 class="c-admin-page-title">Today's Call Stats</h2>-->
          <!--          <VCallStatsSummary :summary-data="summaryData" />-->
        </div>
        <div class="col-12 col-lg-6 h-mt-lg-1 h-mt-xl-0"> </div>
      </div>
    </section>

    <section
      v-if="
        checkPermission('view_tasks') &&
          !systemFeatureIsDisabled('disabled_tasks')
      "
      class="c-section -third-vertical-padding"
    >
      <VTaskTable
        :with-sort-options="true"
        :task-owner="getTaskOwner"
        :task-data="getOwnerTaskData"
        title="Open Tasks"
      />
    </section>
  </div>
</template>

<script>
import ReportingService from "js-admin/classes/AjaxServices/ReportingService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VAvailableAgents from "js-admin/components/agents/AvailableAgents.vue";
import VHomeWithdrawalWidget from "js-admin/components/withdrawals/HomeWithdrawakWidget.vue";
import VTaskTable from "js-admin/components/tasks/TaskTable.vue";
// import VCallStatsSummary from "js-admin/components/call-stats/CallStatsSummary.vue";

export default {
  components: {
    VAvailableAgents,
    VTaskTable,
    // VCallStatsSummary,
    VHomeWithdrawalWidget,
  },
  data() {
    return {
      summaryData: null,
      callStatResults: false,
    };
  },
  computed: {
    fullname() {
      return this.$store.state.user.name;
    },
    fromDate() {
      const today = new Date();
      return this.formattedDateForApi(today, "00:00:00");
    },
    toDate() {
      const today = new Date();
      return this.formattedDateForApi(today, "23:59:59");
    },
    primaryMarketUrl() {
      return this.$store.state.config.primary_market_admin_url;
    },
  },
  watch: {},
  mounted() {
    this.$store.dispatch("fetchOwners");

    if (!this.systemFeatureIsDisabled("disabled_phone_system")) {
      this.getResults();
    }
  },

  methods: {
    getResults() {
      const data = {
        from_date: this.fromDate,
        to_date: this.toDate,
      };

      this.callStatResults = true;

      if (!this.checkRole("compliance")) {
        ReportingService.getCallStats(data)
          .then((response) => {
            this.callStatResults = false;
            this.summaryData = response.data.data.summary;
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      } else {
        ReportingService.getComplianceCallStats(data)
          .then((response) => {
            this.callStatResults = false;
            this.summaryData = response.data.data.summary;
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      }
    },
  },
};
</script>
