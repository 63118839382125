<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title>
          <div class="text-center"> Latest Logins to Account </div>
        </template>

        <div v-if="dataReady" class="c-admin-panel-section">
          <div v-if="logins">
            <div v-for="(login, index) in logins" :key="index" class="">
              <div class="row">
                <div class="col-5">
                  {{ dateTimeFormat(login.date) }}
                </div>
                <div class="col-7">
                  {{ login.user }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center">
            None
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import AccountService from "js-admin/classes/AjaxServices/AccountService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  data() {
    return {
      dataReady: false,
      logins: null,
    };
  },

  computed: {
    accountUuid() {
      return this.$store.state.fieldStore.accountData.uuid;
    },
  },

  methods: {
    accordionToggle(bool) {
      this.dataReady = false;

      return AccountService.getAccountLogins({
        uuid: this.accountUuid,
      })
        .then((response) => {
          if (response.data) {
            this.logins = response.data.data;
            this.dataReady = true;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
        });
    },
  },
};
</script>
