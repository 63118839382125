<template>
  <div class="c-admin-panel">
    <form :data-vv-scope="createLeadFormScope" @submit.prevent="createLead">
      <div class="c-admin-panel-section">
        <div class="row">
          <div class="col-12 col-md-5">
            <h3 class="c-admin-panel-title">
              Personal Details
            </h3>

            <div>
              <v-text-input
                :scope="createLeadFormScope"
                :state-key="stateKey"
                field="title"
                label="Title"
                validation="required"
              />
            </div>

            <div>
              <v-text-input
                :scope="createLeadFormScope"
                :state-key="stateKey"
                field="first_name"
                label="First Name"
                validation="required"
              />
            </div>

            <div>
              <v-text-input
                :scope="createLeadFormScope"
                :state-key="stateKey"
                field="middle_name"
                label="Middle Name"
                validation=""
              />
            </div>

            <div>
              <v-text-input
                :scope="createLeadFormScope"
                :state-key="stateKey"
                field="last_name"
                label="Last Name"
                validation="required"
              />
            </div>
          </div>
          <div class="col-12 col-md-7">
            <h3 class="c-admin-panel-title">
              Contact Details
            </h3>

            <div class="row">
              <div class="col-12">
                <v-text-input
                  :scope="createLeadFormScope"
                  :state-key="stateKey"
                  field="primary_contact_number"
                  label="Primary Contact Number"
                  validation="required|phone_number"
                  error-name="primary contact number"
                />
              </div>
            </div>

            <div>
              <v-text-input
                :scope="createLeadFormScope"
                :state-key="stateKey"
                field="email"
                label="Email address"
                validation=""
                error-name="email address"
              />
            </div>
          </div>
        </div>

        <h3 class="c-admin-panel-title">
          Primary Address Details
        </h3>
        <div class="row">
          <div class="col-12">
            <VPostcodeFinder
              :scope="createLeadFormScope"
              :state-key="stateKey"
              validation=""
              prefix="primary_address_"
            />
          </div>
        </div>

        <h3 class="c-admin-panel-title">
          <i class="fas fa-bell" />
          Lead Information
        </h3>
        <div class="row mb-5">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-12">
                <v-select-input
                  :scope="createLeadFormScope"
                  :state-key="stateKey"
                  :options="leadTypes"
                  :center-error="false"
                  field="type"
                  validation="required"
                  error-name="lead type"
                  placeholder="Select a lead type"
                  class="mb-4"
                  :enable-blank-option="true"
                />
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-12">
                <v-textarea
                  :scope="createLeadFormScope"
                  :state-key="stateKey"
                  field="description"
                  label="Lead description"
                  placeholder="Lead Description (optional)"
                  validation=""
                  error-name="lead description"
                />
              </div>
            </div>
          </div>
        </div>

        <h3 class="c-admin-panel-title">
          <i class="fas fa-bell" />
          Preferences
        </h3>
        <div class="row mb-5">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-8">
                GPDR Marketing Opt In
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="gdpr_optin_flag"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                Marketing notifications
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_marketing_notifications"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                Product updates
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_product_notifications"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                Industry news
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_industry_news_notifications"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 class="c-admin-panel-title">
          <i class="fas fa-inbox"></i>
          Contact Preferences
        </h3>
        <div class="row mb-5">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-8">
                Phone
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_phone"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                SMS
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_sms"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8">
                Email
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_email"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-8">
                Post
              </div>
              <div class="col-4">
                <div class="c-notifications-switches">
                  <div
                    class="c-notifications-switches-item justify-content-end"
                  >
                    <v-switch
                      field="subscribed_post"
                      :state-key="stateKey"
                      class="d-inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 class="c-admin-panel-title">
            <i class="fas fa-exclamation" />
            Actions
          </h3>
          <div class="row align-items-center">
            <div class="col-12 col-md-5">
              Who do you want to assign the lead to?
            </div>
            <div class="col-4">
              <div v-if="checkRole('account_manager')">
                {{ this.$store.state.user.name }}
              </div>
              <div v-else>
                <v-select-input
                  :scope="createLeadFormScope"
                  :state-key="stateKey"
                  :options="ownerData"
                  :center-error="false"
                  field="owner_uuid"
                  validation="required"
                  error-name="owner"
                  placeholder="Select a owner"
                  class="mb-0"
                  :enable-blank-option="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <v-button
              class="mt-3"
              :loading="submittingLeadDetails"
              :disabled="submittingLeadDetails"
            >
              Create
            </v-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VPostcodeFinder from "js-admin/components/form/PostcodeFinder.vue";
import LeadService from "js-admin/classes/AjaxServices/LeadService";

export default {
  components: {
    VPostcodeFinder,
  },
  props: {
    linkedCall: {
      default: null,
    },
  },

  data() {
    return {
      createLeadFormScope: "createLeadForm",
      stateKey: "createLead",
      submittingLeadDetails: false,
    };
  },

  computed: {
    ownerData() {
      return this.$store.state.accountManagers;
    },
    leadTypes() {
      const types = this.$store.state.config.lead_types;
      const leadTypes = [];

      Object.keys(types).forEach((key) => {
        leadTypes.push({
          value: key,
          name: types[key],
        });
      });

      return leadTypes;
    },
  },

  mounted() {
    if (!this.checkRole("account_manager")) {
      this.$store.dispatch("getAccountManagers");
    }
  },

  methods: {
    createLead() {
      this.$validator.validateAll(this.createLeadFormScope).then((result) => {
        if (result) {
          this.submittingLeadDetails = true;

          const data = {
            title: this.$store.state.fieldStore[this.stateKey].title,
            first_name: this.$store.state.fieldStore[this.stateKey].first_name,
            middle_name: this.$store.state.fieldStore[this.stateKey]
              .middle_name,
            last_name: this.$store.state.fieldStore[this.stateKey].last_name,
            primary_contact_number: this.$store.state.fieldStore[this.stateKey]
              .primary_contact_number,
            email: this.$store.state.fieldStore[this.stateKey].email,
            primary_address_1: this.$store.state.fieldStore[this.stateKey]
              .primary_address_line_1,
            primary_address_2: this.$store.state.fieldStore[this.stateKey]
              .primary_address_line_2,
            primary_address_3: this.$store.state.fieldStore[this.stateKey]
              .primary_address_line_3,
            primary_address_town: this.$store.state.fieldStore[this.stateKey]
              .primary_address_town,
            primary_address_county: this.$store.state.fieldStore[this.stateKey]
              .primary_address_county,
            primary_address_postcode: this.$store.state.fieldStore[
              this.stateKey
            ].primary_address_postcode,
            primary_address_country: this.$store.state.fieldStore[this.stateKey]
              .primary_address_country,
            type: this.$store.state.fieldStore[this.stateKey].type,
            description: this.$store.state.fieldStore[this.stateKey]
              .description,
            gdpr_optin_flag: this.$store.state.fieldStore[this.stateKey]
              .gdpr_optin_flag,
            subscribed_marketing_notifications: this.$store.state.fieldStore[
              this.stateKey
            ].subscribed_marketing_notifications,
            subscribed_product_notifications: this.$store.state.fieldStore[
              this.stateKey
            ].subscribed_product_notifications,
            subscribed_industry_news_notifications: this.$store.state
              .fieldStore[this.stateKey].subscribed_industry_news_notifications,
            subscribed_phone: this.$store.state.fieldStore[this.stateKey]
              .subscribed_phone,
            subscribed_sms: this.$store.state.fieldStore[this.stateKey]
              .subscribed_sms,
            subscribed_email: this.$store.state.fieldStore[this.stateKey]
              .subscribed_email,
            subscribed_post: this.$store.state.fieldStore[this.stateKey]
              .subscribed_post,
          };

          if (this.checkRole("account_manager")) {
            data.owner_uuid = this.$store.state.user.owner.uuid;
          } else {
            data.owner_uuid = this.$store.state.fieldStore[
              this.stateKey
            ].owner_uuid;
          }

          if (this.linkedCall) {
            data.call_uuid = this.linkedCall;
          }

          LeadService.createLead(data)
            .then((response) => {
              FlashMessageService.success("Lead has been created");

              this.submittingLeadDetails = false;

              this.$store.dispatch("updateStoreItem", {
                value: this.getEmptyLead(),
                stateKey: this.stateKey,
                field: null,
              });

              this.clearErrors(1);

              if (this.linkedCall) {
                this.$store.dispatch("updateInProgressCall", this.linkedCall);
              }

              this.$router.push("/account/" + response.data.data.account_uuid);
              return true;
            })
            .catch((error) => {
              this.submittingLeadDetails = false;
              FlashMessageService.error(error.response.data.message);
            });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },
  },
};
</script>
