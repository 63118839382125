<template>
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title> Permissions</template>

        <div v-if="dataReady">
          <div class="c-admin-table">
            <form @submit.prevent="setUserPermissions">
              <div class="c-admin-table-header">
                <div class="row align-items-center">
                  <div class="col-8">
                    Permission
                  </div>
                  <div class="col-4 text-right">
                    Has Permission?
                  </div>
                </div>
              </div>
              <div
                v-for="(permission, index) in userPermissionData"
                :key="index"
                class="c-admin-table-row"
              >
                <div class="row align-items-center">
                  <div class="col-8">
                    {{ index }}
                  </div>
                  <div class="col-4 text-right">
                    <div class="c-notifications-switches">
                      <div
                        class="c-notifications-switches-item justify-content-end"
                      >
                        <v-switch
                          :field="index"
                          :state-key="userPermissionsStateKey"
                          class="d-inline-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-admin-table-footer">
                <div class="row align-items-center">
                  <div class="col-12 text-right">
                    <v-button
                      v-if="checkRole('superadmin')"
                      :loading="submittingSetUserPermissionsForm"
                      :disabled="submittingSetUserPermissionsForm"
                    >
                      Update
                    </v-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import RolePermissionService from "js-admin/classes/AjaxServices/RolePermissionService";

export default {
  data() {
    return {
      dataReady: false,
      stateKey: "userData",
      submittingSetUserPermissionsForm: false,
      userPermissionsStateKey: "userPermissions",
    };
  },

  computed: {
    userUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
    userPermissionData() {
      return this.$store.state.fieldStore[this.userPermissionsStateKey];
    },
  },

  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        RolePermissionService.getUserPermissions(this.userUuid)
          .then((response) => {
            this.$store.dispatch("updateStoreItem", {
              value: response.data.data,
              stateKey: this.userPermissionsStateKey,
            });
            this.dataReady = true;
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      }
    },

    setUserPermissions() {
      this.submittingSetUserPermissionsForm = true;

      RolePermissionService.setUserPermissions({
        user_uuid: this.userUuid,
        permissions: this.userPermissionData,
      })
        .then(() => {
          FlashMessageService.success("User permissions have been updated.");
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });

      this.submittingSetUserPermissionsForm = false;
    },
  },
};
</script>
