import APIService from "js-admin/classes/APIService";

export default class CarouselSlideService {
  static CRUD_URL = "carousel-slides";

  static async createSlide(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (value !== null && value !== "") {
        formData.append(key, value);
      }
    });

    return APIService.request(this.CRUD_URL + "/", "post", formData, {
      "Content-Type": "multipart/form-data",
    });
  }

  static async deleteSlide(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }
}
