<template>
  <div>
    <div class="row align-items-center">
      <div class="col-1">
        {{ dateTimeFormat(call.call_started) }}
      </div>
      <div class="col-2">
        {{ callFrom }}
      </div>
      <div class="col-2">
        {{ callTo }}
      </div>
      <div class="col-1">
        {{ callDirection }}
      </div>
      <div class="col-1">
        {{ call.status }}
      </div>
      <div class="col-1">
        <div v-if="call.status !== 'Missed call'">
          {{ formattedDuration(call.duration) }}
        </div>
      </div>
      <div class="col-1">
        <div v-if="call.call_disposition">
          {{ call.call_disposition }}
        </div>
        <div v-else> Not selected </div>
      </div>
      <div class="col-1">
        <div v-if="call.status !== 'Missed call'">
          {{ call.caller }}
        </div>
      </div>
      <div class="col text-center">
        <div v-if="call.status !== 'Missed call' && call.identity_verified_at">
          Verified Client at: {{ dateTimeFormat(call.identity_verified_at) }}
        </div>
        <div v-if="viewRecordings && checkCallStatusForRecording">
          <div v-if="!recordingUrl">
            <a
              v-if="!loadingRecording"
              :href="false"
              class="c-button -admin-small"
              @click="fetchRecording"
            >
              Get recording
            </a>
            <v-loader v-else />
          </div>
        </div>
      </div>
    </div>
    <div v-if="recordingUrl" class="row mt-2">
      <div class="col">
        <audio controls class="c-admin-audio">
          <source :src="recordingUrl" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  </div>
</template>

<script>
import { parsePhoneNumberFromString } from "js-admin/classes/PhoneService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";

export default {
  props: {
    call: {
      required: true,
    },
    index: {
      required: true,
    },
  },

  data() {
    return {
      stateKey: "accountData",
      loadingRecording: false,
      recordingUrl: null,
    };
  },

  computed: {
    viewRecordings() {
      if (this.checkPermission("listen_to_recordings")) {
        if (
          this.checkRole(["superadmin", "senior_account_manager", "compliance"])
        ) {
          return true;
        }
        if (
          this.$store.state.user.owner &&
          this.call.caller_uuid == this.$store.state.user.owner.uuid
        ) {
          return true;
        }
      }
      return false;
    },
    checkCallStatusForRecording() {
      if (
        this.call.status !== "Missed call" &&
        this.call.status !== "No answer"
      ) {
        return true;
      }
      return false;
    },

    callDirection() {
      const direction = this.call.direction;

      if (direction == "outbound-api") {
        return "Outbound";
      } else {
        return this.capitaliseFirstLetter(direction);
      }
    },

    callFrom() {
      const companyFromNo = this.$store.state.config.company_info
        .calling_from_number;

      const phoneNumber = parsePhoneNumberFromString(
        this.call.from,
        "GB"
      ).formatInternational();

      if (phoneNumber === companyFromNo) {
        return (
          this.$store.state.config.company_info.name +
          " (" +
          this.call.from +
          ")"
        );
      } else {
        return this.call.from;
      }
    },

    callTo() {
      const companyToNo = this.$store.state.config.company_info.phone_formatted;

      const phoneNumber = parsePhoneNumberFromString(
        this.call.from,
        "GB"
      ).formatInternational();

      if (phoneNumber === companyToNo) {
        return (
          this.$store.state.config.company_info.name + " (" + this.call.to + ")"
        );
      } else {
        return this.call.to;
      }
    },
  },

  methods: {
    fetchRecording() {
      if (this.checkPermission("listen_to_recordings")) {
        this.loadingRecording = true;

        CommunicationService.fetchCallRecording(this.call.uuid).then(
          (response) => {
            this.loadingRecording = false;
            if (response.data.data) {
              if (response.data.data.url) {
                this.recordingUrl = response.data.data.url;

                FlashMessageService.success(
                  "Recording has been retrieved, you can to listen to the recording."
                );
                return true;
              }
            }

            FlashMessageService.error(
              "There was an error in retrieving the recording. Try again later or contact support."
            );
            return false;
          }
        );
      }
    },
  },
};
</script>
