<template>
  <button :disabled="disabled" class="c-sidebar-item" @click="clicked">
    <div class="c-sidebar-item-icon">
      <i class="fas fa-sign-out-alt" />
    </div>
    Logout
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      default: false,
    },
  },
  computed: {
    channelName() {
      return window.pusher.channel_name;
    },

    inboundChannelBroadcast() {
      return window.pusher.phone_calls_inbound_channel_broadcast;
    },
    inProgressChannelBroadcast() {
      return window.pusher.phone_calls_in_progress_channel_broadcast;
    },
  },
  methods: {
    clicked() {
      this.$store.dispatch("logout").then(() => {
        const channel = this.$pusher.subscribe(this.channelName);
        channel.unbind(this.inProgressChannelBroadcast);
        channel.unbind(this.inboundChannelBroadcast);

        this.$store.dispatch("setUserAction", {});
        this.$store.dispatch("storeLoginRedirect", null);
        this.$store.dispatch("updateStoreItem", {
          value: {
            username: null,
            password: null,
          },
          stateKey: "loginFields",
        });
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>
