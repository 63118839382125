<template>
  <div class="v-profile">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            <span v-if="overdueReport">
              Overdue
            </span>
            Tasks Report
          </h1>
        </div>
      </div>

      <v-date-select-checkbox-search
        ref="searchDate"
        :scope="reportScope"
        :report-state-key="reportStateKey"
        :mode="mode"
        :select-data="ownerData"
        :submit-results="submitResults"
        @get-results="getResults"
      />

      <!-- CSV Export -->
      <v-csv-export
        v-if="reportData"
        class="mt-4"
        @start-csv-export="handleExport"
      />

      <div class="row">
        <div class="col-12">
          <div class="c-admin-table c-task-table mt-4">
            <div class="c-admin-table-header -bold">
              <div class="row">
                <div class="col-2">
                  Due Date
                </div>
                <div class="col-2">
                  Title
                </div>
                <div class="col-2">
                  Account
                </div>
                <div class="col-2">
                  Status
                </div>
                <div class="col-2">
                  Account Manager
                </div>
                <div class="col-2" />
              </div>
            </div>
            <div v-if="!submitResults">
              <div v-if="reportResults">
                <div
                  v-for="data in reportResults"
                  :key="data.owner_uuid"
                  :class="[
                    'c-admin-table-row',
                    {
                      '-overdue': data.overdue === true,
                      '-completed': data.status === 'Completed',
                    },
                  ]"
                >
                  <div class="row align-items-center">
                    <div class="col-2">
                      {{ dateTimeFormat(data.due_date) }}
                    </div>
                    <div class="col-2">
                      {{ data.title }}
                    </div>
                    <div class="col-2">
                      <router-link
                        class="c-link"
                        :to="`/account/${data.account.uuid}`"
                      >
                        {{ data.account.full_name }}
                      </router-link>
                    </div>
                    <div class="col-2">
                      {{ data.status }}
                    </div>
                    <div class="col-2">
                      {{ data.owner.name }}
                    </div>
                    <div class="col-2">
                      <router-link
                        class="c-button -admin-small"
                        :to="`/tasks/task-details/${data.uuid}`"
                      >
                        View Task
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="c-admin-table-row">
                <div class="row">
                  <div class="col-12">
                    No results found.
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  <v-loader />
                </div>
              </div>
            </div>
            <div class="c-admin-table-footer -bold">
              <div class="row">
                <div class="col-9 text-right">
                  Total:
                </div>
                <div class="col-3">
                  <span v-if="!submitResults">
                    {{ reportTotal }}
                  </span>
                  <span v-else>
                    <v-loader />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CSV Export -->
      <v-csv-export
        v-if="reportData"
        class="mt-4"
        @start-csv-export="handleExport"
      />
    </section>
  </div>
</template>

<script>
import VDateSelectCheckboxSearch from "js-admin/components/Search/DateSelectCheckboxSearch";
import ReportingService from "js-admin/classes/AjaxServices/ReportingService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
// import VCheckbox from "js-admin/components/form/checkbox/Checkbox.vue";

import VCsvExport from "js-admin/components/csv-export/CsvExport";
import mixinReport from "js-admin/mixins/mixinReport";

export default {
  components: {
    // DatePicker,
    VDateSelectCheckboxSearch,
    // VCheckbox,
    VCsvExport,
  },
  mixins: [mixinReport],
  data() {
    return {
      reportScope: "reportSearch",
      reportStateKey: "reportSearch",
      reportData: null,
      submitResults: false,
      currentDate: new Date(),
      mode: "single",
    };
  },
  computed: {
    ownerData() {
      return this.$store.state.agents;
    },
    reportResults() {
      return this.reportData;
    },
    reportTotal() {
      let total = 0;
      if (this.reportResults) {
        total = this.reportResults.length;
      }
      return total;
    },
    overdueReport() {
      return !!this.$route.query.overdueTasks;
    },
  },
  mounted() {
    if (
      !this.checkPermission("reporting_level1") ||
      this.systemFeatureIsDisabled("disabled_tasks")
    ) {
      return this.$router.push({ name: "home" });
    }
    this.$store.dispatch("fetchOwners");

    if (this.overdueReport) {
      this.setOverdueTasksOnly({
        reportStateKey: this.reportStateKey,
        bool: true,
      });
      this.setHideCompletedTasks({
        reportStateKey: this.reportStateKey,
        bool: true,
      });
    }
  },
  methods: {
    async getResults(e = null, downloadCsv = false) {
      let asCsv = {};
      if (downloadCsv) {
        asCsv = {
          as_csv: true,
        };
      } else {
        this.submitResults = true;
      }

      let data = this.formatDateData(
        this.$refs.searchDate.fromDate,
        this.$refs.searchDate.toDate
      );
      data = { ...data, ...asCsv };

      if (
        this.$store.state.fieldStore[this.reportStateKey]
          .account_manager_uuid &&
        /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/gi.test(
          this.$store.state.fieldStore[this.reportStateKey].account_manager_uuid
        )
      ) {
        data.account_manager_uuid = this.$store.state.fieldStore[
          this.reportStateKey
        ].account_manager_uuid;
      }

      if (
        this.$store.state.fieldStore[this.reportStateKey].hide_completed_tasks
      ) {
        data.hide_completed_tasks = this.$store.state.fieldStore[
          this.reportStateKey
        ].hide_completed_tasks;
      }

      if (
        this.$store.state.fieldStore[this.reportStateKey]
          .show_overdue_tasks_only
      ) {
        data.show_overdue_tasks_only = this.$store.state.fieldStore[
          this.reportStateKey
        ].show_overdue_tasks_only;
      }

      return ReportingService.getTasksReport(data)
        .then((response) => {
          this.submitResults = false;
          if (downloadCsv) {
            return {
              response,
              label: "Task_Report",
            };
          } else {
            this.reportData = null;
            if (response.data.data.length) {
              this.reportData = response.data.data;
            }
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },
  },
};
</script>
