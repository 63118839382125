<template>
  <div class="c-input" :class="['t-' + field, { '-value': value }]">
    <div class="c-input-group">
      <input
        :ref="fieldRef"
        v-model="value"
        v-validate="validation"
        :name="field"
        :type="type"
        :class="{
          '-error': hasErrors && !focused,
          '-value': value,
          '-highlight': placeholder != null && value == null,
          '-icon': icon,
          '-uppercase': uppercaseField,
          '-readonly': readonly,
        }"
        :data-vv-as="errorName"
        :disabled="disabled"
        :maxlength="maxlength"
        :max="max"
        :min="min"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :pattern="pattern"
        class="c-input-field"
        :readonly="readonly"
        @focus="handleFocus"
        @blur="handleBlur"
        @keypress="keypress($event)"
        @keyup.delete="handleDelete"
        @change="handleChange"
      />
      <label
        :for="field"
        class="c-input-label"
        :class="{ '-focus': focused || value != null }"
        @click="labelClick"
        v-text="label"
      />
      <VValidationIcon
        :has-errors="hasErrors"
        :value="value"
        :focused="focused"
      />
      <VErrorMsg :scope="scope" :field="field" :focused="focused" />
    </div>
    <aside
      v-show="focused || alwaysInstructions"
      v-if="instructions"
      class="c-input-instructions"
      v-html="instructions"
    />
    <slot />
  </div>
</template>

<script>
import VValidationIcon from "js-admin/components/form/ValidationIcon.vue";
import VErrorMsg from "js-admin/components/form/ErrorMsg.vue";

export default {
  components: {
    VValidationIcon,
    VErrorMsg,
  },
  inject: ["$validator"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    field: {
      required: true,
    },
    stateKey: {
      required: true,
    },
    autocomplete: {
      default: "",
    },
    instructions: {
      type: String,
      required: false,
    },
    alwaysInstructions: {
      type: Boolean,
      default: false,
    },
    type: {
      default: "text",
    },
    validation: {
      default: "required",
    },
    scope: {
      default: "",
    },
    label: {
      default: null,
    },
    errorName: {
      default: null,
    },
    maxlength: {
      default: null,
    },
    max: {
      default: null,
    },
    min: {
      default: null,
    },
    disabled: {
      default: false,
    },
    dateField: {
      default: null,
    },
    placeholder: {
      required: false,
      default: null,
    },
    icon: {
      default: null,
    },
    pattern: {
      default: null,
    },
    focusOnMounted: {
      default: false,
    },
    uppercaseField: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    fieldRef() {
      return this.scope + "-" + this.field;
    },
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
    value: {
      get() {
        let val = "";
        if (
          this.dateField &&
          this.$store.state.fieldStore[this.stateKey][this.field]
        ) {
          val = this.$store.state.fieldStore[this.stateKey][this.field]
            .split("-")
            .reverse()
            .join("/");
        } else {
          val = this.$store.state.fieldStore[this.stateKey][this.field];
          if (
            (this.field === "primary_contact_number" && val) ||
            (this.field === "secondary_contact_number" && val)
          ) {
            val = val.replace(/ +/g, ""); // strip spaces out of phone numbers as it breaks validation
          }
        }
        return val;
      },
      set(val) {
        if (this.field === "reminder_hour") {
          if (parseInt(val) > 23) {
            val = 23;
          }
        }
        if (this.field === "reminder_minute") {
          if (parseInt(val) > 59) {
            val = 59;
          }
        }
        if (this.uppercaseField) {
          val = val.toUpperCase();
        }
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: this.stateKey,
          field: this.field,
        });
        this.$emit("change", val);
      },
    },
  },
  mounted() {
    if (this.focusOnMounted) {
      this.$refs[this.fieldRef].focus();
    }
  },
  methods: {
    keypress($event) {
      this.$emit("keypress", $event);
    },
    handleFocus() {
      if (!this.readonly) {
        this.focused = true;
        this.$emit("focus");
      }
    },
    handleChange() {
      this.$emit("change");
    },
    handleBlur() {
      this.focused = false;
      this.$emit("blur");
    },
    handleDelete() {
      this.$emit("delete-pressed");
    },
    labelClick() {
      this.$refs[this.fieldRef].focus();
    },
  },
};
</script>
