<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2">
        {{ dateTimeFormat(securedMessage.created_at) }}
      </div>
      <div class="col-3">
        {{ securedMessage.from }}
      </div>
      <div class="col-3">
        {{ securedMessage.subject }}
      </div>
      <div class="col-2">
        <div v-if="securedMessage.open_at">
          {{ dateTimeFormat(securedMessage.open_at) }}
        </div>
        <div v-else>
          No Yet
        </div>
      </div>
      <div class="col-2 text-center">
        <a :href="false" class="c-button -admin-small" @click="showMessage">
          View
        </a>

        <v-modal ref="messageModal" :trigger="false" size="xl">
          <h3 class="c-admin-panel-title text-center">
            Secured Message
          </h3>
          <iframe
            ref="messageBody"
            style="border: 0;"
            class="c-secure-message-iframe"
          />
        </v-modal>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    securedMessage: {
      required: true,
    },
  },

  data() {
    return {
      message: null,
    };
  },

  computed: {},

  methods: {
    showMessage() {
      const location = this.$parent.$parent.$parent.$parent;
      location.$refs.messageModal.toggle();
      this.$store
        .dispatch("fetchSecuredMessage", this.securedMessage.uuid)
        .then((response) => {
          if (response.status == 200) {
            this.message = response.data.data.body;
            this.message = this.message.replace(/href/g, "data-href");

            location.$refs.messageBody.contentWindow.document.open();
            location.$refs.messageBody.contentWindow.document.write(
              this.message
            );
            location.$refs.messageBody.contentWindow.document.close();
          }
        });
      return true;
    },
  },
};
</script>
