<template>
  <div class="c-admin-panel mt-0">
    <h3>
      Quick Actions:
    </h3>
    <div class="c-admin-panel-section">
      <a
        v-if="checkPermission('view_notes')"
        :href="false"
        class="c-button -admin-small mr-2"
        @click="scrollToNotes"
      >
        View Notes
      </a>

      <VCreateTask
        v-if="
          checkPermission('create_tasks') &&
            !systemFeatureIsDisabled('disabled_tasks')
        "
        container-classes="d-inline mr-2"
      />
      <VDisableLogin
        v-if="checkPermission('disable_account_login')"
        :user-uuid="userUuid"
        :disabled-login="disabledLogin"
      />
    </div>
  </div>
</template>

<script>
import VCreateTask from "js-admin/components/tasks/CreateTask.vue";
import VDisableLogin from "js-admin/components/users/DisableLogin.vue";

export default {
  components: {
    VCreateTask,
    VDisableLogin,
  },

  computed: {
    accountClosed() {
      return this.$store.state.fieldStore.accountData.closed_on;
    },
    disabledLogin() {
      return this.$store.state.fieldStore.accountData.users[0].disable_login;
    },
    userUuid() {
      return this.$store.state.fieldStore.accountData.users[0].uuid;
    },
  },

  methods: {
    scrollToNotes() {
      this.$scrollTo(".ref-notes-panel", 500, { offset: -100 });
      setTimeout(() => {
        this.$parent.$refs.notes.$refs[
          "accordion-notes"
        ]._data.items[0].isActive = true;
      }, 700);
    },
  },
};
</script>
