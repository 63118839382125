<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item @toggle="accordionToggle">
        <template v-slot:title>
          Bank Details ({{ bankDetailsCount }})
        </template>

        <div v-if="dataReady" class="c-admin-panel">
          <div v-if="bankAccountDetails" class="c-admin-table">
            <div class="c-admin-table-header">
              <div class="row align-items-center">
                <div class="col-2 text-center">
                  Primary Bank Details?
                </div>
                <div class="col-1" />
                <div class="col-7">
                  Bank Details
                </div>
                <div
                  v-if="checkPermission('delete_bank_details')"
                  class="col-2 text-center"
                />
              </div>
            </div>

            <div
              v-for="(bankDetails, index) in bankAccountDetails"
              :key="bankDetails.uuid"
              class="c-admin-table-row"
            >
              <VLinkedBankDetailsRow
                :bank-details="bankDetails"
                :index="index"
              />
            </div>

            <div class="c-admin-table-footer">
              <div class="row">
                <div class="col-12">
                  <VPagination
                    v-model="page"
                    :page-count="pages.length"
                    :classes="bootstrapPaginationClasses"
                    :labels="paginationAnchorTexts"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            No linked bank details.
          </div>
        </div>
        <v-ajax-loader v-else />
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import AccountService from "js-admin/classes/AjaxServices/AccountService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VPagination from "vue-plain-pagination";
import VLinkedBankDetailsRow from "js-admin/components/accounts/bank-details/LinkedBankDetailsRow.vue";

export default {
  components: {
    VPagination,
    VLinkedBankDetailsRow,
  },
  data() {
    return {
      dataReady: false,
      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      bankDetailsData: null,
      bankDetailsCount: 0,
      accountUuid: this.$store.state.fieldStore.accountData.uuid,
    };
  },
  computed: {
    bankAccountDetails() {
      return this.bankDetailsData;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  mounted() {
    AccountService.getAccountBankDetails({
      uuid: this.accountUuid,
      return_count: true,
    })
      .then((response) => {
        this.bankDetailsCount = response.data.data.count;
        this.setPages();
      })
      .catch((error) => {
        FlashMessageService.error(error.response.data.message);
      });
  },

  methods: {
    accordionToggle(bool) {
      if (bool && !this.dataReady) {
        this.paginate();
      }
    },

    setPages() {
      this.pages = this.calculatePaginationPages(
        this.bankDetailsCount,
        this.perPage
      );
    },

    paginate() {
      this.dataReady = false;
      AccountService.getAccountBankDetails({
        uuid: this.accountUuid,
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      }).then((response) => {
        if (response.data.data) {
          this.bankDetailsData = null;
          if (response.data.data.length) {
            this.bankDetailsData = response.data.data;
            this.bankDetailsCount = response.data.data.length;
          }
          this.dataReady = true;
        }
      });
    },
  },
};
</script>
