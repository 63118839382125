<template>
  <div class="c-note-comments row">
    <div class="col-12">
      <form :data-vv-scope="scope" @submit.prevent="updateNote">
        <div>
          <v-textarea
            :scope="scope"
            :state-key="stateKey"
            class="mb-3"
            field="note"
            label="Note"
            placeholder="Note"
            validation="required"
          />
        </div>
        <v-button
          :loading="updateInProgress"
          :disabled="updateInProgress"
          class="-admin-small"
        >
          Add
        </v-button>
      </form>
    </div>
    <div class="col-12">
      <div
        v-for="(note, index) in notesData"
        :key="index"
        class="c-note-comments-list row"
      >
        <div class="col-12">
          <p class="c-note-comments-date">
            {{ noteTime(note.created_at) }} by {{ note.author }}
          </p>
        </div>
        <div class="col-12">
          <p class="c-note-comments-text">
            {{ note.note }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default {
  props: {
    accountUuid: {
      default: null,
    },
    applicationUuid: {
      default: null,
    },
    investmentAccountUuid: {
      default: null,
    },
    notesData: {
      default: null,
    },
  },
  data() {
    return {
      updateInProgress: false,
      scope: "updateNoteForm",
      stateKey: "updateNote",
    };
  },
  computed: {},
  mounted() {
    dayjs.extend(relativeTime);
  },
  methods: {
    noteTime(time) {
      return dayjs(time).fromNow();
    },
    updateNote() {
      const params = {
        account_uuid: this.$store.state.taskState.selectedTask.account.uuid,
        author_uuid: this.$store.state.user.uuid,
        task_uuid: this.$store.state.taskState.selectedTask.uuid,
        note: this.$store.state.fieldStore.updateNote.note,
        application_uuid: null,
        investment_account_uuid: null,
      };

      this.$store.dispatch("createNote", params);
    },
  },
};
</script>
