<template lang="html">
  <div class="c-merge-section h-mt-1">
    <div v-if="getMergeAccountArrayLength > 1">
      <div class="c-admin-table">
        <div class="c-admin-table-header">
          <div class="row">
            <div class="col-4 -pointer -clickable" />
            <div class="col-4 -pointer -clickable">
              <span class="c-merge-checkbox-wrap" @click="handleSelectAll(0)">
                <VCheckboxVisual :active="selectAll[0]" />
                Select All
              </span>
            </div>
            <div class="col-4 -pointer -clickable">
              <span class="c-merge-checkbox-wrap" @click="handleSelectAll(1)">
                <VCheckboxVisual :active="selectAll[1]" />
                Select All
              </span>
            </div>
          </div>
        </div>
        <div :class="['c-admin-table-row', {}]">
          <div class="row">
            <div class="col-4">
              <b>Master Record</b>
            </div>
            <div class="col-4">
              <span class="c-merge-checkbox-wrap" @click="handleMasterPick(0)">
                <VCheckboxVisual :active="masterRecord[0]" />
              </span>
            </div>
            <div class="col-4">
              <span class="c-merge-checkbox-wrap" @click="handleMasterPick(1)">
                <VCheckboxVisual :active="masterRecord[1]" />
              </span>
            </div>
          </div>
        </div>

        <div
          v-for="(header, index) in getMergeHeaders"
          :key="index"
          :class="['c-admin-table-row', {}]"
        >
          <div class="row">
            <div class="col-4">
              {{ header.title }}
            </div>
            <div class="col-4">
              <span class="c-merge-checkbox-wrap" @click="handlePick(index, 0)">
                <VCheckboxVisual :active="selectArr[index][0]" />
                <span v-if="header.title == 'Owner'">
                  <span v-if="getMergeAccountArray[0]['owner']">
                    {{ getMergeAccountArray[0]["owner"]["name"] }}
                  </span>
                </span>
                <span v-else>
                  {{ getMergeAccountArray[0][header.value] }}
                </span>
              </span>
            </div>
            <div class="col-4">
              <span class="c-merge-checkbox-wrap" @click="handlePick(index, 1)">
                <VCheckboxVisual :active="selectArr[index][1]" />
                <span v-if="header.title === 'Owner'">
                  <span v-if="getMergeAccountArray[1]['owner']">
                    {{ getMergeAccountArray[1]["owner"]["name"] }}
                  </span>
                </span>
                <span v-else>
                  {{ getMergeAccountArray[1][header.value] }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 h-mt-1 text-right">
        <span @click="handleMerge">
          <v-button
            class="c-button -admin-small"
            :loading="submittingForm"
            :disabled="submittingForm"
          >
            Merge
          </v-button>
        </span>
      </div>
    </div>
    <div v-else>
      <p>
        Please select 2 records to merge
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VCheckboxVisual from "js-admin/components/form/checkbox/CheckboxVisual.vue";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import AccountService from "js-admin/classes/AjaxServices/AccountService";

export default {
  components: {
    VCheckboxVisual,
  },
  data() {
    return {
      selectArr: [],
      selectAll: [false, false],
      masterRecord: [true, false],
      submittingForm: false,
    };
  },
  computed: {
    ...mapGetters(["getMergeAccountArray", "getMergeHeaders"]),
    getMergeAccountArrayLength() {
      if (this.getMergeAccountArray != null) {
        return this.getMergeAccountArray.length;
      }
      return 0;
    },
  },
  mounted() {
    setTimeout(() => {
      for (let i = 0; i < this.getMergeHeaders.length; i++) {
        this.selectArr.push({
          "0": false,
          "1": false,
        });
      }
    }, 1000);
  },
  methods: {
    handleMerge() {
      if (
        confirm(
          "These records will be merged into one record using the selected values. Merging cannot be undone. Proceed with the record merge?"
        )
      ) {
        this.submittingForm = true;

        // set master uuid index return as tuple
        const masterInd = this.masterRecord[0] === true ? [0, 1] : [1, 0];
        // set result index array
        const resultArr = this.selectArr.map((i) => (i[0] === true ? 0 : 1));
        // set intial merge record object with master uuid
        const mergeRecord = {
          master_account_uuid: this.getMergeAccountArray[masterInd[0]].uuid,
          child_account_uuid: this.getMergeAccountArray[masterInd[1]].uuid, // make list of uuids
        };
        // use header object as template to loop through and set merge record object against defined result array
        for (let i = 0; i < this.getMergeHeaders.length; i++) {
          if (
            typeof this.getMergeHeaders[i].value !== "undefined" &&
            typeof this.getMergeAccountArray[resultArr[i]][
              this.getMergeHeaders[i].value
            ] !== "undefined"
          ) {
            const storeValue = this.getMergeAccountArray[resultArr[i]][
              this.getMergeHeaders[i].value
            ];

            Object.assign(mergeRecord, {
              [this.getMergeHeaders[i].value]: storeValue,
            });
          }
        }

        AccountService.mergeAccounts(
          mergeRecord.master_account_uuid,
          mergeRecord.child_account_uuid,
          mergeRecord
        )
          .then((response) => {
            this.submittingForm = false;
            FlashMessageService.success(response.message);

            this.$router.push(
              "/account/" + response.data.data.master_account_uuid
            );
            return true;
          })
          .catch((err) => {
            FlashMessageService.error(err.response.data.message);
          });
      }
    },
    handleSelectAll(ind) {
      this.resetValue(this.selectAll, ind);
      this.$set(this.selectAll, ind, true);
      for (let i = 0; i < this.getMergeHeaders.length; i++) {
        this.handlePick(i, ind);
      }
    },
    handleMasterPick(ind) {
      this.resetValue(this.masterRecord, ind);
      this.$set(this.masterRecord, ind, true);
    },
    handlePick(ind0, ind1) {
      this.resetValue(this.selectArr[ind0], ind1);
      this.$set(this.selectArr[ind0], ind1, true);
    },
    resetValue(arrayVal, ind) {
      if (ind == 0) {
        this.$set(arrayVal, 1, false);
      } else {
        this.$set(arrayVal, 0, false);
      }
    },
  },
};
</script>
