import APIService from "js-admin/classes/APIService";
import { prepUrlParameters } from "js-admin/abstract/helpers";

export default class PrimaryMarketService {
  static CRUD_URL = "primary-market";

  static async getClient(uuid) {
    return APIService.request(this.CRUD_URL + "/clients/" + uuid);
  }

  static async getInstrument(uuid) {
    return APIService.request(this.CRUD_URL + "/instruments/" + uuid);
  }

  static async getTriggeredInstruments(params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/instruments/triggered?" + extraParams
    );
  }

  static async triggerInstrumentDrawdown(uuid) {
    return APIService.request(
      this.CRUD_URL + "/instruments/" + uuid + "/trigger-drawdown",
      "post"
    );
  }

  static async getScheduledRepayments(uuid, params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL +
        "/instruments/" +
        uuid +
        "/scheduled-repayments?" +
        extraParams
    );
  }

  static async getRepayments(uuid, params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL + "/instruments/" + uuid + "/repayments?" + extraParams
    );
  }

  static async getInstrumentRepayment(uuid) {
    return APIService.request(this.CRUD_URL + "/instrument-repayment/" + uuid);
  }

  static async getInstrumentRepaymentLines(uuid, params) {
    const extraParams = prepUrlParameters(params);

    return APIService.request(
      this.CRUD_URL +
        "/instrument-repayment/" +
        uuid +
        "/repayment-lines?" +
        extraParams
    );
  }
}
