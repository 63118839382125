import APIService from "js-admin/classes/APIService";

export default class DocumentService {
  static CRUD_URL = "documents";

  static async addDocument(data) {
    const header = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return APIService.request(this.CRUD_URL, "post", data, header);
  }

  static async deleteDocument(uuid) {
    return APIService.request(this.CRUD_URL + "/" + uuid, "delete");
  }
}
