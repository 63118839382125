<template>
  <div class="c-phone-call-box-container">
    <a
      class="c-phone-call-box-container-close-box"
      @click="closeInProgressBox(inProgressCallsStateKey, inProgressCall)"
    >
      <i class="c-phone-call-box-container-close-box-icon fas fa-times" />
    </a>

    <div class="row align-items-center">
      <div class="col" :class="{ 'col-4': inProgressCall.is_open }">
        <div>
          Talking with: <strong>{{ inProgressCall.from }}</strong>
        </div>
        <div v-show="inProgressCall.name">
          Customer: <strong>{{ inProgressCall.name }}</strong>
        </div>
        <div v-show="inProgressCall.account_uuid">
          <a
            class="c-button -admin-small"
            @click="viewAccount(inProgressCall.account_uuid)"
          >
            View Account
          </a>
        </div>
        <div v-if="inProgressCall.is_open">
          <a
            class="c-button -admin-small"
            @click="assignCall(inProgressCall.uuid, index)"
          >
            Assign Call
          </a>
        </div>
        <div v-if="inProgressCall.is_open" class="text-center">
          <a
            class="c-button -admin-small"
            @click="createNewAccount(inProgressCall)"
          >
            Create New Account/Lead
          </a>
        </div>
        <div>
          <a
            class="c-button -secondary -admin-small"
            @click="expandCallInProgressBox(index, inProgressCall)"
          >
            <span v-if="inProgressCall.is_open"> Less </span>
            <span v-else> More </span>
            Options
          </a>
        </div>
      </div>

      <div v-if="inProgressCall.is_open" class="col-4">
        <VPhoneCallIdentityVerifiedBox
          field="identity_verified_at"
          :call="inProgressCall"
          :index="index"
          :state-key="inProgressCallsStateKey"
        >
          I have confirmed the customer's identity
        </VPhoneCallIdentityVerifiedBox>

        <form
          :data-vv-scope="inProgressCall.call_uuid + '_add_call_notes'"
          @submit.prevent="
            addCallNotes(
              inProgressCall.call_uuid + '_add_call_notes',
              index,
              inProgressCall
            )
          "
        >
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <VCallNotesField
                  :ref="inProgressCall.call_uuid + '_call_notes'"
                  :scope="inProgressCall.call_uuid + '_add_call_notes'"
                  class="mb-2"
                  field="call_notes"
                  label="Call Notes"
                  placeholder="Call Notes"
                  validation="required"
                  error-name="call notes"
                  :default-value="inProgressCall.note"
                />
              </div>
            </div>
            <div class="col-12 text-center">
              <v-button
                :loading="addCallNotesProgress"
                :disabled="addCallNotesProgress"
                class="-admin-small"
              >
                <span v-if="inProgressCall.note_uuid"> Update Note </span>
                <span v-else> Add Note </span>
              </v-button>
            </div>
          </div>
        </form>
      </div>

      <div v-if="inProgressCall.is_open" class="col-4">
        <div
          v-show="activeAccountManagers"
          class="c-phone-call-box-container-transfer"
        >
          <form
            :data-vv-scope="inProgressCall.call_uuid + '_transferCallScope'"
            @submit.prevent="
              transferCall(
                inProgressCall.call_uuid + '_transferCallScope',
                inProgressCall
              )
            "
          >
            <div class="row">
              <div class="col-12">
                <div class="text-center">
                  <VPhoneCallTransferBox
                    :ref="inProgressCall.call_uuid + '_transfer_am_uuid'"
                    :scope="inProgressCall.call_uuid + '_transferCallScope'"
                    :options="activeAccountManagers"
                    :enable-blank-option="true"
                    field="am_uuid"
                    error-name="account manager"
                    placeholder="Transfer call to"
                    class="-centered mb-1"
                  />
                </div>
              </div>
              <div class="col-12 text-center">
                <v-button
                  :loading="transferCallInProgress"
                  :disabled="transferCallInProgress"
                  class="-admin-small"
                >
                  Transfer
                </v-button>
              </div>
            </div>
          </form>
        </div>

        <div class="mt-3">
          <form
            :data-vv-scope="inProgressCall.call_uuid + '_CallDisposition'"
            @submit.prevent="
              updateCallDisposition(
                inProgressCall.call_uuid + '_CallDisposition',
                index,
                inProgressCall
              )
            "
          >
            <div class="row">
              <div class="col-12">
                <div class="text-center">
                  <VCallDispositionBox
                    :ref="inProgressCall.call_uuid + '_call_disposition'"
                    :scope="inProgressCall.call_uuid + '_CallDisposition'"
                    class="-centered mb-1"
                    :default-value="inProgressCall.call_disposition"
                    :call-uuid="inProgressCall.call_uuid"
                  />
                </div>
              </div>
              <div class="col-12 text-center">
                <v-button
                  :loading="updateCallDispositionLoader"
                  :disabled="updateCallDispositionLoader"
                  class="-admin-small"
                >
                  Update
                </v-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import CommunicationService from "js-admin/classes/AjaxServices/CommunicationService";

import VCallNotesField from "js-admin/components/form/phone/CallNotesField.vue";
import VPhoneCallIdentityVerifiedBox from "js-admin/components/form/phone/CallIdentityVerifiedBox.vue";
import VPhoneCallTransferBox from "js-admin/components/form/phone/CallTransferBox.vue";
import VCallDispositionBox from "js-admin/components/form/phone/CallDepoistionBox.vue";

export default {
  components: {
    VCallNotesField,
    VPhoneCallIdentityVerifiedBox,
    VPhoneCallTransferBox,
    VCallDispositionBox,
  },
  props: {
    inProgressCall: {
      required: true,
    },
    inProgressCallsStateKey: {
      required: true,
    },
    index: {
      required: true,
    },
    accountManagers: {
      required: true,
    },
  },
  data() {
    return {
      transferCallInProgress: false,
      addCallNotesProgress: false,
      updateCallDispositionLoader: false,
      createInProgress: false,
    };
  },
  computed: {
    activeAccountManagers() {
      const activeAms = [];

      if (!this.accountManagers || !this.loggedUserPhoneNumber) {
        return null;
      }

      this.accountManagers.forEach((am) => {
        if (am.phone_number && am.phone_number !== this.loggedUserPhoneNumber) {
          activeAms.push(am);
        }
      });

      return activeAms;
    },
    loggedUserPhoneNumber() {
      if (this.$store.state.user.owner) {
        return this.$store.state.user.owner.phone_number;
      }

      return null;
    },
  },
  methods: {
    closeBox(stateKey, call, toConfirm = false) {
      if (toConfirm) {
        if (!confirm("Are you sure?")) {
          return;
        }
      }

      this.$store.dispatch("deleteFromArrayItemStore", {
        stateKey: stateKey,
        callUuid: call.uuid,
      });
    },
    viewAccount(uuid) {
      if (this.$router.currentRoute.path !== "/account/" + uuid) {
        this.$router.push({ name: "account", params: { uuid } });
      }

      return false;
    },
    assignCall(uuid, index) {
      this.$store.dispatch("updateSubArrayItemStore", {
        value: 0,
        stateKey: this.inProgressCallsStateKey,
        field: "call",
        index: index,
        subArrayField: "is_open",
      });

      if (this.$router.currentRoute.path !== "/assign-phone-call/" + uuid) {
        return this.$router.push({ name: "AssignPhoneCall", params: { uuid } });
      }

      return false;
    },
    createNewAccount(call) {
      if (this.$router.currentRoute.path !== "/create-new-account/") {
        this.$router.push({
          name: "create-account",
          query: { contact_number: call.from, call_uuid: call.uuid },
        });
      }

      return false;
    },
    closeInProgressBox(stateKey, inProgressCall) {
      if (!inProgressCall.note_uuid) {
        FlashMessageService.error("Please add a call note before closing.");
        return;
      }

      if (!inProgressCall.call_disposition) {
        FlashMessageService.error(
          "Please select a call disposition before closing."
        );
        return;
      }

      if (!confirm("Are you sure?")) {
        return;
      }

      this.$store.dispatch("deleteFromArrayItemStore", {
        stateKey: stateKey,
        callUuid: inProgressCall.uuid,
      });
    },
    expandCallInProgressBox(index, inProgressCall) {
      this.$store.dispatch("updateSubArrayItemStore", {
        value: !inProgressCall.is_open,
        stateKey: this.inProgressCallsStateKey,
        field: "call",
        index: index,
        subArrayField: "is_open",
      });
    },
    transferCall(scope, inProgressCall) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          if (!confirm("Are you sure?")) {
            return;
          }
          this.transferCallInProgress = true;

          const ownerUuid = this.$refs[
            inProgressCall.call_uuid + "_transfer_am_uuid"
          ].value;

          CommunicationService.transferPhoneCall(
            inProgressCall.call_uuid,
            ownerUuid
          ).then((response) => {
            if (response.data) {
              this.transferCallInProgress = false;
              this.$refs[
                inProgressCall.call_uuid + "_transfer_am_uuid"
              ].value = null;
            }
          });
        }
      });
    },
    addCallNotes(scope, index, inProgressCall) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          const data = {
            note: this.$refs[inProgressCall.call_uuid + "_call_notes"].value,
            author_uuid: this.$store.state.user.uuid,
            call_uuid: inProgressCall.call_uuid,
          };

          if (inProgressCall.account_uuid) {
            data.account_uuid = inProgressCall.account_uuid;
          }

          let action = "createNote";
          let actionText = "created";
          if (inProgressCall.note_uuid) {
            actionText = "updated";
            action = "updateNote";
            data.uuid = inProgressCall.note_uuid;
          }

          this.createInProgress = true;
          this.$store.dispatch(action, data).then((response) => {
            this.createInProgress = false;
            if (response.data) {
              if (!inProgressCall.note_uuid) {
                this.$store.dispatch("updateSubArrayItemStore", {
                  value: response.data.uuid,
                  stateKey: this.inProgressCallsStateKey,
                  field: "note",
                  index: index,
                  subArrayField: "uuid",
                });
              }

              FlashMessageService.success("Note has been " + actionText);
            } else {
              console.log(response);
              return false;
            }
          });
        }
      });
    },
    updateCallDisposition(scope, index, inProgressCall) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.updateCallDispositionLoader = true;

          CommunicationService.updateCallDisposition(inProgressCall.call_uuid, {
            call_disposition: this.$refs[
              inProgressCall.call_uuid + "_call_disposition"
            ].value,
          })
            .then((response) => {
              this.$store.dispatch("updateSubArrayItemStore", {
                value: this.$refs[
                  inProgressCall.call_uuid + "_call_disposition"
                ].value,
                stateKey: this.inProgressCallsStateKey,
                field: "call",
                index: index,
                subArrayField: "call_disposition",
              });
              FlashMessageService.success(response.data.message);
            })
            .catch((error) => {
              FlashMessageService.error(error.response.data.data.message);
            });

          this.updateCallDispositionLoader = false;
        }
      });
    },
  },
};
</script>
