<template>
  <!-- Start of Delete Account -->
  <div class="c-admin-panel">
    <v-accordion class="">
      <v-accordion-item>
        <template v-slot:title>
          {{ toggleArchiveAccountText }}
        </template>

        <div class="c-admin-panel-section">
          <a
            :href="false"
            class="c-button -admin-small"
            @click="toggleArchiveAccount"
          >
            {{ toggleArchiveAccountText }}
          </a>
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
  <!-- End of Delete Account -->
</template>

<script>
import AccountService from "js-admin/classes/AjaxServices/AccountService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  data() {
    return {
      stateKey: "accountData",
    };
  },
  computed: {
    accountUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
    isArchived() {
      return !!this.$store.state.fieldStore.accountData.archived_on;
    },
    toggleArchiveText() {
      return this.isArchived ? "unarchive" : "archive";
    },
    toggleArchiveAccountText() {
      return this.capitaliseFirstLetter(this.toggleArchiveText) + " Account";
    },
  },
  methods: {
    async toggleArchiveAccount() {
      if (
        confirm(
          "Are you sure you want to " +
            this.toggleArchiveText +
            " this account?"
        )
      ) {
        const { data } = await AccountService.archiveAccount({
          uuid: this.accountUuid,
          archive_account: !this.isArchived,
        });

        this.$store.dispatch("updateStoreItem", {
          value: data.data,
          stateKey: this.stateKey,
        });

        await this.$nextTick();
        FlashMessageService.success(
          "Account has been " + (this.isArchived ? "archived" : "unarchived")
        );
      }
    },
  },
};
</script>
