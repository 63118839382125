export const prepUrlParameters = (params) => {
  const filterList = {
    start: 0,
  };

  if (params.exchangeType) {
    params.exchange = params.exchangeType;
    delete params.exchangeType;
  }

  [
    "start",
    "limit",
    "filter_by_type",
    "filter",
    "return_count",
    "type",
    "count",
    "term",
    "from_date",
    "to_date",
    "report_type",
    "page",
    "status",
    "source",
    "only_owned",
  ].forEach((key) => {
    if (params[key]) {
      filterList[key] = params[key];
    }
  });

  return new URLSearchParams(filterList).toString();
};

export const prepSearchUrlParameters = (params) => {
  return new URLSearchParams(params).toString();
};

export const contentDownloader = (data, filename, type = "text/csv") => {
  const blob = new Blob([data], { type });
  blob.name = filename;

  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";

  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();

  window.URL.revokeObjectURL(url);
};

export const formatDate = (date) => {
  return date
    .split("/")
    .reverse()
    .join("-");
};

export const formatObjectForSelect = (types = {}) => {
  return Object.keys(types).map((key) => {
    return {
      value: key,
      name: types[key],
    };
  });
};
