<template>
  <div class="v-account">
    <div v-if="instrumentLoadedData">
      <section v-if="!instrument404" class="c-section">
        <div class="row align-items-start">
          <div class="col-lg-9 col-sm-12">
            <h1 class="c-admin-page-title">
              {{ title }}
            </h1>
            <button
              :href="false"
              class="c-button -secondary -sidebar"
              @click="viewInstrument(instrument.uuid)"
            >
              <strong>
                Back
              </strong>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h3 class="c-admin-panel-title">
              Shareholders
            </h3>
            <div v-if="allocationsDataReady">
              <div v-if="allocations" class="c-admin-table mt-2">
                <div class="c-admin-table-header">
                  <div class="row align-items-center">
                    <div class="col-3">
                      Shareholder
                    </div>
                    <div class="col-2">
                      Account
                    </div>
                    <div class="col-2">
                      Shares
                    </div>
                    <div class="col-2">
                      Status
                    </div>
                    <div class="col-3"></div>
                  </div>
                </div>
                <div
                  v-for="allocation in allocations"
                  :key="allocation.uuid"
                  class="c-admin-table-row"
                >
                  <VInstrumentAllocationsRow :allocation="allocation" />
                </div>
                <div class="c-admin-table-footer">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <div class="c-admin-table-footer-text">
                        <VPagination
                          v-model="page"
                          :page-count="pages.length"
                          :classes="bootstrapPaginationClasses"
                          :labels="paginationAnchorTexts"
                        />
                      </div>
                    </div>
                    <div class="col-4 text-center">
                      Total Shares: <strong> {{ totalShares }} </strong>
                    </div>
                    <div class="col-4 text-right">
                      <div v-if="instrument.status === 'claimable'">
                        <v-button
                          class="c-button -admin-small mr-4"
                          type="button"
                          :disabled="
                            bulkApprovalReady ||
                              instrument.is_paused ||
                              instrument.cancelled_on
                          "
                          :loading="bulkApprovalReady"
                          @click.native="bulkApproval(instrument.uuid)"
                        >
                          Approve All Pending
                        </v-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                No found allocations.
              </div>
            </div>
            <v-ajax-loader v-else />
          </div>
        </div>
      </section>
      <div v-else>
        <section class="c-section">
          <div class="row">
            <div class="col-12">
              <h1 class="c-admin-page-title">
                Instrument Not Found
              </h1>
              <div class="mt-4">
                Please use the buttons below to go back to the instrument list.
              </div>
              <div class="mt-4">
                <a
                  :href="false"
                  class="c-button -admin-small"
                  @click="instrumentPage"
                >
                  Back to Instruments List
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import InstrumentService from "js-admin/classes/AjaxServices/InstrumentService";
import VInstrumentAllocationsRow from "js-admin/components/instrument/allocations/InstrumentAllocationsRow.vue";
import VPagination from "vue-plain-pagination";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  components: {
    VInstrumentAllocationsRow,
    VPagination,
  },
  data() {
    return {
      scope: "instrumentData",
      stateKey: "instrumentData",
      instrumentDataReady: false,
      instrumentNotFound: false,
      allocationsDataReady: false,
      bulkApprovalReady: false,
      allocationsCount: null,
      totalShares: 0,
      allocations: null,

      page: 1,
      perPage: 5,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
    };
  },

  computed: {
    instrument() {
      return this.$store.state.fieldStore[this.stateKey];
    },
    instrumentLoadedData() {
      return this.instrumentDataReady;
    },
    title() {
      if (!this.instrument.cancelled_on) {
        return this.instrument.name + " - Share Allocations (Cancelled)";
      }
      if (this.instrument.is_paused) {
        return this.instrument.name + "- Share Allocations (Paused)";
      }

      return this.instrument.name + " - Share Allocations";
    },
    instrument404() {
      return this.instrumentNotFound;
    },
  },

  watch: {
    page() {
      this.paginate();
    },
  },

  async mounted() {
    if (!this.checkPermission("view_issuer")) {
      return this.$router.push({ name: "home" });
    }

    await InstrumentService.getInstrument(this.$route.params.uuid)
      .then((response) => {
        this.$store.dispatch("updateStoreItem", {
          value: response.data.data,
          stateKey: this.stateKey,
          field: null,
        });
      })
      .catch(() => {
        this.instrumentNotFound = true;
      });

    this.instrumentDataReady = true;

    if (!this.instrumentNotFound) {
      await InstrumentService.getInstrumentAllocations(
        this.$route.params.uuid,
        { return_count: true }
      ).then((response) => {
        this.allocationsCount = response.data.data.count;
        this.totalShares = response.data.data.total_shares;
        this.setPages();
        this.paginate();
      });
    }
  },

  methods: {
    viewInstrument(uuid) {
      this.$router.push({ name: "instrument", params: { uuid } });
      return false;
    },
    instrumentPage() {
      this.$router.push({ name: "instruments" });
      return false;
    },

    paginate() {
      this.allocationsDataReady = false;
      return InstrumentService.getInstrumentAllocations(
        this.$route.params.uuid,
        {
          start: this.page * this.perPage - this.perPage,
          limit: this.perPage,
        }
      )
        .then((response) => {
          this.allocations = response.data.data;
          this.allocationsDataReady = true;
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.data.message);
          this.allocationsDataReady = false;
        });
    },

    setPages() {
      this.pages = this.calculatePaginationPages(
        this.allocationsCount,
        this.perPage
      );
    },

    bulkApproval(uuid) {
      if (this.instrument.status !== "claimable") {
        return FlashMessageService.error(
          "Instrument needs to be claimable to bulk approving."
        );
      }

      this.$toasted.info(
        "<div class='toasted-text-box'> Are you sure you want to approve all pending allocation claims? </div>",
        {
          duration: null,
          action: [
            {
              text: "Yes",
              class: "c-button -admin-small",
              onClick: (e, toastObject) => {
                this.makeClaimableReady = true;

                InstrumentService.bulkApproveAllocations(uuid)
                  .then((response) => {
                    FlashMessageService.success(
                      "Allocations have been approved"
                    );
                    this.paginate();
                  })
                  .catch((error) => {
                    FlashMessageService.error(error.response.data.message);
                  });

                this.makeClaimableReady = true;
                toastObject.goAway(0);
              },
            },
            {
              text: "No",
              class: "c-button -admin-small",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        }
      );
    },
  },
};
</script>
