<template>
  <div v-if="getSelectedTask" class="c-task-details h-mt-md-1">
    <form :data-vv-scope="scope">
      <div class="row h-mb-1 align-items-center">
        <div class="col-6">
          <div class="c-task-header">
            <VEditInput :table-field="true" :edit="edit">
              <template slot="static">
                <h2 class="mb-0">
                  {{ getSelectedTask.title }}
                </h2>
              </template>
              <template slot="input">
                <v-text-input
                  :scope="scope"
                  state-key="task"
                  field="task_name"
                  label="Task Name"
                  validation="required"
                  error-name="task name"
                />
              </template>
            </VEditInput>
          </div>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div
            v-if="
              getSelectedTask.status !== 'Completed' &&
                checkPermission('edit_tasks')
            "
            class="c-button -admin-small -set-height"
            @click="handleEdit"
          >
            <span v-if="!edit">Edit</span>
            <span v-else>Save</span>
          </div>
        </div>
      </div>
      <div class="row h-mb-1">
        <div class="col-12 c-task-details-label"> Description </div>
        <div class="col-8 c-task-details-value">
          <VEditInput :table-field="true" :edit="edit">
            <template slot="static">
              {{ getSelectedTask.description }}
            </template>
            <template slot="input">
              <v-textarea
                :scope="scope"
                state-key="task"
                field="task_description"
                label=""
                :validation="null"
              />
            </template>
          </VEditInput>
        </div>
      </div>
      <div class="row h-mb-1 h-mt-2 align-items-center">
        <div class="col-3 text-right c-task-details-label"> Account </div>
        <div class="col-9 c-task-details-value">
          {{ getLeadName }}
          <div
            class="c-button -admin-small ml-5"
            @click="viewAccount(getSelectedTask.account.uuid)"
          >
            View Account
          </div>
        </div>
      </div>
      <div class="row h-mb-1">
        <div class="col-3 text-right c-task-details-label">Due Date </div>
        <div class="col-6 c-task-details-value">
          <VEditInput :table-field="true" :edit="edit">
            <template slot="static">
              {{ dateTimeFormat(getSelectedTask.due_date) }}
            </template>
            <template slot="input">
              <Datetime
                key="1"
                v-model="due_date"
                type="datetime"
                format="dd-MM-yyyy HH:mm"
                :min-datetime="currentDate"
                style=" width: 175px;max-width: unset;"
                value-zone="Europe/London"
              />
              <div class="vdatetime-clear" @click="handleClear('due_date')">
                <svg
                  aria-hidden="true"
                  data-prefix="fas"
                  data-icon="times"
                  class="svg-inline--fa fa-times fa-w-11"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                >
                  <path
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  />
                </svg>
              </div>
              <v-input-hidden
                ref="input"
                :scope="scope"
                state-key="task"
                error-name="due date"
                field="due_date"
                validation="required"
              />
            </template>
          </VEditInput>
        </div>
      </div>
      <div class="row h-mb-1">
        <div class="col-3 text-right c-task-details-label">Reminder Date</div>
        <div class="col-6 c-task-details-value">
          <VEditInput :table-field="true" :edit="edit">
            <template slot="static">
              {{ reminderDate }}
            </template>
            <template slot="input">
              <Datetime
                key="1"
                v-model="reminder_date"
                type="datetime"
                format="dd-MM-yyyy HH:mm"
                :min-datetime="currentDate"
                style=" width: 175px;max-width: unset;"
                value-zone="Europe/London"
              />
              <div
                class="vdatetime-clear"
                @click="handleClear('reminder_date')"
              >
                <svg
                  aria-hidden="true"
                  data-prefix="fas"
                  data-icon="times"
                  class="svg-inline--fa fa-times fa-w-11"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                >
                  <path
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  />
                </svg>
              </div>
              <v-input-hidden
                ref="input"
                :scope="scope"
                state-key="task"
                error-name="due date"
                field="reminder_date"
                :validation="null"
              />
            </template>
          </VEditInput>
        </div>
      </div>
      <div class="row h-mb-1 align-items-center">
        <div class="col-3 text-right c-task-details-label"> Priority </div>
        <div class="col-6 c-task-details-value">
          <VEditInput :table-field="true" :edit="edit">
            <template slot="static">
              <VTaskPill :priority="getSelectedTask.priority" />
            </template>
            <template slot="input">
              <v-select-input
                :options="getPriorityListAsArray"
                :scope="scope"
                field="task_priority"
                placeholder=""
                state-key="task"
                error-name="Priority"
              />
            </template>
          </VEditInput>
        </div>
      </div>
      <div class="row h-mb-1 align-items-center">
        <div class="col-3 text-right c-task-details-label"> Status </div>
        <div class="col-6 c-task-details-value">
          <VEditInput :table-field="true" :edit="edit">
            <template slot="static">
              {{ getSelectedTask.status }}
            </template>
            <template slot="input">
              <v-select-input
                :options="getStatusListAsArray"
                :scope="scope"
                field="task_status"
                placeholder=""
                state-key="task"
                error-name="Priority"
              />
            </template>
          </VEditInput>
        </div>
      </div>
      <div class="row h-mb-1 align-items-center">
        <div class="col-3 text-right c-task-details-label"> Task Owner </div>
        <div class="col-6 c-task-details-value">
          <VEditInput :table-field="true" :edit="edit">
            <template slot="static">
              {{ getOwnerName }}
            </template>
            <template slot="input">
              <v-select-input
                :options="ownerData"
                :scope="scope"
                state-key="task"
                field="task_owner"
                validation="required"
                error-name="task owner"
                placeholder="Assign to"
              />
            </template>
          </VEditInput>
        </div>
      </div>
    </form>
    <div class="row h-mt-2">
      <div class="col-12">
        <VInfoExpander
          open-text="hide system information"
          close-text="show system information"
        >
          <div class="row h-mb-1">
            <div class="col-3 c-task-details-label"> Created at </div>
            <div class="col-6 c-task-details-value">
              {{ dateTimeFormat(getSelectedTask.created_at) }}
            </div>
          </div>
          <div class="row h-mb-1">
            <div class="col-3 c-task-details-label"> Last Modified at </div>
            <div class="col-6 c-task-details-value">
              {{ dateTimeFormat(getSelectedTask.last_updated_at) }}
            </div>
          </div>
        </VInfoExpander>
      </div>
    </div>
    <div class="row h-mt-2">
      <div class="col-12 c-task-details-label">
        <VInfoExpander open-text="hide&nbsp;notes" close-text="show&nbsp;notes">
          <VNotesComments :notes-data="getTaskNotes" />
        </VInfoExpander>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { Datetime } from "vue-datetime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import VInfoExpander from "js-admin/components/layout/expander/InfoExpander.vue";
import VNotesComments from "js-admin/components/notes/NoteComments.vue";
import VTaskPill from "js-admin/components/tasks/TaskPill.vue";
import VEditInput from "js-admin/components/form/EditInput.vue";
dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  inject: ["$validator"],
  components: {
    VInfoExpander,
    VNotesComments,
    VTaskPill,
    VEditInput,
    Datetime,
  },
  data() {
    return {
      scope: "task",
      edit: false,
    };
  },
  computed: {
    reminderDate() {
      return this.dateTimeFormat(this.getSelectedTask.reminder_date) !==
        "Invalid Date"
        ? this.dateTimeFormat(this.getSelectedTask.reminder_date)
        : null;
    },
    ownerData() {
      return this.$store.state.accountManagers;
    },
    getOwnerName() {
      if (this.getSelectedTask.owner) {
        return this.getSelectedTask.owner.name;
      }
      return null;
    },
    getLeadName() {
      if (typeof this.getSelectedTask.account !== "undefined") {
        return this.getSelectedTask.account.full_name;
      }
      return null;
    },
    getTaskNotes() {
      if (typeof this.getSelectedTask.notes !== "undefined") {
        return this.getSelectedTask.notes;
      }
      return null;
    },
    due_date: {
      get() {
        return this.$store.state.fieldStore.task.due_date;
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: "task",
          field: "due_date",
        });
      },
    },
    reminder_date: {
      get() {
        return this.$store.state.fieldStore.task.reminder_date;
      },
      set(val) {
        this.$store.dispatch("updateStoreItem", {
          value: val,
          stateKey: "task",
          field: "reminder_date",
        });
      },
    },
    currentDate() {
      return dayjs(new Date()).format("YYYY-MM-DD");
    },
  },
  mounted() {
    if (!this.checkPermission("view_tasks")) {
      return this.$router.push({
        name: "home",
      });
    }
  },
  methods: {
    handleClear(date) {
      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: "task",
        field: date,
      });
    },
    checkhasReminded(rd) {
      return dayjs(rd).isBefore(dayjs());
    },
    handleEdit() {
      if (this.edit == false) {
        this.edit = true;
      } else {
        this.$validator.validate(`${this.scope}.*`).then((result) => {
          if (result) {
            const params = {
              uuid: this.$store.state.taskState.selectedTask.uuid,
              title: this.$store.state.fieldStore.task.task_name,
              description: this.$store.state.fieldStore.task.task_description,
              priority: this.$store.state.fieldStore.task.task_priority.toLowerCase(),
              status: this.prepareTaskStatus(
                this.$store.state.fieldStore.task.task_status
              ),
              owner_uuid: this.$store.state.fieldStore.task.task_owner,
              due_date: this.$store.state.fieldStore.task.due_date,
              has_reminded: this.checkhasReminded(
                this.$store.state.fieldStore.task.reminder_date
              ),
            };

            if (
              !this.isEmptyValue(
                this.$store.state.fieldStore.task.reminder_date
              )
            ) {
              params.reminder_date = this.$store.state.fieldStore.task.reminder_date;
            }

            this.$store.dispatch("updateTask", params).then(() => {
              this.edit = !this.edit;
            });
          }
        });
      }
    },
    viewAccount(uuid) {
      this.$router.push({
        name: "account",
        params: {
          uuid,
        },
      });
      return false;
    },
  },
};
</script>
