<template>
  <div class="v-accounts">
    <section v-if="instrumentsReady" class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title mb-5">
            Secondary Market Instruments
          </h1>
        </div>
      </div>

      <div class="row align-items-end">
        <div v-if="checkPermission('view_issuer')" class="col-3">
          <v-select-input
            class="h-mb-1 mr-3"
            :state-key="searchStateKey"
            :options="instrumentTypes"
            :center-error="false"
            field="type_filter"
            label="Filter by Type:"
            @selectionUpdated="filterListing"
          />
          <a
            v-if="type_filter !== 'all'"
            :href="false"
            class="c-button -admin-small mb-2"
            @click="resetTypeFilter"
          >
            Reset Type
          </a>
        </div>
        <div v-if="checkPermission('view_issuer')" class="col-3">
          <v-select-input
            class="h-mb-1 mr-3"
            :state-key="searchStateKey"
            :options="instrumentStatuses"
            :center-error="false"
            field="status_filter"
            label="Filter by Status:"
            @selectionUpdated="filterListing"
          />
          <a
            v-if="status_filter !== 'all'"
            :href="false"
            class="c-button -admin-small mb-2"
            @click="resetStatusFilter"
          >
            Reset Status
          </a>
        </div>
        <div v-if="checkPermission('view_issuer')" class="col-4">
          <div class="mb-3">
            Search: <br />
            Please enter instrument symbol or name.
          </div>
          <form :data-vv-scope="searchScope" @submit.prevent="search">
            <v-text-input
              :scope="searchScope"
              :state-key="searchStateKey"
              field="searchTerm"
              label="Search"
              validation="required"
              error-name="search term"
              class="mr-3 h-mb-1"
            />
            <v-button
              class="c-button -admin-small mb-2"
              :loading="searchInProgress"
              :disabled="searchInProgress"
            >
              Search
            </v-button>

            <a
              v-if="searchPerformed"
              :href="false"
              class="c-button -admin-small mb-2 ml-1"
              @click="clearSearch"
            >
              Clear Search
            </a>
          </form>
        </div>
        <div v-if="checkPermission('view_issuer')" class="col-2">
          <a
            :href="false"
            class="c-button -admin-small h-mb-1"
            @click="viewIssuers"
          >
            View Secondary <br />
            Market Issuers
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="c-admin-table mt-4">
            <div class="c-admin-table-header">
              <div class="row">
                <div class="col-2">
                  Name
                </div>
                <div class="col-2">
                  Ticker
                </div>
                <div class="col-2">
                  Type
                </div>
                <div class="col-2">
                  Issuer
                </div>
                <div class="col-2">
                  Details
                </div>
                <div class="col-2">
                  &nbsp;
                </div>
              </div>
            </div>

            <div v-if="instrumentCount">
              <div
                v-for="(instrument, index) in displayedInstruments"
                :key="index"
                class="c-admin-table-row"
              >
                <div class="row">
                  <div class="col-2">
                    {{ instrument.name }}
                    <br />
                    <small>
                      Imported on: <br />
                      {{ dateTimeFormat(instrument.created_at, "DD/MM/YYYY") }}
                    </small>
                  </div>
                  <div class="col-2">
                    {{ instrument.ticker }}
                  </div>
                  <div class="col-2">
                    <div v-if="instrument.type">
                      {{ stripUnderScoresAndCapitalise(instrument.type) }}
                    </div>
                  </div>
                  <div class="col-2">
                    <div v-if="instrument.issuer_name">
                      {{ instrument.issuer_name }}
                    </div>
                    <div v-else>
                      N.A.
                    </div>
                  </div>
                  <div class="col-2">
                    <div v-if="instrument.status">
                      Status:
                      <strong>
                        {{ capitaliseFirstLetter(instrument.status) }}
                      </strong>
                    </div>
                    <div v-if="instrument.currency">
                      Base Currency:
                      <strong> {{ instrument.currency.code }} </strong>
                    </div>
                    <div v-if="instrument.trade_currency">
                      Trade Currency:
                      <strong> {{ instrument.trade_currency.code }} </strong>
                    </div>
                    <div v-if="instrument.source">
                      Source: <strong> {{ instrument.source }} </strong>
                    </div>
                    <div
                      v-if="instrument.cancelled_on"
                      class="c-admin-alert-text -error"
                    >
                      Instrument has been cancelled.
                    </div>
                    <div
                      v-else-if="instrument.is_paused"
                      class="c-admin-alert-text -error"
                    >
                      Instrument has been paused.
                    </div>
                  </div>
                  <div class="col-2 text-center">
                    <a
                      :href="false"
                      class="c-button -admin-small"
                      @click="viewInstrument(instrument.uuid)"
                    >
                      View
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  No instruments found
                </div>
              </div>
            </div>

            <div class="c-admin-table-footer">
              <div class="row">
                <div class="col-12">
                  <v-pagination
                    v-model="page"
                    :page-count="pages.length"
                    :classes="bootstrapPaginationClasses"
                    :labels="paginationAnchorTexts"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import vPagination from "vue-plain-pagination";
import InstrumentService from "js-admin/classes/AjaxServices/InstrumentService";

export default {
  components: {
    vPagination,
  },

  data() {
    return {
      instruments: null,
      page: 1,
      perPage: 10,
      pages: [],
      bootstrapPaginationClasses: {
        ul: "c-admin-paginator -margin-top",
        li: "c-admin-paginator-item",
        liActive: "-active",
        liDisable: "-disabled",
        button: "c-admin-paginator-item-button",
      },
      paginationAnchorTexts: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      searchScope: "searchForm",
      searchStateKey: "instrumentsList",
      searchInProgress: false,
      searchPerformed: false,
      instrumentCount: null,
      instrumentsReady: false,
    };
  },
  computed: {
    searchTerm() {
      return this.$store.state.fieldStore[this.searchStateKey].searchTerm;
    },
    type_filter() {
      return this.$store.state.fieldStore[this.searchStateKey].type_filter;
    },
    status_filter() {
      return this.$store.state.fieldStore[this.searchStateKey].status_filter;
    },
    displayedInstruments() {
      return this.instruments;
    },
    instrumentStatuses() {
      const statuses = this.$store.state.config
        .secondary_market_instrument_statuses;
      const instrumentTypes = [];

      instrumentTypes.push({
        value: "all",
        name: "All",
      });

      Object.keys(statuses).forEach((key) => {
        instrumentTypes.push({
          value: key,
          name: statuses[key],
        });
      });

      return instrumentTypes;
    },
    instrumentTypes() {
      const types = this.$store.state.config.secondary_market_instrument_types;
      const issuerTypes = [];

      issuerTypes.push({
        value: "all",
        name: "All",
      });

      Object.keys(types).forEach((key) => {
        issuerTypes.push({
          value: key,
          name: types[key],
        });
      });

      return issuerTypes;
    },
  },

  watch: {
    page() {
      this.paginate();
    },

    instruments() {
      this.instrumentsReady = true;
    },

    instrumentCount() {
      this.setPages();
    },
  },

  mounted() {
    if (!this.checkPermission("view_issuer")) {
      return this.$router.push({ name: "home" });
    }

    this.clearSearch();
  },

  methods: {
    viewInstrument(uuid) {
      this.$router.push({ name: "instrument", params: { uuid } });
      return false;
    },

    viewIssuers() {
      this.$router.push({ name: "issuers" });
      return false;
    },

    setPages() {
      this.pages = [];
      const numberOfPages = Math.ceil(this.instrumentCount / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
      if (this.page > numberOfPages) {
        this.page = 1;
      }
    },

    loadData() {
      this.page = 1;
      this.paginate();
    },

    paginate() {
      const paginateParams = {
        start: this.page * this.perPage - this.perPage,
        limit: this.perPage,
      };

      const params = {
        source: "asmx",
      };

      if (this.searchTerm) {
        params.term = this.searchTerm;
      }
      if (this.type_filter !== "all") {
        params.type = this.type_filter;
      }
      if (this.status_filter !== "all") {
        params.status = this.status_filter;
      }

      InstrumentService.getInstruments({
        count: true,
        ...params,
      }).then((response) => {
        if (response.data) {
          this.instrumentCount = response.data.data.instruments_count;
        }
      });

      InstrumentService.getInstruments({
        ...paginateParams,
        ...params,
      }).then((response) => {
        if (response.data) {
          this.instruments = response.data.data;
        }
      });
    },

    filterListing() {
      this.$nextTick(() => {
        this.loadData();
      });
    },

    search() {
      this.$validator.validateAll(this.searchScope).then(async (result) => {
        this.searchPerformed = true;
        if (result) {
          this.$nextTick(() => {
            this.loadData();
          });
        }
      });
    },

    resetTypeFilter() {
      this.$store.dispatch("updateStoreItem", {
        field: "type_filter",
        stateKey: this.searchStateKey,
        value: "all",
      });

      this.$nextTick(() => {
        this.loadData();
      });
    },
    resetStatusFilter() {
      this.$store.dispatch("updateStoreItem", {
        field: "status_filter",
        stateKey: this.searchStateKey,
        value: "all",
      });

      this.$nextTick(() => {
        this.loadData();
      });
    },
    clearSearch() {
      this.searchPerformed = false;

      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.searchStateKey,
        field: "searchTerm",
      });

      this.clearErrors(1);

      this.$nextTick(() => {
        this.loadData();
      });
    },
  },
};
</script>
