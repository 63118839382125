<template>
  <div>
    <div class="row align-items-center">
      <div class="col-12">
        <div v-if="!showEditForm" class="row align-items-center">
          <div class="col-2">
            {{ company.name }}
          </div>
          <div class="col-2 text-break">
            {{ company.registration_number }}
          </div>
          <div class="col-2 text-break">
            {{ company.legal_entity_identifier }}
          </div>
          <div class="col-2">
            {{ company.country_of_incorporation }}
          </div>
          <div class="col-2">
            {{ company.nature_of_business }}
          </div>
          <div v-if="checkPermission('edit_account')" class="col-2">
            <a :href="false" class="c-button -admin-small" @click="showForm">
              Edit
            </a>
          </div>
          <div v-if="company.signatures" class="col-2">
            <a
              :href="company.signatures"
              target="_blank"
              rel="noopener"
              class="c-button -admin-small mt-3"
            >
              View Signatures
            </a>
          </div>
          <div v-if="company.pdf_application" class="col-2">
            <a
              :href="company.pdf_application"
              target="_blank"
              rel="noopener"
              class="c-button -admin-small mt-3"
            >
              View Applciation
            </a>
          </div>
        </div>
        <div v-else>
          <form :data-vv-scope="scope" @submit.prevent="updateCompany(index)">
            <div class="row">
              <div class="col-5">
                <div>
                  <v-text-input
                    :scope="scope"
                    :state-key="stateKey"
                    field="name"
                    label="Company Name"
                    validation="required"
                  />
                </div>

                <div>
                  <v-text-input
                    :scope="scope"
                    :state-key="stateKey"
                    field="country_of_incorporation"
                    label="Country of Incorporation"
                    validation="required"
                  />
                </div>

                <div>
                  <v-textarea
                    :scope="scope"
                    :state-key="stateKey"
                    field="nature_of_business"
                    label="Nature of Business"
                    validation="required"
                  />
                </div>

                <div v-if="!applicationDoc">
                  Application Form:
                  <VFileInput
                    :scope="scope"
                    :state-key="stateKey"
                    field="pdf_application"
                    validation=""
                  />
                </div>
              </div>

              <div class="col-5">
                <div>
                  <v-text-input
                    :scope="scope"
                    :state-key="stateKey"
                    field="registration_number"
                    label="Registration Number"
                    validation="required"
                  />
                </div>

                <div>
                  <v-text-input
                    :scope="scope"
                    :state-key="stateKey"
                    field="legal_entity_identifier"
                    label="LEI"
                    validation="required"
                  />
                </div>

                <div v-if="!signaturesDoc">
                  Signatures:
                  <VFileInput
                    :scope="scope"
                    :state-key="stateKey"
                    field="signatures"
                    validation=""
                  />
                </div>

                <br />
                <v-button
                  class="-admin-small"
                  :loading="updateInProgress"
                  :disabled="updateInProgress"
                >
                  Update
                </v-button>
              </div>
              <div class="col-2">
                <a
                  :href="false"
                  class="c-button -admin-small"
                  @click="closeForm"
                >
                  Close
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessageService from "js-admin/classes/FlashMessageService";
import VFileInput from "js-admin/components/form/FileInput.vue";

export default {
  components: {
    VFileInput,
  },
  props: {
    companyData: {
      required: true,
    },
    index: {
      required: true,
    },
  },

  data() {
    return {
      scope: "updateCompanyForm",
      stateKey: "updateCompany",
      stateKey2: "accountData",
      updateInProgress: false,
      showEditForm: false,
      InProgress: false,
      signaturesDocument: false,
      applicationDocument: false,
      company: this.companyData,
    };
  },

  computed: {
    accountUuid() {
      return this.$store.state.fieldStore[this.stateKey2].uuid;
    },
    signaturesDoc() {
      return this.signaturesDocument;
    },
    applicationDoc() {
      return this.applicationDocument;
    },
  },

  methods: {
    showForm() {
      this.$store.dispatch("updateStoreItem", {
        value: this.company,
        stateKey: this.stateKey,
        field: null,
      });

      if (this.company.signatures) {
        this.signaturesDocument = true;
      }

      if (this.company.pdf_application) {
        this.applicationDocument = true;
      }

      this.$parent.toggle(this);
    },

    closeForm() {
      this.showEditForm = false;
      this.signaturesDocument = false;
      this.applicationDocument = false;

      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.stateKey,
        field: null,
      });
    },

    updateCompany(index) {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          const formData = new FormData();

          formData.append("_method", "put");
          formData.append(
            "name",
            this.$store.state.fieldStore[this.stateKey].name
          );
          formData.append(
            "registration_number",
            this.$store.state.fieldStore[this.stateKey].registration_number
          );
          formData.append(
            "legal_entity_identifier",
            this.$store.state.fieldStore[this.stateKey].legal_entity_identifier
          );
          formData.append(
            "country_of_incorporation",
            this.$store.state.fieldStore[this.stateKey].country_of_incorporation
          );
          formData.append(
            "nature_of_business",
            this.$store.state.fieldStore[this.stateKey].nature_of_business
          );
          formData.append(
            "uuid",
            this.$store.state.fieldStore[this.stateKey].uuid
          );

          if (!this.signaturesDoc) {
            formData.append(
              "signatures_file",
              this.$store.state.fieldStore[this.stateKey].signatures
            );
          }

          if (!this.applicationDoc) {
            formData.append(
              "application_file",
              this.$store.state.fieldStore[this.stateKey].pdf_application
            );
          }

          this.updateInProgress = true;
          this.$store
            .dispatch("updateCompany", formData)
            .then((response) => {
              this.updateInProgress = false;
              if (response.data) {
                FlashMessageService.success("Company has been updated.");
                this.$store.dispatch("updateArrayItemInStore", {
                  value: response.data,
                  stateKey: this.stateKey2,
                  field: "companies",
                  index: index,
                });
                this.company = response.data;
                this.showEditForm = false;
              }
            })
            .catch((error) => {
              this.updateInProgress = false;
              FlashMessageService.error(error.response.data.message);
            });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },
  },
};
</script>
