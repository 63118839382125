<template>
  <div :class="['c-info-expander']">
    <div class="row">
      <div
        class="col-auto c-info-expander-button -pointer"
        @click.prevent="handleExpand"
      >
        <h3 v-if="open" v-html="openText" />
        <h3 v-else v-html="closeText" />
      </div>
      <div
        :class="['col-auto h-p-0 -pointer', rotateSvg]"
        @click="handleExpand"
      >
        <i class="fas fa-chevron-down" />
      </div>
      <div class="col-12 h-mt-1">
        <div :class="['c-info-expander-content', expandElement]">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    openText: {
      default: "hide&nbsp;details",
    },
    closeText: {
      default: "show&nbsp;details",
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    expandElement() {
      return {
        "expand-element": this.open == true,
      };
    },
    rotateSvg() {
      return {
        "rotate-svg": this.open == true,
      };
    },
  },
  methods: {
    handleExpand() {
      this.open = !this.open;
    },
  },
};
</script>
