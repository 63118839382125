export default {
  getSearchResult: (state) => state.fieldStore.search.searchResult,
  getMergeAccountArray: (state) => {
    if (state.fieldStore.mergeAccount.accountArray != null) {
      return state.fieldStore.mergeAccount.accountArray;
    }
    return null;
  },
  getMergeHeaders: (state) => state.fieldStore.mergeAccount.headerArray,
};
