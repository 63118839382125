<template lang="html">
  <div class="c-available-agents">
    <h2 class="c-admin-page-title">Available Agents</h2>
    <div class="row">
      <div class="col-12">
        <div class="c-admin-table">
          <div class="c-admin-table-header">
            <div class="row">
              <div class="col-3">
                Name
              </div>
              <div class="col-6">
                Email
              </div>
              <div class="col-3">
                Status
              </div>
            </div>
          </div>

          <div v-if="getAgents.length">
            <div
              v-for="(agent, index) in getAgents"
              :key="index"
              class="c-admin-table-row"
            >
              <div class="row">
                <div class="col-3 text-break">
                  {{ agent.name }}
                </div>
                <div class="col-6 text-break">
                  {{ agent.email }}
                </div>
                <div class="col-3">
                  {{ agent.status }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="c-admin-table-row">
            No agents
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getAgents"]),
  },
};
</script>
