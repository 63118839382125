<template>
  <div class="c-2fsms">
    <h4 class="c-admin-panel-title mb-4">
      Enable SMS Two-Factor
    </h4>

    <div v-if="!twoFactorDisplayNumber">
      <p>
        <strong>
          Please first add your mobile number
        </strong>
      </p>
    </div>

    <div v-else>
      <p>
        We'll send your access code to the following number:
        <strong v-text="twoFactorDisplayNumber"></strong>
      </p>

      <v-button
        class="h-mb-2"
        :class="{ '-secondary': sentSms }"
        :disabled="sentRecently"
        @click.native="sendSms"
        v-text="sendText"
      />

      <form
        v-if="sentSms"
        ref="validation"
        :data-vv-scope="scope"
        @submit.prevent="validateCode"
      >
        <div>
          <h2 class="type-heading-body">Enter access code below.</h2>
          <p>Enter your 6-digit access code.</p>
          <div>
            <v-text-input
              :ref="field"
              :scope="scope"
              :state-key="stateKey"
              label="SMS Code"
              :maxlength="6"
              error-name="sms code"
              :field="field"
              validation="required|numeric|min:6"
              autofocus
            />
            <div
              v-if="smsError"
              class="c-input-error -standalone text-right"
              v-text="smsError"
            />
            <v-button :loading="working" class="-admin-small">
              Submit
            </v-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import TwoFactorService from "js-admin/classes/AjaxServices/TwoFactorService";
import UserService from "js-admin/classes/AjaxServices/UserService";
export default {
  name: "VTwoFactorSmsSetup",
  data() {
    return {
      scope: "two-factor-sms",
      stateKey: "validateTwoFactor",

      smsError: null,
      sentSms: false,
      working: false,
      field: "sms_security_code",
      sentRecently: false,
    };
  },
  computed: {
    twoFactorDisplayNumber() {
      if (this.$store.state.user.mobile_number) {
        return (
          "XXXXX XXX " +
          this.$store.state.user.mobile_number.slice(
            this.$store.state.user.mobile_number.length - 3
          )
        );
      }
      return null;
    },

    sendText() {
      if (this.sentSms) {
        return this.sentRecently ? "Sending" : "Resend SMS";
      }
      return "Send SMS";
    },
    twoFactorSmsCode() {
      return this.$store.state.fieldStore[this.stateKey][this.field];
    },
  },
  mounted() {
    this.updateSMScodeStore();
  },

  methods: {
    updateSMScodeStore() {
      this.$store.dispatch("updateStoreItem", {
        value: null,
        stateKey: this.stateKey,
        field: this.field,
      });
    },

    refreshUser() {
      const loggedIn = this.$store.state.me;
      UserService.getUserInfo(loggedIn).then((response) => {
        const user = response.data.data;
        this.$store.commit("setUser", user);
      });
    },

    restrictButton() {
      this.sentRecently = true;
      setTimeout(() => {
        this.sentRecently = false;
      }, 3000);
    },

    sendSms() {
      this.restrictButton();
      TwoFactorService.dashboardGenerateSmsTwoFactor().then(
        () => {
          this.sentSms = true;
        },
        (response) => {
          this.smsError = response.message;
        }
      );
    },
    validateCode() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          TwoFactorService.dashboardValidateSmsTwoFactor({
            sms_security_code: this.twoFactorSmsCode,
          })
            .then(() => {
              this.refreshUser();
            })
            .catch((err) => {
              this.smsError = err.response.data.message;
            });
        }
      });
    },
  },
};
</script>
