<template>
  <div>
    <div class="c-admin-panel mt-2">
      <form :data-vv-scope="updateFormScope" @submit.prevent="updateIssuer">
        <div class="c-admin-panel-section">
          <div class="row">
            <div class="col-12 col-md-5">
              <h3 class="c-admin-panel-title">
                Primary Contact
              </h3>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="contactStateKey"
                  field="title"
                  label="Title"
                  validation=""
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="contactStateKey"
                  field="first_name"
                  label="First Name"
                  validation="required"
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="contactStateKey"
                  field="last_name"
                  label="Last Name"
                  validation="required"
                />
              </div>
            </div>
            <div class="col-12 col-md-7">
              <h3 class="c-admin-panel-title">
                Contact Details
              </h3>

              <div class="row">
                <div class="col-12">
                  <v-phone-number-text-input
                    :scope="updateFormScope"
                    :state-key="contactStateKey"
                    field="primary_contact_number"
                    type="tel"
                    label="Primary Contact Number"
                    error-name="primary contact number"
                    autocomplete="tel"
                    :maxlength="13"
                    validation="required|numeric"
                  />
                </div>
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="contactStateKey"
                  field="email"
                  label="Email address"
                  validation="email"
                  error-name="email address"
                />
              </div>
            </div>
          </div>

          <h3 class="c-admin-panel-title">
            Registered Address
          </h3>
          <div class="row">
            <div class="col-12 col-md-5">
              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="building_name"
                  label="Building Name"
                  error-name="building name"
                  validation=""
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="building_number"
                  label="Building Number"
                  error-name="building name"
                  validation=""
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="street_name"
                  label="Street Name"
                  validation=""
                  error-name="street name"
                />
              </div>
            </div>
            <div class="col-12 col-md-7">
              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="town"
                  label="Town"
                  validation=""
                  error-name="town"
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="county"
                  label="County"
                  validation=""
                  error-name="county"
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="postcode"
                  label="Postcode"
                  validation=""
                  error-name="postcode"
                />
              </div>

              <div>
                <v-combo-box
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  :options="countries"
                  :focus-on-mounted="false"
                  class="-no-left-padding"
                  field="country"
                  label="Country"
                  error-name="country"
                />
              </div>
            </div>
          </div>

          <h3 class="c-admin-panel-title">
            Company Details
          </h3>
          <div class="row">
            <div class="col-12 col-md-5">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="originator"
                label="Originator"
                validation=""
                error-name="originator"
              />
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div class="row">
            <div class="col-12 col-md-5">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="name"
                label="Company Name"
                validation="required"
                error-name="company name"
              />
            </div>
            <div class="col-12 col-md-7">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="company_number"
                label="Company Number"
                validation="required"
                error-name="company number"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-5">
              <v-combo-box
                :scope="updateFormScope"
                :state-key="stateKey"
                :options="countries"
                :focus-on-mounted="false"
                class="-no-left-padding"
                field="country_incorporation"
                label="Country of Incorporation"
                error-name="country of incorporation"
                validation="required"
              />
            </div>
            <div class="col-12 col-md-7">
              <v-combo-box
                :scope="updateFormScope"
                :state-key="stateKey"
                :options="countries"
                :focus-on-mounted="false"
                class="-no-left-padding"
                field="company_jurisdiction"
                label="Company Jurisdiction"
                error-name="company jurisdiction"
                validation="required"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-5">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="company_type"
                label="Company Type"
                validation="required"
                error-name="company type"
              />
            </div>
            <div class="col-12 col-md-7">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="company_logo"
                label="Company Logo"
                validation="required"
                error-name="company logo"
              />
              <a
                v-if="companyLogo"
                :href="companyLogo"
                class="c-button -admin-small ml-2 mb-3"
                target="_blank"
                rel="noopener"
              >
                View
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-5">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="sector"
                label="Company Sector"
                validation="required"
                error-name="company sector"
              />
            </div>
            <div class="col-12 col-md-7">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="financial_year_end"
                label="Financial Year End"
                validation="required"
                error-name="financial year end"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-5">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="corporate_adviser"
                label="Corporate Adviser"
                validation="required"
                error-name="corporate adviser"
              />
            </div>
            <div class="col-12 col-md-7">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="auditors"
                label="Auditors"
                validation="required"
                error-name="auditors"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-5">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="website"
                label="Website"
                validation=""
                error-name="website"
              />
              <a
                v-if="companyWebsite"
                :href="companyWebsite"
                class="c-button -admin-small ml-2 mb-3"
                target="_blank"
                rel="noopener"
              >
                View
              </a>
            </div>
            <div class="col-12 col-md-7">
              <div>
                Brand Colour
                <color-picker v-model="colors"></color-picker>
              </div>
            </div>
          </div>
          <!--            <div class="row">-->
          <!--                <div class="col-12 col-md-5">-->
          <!--                    <div class="row m-0 align-items-center">-->
          <!--                        <div class="col-5">-->
          <!--                            Active-->
          <!--                        </div>-->
          <!--                        <div class="col-6">-->
          <!--                            <div class="c-notifications-switches">-->
          <!--                                <div-->
          <!--                                        class="c-notifications-switches-item justify-content-end"-->
          <!--                                >-->
          <!--                                    <v-switch-->
          <!--                                            field="active"-->
          <!--                                            :state-key="stateKey"-->
          <!--                                            class="d-inline-block"-->
          <!--                                    />-->
          <!--                                </div>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                </div>-->
          <!--                <div class="col-12 col-md-7" />-->
          <!--            </div>-->

          <h3 class="c-admin-panel-title">
            Social Media Details
          </h3>
          <div class="row">
            <div class="col-12 col-md-5">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="linkedin"
                label="Linkedin"
                validation=""
                error-name="linkedin"
              />
            </div>
            <div class="col-12 col-md-7">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="twitter"
                label="Twitter"
                validation=""
                error-name="twitter"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-5">
              <v-text-input
                :scope="updateFormScope"
                :state-key="stateKey"
                field="instagram"
                label="Instagram"
                validation=""
                error-name="instagram"
              />
            </div>
            <div class="col-12 col-md-7" />
          </div>

          <div class="row">
            <div class="col-12 text-center">
              <v-button
                v-if="checkPermission('update_issuer')"
                class="mt-5"
                :loading="submittingIssuerUpdate"
                :disabled="submittingIssuerUpdate"
              >
                Update
              </v-button>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-5">
              <h3 class="c-admin-panel-title">
                Logo
              </h3>
              <div>
                <VDocumentsForm
                  v-if="checkPermission('upload_files')"
                  :issuer-uuid="issuerUuid"
                  :state-key="stateKey"
                  label="+ Add Logo"
                  title="Add New Logo"
                  submit-label="Upload File"
                  :file-type-options="fileTypeOptions"
                  @addDocument="addDocument"
                />
              </div>

              <v-button
                v-if="logo"
                class="c-button -admin-small"
                type="button"
                @click.native="previewLogo"
              >
                {{ logoPreviewButtonText }}
              </v-button>
              <div v-if="logoPreview">
                <img v-if="logo" :src="logo" :alt="logo.name" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Chrome } from "vue-color";
import { formatObjectForSelect } from "js-admin/abstract/helpers.js";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import IssuerService from "js-admin/classes/AjaxServices/IssuerService";
import VDocumentsForm from "js-admin/components/issuers/issuer-documents/DocumentsForm.vue";

export default {
  components: {
    "color-picker": Chrome,
    VDocumentsForm,
  },
  data() {
    return {
      updateFormScope: "issuerData",
      stateKey: "issuerData",
      contactStateKey: "issuerContactData",
      submittingIssuerUpdate: false,
      callInProgress: false,
      uploadReady: true,
      colors: this.$store.state.fieldStore.issuerData.brand_colour || "#FFFFFF",
      logoPreview: false,
    };
  },

  computed: {
    logoPreviewButtonText() {
      return this.logoPreview ? "Close" : "Preview";
    },
    countries() {
      return this.$store.state.config.countries;
    },
    issuerUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
    logo() {
      return this.$store.state.fieldStore[this.stateKey].logo;
    },
    companyLogo() {
      return this.$store.state.fieldStore[this.stateKey].company_logo;
    },
    companyWebsite() {
      return this.$store.state.fieldStore[this.stateKey].website;
    },
    fileTypeOptions() {
      return formatObjectForSelect({ logo: "Logo" });
    },
  },

  methods: {
    previewLogo() {
      this.logoPreview = !this.logoPreview;
    },
    async modifyInstrumentIssuer() {
      const data = Object.fromEntries(
        Object.entries(this.$store.state.fieldStore[this.stateKey]).filter(
          ([_, value]) => value !== null && typeof value !== "object"
        )
      );

      if (this.colors.hex) {
        data.brand_colour = this.colors.hex;
      } else {
        data.brand_colour = this.colors;
      }

      IssuerService.updateIssuer(data)
        .then((response) => {
          FlashMessageService.success("Issuer has been updated");
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },

    async modifyIssuerContact() {
      const { uuid } = this.$store.state.fieldStore[this.contactStateKey];

      const contactData = {
        issuer_uuid: this.$store.state.fieldStore[this.stateKey].uuid,
        title: this.$store.state.fieldStore[this.contactStateKey].title,
        first_name: this.$store.state.fieldStore[this.contactStateKey]
          .first_name,
        last_name: this.$store.state.fieldStore[this.contactStateKey].last_name,
        email: this.$store.state.fieldStore[this.contactStateKey].email,
        primary_contact_number:
          this.$store.state.fieldStore[this.contactStateKey]
            .primary_contact_number_dial_code +
          this.$store.state.fieldStore[this.contactStateKey]
            .primary_contact_number,
        primary_contact: this.$store.state.fieldStore[this.contactStateKey]
          .primary_contact,
      };

      await IssuerService.updateIssuerContact(uuid, contactData)
        .then((response) => {
          FlashMessageService.success(
            "Issuer Primary Contact has been updated"
          );
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },

    updateIssuer() {
      this.$validator.validateAll(this.updateFormScope).then(async (result) => {
        if (result) {
          this.submittingIssuerUpdate = true;

          await this.modifyIssuerContact();

          await this.modifyInstrumentIssuer();

          this.submittingIssuerUpdate = false;
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },

    async addDocument(formData) {
      try {
        const response = await this.$store.dispatch("addIssuerFile", {
          formData,
          uuid: this.issuerUuid,
        });
        if (response.data) {
          FlashMessageService.success("Logo has been uploaded.");
          this.$parent.fetchIssuer();
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
        FlashMessageService.error(`
          ${error.response.data.message} <br/>
          ${error.response.data.errors?.file.join("<br/>")}
          `);
      }
    },
  },
};
</script>
